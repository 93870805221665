import { useSelector } from 'react-redux';
import React, { useMemo, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { ChakraProvider } from '@chakra-ui/react';
import CookieConsent from './CookieConsent';
import Routes from './Routes';
import colors from '../styles/colors';
import '../services/bridge';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// import '../styles/tooltip.scss';
import '../styles/tippy.css';
import generalSelectors from '../redux/selectors/generalSelectors';

const queryClient = new QueryClient();

const App = () => {
  const isMobile = useSelector(generalSelectors.isMobile);
  const isTablet = useSelector(generalSelectors.isTablet);

  const theme = useMemo(() => ({
    ...colors,
    isMobile,
    isTablet,
  }), [isMobile, isTablet]);

  // Temp fixes hot-reloading until react-scripts is v5+
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <BrowserRouter>
      {/* <ChakraProvider> */}
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Routes />
          <ToastContainer style={{ maxWidth: 535, width: '100%' }} />
          <CookieConsent isMobile={theme.isMobile} />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      {/* </ChakraProvider> */}
    </BrowserRouter>
  );
};

export default App;
