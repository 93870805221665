export const SET_1INCH_TOKENS = 'SET_1INCH_TOKENS';
export const GET_TOKENS_REQUEST = 'GET_TOKENS_REQUEST';
export const GET_TOKENS_SUCCESS = 'GET_TOKENS_SUCCESS';
export const GET_TOKENS_FAIL = 'GET_TOKENS_FAIL';
export const GET_BALANCES_REQUEST = 'GET_BALANCES_REQUEST';
export const GET_BALANCES_SUCCESS = 'GET_BALANCES_SUCCESS';
export const GET_BALANCES_FAIL = 'GET_BALANCES_FAIL';
export const ADD_TOKEN = 'ADD_TOKEN';

export const GET_TOKEN_TYPE_SUCCESS = 'GET_TOKEN_TYPE_SUCCESS';
export const GET_TOKEN_TYPE_FAIL = 'GET_TOKEN_TYPE_FAIL';

export const GET_TOKEN_ORIGIN_SUCCESS = 'GET_TOKEN_ORIGIN_SUCCESS';
export const GET_TOKEN_ORIGIN_FAIL = 'GET_TOKEN_ORIGIN_FAIL';

export const IS_TOKEN_CFB = 'IS_TOKEN_CFB';
