export const CONNECT_WALLET_START = 'CONNECT_WALLET_START';
export const CONNECT_WALLET_END = 'CONNECT_WALLET_END';

export const CONNECT_WALLET_PROVIDER = 'CONNECT_WALLET_PROVIDER';
export const CONNECT_WALLET_PROVIDER_SUCCESS =
  'CONNECT_WALLET_PROVIDER_SUCCESS';
export const CONNECT_WALLET_PROVIDER_FAILURE =
  'CONNECT_WALLET_PROVIDER_FAILURE';

export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SOFT_CLEAR_ACCOUNT = 'SOFT_CLEAR_ACCOUNT';

export const SET_WALLET_BALANCE = 'SET_WALLET_BALANCE';

export const SET_RECIPIENT_WALLET_ADDRESS = 'SET_RECIPIENT_WALLET_ADDRESS';

export const SET_CARDANO_ADDRESS = 'SET_CARDANO_ADDRESS';
export const SET_WALLET_TYPE = 'SET_WALLET_TYPE';
