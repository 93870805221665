import styled from 'styled-components/macro';
import colors from '../../../styles/colors';

const Typography = styled.div`
  font: normal normal normal 16px/19px Roboto;
  text-align: center;
  color: ${colors.white};
  text-transform: capitalize;

  &.head {
    font: normal normal bold 25px/29px Roboto;
  }

  &.aqua,
  &.aqua a {
    color: ${colors.aqua_bg};
  }

  &.weight-medium {
    font-weight: 500;
  }

  &.mb-15 {
    margin-bottom: 15px;
  }

  &.mb-30 {
    margin-bottom: 30px;
  }
`;

const Wrapper = styled.div`
  .modal-body .modal-body-content {
    max-width: 280px;
    margin: 0 auto;
  }

  .loader {
    margin-bottom: 18px;
  }

  button {
    min-width: 190px;
  }

  .result-icon {
    margin: 25px auto 20px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const S = { Typography, Wrapper };

export default S;
