import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as InvalidSVG } from '../../assets/invalid-icon.svg';
import S from './styled';

const Warning = ({ children, isMobile, strict = true, classes }) => (
  <S.ErrorInfo className={clsx(classes, { mobile: isMobile, strict, })}>
    <InvalidSVG />
    {children}
  </S.ErrorInfo>
);

Warning.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
  strict: PropTypes.bool,
  classes: PropTypes.string,
};

export default Warning;
