import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchSVG } from '../../assets/search.svg';
import TextInput from '../TextInput';
import S from './styled';
import CloseIcon from '../Icons/CloseIcon';
import COLORS from '../../styles/colors';

const rightIcon = (handleClearSearch) => <div onClick={handleClearSearch} className="close-icon"><CloseIcon size={10} color={COLORS.gray_light} strokeWidth={4} /></div>;

const SearchBar = ({ placeholderText, searchString, handleSearch, isBorder, autoFocus, isCloseIcon, handleClearSearch }) => (
  <S.Search isBorder={isBorder}>
    <TextInput
      className="dark"
      placeholder={placeholderText}
      value={searchString}
      onChange={handleSearch}
      Icon={<SearchSVG />}
      autoFocus={autoFocus}
      isCloseIcon={isCloseIcon}
      RightIcon={isCloseIcon && rightIcon(handleClearSearch)}

    />
  </S.Search>);

SearchBar.defaultProps = {
  isCloseIcon: false,
};

SearchBar.propTypes = {
  placeholderText: PropTypes.string,
  searchString: PropTypes.string,
  handleSearch: PropTypes.func,
  isBorder: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isCloseIcon: PropTypes.bool,
  handleClearSearch: PropTypes.func,

};

export default SearchBar;
