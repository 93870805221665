import arbitrum from '../assets/flatWhiteRpc/arbitrum.svg';
import aurora from '../assets/flatWhiteRpc/aurora.svg';
import avalanche from '../assets/flatWhiteRpc/avalanche.svg';
import bsc from '../assets/flatWhiteRpc/bsc.svg';
import ethereum from '../assets/flatWhiteRpc/ethereum.svg';
import fantom from '../assets/flatWhiteRpc/fantom.svg';
import fuse from '../assets/flatWhiteRpc/fuse.svg';
import heco from '../assets/flatWhiteRpc/heco.svg';
import moonriver from '../assets/flatWhiteRpc/moonriver.svg';
import okex from '../assets/flatWhiteRpc/okex.svg';
import polygon from '../assets/flatWhiteRpc/polygon.svg';
import dogechain from '../assets/flatWhiteRpc/dogechain.svg';
import milkomeda from '../assets/flatWhiteRpc/milkomedaWhite.svg';
import telos from '../assets/flatWhiteRpc/telosWhite.svg';
import optimism from '../assets/flatWhiteRpc/optimism.svg';

const srcFlatWhite = {
  arbitrum,
  aurora,
  avalanche,
  bsc,
  ethereum,
  fantom,
  fuse,
  heco,
  moonriver,
  okex,
  polygon,
  dogechain,
  milkomeda,
  telos,
  optimism
};

export default srcFlatWhite;
