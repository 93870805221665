const txHash = (state) => state.transactions.tx_hash;
const blockConfirmations = (state) => state.transactions.block_confirmations;
const processingLoader = (state) => state.processingLoader;
const intervalId = (state) => state.interval_id;
const portTx = (state) => state.portTx;
const redeemTx = (state) => state.redeemTx;
const isRedeemOnGoing = (state) => state.transactions.isRedeemOnGoing;
const portTimeoutId = (state) => state.transactions.portTimeoutId;
const currentTxNonce = (state) => state.transactions.currentTxNonce;
const currentTxBlock = (state) => state.transactions.currentTxBlock;
const portxFee = (state) => state.transactions.portxFee;
const cardanoDepositTx = (state) => state.transactions.cardanoDepositTx;
const cardanoBurnTx = (state) => state.transactions.cardanoBurnTx;

export default {
  txHash,
  blockConfirmations,
  processingLoader,
  portTx,
  intervalId,
  redeemTx,
  isRedeemOnGoing,
  portTimeoutId,
  currentTxNonce,
  currentTxBlock,
  portxFee,
  cardanoDepositTx,
  cardanoBurnTx
};
