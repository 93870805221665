import styled from 'styled-components/macro';
import colors from '../../../styles/colors';

const Wrapper = styled.div`
  .modal-body {
    max-width: 480px;
    /* padding: ${({ theme }) => theme.isMobile ? '0px 32px' : ''}; */
    margin: 0 auto;
    .modal-header {
      .modal-title {
        text-align: center !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
    .icon-close {
      display: ${(props) => props.isCloseEnable ? '' : 'none'};
    }
    .modal-body-content {
      padding: 0;
      margin-top: ${({ theme }) => theme.isMobile ? '20px' : ''};
    }
  }

  .loader {
    margin-bottom: 18px;
  }

  button {
    min-width: 190px;
  }

  .result-icon {
    margin: 25px auto 20px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const PortAmountWrapper = styled.div`
  margin-top: ${({ theme }) => theme.isMobile ? '' : '20px'};
  text-align: left;
  padding: ${({ theme }) => theme.isMobile ? '1rem 2rem' : '0rem 2rem'};
`;

const CurrentStakedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.isMobile ? '-13px' : '-23px'};
  padding: 0rem 2rem;
`;

const ItemTitle = styled.div`
  color: #FFFFFF;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  width: ${({ theme }) => theme.isMobile ? '30%' : ''};
  text-align: ${({ theme }) => theme.isMobile ? 'left' : ''};
  white-space: ${({ theme }) => theme.isMobile ? 'break-spaces' : ''};
`;

const ItemTitleAmountInput = styled.div`
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
`;

const MiniValue = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
`;

const DetailsWrapper = styled.div`
  padding: 0rem 2rem;
  display: flex;
  flex-direction: ${({ theme }) => theme.isMobile ? 'column' : 'row'};
  width: 100%;
  min-height: 165px;
  justify-content: space-around;
  align-items: center;
  /* border-bottom: 1px solid rgba(255, 255, 255, .1); */
  gap: 20px;
`;

const ColumDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  gap: 1.25rem;
`;

const ColumnWrapper = styled.div`
height: 100%;
width: 100%;
align-items: ${({ theme }) => theme.isMobile ? 'center' : 'flex-start'};
padding-left: ${({ theme }) => theme.isMobile ? '' : '1rem'};
display: flex;
flex-direction: ${({ theme }) => theme.isMobile ? 'row' : 'column'};
justify-content: ${({ theme }) => theme.isMobile ? 'space-between' : 'center'};
gap: 0.75rem;
`;

const Value = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 700;
`;

const Value25 = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: ${({ theme }) => theme.isMobile ? '18px' : '25px'};
  font-weight: 700;
`;

const ValuePORTX = styled.div`
  color: rgba(47, 246, 211, 1);
  font-size: ${({ theme }) => theme.isMobile ? '18px' : '25px'};
  font-weight: 700;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 1rem 2rem 0.5rem 2rem;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LinkTo = styled.div`
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
  :hover{
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`;

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  .error-info {
    margin-bottom: -10px;
  }
`;

const YellowWarningWrapper = styled.div`
  min-height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: ${({ theme }) => theme.isMobile ? '0rem 2rem 0.5rem 2rem' : '0rem 2rem'};
  border-bottom: 1px solid rgba(255,255,255,0.1);
  svg {
    height: 19px;
    width: 20px;
  }
  span {
    color: white;
  }
`;

const YellowWarning = styled.div`
  color: rgba(243, 186, 47, 1);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 0.5rem;
`;

const SVGwarningWrapper = styled.div`
  width: 50px;
  padding-right: 1rem;
`;

const CheckTx = styled.a`
  transform: translateY(-15px);
  text-decoration: underline;
  color: ${colors.aqua_bg}; 
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  -webkit-transition: all .5s ease;
  transition: all .5s ease;

  svg g, svg path {
    stroke: ${colors.aqua_bg}
  }

  &:not(:disabled):hover {
    color: ${colors.white};
    -webkit-transition: all .35s ease;
    transition: all .35s ease;

    svg g, svg path {
      stroke: ${colors.white}
    }

    path {
      stroke: ${colors.white}
    }
  }

  & > svg {
    margin-left: 5px;
 g{
  opacity: 1;
 }
    path {
      stroke: ${colors.aqua_bg}
    
    }
  }
`;

const S = {
  Wrapper,
  ItemTitle,
  ItemTitleAmountInput,
  MiniValue,
  CurrentStakedWrapper,
  PortAmountWrapper,
  DetailsWrapper,
  ColumDetailsWrapper,
  ColumnWrapper,
  Value,
  ButtonWrapper,
  LinkTo,
  ValuePORTX,
  Value25,
  WarningWrapper,
  YellowWarningWrapper,
  YellowWarning,
  SVGwarningWrapper,
  CheckTx,
};

export default S;
