import Web3 from 'web3';
// import perpetualTokensFarmSDKAbi from './abi/PerpetualTokensFarmSDK.json';
import { AVALANCHE_CHAIN_IDS } from '../constants/general';
import config from '../config/config';

const { TESTNET, MAINNET } = AVALANCHE_CHAIN_IDS;
const getERC20Abi = () => window.CONFIG.abis.BridgeMintableTokenV2;
const getMainChainAbi = () => window.CONFIG.abis.ChainportMainBridge;
const getSideChainAbi = () => window.CONFIG.abis.ChainportSideBridge;
const getFeeManagerChainAbi = () => window.CONFIG.abis.ChainportFeeManager;
const getSdkAbi = () => window.CONFIG.abis.Sdk;

export function promisify(func, args) {
  return new Promise((res, rej) => {
    func(...args, (err, data) => {
      if (err) return rej(err);
      return res(data);
    });
  });
}

export function whatDecimalSeparator() {
  const n = 1.1;
  return n.toLocaleString().substring(1, 2);
}

export function decimalAdjust(value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.floor(value);
  }
  /* eslint-disable no-param-reassign */
  value = +value;

  exp = +exp;
  if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  value = value.toString().split('e');
  value = Math.floor(+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
  value = value.toString().split('e');
  /* eslint-enable no-param-reassign */
  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
}

const bridgeUtil = Object.freeze({
  checkPositiveAmount(amountWei) {
    if (!/^[1-9]/.test(amountWei)) {
      throw new Error('Wrong amount!');
    }
  },
  getWeb3({ chainId, injected = true } = {}) {
    if (!injected) {
      const { networksByChain } = window.CONFIG;
      return new Web3(networksByChain[chainId]?.rpc);
    }
    if (!window._web3) {
      throw new Error('Web3 is not injected!');
    }
    return window._web3;
  },
  getPolyWeb3() {
    let chainId = 80001;
    if (config.environment === 'production') {
      chainId = 137;
    }
    if (!window._web3poly) {
      const { networksByChain } = window.CONFIG;
      return new Web3(networksByChain[chainId]?.rpc);
    }
    return window._web3poly;
  },
  async checkInjectedWeb3(chainId) {
    const web3 = this.getWeb3();
    const netId = await web3.eth.net.getId();
    if (chainId !== TESTNET && chainId !== MAINNET) {
      if (netId !== chainId) {
        throw new Error('Wrong network!');
      }
    }
    return true;
  },
  async estimateGas(method, txMeta, estimateGasFee = 0) {
    const gas = await method.estimateGas({ from: txMeta.from, value: estimateGasFee });
    return Math.ceil(gas * 1.2);
  },
  // ------------------------------
  async injectMaxPriorityAndMaxFee(txMeta) {
    const modifiedTxMeta = { ...txMeta };
    // pass miner fee and max fee only for polygon
    const maxPriorityFeePerGas = 35000000000;
    const maxFeePerGas = 35000000000;
    return { ...modifiedTxMeta, maxPriorityFeePerGas, maxFeePerGas };
  },
  async injectGasPrice(txMeta) {
    if (txMeta.gasPrice) {
      return txMeta;
    }
    const gasPrice = await window._web3.eth.getGasPrice();
    if (gasPrice !== '0') {
      return txMeta;
    }
    return { ...txMeta, gasPrice: (10 ** 9).toString() };
  },
  async injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta) {
    let newTxMeta;
    if (chainId === window.CONFIG.networksByName.POLYGON.chain_id) {
      newTxMeta = await this.injectMaxPriorityAndMaxFee(txMeta);
    } else {
      newTxMeta = await this.injectGasPrice(txMeta);
    }
    return newTxMeta;
  },

  // ------------------------------
  // ---------- CONTRACTS RELATED ----------
  getContract(abi, address, opts) {
    if (!address || !abi) {
      throw new Error('Address and abi are required');
    }

    const web3 = this.getWeb3(opts);
    return new web3.eth.Contract(abi, address);
  },
  getPolyContract(abi, address, opts) {
    if (!address || !abi) {
      throw new Error('Address and abi are required');
    }
    const web3 = this.getPolyWeb3(opts);
    return new web3.eth.Contract(abi, address);
  },
  getERC20Contract(address, opts) {
    return this.getContract(getERC20Abi(), address, opts);
  },
  // get the bridge contract for the relevant network
  getMainChainContract(
    address,
    opts
  ) {
    return this.getContract(getMainChainAbi(), address, opts);
  },
  getSideChainContract(address, opts) {
    return this.getContract(getSideChainAbi(), address, opts);
  },
  getFeeManagerChainAbiContract(isPolyProvider = true) {
    if (isPolyProvider) return this.getPolyContract(getFeeManagerChainAbi(), window.CONFIG.fee_manager);
    return this.getContract(getFeeManagerChainAbi(), window.CONFIG.fee_manager);
  },
  getPerpetualTokensFarmSDK(isPolyProvider = true) {
    if (isPolyProvider) return this.getPolyContract(getSdkAbi(), window.CONFIG.perpetual_contract);
    return this.getContract(getSdkAbi(), window.CONFIG.perpetual_contract);
  }
});

export default bridgeUtil;
window.chainport = bridgeUtil;
