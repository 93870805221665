const inchTokens = (state) => state.token.inch;
const tokens = (state) => state.token.tokens;
const tokensById = (state) => state.token.tokensById;
const tokensForApprovalByExactUserBalance = (state) => state.token.tokensForApprovalByExactUserBalance;
const blackListedTokens = (state) => state.token.blackListedTokens;
const liquidityTokens = (state) => state.token.liquidityTokens;
const balances = (state) => state.token.balances;
const balancesWei = (state) => state.token.balancesWei;
const isTokenNative = (state) => state.token.isTokenNative;
const originNetworkId = (state) => state.token.originNetworkId;
const tokenError = (state) => state.token.error;
const isTokenCFB = (state) => state.token.isTokenCFB;

export default { inchTokens, tokens, balances, balancesWei, tokensById, tokensForApprovalByExactUserBalance, blackListedTokens, liquidityTokens, isTokenNative, originNetworkId, tokenError, isTokenCFB };
