import styled, { css } from 'styled-components/macro';
import colors from '../../../styles/colors';

const Wrapper = styled.div`
  .modal-body {
    .modal-body-content {
      padding: 0;
    }
  }

  .confirm-button-wrapper {
margin: 20px 35px 20px 35px;
}


.explorer-link{
  color: rgba(243, 186, 47, 1);
  text-decoration: underline;
  font-size: 15px;
  &:hover {
    color: white;
  }
}

.extra-minting-fee{
  margin-left:3px;
}
`;

const ConfirmTxDetailsWrapper = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
padding: 12px 35px 12px 35px;

.gasTx {
  opacity: 0.5;
  font-size: 14px;
}

&:nth-child(3)  {
    border-bottom: 1px solid ${colors.form_inp_bg};
    padding: 12px 35px 24px 35px;
}

&:nth-child(5) {
  border-bottom: 1px solid ${colors.form_inp_bg};
  padding: 0px 35px 24px 35px;
}


${(props) => props.isAdaFeeWarningOpen && css`
&:nth-child(7) {
  border-bottom: 1px solid ${colors.form_inp_bg};
  padding: 24px 35px 24px 35px;
}

`}



&:nth-child(1) {
  padding: 24px 35px 12px 35px;
  div {
    img {
      margin-left: 16rem;

      @media screen and (max-width: 770px) {
        margin-left: 9rem;
      }
    }
  }
}

&:nth-child(4) {
  padding: 24px 35px 12px 35px;
  div {
    .labelSVGcontainer {
      svg {
        margin-top: 1rem;
        filter: invert(59%) sepia(12%) saturate(382%) hue-rotate(177deg) brightness(89%) contrast(88%);
        transform:rotate(270deg);
        }
      }
    }
}

&:nth-child(6) {
  padding: 24px 35px 0px 35px;
}

`;

const Label = styled.div`
display: flex;
color: ${colors.gray_light};
  svg {
    margin-left: 5px;
  }

  .ada-icon-wrapper{
    margin:0 5px 0 1px;
  }
`;

const CFBcontainer = styled.div`
font-size:15px;
line-height:21px;
margin: 15px 35px -10px 35px;
text-align: justify;
color:${colors.yellow};
a:visited {
  text-decoration: none; 
  color: ${colors.yellow};
}
`;

const TokenAddress = styled.div`
// text-decoration: underline;
border-bottom:1px solid white;
&:hover {
  color: ${colors.aqua_bg};
  border-bottom:1px solid ${colors.aqua_bg};
  cursor:pointer;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}
`;

const Value = styled.div`
font-weight: bold;
font-size: 18px;
/* opacity: ${(props) => (props.label === 'Gas Fee' ? '0.5' : 'none')}; */
`;

const NoLiqudityWarning = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding: 0rem 2rem;
  display: flex;
  flex-direction: row;
  text-align: left;
  color: rgba(243, 186, 47, 1);
  gap: 32px;
  padding-top: 24px;
`;

const WarningIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkWrapper = styled.div`

`;

const InfoIconContainer = styled.div`
display: inline-block;
width: 24px;
vertical-align: middle;
svg {
  fill: green;
}
`;

const TermsConditionsText = styled.div`
margin-left:25px;
margin-right:25px;
text-align:center;
line-height:30px;
margin-bottom:5px;
margin-top:-25px;
font-size:12px;
color: ${colors.gray_light};

& > a {
  text-decoration: none;
  color: ${colors.gray_light};
  
}
`;

const TermsConditionsSpan = styled.span`

  text-decoration: underline;
  margin-left:4px;
  color: ${colors.gray_light};

  &:hover {
    color: ${colors.aqua_bg};
    cursor: pointer;
   
  }

`;

const AdaWrapper = styled.div`
color: ${colors.yellow03} ;
display: flex;
margin-left:3px;


.arrow-icon {
  width: 5px;
  margin-left: 10px;
  margin-top:2px;
  path{
    fill: ${colors.yellow03} ;
  }

  transition: transform .3s ease-in-out;
  transform:rotate(270deg);
  &.rotate {
    transform:rotate(90deg);
  }

  
}


  
`;

const AdaWarningTextBox = styled.div`
background: rgba(255, 255, 255, 0.1);
font-size: 14px;
color: ${colors.yellow03};
line-height: 18px;
padding:14px;
border-radius: 5px;
text-align: left;
`;
const S = {
  ConfirmTxDetailsWrapper,
  Label,
  Value,
  Wrapper,
  TokenAddress,
  NoLiqudityWarning,
  CFBcontainer,
  InfoIconContainer,
  TermsConditionsText,
  TermsConditionsSpan,
  WarningIconWrapper,
  LinkWrapper,
  AdaWrapper,
  AdaWarningTextBox
};

export default S;
