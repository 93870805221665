import styled from 'styled-components/macro';
import colors from '../../styles/colors';
import mixins from '../../styles/mixins';

const Backdrop = styled.div`
  position: absolute;
  outline: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: all 0.15s ease-out;
  text-align: center;
  visibility: hidden;

  &.open {
    z-index: 1000;
    visibility: visible;

    .modal {
      transform: translateY(15%);
      opacity: 1;
    }
  }

  &.open.aligned-top {
    .modal {
      transform: translateY(18%);
    }
  }

  &.open.aligned-top-25 {
    .modal {
      transform: translateY(25%);
    }
  }

  &.open.aligned-top-40-isMobile-20 {
    .modal {
      transform: ${({ theme }) => theme.isMobile ? 'translateY(20%)' : 'translateY(40%)'};
    }
  }

  &.open.aligned-top-100-isMobile-40 {
    .modal {
      transform: ${({ theme }) => theme.isMobile ? 'translateY(40%)' : 'translateY(100%)'};
    }
  }

  &.open.aligned-top-100 {
    .modal {
      transform: translateY(100%);
    }
  }

  @media only screen and (max-width: 500px) {
    .modal .modal-body .modal-header {
      /* padding: 20px; */
      padding-top: 30px;

      .modal-title {
        font-size: 25px;
        display: flex;
        justify-content: center;
        gap: 6px;
      }
      .token-modal {
        text-align: center;

        .icon-close {
          right: unset;
        }
      }
    }
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  background: rgba(0, 27, 67, 0.9);

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  position: ${(props) => (props.isDeviceIOS ? 'fixed' : 'static')};
`;

const ModalWrapper = styled.div`
  width: 100%;
  display: inline-block;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  max-height:100%;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
    position: ${(props) => (props.isDeviceIOS ? 'static' : 'relative')};  
    overflow: ${(props) => (props.isDeviceIOS ? 'hidden' : 'auto')}
  }
`;

const ModalContent = styled.div`
  margin-top: 30px;
  top: 0;
  transform: translateY(0);
  opacity: 0;
  transition: all 0.3s ease-out;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
  padding: 0;
  background: ${colors.dark_bg};
  border-radius: ${({ theme }) => theme.isMobile ? '' : '25px'};

  &.mobile {
    /* width: 90%; */
    margin-right: auto;
    margin-left: auto;
  }

  .modal-header {
    background: transparent;

    padding-bottom: 30px;
    border-bottom: 1px solid ${colors.gray_extra_light};

    color: ${colors.white};
    font: normal normal bold 25px/29px Roboto;
    ${mixins.flexItXY('space-between', 'center')}
    flex-direction: column;
    position: relative;

    .icon-close {
      transition: all 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
      cursor: pointer;
      position: absolute;
      top: 30px;
      right: 30px;

      svg {
        transition: all 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
        path {
          stroke-width: 3;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &.tokenModal {
      position: relative;
      display: block;
      text-align: center;

      .modal-title {
        text-align: center !important;
        display: flex;
        justify-content: center;
        gap: 6px;
      }

      .icon-close {
        position: absolute;
        left: 30px;
        right: unset;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;

    .tippy-popper{
      max-width:20px !important;
    }
    .modal-header {
      padding: 30px 0px 5px 0px;
    }

    .icon-wrapper{
      margin-left:5px;
    }

    .modal-title {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 6px;
    }

    .modal-text {
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      font-weight: normal;
      margin-bottom: unset;
    }

    .modal-body-content {
      padding: 30px 5px;
    }
  }
`;

const S = {
  Backdrop,
  ScrollContainer,
  ModalWrapper,
  ModalContent,
};

export default S;
