import { es, fr, ja, tr, zhCN, enGB } from 'date-fns/locale';

export const SUPPORTED_LANGUAGES =
[{ value: 'en', label: 'English', dateFns: enGB },
  { value: 'es-ES', label: 'Español (España)', dateFns: es },
  { value: 'fr-FR', label: 'Français (France)', dateFns: fr },
  { value: 'ja-JP', label: '日本語', dateFns: ja },
  { value: 'tr-TR', label: 'Türkçe', dateFns: tr },
  { value: 'zh-CN', label: '中文（中国', dateFns: zhCN }];

// export const getLangFull = (key) =>
//   ({
//     en: 'English',
//   }[key]);

export const setLang = (lang) => {
  localStorage.setItem('lang', lang);
  document.location.reload();
};
