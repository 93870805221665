/* eslint-disable */
import React from 'react';

export default function MetaMaskIcon(props) {
  return (
    <svg
      className="mm-icon"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.1214 14.8509L17.9556 18.7926L15.667 18.1667L19.1214 14.8509Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M15.667 18.1667L17.8743 15.1423L19.1214 14.8509L15.667 18.1667Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M17.4062 11.8791L19.1215 14.8509L17.8745 15.1423L17.4062 11.8791ZM17.4062 11.8791L18.5099 11.1146L19.1215 14.8509L17.4062 11.8791Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M16.002 9.63355L19.1744 8.33398L19.0168 9.04588L16.002 9.63355ZM18.9164 9.90111L16.002 9.63355L19.0168 9.04588L18.9164 9.90111Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M18.9164 9.90111L18.5103 11.1147L16.002 9.63355L18.9164 9.90111ZM19.5519 8.63499L19.0168 9.04588L19.1744 8.33398L19.5519 8.63499ZM18.9164 9.90111L19.0168 9.04588L19.442 9.39944L18.9164 9.90111Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M13.2061 17.2064L13.9992 17.4596L15.6666 18.1668L13.2061 17.2064Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M18.5097 11.1147L18.9158 9.9011L19.2646 10.1687L18.5097 11.1147ZM18.5097 11.1147L15.2178 10.0301L16.0013 9.63354L18.5097 11.1147Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M16.7849 5.91162L16.0013 9.63355L15.2178 10.0301L16.7849 5.91162Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M19.1744 8.33398L16.002 9.63355L16.7855 5.91162L19.1744 8.33398Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M16.7852 5.91158L20.0962 5.52936L19.1741 8.33395L16.7852 5.91158Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M18.5097 11.1147L17.406 11.8791L15.2178 10.0301L18.5097 11.1147Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M19.9529 2.849L20.0962 5.52936L16.7852 5.91159L19.9529 2.849Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M19.9533 2.849L13.336 7.66028L13.25 4.39702L19.9533 2.849Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M8.32812 4.00525L13.2493 4.39703L13.3353 7.66029L8.32812 4.00525Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M15.2184 10.0301L13.3359 7.66031L16.7855 5.91162L15.2184 10.0301Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M15.2179 10.0301L17.4061 11.8791L14.334 12.2183L15.2179 10.0301Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M14.3345 12.2184L13.3359 7.66034L15.2184 10.0301L14.3345 12.2184Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M16.7855 5.91159L13.3359 7.66028L19.9532 2.849L16.7855 5.91159Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M8.3714 18.0903L9.93375 19.7196L7.80762 17.7845L8.3714 18.0903Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0477783"
      />
      <path
        d="M15.667 18.1668L16.4076 15.3526L17.8743 15.1424L15.667 18.1668Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        d="M2.03613 10.2499L4.74039 7.40704L2.38491 9.91063L2.03613 10.2499Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M17.8742 15.1424L16.4074 15.3526L17.406 11.8791L17.8742 15.1424ZM13.3353 7.66029L10.7027 7.57429L8.32812 4.00525L13.3353 7.66029Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M17.406 11.8791L16.4074 15.3526L16.3262 13.6564L17.406 11.8791Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0477783"
      />
      <path
        d="M14.334 12.2183L17.4061 11.8791L16.3263 13.6564L14.334 12.2183Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        d="M10.7031 7.57428L13.3357 7.66028L14.3343 12.2183L10.7031 7.57428Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M10.703 7.5743L3.59833 1L8.32839 4.00526L10.703 7.5743ZM8.32361 19.0889L2.15065 21L0.917969 16.2938L8.32361 19.0889Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M2.95312 11.4252L5.28471 9.59534L7.23884 10.0492L2.95312 11.4252Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M7.23929 10.0492L5.28516 9.59534L6.3315 5.27618L7.23929 10.0492Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M2.38574 9.91067L5.28589 9.59534L2.9543 11.4252L2.38574 9.91067Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M16.3263 13.6565L15.1223 13.0497L14.334 12.2184L16.3263 13.6565Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M2.38513 9.91068L2.17969 8.82611L5.28528 9.59534L2.38513 9.91068Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="dark-part"
        d="M14.874 14.4162L15.1225 13.0497L16.3265 13.6565L14.874 14.4162Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0477783"
      />
      <path
        d="M16.4077 15.3526L14.874 14.4161L16.3265 13.6564L16.4077 15.3526Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M5.28557 9.59534L2.17998 8.82611L1.92676 7.91355L5.28557 9.59534ZM6.33192 5.27618L5.28557 9.59534L1.92676 7.91355L6.33192 5.27618ZM6.33192 5.27618L10.7036 7.57432L7.23971 10.0492L6.33192 5.27618Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M7.23926 10.0492L10.7032 7.57428L12.2464 12.29L7.23926 10.0492ZM12.2464 12.29L7.45904 12.1897L7.23926 10.0492L12.2464 12.29Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M2.95312 11.4252L7.23884 10.0492L7.45862 12.1897L2.95312 11.4252ZM14.3339 12.2183L12.246 12.29L10.7028 7.57428L14.3339 12.2183Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M15.1223 13.0497L14.8739 14.4162L14.334 12.2184L15.1223 13.0497Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M3.59863 1L10.7033 7.5743L6.33155 5.27616L3.59863 1Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M0.917969 16.2938L6.90937 16.0453L8.32361 19.0888L0.917969 16.2938Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M8.32439 19.0889L6.91016 16.0454L9.8533 15.902L8.32439 19.0889Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        d="M14.874 14.4162L16.4077 15.3526L17.2534 17.345L14.874 14.4162ZM7.45882 12.1897L0.917969 16.2938L2.95333 11.4252L7.45882 12.1897ZM6.90937 16.0454L0.917969 16.2938L7.45882 12.1897L6.90937 16.0454ZM14.3341 12.2184L14.7259 13.6421L12.8339 13.752L14.3341 12.2184ZM12.8339 13.752L12.2462 12.29L14.3341 12.2184L12.8339 13.752Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M9.93337 19.7195L8.32324 19.0889L13.9229 20.0301L9.93337 19.7195Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M2.95348 11.4253L2.03613 10.2499L2.38491 9.91071L2.95348 11.4253Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M14.5583 19.3325L13.9229 20.0301L8.32324 19.0889L14.5583 19.3325Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0477783"
      />
      <path
        d="M14.8593 17.6221L8.32324 19.0889L9.85215 15.902L14.8593 17.6221Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0477783"
      />
      <path
        d="M8.32324 19.0889L14.8593 17.6221L14.5583 19.3325L8.32324 19.0889Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M1.92692 7.91353L1.71191 4.23938L6.33208 5.27617L1.92692 7.91353ZM2.38559 9.91067L1.75014 9.26566L2.18014 8.8261L2.38559 9.91067Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M11.1758 13.236L12.246 12.29L12.0931 14.6025L11.1758 13.236Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        d="M12.246 12.29L11.1757 13.2361L9.61816 14.0435L12.246 12.29Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        d="M17.2534 17.3449L16.9619 17.1299L14.874 14.4161L17.2534 17.3449Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M9.61856 14.0435L7.45898 12.1897L12.2464 12.29L9.61856 14.0435Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0477783"
      />
      <path
        d="M12.0938 14.6025L12.2466 12.29L12.8343 13.7521L12.0938 14.6025Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M1.54004 8.27663L1.92704 7.91351L2.18027 8.82608L1.54004 8.27663Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        className="dark-part"
        d="M12.0931 14.6025L9.61816 14.0435L11.1757 13.236L12.0931 14.6025Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0477783"
      />
      <path
        className="part"
        d="M6.33208 5.27616L1.71191 4.23937L3.59916 1L6.33208 5.27616Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0477783"
      />
      <path
        d="M13.9241 20.0301L14.1868 20.7468L9.93457 19.7195L13.9241 20.0301Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0477783"
      />
      <path
        d="M9.85228 15.902L9.61816 14.0435L12.0931 14.6025L9.85228 15.902Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M7.45898 12.1897L9.61856 14.0435L9.85268 15.9021L7.45898 12.1897Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0477783"
      />
      <path
        d="M12.8347 13.752L14.7267 13.6422L16.9627 17.13L12.8347 13.752ZM7.45961 12.1897L9.8533 15.9021L6.91016 16.0454L7.45961 12.1897Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M12.8343 13.752L15.185 17.3306L12.0938 14.6025L12.8343 13.752Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0477783"
      />
      <path
        d="M12.0938 14.6025L15.185 17.3306L14.8601 17.6221L12.0938 14.6025Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M14.8597 17.6221L9.85254 15.902L12.0933 14.6025L14.8597 17.6221ZM16.962 17.1299L15.1846 17.3306L12.8339 13.752L16.962 17.1299Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0477783"
      />
      <path
        d="M17.4308 18.8118L16.6663 20.3455L14.1866 20.7468L17.4308 18.8118ZM14.1866 20.7468L13.9238 20.0301L14.5593 19.3326L14.1866 20.7468Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0477783"
      />
      <path
        d="M14.5592 19.3326L15.0274 19.1462L14.1865 20.7468L14.5592 19.3326ZM14.1865 20.7468L15.0274 19.1462L17.4307 18.8118L14.1865 20.7468Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0477783"
      />
      <path
        className="dark-part"
        d="M16.9629 17.1299L17.5506 17.4883L15.7637 17.7033L16.9629 17.1299Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0477783"
      />
      <path
        className="dark-part"
        d="M15.7627 17.7033L15.1846 17.3306L16.9619 17.1299L15.7627 17.7033ZM15.5572 17.9852L17.7312 17.7224L17.4302 18.8117L15.5572 17.9852Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0477783"
      />
      <path
        className="dark-part"
        d="M17.4301 18.8118L15.0268 19.1462L15.5572 17.9852L17.4301 18.8118ZM15.0268 19.1462L14.5586 19.3325L14.8596 17.6221L15.0268 19.1462ZM14.8596 17.6221L15.1845 17.3306L15.7626 17.7033L14.8596 17.6221ZM17.5495 17.4883L17.7311 17.7224L15.5572 17.9852L17.5495 17.4883Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0477783"
      />
      <path
        className="dark-part"
        d="M15.5579 17.9852L15.7634 17.7033L17.5503 17.4883L15.5579 17.9852ZM14.8604 17.6221L15.5579 17.9852L15.0276 19.1462L14.8604 17.6221Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0477783"
      />
      <path
        className="dark-part"
        d="M15.7634 17.7033L15.5579 17.9852L14.8604 17.6221L15.7634 17.7033Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0477783"
      />
    </svg>
  );
}
