import {
  useQuery,
} from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import bridgeSelectors from '../redux/selectors/bridgeSelectors';
import walletSelectors from '../redux/selectors/walletSelectors';
import bridgeService from '../services/bridge';
import axios from '../services/axios';

export const usePortxBalances = () => {
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const { blockchain_type: blockChainType } = useSelector(bridgeSelectors.baseNetwork);
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['portx_balances', walletAddress, blockChainType],
    queryFn: async () => {
      const { data } = await axios.get('/fees/portx_balances', {
        params: { address: walletAddress, blockchain_type: blockChainType },
      });

      const portxStakedConverted = bridgeService.fromWei(data?.portx_staked, parseFloat(data?.portx_decimals, 10));
      const portxTotalAmountConverted = bridgeService.fromWei(data?.portx_total_wei, parseFloat(data?.portx_decimals, 10));
      const totalStakedBalance = parseInt(portxStakedConverted, 10) + parseInt(portxTotalAmountConverted, 10);

      return {
        ...data,
        portxStakedConverted,
        portxTotalAmountConverted,
        totalStakedBalance,
      };
    },
    enabled: !!walletAddress && !!blockChainType,
  });
  return {
    portXBalances: data,
    refetchBalances: refetch,
    isLoading,
    isError,
  };
};
