import {
  TOGGLE_MENU_MODE,
  RESIZE_WINDOW,
  SET_NETWORK_ID,
  SET_APPROVE_LOADING,
  SET_WITHDRAW_LOADING,
  GET_GAS_PRICES_SUCCESS,
  SET_GAS_PRICE,
  SET_NATIVE_COIN_RATES,
  GET_APP_META,
  SET_ALLOWANCE_LOADING,
  GET_TVL,
  SET_ACKNOWLEDGE_LOADING,
  SET_ACTIVE_PAGE,
  UPDATE_BANNER_STATE,
  UPDATE_PROGRESSBAR,
  UPDATE_PROGRESSBAR_PERCENTAGE

} from '../actionTypes/generalActionTypes';
import INITIAL_STATE from './generalInitialState';
import {
  DEPOSIT_TOKENS_FINISHED,
  // WITHDRAW_TOKENS_FINISHED,
  HANDLE_PORT_OUT_TOKENS_FINISHED,
} from '../actionTypes/transactionActionTypes';
import {
  STAKE_TOKENS_FINISHED,
  CLAIM_TOKENS_FINISHED,
} from '../actionTypes/stakeActionTypes';

export default (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
  case CLAIM_TOKENS_FINISHED:
    return {
      ...state,
      loading: { ...state.loading, withdraw: false },
    };
  case STAKE_TOKENS_FINISHED:
    return {
      ...state,
      loading: { ...state.loading, withdraw: false },
    };
  case SET_ACTIVE_PAGE:
    return {
      ...state,
      activePage: action.payload,
    };
  case HANDLE_PORT_OUT_TOKENS_FINISHED:
    return {
      ...state,
      loading: { ...state.loading, withdraw: false },
    };
  // case WITHDRAW_TOKENS_FINISHED:
  //   return {
  //     ...state,
  //     loading: { ...state.loading, withdraw: false },
  //   };
  case DEPOSIT_TOKENS_FINISHED:
    return {
      ...state,
      loading: { ...state.loading, withdraw: false },
    };
  case SET_ACKNOWLEDGE_LOADING:
    return {
      ...state,
      isAckLoading: action.payload,
    };
  case TOGGLE_MENU_MODE:
    return {
      ...state,
      isMenuOpen: !state.isMenuOpen,
    };
  case RESIZE_WINDOW:
    return {
      ...state,
      isMobile: action.payload < 767,
      isTablet: action.payload < 1280,

    };
  case SET_NETWORK_ID:
    return {
      ...state,
      networkId: action.payload,
    };
  case SET_APPROVE_LOADING:
    return {
      ...state,
      loading: { ...state.loading, approve: action.payload },
    };
  case SET_WITHDRAW_LOADING:
    return {
      ...state,
      loading: { ...state.loading, withdraw: action.payload },
    };

  case SET_ALLOWANCE_LOADING:
    return {
      ...state,
      loading: { ...state.loading, allowance: action.payload },
    };
  case GET_GAS_PRICES_SUCCESS:
    return {
      ...state,
      gasPrices: action.payload,
    };
  case SET_GAS_PRICE:
    return {
      ...state,
      settledGasPrice: action.payload,
    };
  case SET_NATIVE_COIN_RATES:
    return {
      ...state,
      nativeCoinRates:
       { ETHEREUM: action.payload.ethereum,
         POLYGON: action.payload.polygon,
         BSC: action.payload.bsc,
         FANTOM: action.payload.fantom,
         FUSE: action.payload.fuse,
         MOONRIVER: action.payload.moonriver,
         AVALANCHE: action.payload.avalanche,
         AURORA: action.payload.aurora,
         DOGECHAIN: action.payload.dogechain,
         CARDANO: action.payload.cardano,
         MILKOMEDA: action.payload.milkomeda,
         TELOS: action.payload.telos,
         OPTIMISM: action.payload.optimism,
         ARBITRUM: action.payload.arbitrum,
       },

    };
  case GET_APP_META:
    return {
      ...state,
      ...Object.keys(action.payload).reduce((acc, key) => {
        if (key.includes('networks')) {
          acc[key] = action.payload[key];
        }
        return acc;
      }, {}),
    };
  case GET_TVL:
    return {
      ...state,
      tvl: action.payload,
    };

  case UPDATE_BANNER_STATE:
    return {
      ...state,
      isBannerShown: action.payload,
    };

  case UPDATE_PROGRESSBAR:
    return {
      ...state,
      progressBarStep: action.payload,
    };

  case UPDATE_PROGRESSBAR_PERCENTAGE:
    return {
      ...state,
      progressBarPercentage: action.payload,
    };
  default:
    return state;
  }
};
