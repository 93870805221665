import { createStore, applyMiddleware, compose } from 'redux';
import _compact from 'lodash/compact';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { createBlacklistFilter, createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createLogger } from 'redux-logger';
import reducers from './reducers';

const saveSubsetBlacklistFilter = createBlacklistFilter(
  'transactions',
  ['interval_id', 'portTimeoutId', 'portXBalancesLoading'],
);

const saveSubsetFilter = createFilter('wallet', ['recipientWalletAddress']);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['transactions', 'bridge', 'general', 'token', 'wallet'],
  transforms: [saveSubsetBlacklistFilter, saveSubsetFilter],
  stateReconciler: autoMergeLevel2,
};

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const logger = process.env.REACT_APP_REDUX_LOGGER === 'true'
  ? createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
    diff: true,
  })
  : null;

const middlewares = _compact([thunk, logger]);
const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer];

export default (initialState = {}) => {
  const store = createStore(persistReducer(persistConfig, reducers), initialState, composeEnhancers(...enhancers));
  return { store, persistor: persistStore(store) };
};
