export default {
  ethereum: '#565C92',
  bsc: 'rgba(218, 179, 84, 1)',
  polygon: 'rgba(104, 61, 193, 1)',
  avalanche: 'rgba(174, 53, 61, 1)',
  fantom: 'rgba(15, 148, 198, 1)',
  moonriver: 'rgba(75, 185, 185, 1)',
  fuse: 'rgba(121, 158, 121, 1)',
  arbitrum: 'rgba(31, 132, 201, 1)',
  aurora: 'rgba(66, 134, 64, 1)',
  heco: 'rgba(4, 128, 185, 1)',
  okex: 'rgba(0, 63, 165, 1)',
  dogechain: 'rgba(137, 94, 246, 1)',
  cardano: '#0033AD',
  telos: '#571AFF',
  milkomeda: '#FF931E',
  optimism: '#A50012',
};
