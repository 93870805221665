import styled from 'styled-components/macro';
import colors from '../../styles/colors';

const Label = styled.label`
  display: block;
  font: normal normal 400 16px/19px Roboto;
  margin-bottom: 10px;
  color: rgba(140, 140, 140, 1);
  // white-space: nowrap;

  &.error {
    color: ${colors.red};
  }

  &.hidden {
    visibility: hidden;
  }
`;

const S = { Label };

export default S;
