import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import colors from '../../styles/colors';
import translate from '../../services/translate/translate';

const CloseToast = styled.span`
  display: flex;
  align-self: center;
  text-decoration: underline;
  color: ${colors.aqua_bg};
`;

export const toastOptions = {
  position: 'top-center',
  autoClose: false,
  style: {
    top: 50,
    maxWidth: 535,
    minHeight: 75,
    width: '100%',
    borderRadius: 10,
    border: `1px solid ${colors.aqua_bg}`,
    padding: '10px 30px',
    backgroundColor: colors.main_bg,
    color: colors.white,
    font: 'normal normal normal 15px/17px Roboto',
    bodyClassName: 'toast-body'
  },
  closeButton: <CloseToast>{translate('common.close')}</CloseToast>,
  draggable: true,
  draggableDirection: 'y'
};

const ToastBlock = ({ content }) => (
  <div className="toast-content">
    {content}
  </div>);

ToastBlock.propTypes = {
  content: PropTypes.node
};

export default ToastBlock;
