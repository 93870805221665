import { decodeFirst } from 'cbor-web';
import { getNetwork, web3WalletConnector, getCardanoWalletName } from '../../services/walletService';
import { setupWeb3 } from '../../services/web3Service';
import {
  CLEAR_ACCOUNT,
  CLEAR_ERROR,
  CONNECT_WALLET_END,
  CONNECT_WALLET_PROVIDER,
  CONNECT_WALLET_PROVIDER_FAILURE,
  CONNECT_WALLET_PROVIDER_SUCCESS,
  CONNECT_WALLET_START,
  SOFT_CLEAR_ACCOUNT,
  SET_WALLET_BALANCE,
  SET_RECIPIENT_WALLET_ADDRESS,
  SET_CARDANO_ADDRESS,
  SET_WALLET_TYPE,
} from '../actionTypes/walletActionTypes';
import { CLEAR_SELECTED_TOKEN, SET_BASE_NETWORK, SET_DESTINATION_NETWORK } from '../actionTypes/bridgeActionTypes';
import { LS_ACCOUNT, CARDANO_NETWORK_ID } from '../../constants/general';
import { wait, isObjectEmpty, useQuery } from '../../services/utilsService';
import { SET_NETWORK_ID, GET_GAS_PRICES_REQUEST } from '../actionTypes/generalActionTypes';
import bridge from '../../services/bridge';
import { getCardanoSingleWalletBalance } from '../../services/cardanoService';
import { MULTPLE_ADDRESSES_TYPE } from '../../constants/wallet';

// const cbor = require('cbor-web');

// const postLogin = () => () => {};

export const onWalletConnectRequestAction = () => (dispatch) => {
  // const state = getState();

  // const { bridge: { baseNetwork } } = state;

  // const networkIdToPass = (baseNetwork?.blockchainType === 'cardano') ? CARDANO_NETWORK_ID : baseNetwork?.chainId;
  // dispatch({ type: CONNECT_WALLET_START, payload: accountType, networkId: networkIdToPass });
  dispatch({ type: CONNECT_WALLET_PROVIDER });
};

export const onWalletConnectSuccessAction = ({ provider, account, connectionType, ...additions }) => async (dispatch, getState) => {
  dispatch({ type: SET_WALLET_TYPE, payload: connectionType });
  dispatch({ type: GET_GAS_PRICES_REQUEST });
  dispatch({ type: SET_WALLET_TYPE, payload: connectionType });
  const state = getState();
  const { general: { networksByChain, networks, networksByName }, bridge: { destinationNetwork, baseNetwork } } = state;
  const query = useQuery();
  const baseNetworkFromURL = networksByName[query.get('from')?.toUpperCase()];
  const destionationNetworkFromURL = networksByName[query.get('to')?.toUpperCase()];
  let network;
  let balance;
  let decodedWalletBalance;

  if (baseNetwork?.blockchainType !== 'cardano') {
    setupWeb3(provider);
    network = await getNetwork(window._web3);
    balance = await window._web3.eth
      .getBalance(account)
      .then(bridge.fromWei);
  } else {
    network = await provider.getNetworkId();
    const encodedBalance = await provider.getBalance();
    decodedWalletBalance = await decodeFirst(encodedBalance);
    const newDecodedBalance = (typeof decodedWalletBalance === 'number') ? decodedWalletBalance : decodedWalletBalance[0];
    // const ada = newDecodedBalance / 1000000;
    // check why balance is a string

    if (MULTPLE_ADDRESSES_TYPE[connectionType]) {
      const singleAddressBalanceLovelace = await getCardanoSingleWalletBalance(account);
      const singleAddressBalanceAda = bridge.fromWei(singleAddressBalanceLovelace, 6);
      balance = singleAddressBalanceAda;
    } else {
      balance = newDecodedBalance.toString();
    }
  }
  localStorage.setItem(LS_ACCOUNT, connectionType);
  localStorage.setItem(
    'walletData',
    JSON.stringify({ walletName: connectionType, baseNetworkId: baseNetwork.id })
  );
  dispatch({
    type: CONNECT_WALLET_PROVIDER_SUCCESS,
    payload: { account, accountType: connectionType, network, additions },
  });
  dispatch({ type: SET_NETWORK_ID, payload: network });
  if (!baseNetworkFromURL && !destionationNetworkFromURL) {
    const baseNetworkToSet = (network === CARDANO_NETWORK_ID && baseNetwork?.blockchainType === 'cardano') ? networksByName.CARDANO : networksByChain[network];
    dispatch({ type: SET_BASE_NETWORK, payload: baseNetworkToSet });
    if (destinationNetwork?.id === networksByChain[network]?.id) {
      const diffNetwork = networks.find((net) => net.id !== networksByChain[network]?.id);
      dispatch({ type: SET_DESTINATION_NETWORK, payload: diffNetwork });
    }
  }

  // const baseNetworkToSet = network === CARDANO_NETWORK_ID ? networksByName.CARDANO : networksByChain[network];
  // dispatch({ type: SET_BASE_NETWORK, payload: baseNetworkToSet });

  // if (destinationNetwork?.id === networksByChain[network]?.id) {
  //   const diffNetwork = networks.find((net) => net.id !== networksByChain[network]?.id);
  //   dispatch({ type: SET_DESTINATION_NETWORK, payload: diffNetwork });
  // }

  dispatch({ type: CONNECT_WALLET_END });

  localStorage.setItem(LS_ACCOUNT, connectionType);

  if (network) {
    localStorage.setItem('localNetwork', network);
  }

  dispatch({ type: SET_WALLET_BALANCE, payload: { balance, decodedWalletBalance } });
};

export const onWalletConnectErrorAction = (err) => (dispatch, getState) => {
  const state = getState();
  setupWeb3();
  const { blockchainType } = state.bridge.baseNetwork;
  if (blockchainType.toLowerCase() === 'cardano') {
    return;
  }
  dispatch({ type: CONNECT_WALLET_PROVIDER_FAILURE, payload: err.message });
};

/**
 * Tries not silent login for the selected account type
 *
 * @param accountType {String}
 *
 * @return {Function}
 */
export const normalLogin =
  (accountType, baseNetworkId, walletName) => async (dispatch, getState) => {
    const state = getState();
    const cardanoWallet = getCardanoWalletName();
    const { general: { networksById }, bridge: { baseNetwork } } = state;
    const baseNetworkChainId = networksById[baseNetworkId]?.chainId;
    const networkId = baseNetworkChainId || baseNetwork?.chainId;
    const networkIdToPass = baseNetwork?.blockchain_type === 'cardano' ? CARDANO_NETWORK_ID : networkId;
    const accountTypeToPass = baseNetwork?.blockchain_type === 'cardano' ? cardanoWallet : accountType;
    dispatch({
      type: CONNECT_WALLET_START,
      payload: { accountType, walletName, networkIdToPass, normalLogin: true },
    });
    dispatch({ type: CONNECT_WALLET_PROVIDER });
    try {
      if (!web3WalletConnector.mounted) {
        await wait(500);
      }
      const state = getState();
      const { additions = {} } = state.wallet;
      await web3WalletConnector.connect({
        rpcUrl: baseNetwork?.rpc,
        networkId: networkIdToPass,
        connectionType: accountTypeToPass,
        blockchainType: baseNetwork?.blockchain_type,
        ...additions,
      });
    } catch (err) {
      console.warn('ERROR', err);
      dispatch({ type: CONNECT_WALLET_PROVIDER_FAILURE, payload: err.message });
      setupWeb3();
    }

    dispatch({ type: CONNECT_WALLET_END });
  };

/**
 * If the user has already once successfully added an account this will
 * try a silent login for that account type.
 *
 * @return {Function}
 */
export const silentLogin =
  (walletName, baseNetworkId) => async (dispatch, getState) => {
    const { accountType, connectingWallet, account } = getState().wallet;
    if (!(accountType || walletName) || connectingWallet || account || document.hidden) return;
    dispatch({
      type: CONNECT_WALLET_START,
      payload: { accountType: walletName || accountType, walletName, networkId: baseNetworkId || getState().bridge.baseNetwork?.id, siletnLogin: true },
    });

    try {
      if (walletName || accountType) {
        await dispatch(normalLogin(walletName || accountType, baseNetworkId));
      }
    } catch (err) {
      setupWeb3();
    }

    dispatch({ type: CONNECT_WALLET_END });
  };

export const logOut = () => (dispatch, getState) => {
  const state = getState();
  const { isRedeemOnGoing, portTx } = state.transactions;
  const { processingPort, pendingRedeem } = state.bridge;
  dispatch({ type: CLEAR_ACCOUNT });
  if (!isRedeemOnGoing && isObjectEmpty(portTx) && !pendingRedeem && !processingPort) {
    dispatch({ type: CLEAR_SELECTED_TOKEN });
    dispatch({ type: SET_NETWORK_ID, payload: null });
  }
  localStorage.removeItem(LS_ACCOUNT);
  dispatch(normalLogin);
  localStorage.removeItem('walletData');
};
export const onWalletDisconnectAction = () => (dispatch) => {
  dispatch(logOut());
};

export const softClear = () => (dispatch) => {
  dispatch({ type: SOFT_CLEAR_ACCOUNT });
  setupWeb3();
};

export const clearError = () => (dispatch) => dispatch({ type: CLEAR_ERROR });

/**
 * Use it to connect wallet on not farm pages.
 * @param payload
 * @return {(function(*, *): Promise<void>)|*}
 */
export const switchNetwork = (payload) => async (dispatch, getState) => {
  const { accountType, additions = {} } = getState().wallet;
  const { networksByName, networksByChain } = getState().general;
  const newAccountType = payload?.blockchainType === 'cardano' ? '' : accountType;
  if (payload?.blockchainType === 'cardano') {
    dispatch({ type: SET_BASE_NETWORK, payload: networksByName.CARDANO });
  } else {
    dispatch({ type: SET_BASE_NETWORK, payload: networksByChain[payload.networkId] });
  }
  dispatch({ type: SOFT_CLEAR_ACCOUNT });

  await web3WalletConnector.connect({
    rpcUrl: payload?.rpcUrl,
    networkId: payload?.networkId,
    connectionType: newAccountType,
    blockchainType: payload?.blockchainType,
    showModalOnError: false,
    ...additions,
  });
};

export const walletAddressOnEtherScan = () => async (dispatch, getState) => {
  const { account } = getState().wallet;
  const { baseNetwork } = getState().bridge;
  if (!account) {
    return null;
  }
  const url = baseNetwork?.blockchain_type === 'cardano' ? `https://cardanoscan.io/address/${account}` : `https://etherscan.io/address/${account}`;
  window.open(
    url,
    '_blank',
    'noopener,noreferrer'
  );
};

export const setRecipientWalletAddress = (walletAddress) => (dispatch) => {
  dispatch({ type: SET_RECIPIENT_WALLET_ADDRESS, payload: walletAddress });
};

export const setCardanoAddress = (cardanoWalletAddress) => (dispatch) => {
  dispatch({ type: SET_CARDANO_ADDRESS, payload: cardanoWalletAddress });
};
