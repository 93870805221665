import Web3 from 'web3';
import Decimal from 'decimal.js';
import { retry, isToValid, validAddressBool } from './utilsService';
import bridgeUtil, { promisify, decimalAdjust, whatDecimalSeparator } from './bridgeUtil';

const bridge = Object.freeze({
// ---------- helper functions used outside the contract ----------
  toWei(amount, decimals = 18, fraction = 18) {
    const value = decimalAdjust(amount, fraction * -1);

    return Math.trunc(value * 10 ** decimals)
      .toLocaleString('fullwide', {
        useGrouping: false,
        maximumFractionDigits: 0,
      })
      .replace(whatDecimalSeparator(), '.');
  },
  fromWei(amount = 0, decimals = 18, fraction = 18) {
    const decimalValue = Decimal.mul(amount, Decimal.pow(10, (decimals * -1)));
    const value = decimalAdjust(decimalValue, fraction * -1);
    // const value = Decimal.mul(amount, Decimal.pow(10, (fraction * -1)));
    return value
      .toLocaleString('fullwide', {
        useGrouping: false,
        maximumFractionDigits: fraction,
      })
      .replace(whatDecimalSeparator(), '.');
  },
  async getTxCount(address) {
    const transactionCount = await window._web3.eth.getTransactionCount(address);
    return transactionCount;
  },
  async getTx(tx) {
    const tx1 = await window._web3.eth.getTransaction(tx);
    return tx1;
  },
  getTransactionReceiptMined(
    txHash,
    accountAddress,
    currentTxNonce,
    currentTxBlock,
    { timeout = 60 * 60 * 1000, interval = 5 * 1000 } = {}
  ) {
    return new Promise((resolve, reject) => {
      const web3 = bridgeUtil.getWeb3();
      let txInterval;
      let fallbackTimeout = setTimeout(() => {
        if (txInterval) {
          clearInterval(txInterval);
          txInterval = null;
        }
        reject(new Error('Operation timeout'));
      }, timeout);
      txInterval = setInterval(async () => {
        try {
          const receipt = await promisify(web3.eth.getTransactionReceipt, [
            txHash,
          ]);
          let walletNonce;
          if (!receipt) {
            walletNonce = await web3.eth.getTransactionCount(accountAddress);
          }
          if ((walletNonce > currentTxNonce) && !receipt && receipt !== 'CLEAR') {
            clearInterval(txInterval);
            const latestBlock = await web3.eth.getBlock('latest', true); // problem: the latest block may not be correct than we will not find the wallet address.
            let newTxHash = latestBlock.transactions.find((item) => item.from.toLowerCase() === accountAddress);
            let counterBlock = await web3.eth.getBlock(latestBlock.number, true);
            if (latestBlock?.number > currentTxBlock && !newTxHash) {
              try {
                newTxHash = await retry(async () => {
                  counterBlock = await web3.eth.getBlock((counterBlock?.number - 1), true);
                  return counterBlock?.transactions?.find((item) => item.from.toLowerCase() === accountAddress);
                }, 50, 500);
              } catch {
                throw new Error('Failed while trying to fetch sped up / canceled transaction, please wait while we are resolving the issue');
              }
            }
            if (newTxHash && newTxHash.from === newTxHash.to) {
              resolve('CLEAR');
              return;
            }
            if (isToValid(newTxHash.to, accountAddress)) {
              resolve(newTxHash.hash);
            } else {
              throw new Error('Something went wrong, please reach out to our support');
            }
          }
          if (receipt) {
            if (fallbackTimeout) {
              clearTimeout(fallbackTimeout);
              fallbackTimeout = null;
            }
            if (txInterval) {
              clearInterval(txInterval);
              txInterval = null;
            }
            /*
             * receipt: {
             *   status: 0x0 - failed, 0x1 - mined (true|false)
             *   transaction_meta
             * }
             * */

            resolve(receipt);
          }
        } catch (e) {
          if (fallbackTimeout) {
            clearTimeout(fallbackTimeout);
            fallbackTimeout = null;
          }
          if (txInterval) {
            clearInterval(txInterval);
            txInterval = null;
          }
          reject(e);
        }
      }, interval);
    });
  },
  async getTransactionGasPrice(txHash, chainId) {
    const web3 = bridgeUtil.getWeb3({ chainId, injected: false });
    const transaction = await web3.eth.getTransaction(txHash);
    const { gasPrice } = transaction || { gasPrice: 0 };
    // const { gasPrice } = await promisify(web3.eth.getTransaction, [txHash]);
    return this.fromWei(gasPrice, 9);
  },
  getMaxNumberWei() {
    const { BN } = Web3.utils;
    // (2 ** 256) - 1
    return (new BN('2', 10)).pow(new BN('256', 10)).sub(new BN('1', 10)).toString();
  },

  /* ERC20 tokens methods (both Ethereum and Binance networks) */
  getTokenMetaFromAddress(tokenAddress, networkName) {
    const { CONFIG: { networksByName } } = window;
    const contract = bridgeUtil.getERC20Contract(tokenAddress, {
      injected: false,
      chainId: networksByName[networkName]?.chain_id,
    });
    return Promise.all([
      contract.methods.decimals().call(),
      contract.methods.symbol().call(),
      contract.methods.name().call(),
    ]).then(([decimals, symbol, name]) => ({
      web3_address: tokenAddress,
      decimals,
      symbol,
      name,
      network: networkName.toLowerCase(),
      chain_id: networksByName[networkName].chain_id,
      network_name: networkName,
      token_image:
        'https://s2.coinmarketcap.com/static/img/coins/200x200/2165.png',
    }));
  },

  // getTotalSupply(tokenAddress, chainId) {
  //   const contract = bridgeUtil.getERC20Contract(tokenAddress, {
  //     injected: false,
  //     chainId,
  //   });
  //   return contract.methods.totalSupply().call();
  // },

  // getTokenWalletBalance(tokenAddress, walletAddress) {
  //   const contract = bridgeUtil.getContract(getERC20Abi(), tokenAddress);
  //   return contract.methods.balanceOf(walletAddress).call();
  // },

  getTokenBalance(tokenAddress, address, chainId) {
    const contract = bridgeUtil.getERC20Contract(tokenAddress, {
      injected: false,
      chainId,
    });
    return contract.methods.balanceOf(address).call();
  },
  getAllowance(tokenAddress, walletAddress, spenderContractAddress, chainId) {
    const contract = bridgeUtil.getERC20Contract(tokenAddress, {
      injected: false,
      chainId,
    });
    return contract.methods
      .allowance(walletAddress, spenderContractAddress)
      .call();
  },
  // payable Ethereum network
  async depositTokens(baseNetworkId, tokenAddress, amountWei, targetNetworkId, txMeta, extraGasFee) {
    const { CONFIG: { networksById } } = window;
    bridgeUtil.checkPositiveAmount(amountWei);
    const contractAddress = networksById[baseNetworkId].bridges.ChainportMainBridge;
    const contract = bridgeUtil.getMainChainContract(contractAddress);
    const method = contract.methods.depositTokens
      ? contract.methods.depositTokens(tokenAddress, amountWei, targetNetworkId)
      : contract.methods.freezeToken(tokenAddress, amountWei);
    const gas = await bridgeUtil.estimateGas(method, txMeta, extraGasFee);
    if (txMeta.estimateGas) {
      return gas;
    }
    await bridgeUtil.checkInjectedWeb3(networksById[baseNetworkId].chain_id);
    return promisify(method.send, [{ ...txMeta, gas, value: extraGasFee }]);
  },

  // receiverAddress -> /user's cardano address
  async nonEVMDepositTokens(baseNetworkId, tokenAddress, amountWei, targetNetworkId, receiverAddress, txMeta, extraGasFee) {
    const { CONFIG: { networksById } } = window;
    bridgeUtil.checkPositiveAmount(amountWei);
    const contractAddress = networksById[baseNetworkId].bridges.ChainportMainBridge;
    const contract = bridgeUtil.getMainChainContract(contractAddress);
    const method = contract.methods.nonEVMDepositTokens
      ? contract.methods.nonEVMDepositTokens(tokenAddress, amountWei, targetNetworkId, receiverAddress)
      : contract.methods.freezeToken(tokenAddress, amountWei);
    const gas = await bridgeUtil.estimateGas(method, txMeta, extraGasFee);
    if (txMeta.estimateGas) {
      return gas;
    }
    await bridgeUtil.checkInjectedWeb3(networksById[baseNetworkId].chain_id);
    return promisify(method.send, [{ ...txMeta, gas, value: extraGasFee }]);
  },

  // payable Ethereum network
  async releaseTokens(
    signature,
    tokenAddress,
    amountWei,
    nonce,
    txMeta,
    targetNetworkName
  ) {
    const { CONFIG: { networksByName } } = window;
    bridgeUtil.checkPositiveAmount(amountWei);
    const contract = bridgeUtil.getMainChainContract(networksByName[targetNetworkName].bridges.ChainportMainBridge);
    const method = contract.methods.releaseTokens(
      signature,
      tokenAddress,
      amountWei,
      nonce,
    );
    const gas = await bridgeUtil.estimateGas(method, txMeta);
    if (txMeta.estimateGas) {
      return gas;
    }
    if (targetNetworkName !== networksByName.CARDANO.name) {
      await bridgeUtil.checkInjectedWeb3(networksByName[targetNetworkName].chain_id);
    }
    return promisify(method.send, [{ ...txMeta, gas }]);
  },
  // payable nonEthereum network
  async burnTokens(baseNetworkId, tokenAddress, amountWei, txMeta) {
    bridgeUtil.checkPositiveAmount(amountWei);
    const { CONFIG: { networksById } } = window;
    const chainId = networksById[baseNetworkId].chain_id;
    const contractAddress = networksById[baseNetworkId].bridges.ChainportSideBridge;
    // changed naming getNonETHContract to getSideChainContract
    const contract = bridgeUtil.getSideChainContract(contractAddress);
    const method = contract.methods.burnTokens(tokenAddress, amountWei);
    const gas = await bridgeUtil.estimateGas(method, txMeta);

    const newTxMeta = await bridgeUtil.injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta);

    if (txMeta.estimateGas) {
      return gas;
    }

    await bridgeUtil.checkInjectedWeb3(chainId);
    return promisify(method.send, [{ ...newTxMeta, gas }]);
  },
  async crossChainTransfer(baseNetworkId, tokenAddress, amountWei, targetNetworkId, txMeta, extraGasFee) {
    bridgeUtil.checkPositiveAmount(amountWei);
    const { CONFIG: { networksById } } = window;
    const chainId = networksById[baseNetworkId].chain_id;
    const contractAddress = networksById[baseNetworkId].bridges.ChainportSideBridge;
    const contract = bridgeUtil.getSideChainContract(contractAddress);
    const method = contract.methods.crossChainTransfer(tokenAddress, amountWei, targetNetworkId);
    const gas = await bridgeUtil.estimateGas(method, txMeta, extraGasFee);

    const newTxMeta = await bridgeUtil.injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta);
    if (txMeta.estimateGas) {
      return gas;
    }

    await bridgeUtil.checkInjectedWeb3(chainId);
    return promisify(method.send, [{ ...newTxMeta, gas, value: extraGasFee }]);
  },

  async nonEVMCrossChainTransfer(baseNetworkId, tokenAddress, amountWei, targetNetworkId, receiverAddress, txMeta, extraGasFee) {
    bridgeUtil.checkPositiveAmount(amountWei);
    const { CONFIG: { networksById } } = window;
    const chainId = networksById[baseNetworkId].chain_id;
    const contractAddress = networksById[baseNetworkId].bridges.ChainportSideBridge;
    const contract = bridgeUtil.getSideChainContract(contractAddress);

    const method = contract.methods.nonEVMCrossChainTransfer(tokenAddress, amountWei, targetNetworkId, receiverAddress);
    const gas = await bridgeUtil.estimateGas(method, txMeta, extraGasFee);
    // const newTxMeta = await this.injectGasPrice(txMeta);

    const newTxMeta = await bridgeUtil.injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta);
    if (txMeta.estimateGas) {
      return gas;
    }
    await bridgeUtil.checkInjectedWeb3(chainId);
    return promisify(method.send, [{ ...newTxMeta, gas, value: extraGasFee }]);
  },

  // payable all networks
  async approve(chainId, bridgeContractAddress, tokenAddress, amountWei, txMeta) {
    const contract = bridgeUtil.getERC20Contract(tokenAddress);
    const method = contract.methods.approve(
      bridgeContractAddress,
      amountWei || this.getMaxNumberWei()
    );
    const gas = await bridgeUtil.estimateGas(method, txMeta);
    const newTxMeta = await bridgeUtil.injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta);
    if (txMeta.estimateGas) {
      return gas;
    }

    await bridgeUtil.checkInjectedWeb3(chainId);
    return promisify(method.send, [{ ...newTxMeta, gas }]);
  },
  // payable all networks
  async stakeTokens(amountWei, baseNetworkId, txMeta) {
    const { CONFIG: { networksById } } = window;
    bridgeUtil.checkPositiveAmount(amountWei);
    // CHANGE THE HARDCODDED CONTRACT (when BE add it to meta data)
    const chainId = networksById[baseNetworkId].chain_id;
    const contract = bridgeUtil.getFeeManagerChainAbiContract(false);
    const method = contract.methods.depositPORTX(amountWei);
    const gas = await bridgeUtil.estimateGas(method, txMeta);
    const newTxMeta = await bridgeUtil.injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta);
    if (txMeta.estimateGas) {
      return gas;
    }

    await bridgeUtil.checkInjectedWeb3(networksById[baseNetworkId].chain_id);
    return promisify(method.send, [{ ...newTxMeta, gas }]);
  },
  async withdrawStakedTokens(amountWei, baseNetworkId, txMeta) {
    const { CONFIG: { networksById } } = window;
    bridgeUtil.checkPositiveAmount(amountWei);
    const chainId = networksById[baseNetworkId].chain_id;
    const contract = bridgeUtil.getFeeManagerChainAbiContract(false);
    const method = contract.methods.withdrawPORTX(amountWei);
    const gas = await bridgeUtil.estimateGas(method, txMeta);
    const newTxMeta = await bridgeUtil.injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta);
    if (txMeta.estimateGas) {
      return gas;
    }

    await bridgeUtil.checkInjectedWeb3(networksById[baseNetworkId].chain_id);
    return promisify(method.send, [{ ...newTxMeta, gas }]);
  },
  async getUserStakedBalance(walletAddress, txMeta) {
    const contract = bridgeUtil.getFeeManagerChainAbiContract();
    const method = contract.methods.getUserBalance(walletAddress);

    return promisify(method.call, [{ ...txMeta }]);
  },
  // sdk
  async withdrawRewards(walletAddress, baseNetworkId, chainId, txMeta) {
    const { CONFIG: { networksById } } = window;
    const contract = bridgeUtil.getPerpetualTokensFarmSDK(false);
    const method = contract.methods.withdrawRewards(walletAddress);
    const gas = await bridgeUtil.estimateGas(method, txMeta);
    const newTxMeta = await bridgeUtil.injectGasPriceOrMaxPriorityAndMaxFee(chainId, txMeta);
    if (txMeta.estimateGas) {
      return gas;
    }

    await bridgeUtil.checkInjectedWeb3(networksById[baseNetworkId].chain_id);
    return promisify(method.send, [{ ...newTxMeta, gas }]);
  },
  async getStatsForUser(walletAddress, txMeta) {
    if (!validAddressBool(walletAddress) || !validAddressBool(txMeta?.from)) {
      return [];
    }
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    window.SDKContract = contract;
    const method = contract.methods.getStatsForUser(walletAddress);
    return promisify(method.call, [{ ...txMeta }]);
  },
  async ATHStake(walletAddress, txMeta) {
    if (!validAddressBool(walletAddress) || !validAddressBool(txMeta?.from)) {
      return;
    }
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.ATHStake(walletAddress);
    return promisify(method.call, []);
  },
  async epochId() {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.epochId();

    return promisify(method.call, []);
  },
  async startTime(epochId) {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.startTime(epochId);

    return promisify(method.call, []);
  },
  async endTime(epochId) {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.endTime(epochId);

    return promisify(method.call, []);
  },
  async minTimeToStake(epochId) {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.minTimeToStake(epochId);

    return promisify(method.call, []);
  },
  async totalRewards(epochId) {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.totalRewards(epochId);

    return promisify(method.call, []);
  },
  async noOfUsers(epochId) {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.noOfUsers(epochId);

    return promisify(method.call, []);
  },
  async totalDeposits(epochId) {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.totalDeposits(epochId);

    return promisify(method.call, []);
  },
  async rewardPerSecond(epochId) {
    const contract = bridgeUtil.getPerpetualTokensFarmSDK();
    const method = contract.methods.rewardPerSecond(epochId);

    return promisify(method.call, []);
  },
  async getTotalAmountDeposited() {
    const contract = bridgeUtil.getFeeManagerChainAbiContract();
    const method = contract.methods.getTotalAmountDeposited();

    return promisify(method.call, []);
  },

  async gasFeesPerNetwork(networkID, basenetworkId, transferType) {
    const { CONFIG: { networksById } } = window;

    let contract;
    if (transferType === 'depositTokens') {
      const contractAddress = networksById[basenetworkId].bridges.ChainportMainBridge;
      contract = bridgeUtil.getMainChainContract(contractAddress);
    } else {
      const contractAddress = networksById[basenetworkId].bridges.ChainportSideBridge;
      contract = bridgeUtil.getSideChainContract(contractAddress);
    }
    const method = contract.methods.gasFeesPerNetwork(networkID);
    return promisify(method.call, []);
  }
});
export default bridge;
window.chainport = bridge;
