import {
  CONNECT_WALLET_START,
  CONNECT_WALLET_END,
  CONNECT_WALLET_PROVIDER,
  CONNECT_WALLET_PROVIDER_SUCCESS,
  CONNECT_WALLET_PROVIDER_FAILURE,
  CLEAR_ACCOUNT,
  CLEAR_ERROR,
  SET_WALLET_BALANCE,
  SOFT_CLEAR_ACCOUNT,
  SET_RECIPIENT_WALLET_ADDRESS,
  SET_CARDANO_ADDRESS,

  SET_WALLET_TYPE } from '../actionTypes/walletActionTypes';
import { LS_ACCOUNT } from '../../constants/general';

const lsAccountType = localStorage.getItem(LS_ACCOUNT);

const INITIAL_STATE = {
  connectingWallet: false,

  connectingWalletAccountType: '',

  connectingWalletProvider: false,
  connectingWalletNetworkId: null,
  connectingWalletProviderError: '',

  connectedWalletName: '',
  connectedWalletBalance: '',

  account: '',
  accountType: lsAccountType || '',

  additions: {},
  recipientWalletAddress: '',
  cardanoWalletAddress: '',

  decodedWalletBalance: '',
  walletType: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
  case CONNECT_WALLET_START:
    return {
      ...state,
      connectingWallet: true,
      connectingWalletNetworkId: payload.networkId,
      connectingWalletAccountType: payload.accountType,
      connectedWalletName: payload.walletName,
    };

  case CONNECT_WALLET_END:
    return {
      ...state,
      connectingWallet: false,
      connectingWalletNetworkId: null,
      connectingWalletAccountType: '',
    };

  case CONNECT_WALLET_PROVIDER:
    return {
      ...state,
      connectingWalletProvider: true,
      connectingWalletProviderError: '',
      soft: null,
    };

  case CONNECT_WALLET_PROVIDER_SUCCESS:
    return {
      ...state,
      connectingWalletProvider: false,
      connectingWalletNetworkId: null,
      connectingWalletProviderError: '',
      ...payload,
    };

  case CONNECT_WALLET_PROVIDER_FAILURE:
    return {
      ...state,
      connectingWalletProvider: false,
      connectingWalletNetworkId: null,
      connectingWalletProviderError: payload,
    };

  case SET_WALLET_BALANCE:
    return {
      ...state,
      connectedWalletBalance: payload.balance,
      decodedWalletBalance: payload.decodedWalletBalance,
    };

  case CLEAR_ACCOUNT:
    return {
      ...state,
      account: '',
      accountType: '',
      additions: {},
    };

  case SOFT_CLEAR_ACCOUNT:
    return {
      ...state, account: '', soft: true,
    };

  case CLEAR_ERROR:
    return {
      ...state,
      connectingWalletProviderError: '',
    };

  case SET_RECIPIENT_WALLET_ADDRESS:
    return {
      ...state,
      recipientWalletAddress: payload,
    };
  case SET_CARDANO_ADDRESS:
    return {
      ...state,
      cardanoWalletAddress: payload,
    };
  case SET_WALLET_TYPE:
    return {
      ...state,
      walletType: payload,
    };

  default:
    return state;
  }
};
