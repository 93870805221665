import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import S from './styled';
import { ReactComponent as Lock } from '../../assets/lock.svg';
import CloseIcon from '../Icons/CloseIcon';
import { showModalBasedOnLocalStorage } from '../../services/utilsService';
import { updateBannerState } from '../../redux/actions/generalActions';

const BANNER_EXPIRATION_DURATIION = 2592000000; // 30 days in milliseconds

const VerifyBanner = () => {
  const dispatch = useDispatch();
  const [isBannerClosedByUser, setBannerClosedByUser] = useState(false);

  const handleCloseBanner = useCallback(() => {
    setBannerClosedByUser(true);
  }, [setBannerClosedByUser]);

  const location = useLocation();

  const showBannerBasedOnLS = showModalBasedOnLocalStorage('verifyBanner', BANNER_EXPIRATION_DURATIION, isBannerClosedByUser);

  const bannerISShown = useMemo(() => showBannerBasedOnLS && location.pathname === '/' && !isBannerClosedByUser, [showBannerBasedOnLS, location.pathname, isBannerClosedByUser]);

  useEffect(() => {
    dispatch(updateBannerState(bannerISShown));
  }, [showBannerBasedOnLS, isBannerClosedByUser, location.pathname, dispatch, bannerISShown]);

  return (

    <>
      {bannerISShown &&
        <S.VerifyBannerWrapper>
          <S.BannerText>Keep Safe. Always make sure you’re on
          </S.BannerText>
          <S.UrlBanner>
            <Lock />
            <S.UrlBannerText>app.chainport.io</S.UrlBannerText>
          </S.UrlBanner>
          <S.CloseIconWrapper onClick={handleCloseBanner}>
            <CloseIcon color="white" strokeWidth={4} size={13} />
          </S.CloseIconWrapper>
        </S.VerifyBannerWrapper>}

    </>
  );
};

export default VerifyBanner;
