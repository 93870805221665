import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statsForUser } from '../redux/actions/stakeActions';
import generalSelectors from '../redux/selectors/generalSelectors';
import walletSelectors from '../redux/selectors/walletSelectors';

const useSDKdata = () => {
  const dispatch = useDispatch();
  const [sdkData, setSDKdata] = useState({});

  const walletAddress = useSelector(walletSelectors.walletAddress);
  const networksByName = useSelector(generalSelectors.networksByName);

  const polyNet = networksByName.POLYGON;

  const fetchFromSDK = useCallback(async () => {
    if (walletAddress) {
      const res = await dispatch(statsForUser(walletAddress));
      setSDKdata(res);
    } else {
      setSDKdata({});
    }
  },
  [dispatch, walletAddress]);

  useEffect(() => {
    if (walletAddress && polyNet) {
      fetchFromSDK();
    }
  }, [fetchFromSDK, polyNet, walletAddress]);

  return { sdkData, fetchFromSDK };
};

export default useSDKdata;
