import {
  CLEAR_SELECTED_TOKEN,
  GET_PENDING_REDEEM_SUCCESS,
  GET_USER_PENDING_PORT_SUCCESS,
  REFRESH_SELECTED_TOKEN_META,
  RESET_SWAP_AMOUNT,
  SET_ALLOWANCE_FOR_TOKEN,
  SET_BASE_NETWORK,
  SET_DESTINATION_NETWORK,
  SET_GAS_RANGE_PRICE,
  SET_SELECTED_TOKEN,
  SET_SWAP_AMOUNT,
  UPDATE_REDEEM_SUCCESS,
  SET_SELECTED_TOKEN_ON_TARGET_NETWORK,
  SET_IS_BALANCE_MAX,
  GET_USER_PENDING_PORT_REQUEST } from '../actionTypes/bridgeActionTypes';
import { SET_NETWORK_ID } from '../actionTypes/generalActionTypes';
import {
  DEPOSIT_TOKENS_FINISHED,
  WITHDRAW_TOKENS_FINISHED,
  HANDLE_PORT_OUT_TOKENS_FINISHED,
} from '../actionTypes/transactionActionTypes';
import {
  STAKE_TOKENS_FINISHED,
  CLAIM_TOKENS_FINISHED,
} from '../actionTypes/stakeActionTypes';

const INITIAL_STATE = {
  selected_token: {},
  selected_token_on_target_network: {},
  networkId: null,
  swapAmount: 0,
  isUserInputBalanceMax: false,
  baseNetwork: null,
  destinationNetwork: null,
  destinationWallet: '',
  allowanceForToken: {},
  processingPort: null,
  loading: false,
  gasRangePrice: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
  case CLAIM_TOKENS_FINISHED:
    return {
      ...state,
      isUserInputBalanceMax: false,
    };
  case STAKE_TOKENS_FINISHED:
    return {
      ...state,
      swapAmount: 0,
      isUserInputBalanceMax: false,
    };
  case HANDLE_PORT_OUT_TOKENS_FINISHED:
    return {
      ...state,
      loading: false,
    };
  case WITHDRAW_TOKENS_FINISHED:
    return {
      ...state,
      selected_token: {},
      swapAmount: 0,
      isUserInputBalanceMax: false,
    };
  case DEPOSIT_TOKENS_FINISHED:
    return {
      ...state,
      selected_token: {},
      swapAmount: 0,
      isUserInputBalanceMax: false,
    };
  case SET_SELECTED_TOKEN:
    return {
      ...state,
      selected_token: action.payload,
      swapAmount: 0,
    };
  case SET_SELECTED_TOKEN_ON_TARGET_NETWORK:

    return {
      ...state,
      selected_token_on_target_network: action.payload,

    };
  case SET_NETWORK_ID:
    return {
      ...state,
      networkId: action.payload,
    };
  case SET_SWAP_AMOUNT:
    return {
      ...state,
      swapAmount: action.payload,
    };
  case SET_IS_BALANCE_MAX:
    return {
      ...state,
      isUserInputBalanceMax: action.payload,
    };
  case SET_DESTINATION_NETWORK:
    return {
      ...state,
      destinationNetwork: action.payload || null,
    };
  case SET_BASE_NETWORK:
    return {
      ...state,
      baseNetwork: action.payload || null,
    };
  case SET_ALLOWANCE_FOR_TOKEN:
    return {
      ...state,
      allowanceForToken: action.payload,
    };
  case REFRESH_SELECTED_TOKEN_META:
    return {
      ...state,
      selected_token: action.payload,
    };
  case CLEAR_SELECTED_TOKEN:
    return {
      ...state,
      selected_token: {},
      swapAmount: 0
    };
  case RESET_SWAP_AMOUNT:
    return {
      ...state,
      swapAmount: 0,
    };
  case GET_PENDING_REDEEM_SUCCESS:
    return {
      ...state,
      loading: false,
      pendingRedeem: action.payload,
    };
  case GET_USER_PENDING_PORT_REQUEST:
    return {
      ...state,
      processingPort: null,
    };
  case GET_USER_PENDING_PORT_SUCCESS:
    return {
      ...state,
      processingPort: action.payload,
    };
  case UPDATE_REDEEM_SUCCESS:
    return {
      ...state,
      pendingRedeem: { ...state.pendingRedeem, ...action.payload },
    };
  case SET_GAS_RANGE_PRICE:
    return {
      ...state,
      gasRangePrice: action.payload
    };

  default:
    return state;
  }
};
