import Axios from 'axios';
import AssetFingerprint from '@emurgo/cip14-js';
import {
  GET_TOKENS_REQUEST,
  GET_TOKENS_SUCCESS,
  GET_TOKENS_FAIL,
  GET_BALANCES_REQUEST,
  GET_BALANCES_SUCCESS,
  GET_BALANCES_FAIL,
  ADD_TOKEN,
  GET_TOKEN_TYPE_SUCCESS,
  GET_TOKEN_TYPE_FAIL,
  GET_TOKEN_ORIGIN_SUCCESS,
  GET_TOKEN_ORIGIN_FAIL,
  IS_TOKEN_CFB,

} from '../actionTypes/tokenActionTypes';
import bridge from '../../services/bridge';
import config from '../../config/config';
import { isObjectEmpty, convertStringToHex } from '../../services/utilsService';
import { MULTPLE_ADDRESSES_TYPE } from '../../constants/wallet';

// import GeneralSelectors from '../selectors/generalSelectors';

const { baseUrl: baseURL } = config;
const axios = Axios.create({ baseURL });

const getSingleWalletAddressBalance = async (tokenAddress, walletAddress) => {
  const resp = await axios.get('/cardano/token_balance', { params: { asset_fingerprint: tokenAddress, cardano_user_address: walletAddress } });
  return resp.data.balance;
};

export const setIsTokenCFB = (bool) => (dispatch) => {
  dispatch({
    type: IS_TOKEN_CFB,
    payload: bool
  });
};

export const fetchTokens = () => async (dispatch, getState) => {
  const state = getState();

  const {
    bridge: { baseNetwork }, general: { networksById },
  } = state;
  const networkName = baseNetwork?.id ? networksById[baseNetwork?.id]?.name : window.CONFIG.networksByName.ETHEREUM.name;
  dispatch({ type: GET_TOKENS_REQUEST });

  try {
    const [
      {
        data: { tokens: platformTokens, cg_tokens: geckoTokens = [], tokens_to_be_approved_by_exact_user_amount: tokensForApprovalByExactUserBalance, blacklisted_addresses: blackListedTokens, liquidity_addresses: liquidityTokens },
      },

      oneInchTokens,
    ] = await Promise.all([
      axios('/token/list', { params: { network_name: networkName } }),
      window.CONFIG.networksByName.ETHEREUM.chain_id === 1
        ? axios('https://api.1inch.exchange/v3.0/1/tokens')
          .then(({ data }) =>
            Object.values(data.tokens)
              .map((token) => ({
                chain_id: window.CONFIG.networksByName.ETHEREUM.chain_id,
                decimals: token.decimals,
                id: token.address,
                name: token.name,
                symbol: token.symbol,
                token_image: token.logoURI,
                web3_address: token.address,
              }))
              .sort((a, b) => a.symbol.localeCompare(b.symbol))
          )
          .catch((error) => {
            console.warn(error);
            return [];
          })
        : Promise.resolve([]),
    ]);
    // const tokenState = getState().token;
    // const existingTokensById = tokenState.tokensById || {};
    const existingTokensById = {};
    const tokens = platformTokens.concat(geckoTokens);
    const allTokens = oneInchTokens.concat(tokens);
    // const tokensByNetworks = tokenState.tokensByNetworks || {};
    const tokensByNetworks = {};

    allTokens.forEach((token) => {
      existingTokensById[token.id] = token;

      const networkTokens = tokensByNetworks[token.chain_id] || {};
      networkTokens[token.web3_address.toLowerCase()] = token;
      tokensByNetworks[token.chain_id] = networkTokens;
    });
    const lowerCaseLiquidityTokens = liquidityTokens.map((el) => el.toLowerCase());
    const lowerCaseBlackListedTokens = blackListedTokens.map((el) => el.toLowerCase());

    const mergedTokens = Object.values(tokensByNetworks).reduce((acc, curr) => acc.concat(Object.values(curr)), []);
    const payload = {
      tokens: mergedTokens,
      tokensById: existingTokensById,
      tokensByNetworks,
      tokensForApprovalByExactUserBalance,
      blackListedTokens: lowerCaseBlackListedTokens,
      liquidityTokens: lowerCaseLiquidityTokens,
    };
    dispatch({
      type: GET_TOKENS_SUCCESS,
      payload,
    });
    return mergedTokens;
  } catch (error) {
    dispatch({ type: GET_TOKENS_FAIL, payload: error });
  }
};

export const addToken = (token) => async (dispatch) =>
  dispatch({ type: ADD_TOKEN, payload: token });

export const setBalances = (tokens, walletAddress) => async (dispatch, getState) => {
  const state = getState();
  const walletData = JSON.parse(localStorage.getItem('walletData'));

  const {
    bridge: { baseNetwork }, wallet: { decodedWalletBalance },
  } = state;
  dispatch({ type: GET_BALANCES_REQUEST });
  try {
    let allBalances;

    if (MULTPLE_ADDRESSES_TYPE[walletData?.walletName]) {
      const cardanoTokenAddress = tokens[0]?.web3_address;
      const singleAddressBalance = await getSingleWalletAddressBalance(cardanoTokenAddress, walletAddress);
      allBalances = { balances: { [cardanoTokenAddress]: singleAddressBalance } };
    } else if (baseNetwork.blockchainType !== 'cardano') {
      allBalances = await Promise.all(
        tokens.map(async (token) => {
        // get token decimal from contract as token obj in tokens arr not always has.

          const tokenMeta = await bridge.getTokenMetaFromAddress(
            token?.web3_address, token?.network_name
          );

          return bridge
            .getTokenBalance(
              token.web3_address,
              walletAddress,
              token.chain_id
            )
            .then((wei) => ({
              balanceWei: wei,
              balance: bridge.fromWei(wei, parseInt(tokenMeta.decimals, 10)),
              web3_address: token.web3_address,
            }))
            .catch(() => '0');
        })
      ).then((tokenBalances) => tokenBalances.reduce((prev, curr) => {
      // handle eslint no-param-reassign rule
        const newPrev = { ...prev };
        newPrev.balances[curr.web3_address] = curr.balance;
        newPrev.balancesWei[curr.web3_address] = curr.balanceWei;
        return newPrev;
      }, { balances: {}, balancesWei: {} }));
    } else if (decodedWalletBalance && typeof decodedWalletBalance !== 'number') {
      const cardanoTokensListInWallet = [];
      decodedWalletBalance[1].forEach((assets, policyId) => {
        const convertedPolicyId = Buffer.from(policyId, 'hex').toString('hex');
        assets.forEach((quantity, assetName) => {
          // asset name is decoded
          const newQuantity = quantity.toString();
          const decodedAssetName = Buffer.from(assetName).toString('utf8');
          const assetNameHex = convertStringToHex(decodedAssetName);
          const assetFingerprint = AssetFingerprint.fromParts(
            Buffer.from(convertedPolicyId, 'hex'),
            Buffer.from(assetNameHex, 'hex'),
          );
          const bech32Fingerprint = assetFingerprint.fingerprint();
          cardanoTokensListInWallet.push({ newQuantity, decodedAssetName, assetNameHex, convertedPolicyId, bech32Fingerprint });
        });

        const cardanoTokensListInWalletObject = cardanoTokensListInWallet.reduce((prev, curr) => {
          const newPrev = { ...prev };
          newPrev[curr.bech32Fingerprint] = curr.newQuantity;
          return newPrev;
        }, {});
        allBalances = { balances: cardanoTokensListInWalletObject };
      });
    } else {
      allBalances = { balances: {} };
    }
    const { balances, balancesWei } = allBalances;
    dispatch({ type: GET_BALANCES_SUCCESS, payload: { balances, balancesWei } });
  } catch (error) {
    console.warn('GET_BALANCES_FAIL', error);
    dispatch({ type: GET_BALANCES_FAIL, payload: error });
  }
};

export const checkTokenIsNative = (networkId, tokenAddress) => async (dispatch) => {
  try {
    const resp = await axios('/token/get', { params: { token_web3_address: tokenAddress, network_id: networkId,
    } });
    const { base_token } = resp.data;
    if (base_token.cfb) {
      dispatch({ type: IS_TOKEN_CFB, payload: true });
    } else {
      dispatch({ type: IS_TOKEN_CFB, payload: false });
    }
    const originNetworkId = base_token?.network_id;
    // check if the token is native
    // return isTokenNative  true also when the object is empty.
    //  It is only empty if the token from the native chain hasn't been ported yet
    // TODO  delete this  once BE fixes the issue with this endpoint:  && networkId !== 9)
    const isTokenNative = !!((networkId === base_token.network_id && base_token.web3_address === tokenAddress) || (isObjectEmpty(base_token) && networkId !== 9));
    dispatch({ type: GET_TOKEN_TYPE_SUCCESS, payload: { isTokenNative, originNetworkId } });
    return isTokenNative;
  } catch (err) {
    dispatch({ type: GET_TOKEN_TYPE_FAIL, payload: err.response?.data || err.message });
    throw err;
  }
};

export const getTokenOrigin = (networkId, tokenAddress) => async (dispatch) => {
  try {
    const resp = await axios('/token/get', { params: { token_web3_address: tokenAddress, network_id: networkId,
    } });
    const { base_token } = resp.data;
    const originNetworkId = isObjectEmpty(base_token) ? networkId : base_token?.network_id;
    dispatch({ type: GET_TOKEN_ORIGIN_SUCCESS, payload: originNetworkId });
    return originNetworkId;
  } catch (err) {
    dispatch({ type: GET_TOKEN_ORIGIN_FAIL, payload: err.response?.data || err.message });
    throw err;
  }
};

// display toast msg when user tries to port native tokens that are not eth

export const checkIfTxNativeTokentoEth = (selectedToken) => async (dispatch, getState) => {
  const state = getState();
  const {
    general: { networksByName },
    bridge: { destinationNetwork },
  } = state;
  try {
    const tokenNetworkId = networksByName[selectedToken?.network_name].id;
    const originNetworkId = await dispatch(getTokenOrigin(tokenNetworkId, selectedToken.web3_address));
    if ((originNetworkId !== networksByName.ETHEREUM.id) && (destinationNetwork.id === networksByName.ETHEREUM.id)) {
      return true;
    }
    return false;
  } catch (err) {
    dispatch({ type: GET_TOKEN_ORIGIN_FAIL, payload: err.response?.data || err.message });
    throw err;
  }
};
