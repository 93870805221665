import styled, { keyframes } from 'styled-components';
import colors from '../../styles/colors';

const spin = keyframes`
     0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerContainer = styled.div`
  border: 5px solid ${colors.main_bg};
  border-radius: 50%;
  width: 31px;
  height: 31px;
  position: absolute;
  left: 10px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: ${spin} 1s linear infinite;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
    top: -5px;
    background: ${colors.white};
  }
`;

export const S = {
  SpinnerContainer,
};
