import {
  GET_TOKENS_REQUEST,
  GET_TOKENS_FAIL,
  GET_TOKENS_SUCCESS,
  SET_1INCH_TOKENS,
  GET_BALANCES_REQUEST,
  GET_BALANCES_SUCCESS,
  GET_BALANCES_FAIL,
  ADD_TOKEN,
  GET_TOKEN_TYPE_SUCCESS,
  GET_TOKEN_TYPE_FAIL,
  GET_TOKEN_ORIGIN_SUCCESS,
  GET_TOKEN_ORIGIN_FAIL,
  IS_TOKEN_CFB,
} from '../actionTypes/tokenActionTypes';

const INITIAL_STATE = {
  inch: {},
  tokens: [],
  balances: {},
  balancesWei: {},
  loading: false,
  error: '',
  isTokenNative: '',
  originNetworkId: '',
  isTokenCFB: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
  case IS_TOKEN_CFB:
    return {
      ...state,
      isTokenCFB: action.payload,
    };
  case SET_1INCH_TOKENS:
    return {
      ...state,
      inch: action.payload,
    };
  case GET_TOKENS_REQUEST:
  case GET_BALANCES_REQUEST:
    return {
      ...state,
      loading: true,
      balances: {},
    };

  case GET_TOKENS_SUCCESS:
    return {
      ...state,
      loading: false,
      tokens: action.payload.tokens,
      tokensById: action.payload.tokensById,
      tokensByNetworks: action.payload.tokensByNetworks,
      // tokensByAddress: action.payload.tokensByAddress,
      tokensForApprovalByExactUserBalance: action.payload.tokensForApprovalByExactUserBalance,
      blackListedTokens: action.payload.blackListedTokens,
      liquidityTokens: action.payload.liquidityTokens,
    };
  case GET_BALANCES_SUCCESS:
    return {
      ...state,
      loading: false,
      balances: action.payload.balances,

      balancesWei: state.balancesWei
        ? { ...state.balancesWei, ...action.payload.balancesWei }
        : action.payload.balancesWei,
    };
  case GET_TOKEN_TYPE_SUCCESS:
    return {
      ...state,
      isTokenNative: action.payload.isTokenNative,
      originNetworkId: action.payload.originNetworkId,
    };
  case GET_TOKEN_ORIGIN_SUCCESS:
    return {
      ...state,
      originNetworkId: action.payload,
    };
  case GET_TOKEN_ORIGIN_FAIL:
    return {
      ...state,
      error: action.payload,
    };
  case GET_TOKEN_TYPE_FAIL:
  case GET_TOKENS_FAIL:
  case GET_BALANCES_FAIL:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case ADD_TOKEN:
    return {
      ...state,
      tokens: [action.payload, ...state.tokens],
      tokensByNetworks: {
        ...state.tokensByNetworks,
        [action.payload.chain_id]: {
          ...(state.tokensByNetworks[action.payload.chain_id] || {}),
          [action.payload.web3_address.toLowerCase()]: action.payload,
        }
      },
      // tokensByAddress: {
      //   [action.payload.web3_address.toLowerCase()]: action.payload,
      //   ...state.tokensByAddress,
      // },
    };

  default:
    return state;
  }
};
