export const STAKE_TOKENS_FINISHED = 'STAKE_TOKENS_FINISHED';
export const CLAIM_TOKENS_FINISHED = 'CLAIM_TOKENS_FINISHED';
export const GET_PORTX_BALACES_LOADING = 'GET_PORTX_BALACES_LOADING';
export const GET_PORTX_BALACES_SUCCESS = 'GET_PORTX_BALACES_SUCCESS';
export const GET_PORTX_BALACES_FAILURE = 'GET_PORTX_BALACES_FAILURE';
export const RESET_PORTX_BALANCE = 'RESET_PORTX_BALANCE';
export const SET_STAKING_STATS = 'SET_STAKING_STATS';
// loading
export const SET_STAKED_LOADING = 'SET_STAKED_LOADING';
export const SET_CLAIMING_LOADING = 'SET_CLAIMING_LOADING';
export const SET_WITHDROW_STAKED_LOADING = 'SET_WITHDROW_STAKED_LOADING';
// dapi
export const DAPI_DATA_SUCCESS = 'DAPI_DATA_SUCCESS';
export const DAPI_DATA_FAIL = 'DAPI_DATA_FAIL';
// sdkData
export const SET_SDK_DATA = 'SET_SDK_DATA';
