import Axios from 'axios';
import { inPageKey } from '@dcentralab/web3-wallet-connector';
import {
  GET_GAS_PRICES_FAIL,
  GET_GAS_PRICES_REQUEST,
  GET_GAS_PRICES_SUCCESS,
  RESIZE_WINDOW,
  SET_APPROVE_LOADING,
  SET_GAS_PRICE,
  SET_NETWORK_ID,
  SET_WITHDRAW_LOADING,
  TOGGLE_MENU_MODE,
  SET_NATIVE_COIN_RATES,
  GET_APP_META,
  // SET_APP_VERSIONS,
  GET_TVL,
  SET_ACKNOWLEDGE_LOADING,
  SET_ACTIVE_PAGE,
  SET_ALLOWANCE_LOADING,
  UPDATE_BANNER_STATE,
  UPDATE_PROGRESSBAR,
  UPDATE_PROGRESSBAR_PERCENTAGE
} from '../actionTypes/generalActionTypes';
import { getAppMeta, isValidNetworkName, useQuery, localNetwork } from '../../services/utilsService';
import { setBaseNetwork, setDestinationNetwork } from './bridgeActions';
import { normalLogin } from './walletActions';
import config from '../../config/config';

const { baseUrl: baseURL } = config;
const axios = Axios.create({ baseURL });

export const setActivePage = (num) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_PAGE,
    payload: num,
  });
};

export const setAckLoading = (bool) => (dispatch) => {
  dispatch({
    type: SET_ACKNOWLEDGE_LOADING,
    payload: bool,
  });
};

export const toggleMenuMode = () => (dispatch) => {
  dispatch({
    type: TOGGLE_MENU_MODE,
  });
};

export const resizeWindow = () => (dispatch) => {
  dispatch({
    type: RESIZE_WINDOW,
    payload: Math.min(window.innerWidth, window.outerWidth),
  });
};

export const setNetworkId = (id) => (dispatch) => {
  dispatch({
    type: SET_NETWORK_ID,
    payload: id,
  });
};

export const setApproveLoading = (bool) => (dispatch) => {
  dispatch({
    type: SET_APPROVE_LOADING,
    payload: bool,
  });
};

export const setWithdrawLoading = (bool) => (dispatch) => {
  dispatch({
    type: SET_WITHDRAW_LOADING,
    payload: bool,
  });
};

export const setAllowanceLoading = (bool) => (dispatch) => {
  dispatch({
    type: SET_ALLOWANCE_LOADING,
    payload: bool,
  });
};

export const getGasPrices = () => async (dispatch) => {
  dispatch({ type: GET_GAS_PRICES_REQUEST });
  try {
    const { data } = await axios.get(
      'https://ethgasstation.info/api/ethgasAPI.json?api-key=1ecdbc34bc88791dc886a1c055d8f975a1aeef84c8d6cc6c3dc4fd89780f'
    );
    const gasPrices = {
      safeLow: data.safeLow / 10,
      fast: data.average / 10,
      fastest: data.fastest / 10,
    };

    if (gasPrices.fastest === gasPrices.safeLow) {
      gasPrices.fastest += 1;
    }

    if (gasPrices.safeLow < 1) {
      gasPrices.safeLow = data.average / 10;
      gasPrices.fast = data.fast / 10;
    }

    dispatch({ type: GET_GAS_PRICES_SUCCESS, payload: gasPrices });
  } catch (error) {
    dispatch({ type: GET_GAS_PRICES_FAIL, payload: error });
  }
};

export const setGasPrice = (gasPrice) => (dispatch) => {
  dispatch({ type: SET_GAS_PRICE, payload: gasPrice });
};

export const refreshAppMeta = () => (dispatch) =>
  getAppMeta().then(({ general }) => {
    dispatch({ type: GET_APP_META, payload: general });
    // dispatch({ type: SET_APP_VERSIONS, payload: versions });
    dispatch({ type: GET_TVL, payload: general?.tvl });
    const { nativeCoinsUsdValues } = general;
    dispatch({
      type: SET_NATIVE_COIN_RATES,
      payload: nativeCoinsUsdValues,
      // payload: parseFloat(general.stats.result.ethusd),
    });
  });

export const getBaseNetwork = () => (dispatch, getState) => {
  const state = getState();
  const {
    general: { networksByName, networks }, bridge: { baseNetwork }
  } = state;
  const query = useQuery();

  if (baseNetwork) {
    return baseNetwork;
  }
  if (isValidNetworkName(query.get('from'), networksByName) && query.get('to') && query.get('token')
  ) {
    return networksByName[query.get('from').toUpperCase()];
  }

  const switchInProcess = localStorage.getItem(inPageKey);
  if (switchInProcess) {
    const { networkId, connectionType } = JSON.parse(switchInProcess);
    // TODO what to do here?
    normalLogin(connectionType, networkId);

    return networks.find((net) => net.chainId === networkId);
  }

  if (localNetwork) {
    return networks.find((net) => net.chainId === localNetwork);
  }

  return networksByName.ETHEREUM;
};

export const getDestinationNetwork = ({ baseNetwork } = {}) => (dispatch, getState) => {
  const state = getState();
  const query = useQuery();
  const {
    general: { networksByChain, networksByName, networks }, transactions: { redeemTx }
  } = state;
  if (redeemTx && localStorage.getItem('targetNetwork')) {
    return networksByChain[localStorage.getItem('targetNetwork')];
  }
  if (query.get('from') && isValidNetworkName(query.get('to'), networksByName) && query.get('token')) {
    return networksByName[query.get('to').toUpperCase()];
  }
  if (localNetwork) {
    return networks.find((net) => net.chainId !== localNetwork && net.chainId !== baseNetwork?.chainId);
  }
  return networks.find((net) => net.chainId !== baseNetwork?.chainId);
};

export const setNetworks = () => (dispatch, getState) => {
  dispatch(getGasPrices());

  const baseNetwork = dispatch(getBaseNetwork());
  // if (baseNetwork?.name?.toLowerCase()?.includes('eth')) {
  // set eth gas price to the default value of the base network -> TODO: change this when gas monitor on BE is ready

  // }
  const state = getState();
  const { general: { networksByName },
    wallet: { connectingWallet, accountType, connectingWalletNetworkId }
  } = state;
  const destinationNetwork = dispatch(getDestinationNetwork({ baseNetwork }));

  dispatch(setBaseNetwork(baseNetwork, true));
  dispatch(setDestinationNetwork(destinationNetwork));

  const switchTo = parseInt(localStorage.getItem('switchTo'), 10);
  if (!connectingWallet || baseNetwork?.chainId !== connectingWalletNetworkId) {
    if (switchTo && switchTo !== networksByName.ETHEREUM.chainId) {
      normalLogin(accountType, baseNetwork?.id);
      localStorage.removeItem('switchTo');
    }
  }
};

export const setNetworksFromUrl = (baseRpc, destRpc) => (dispatch, getState) => {
  dispatch(getGasPrices());
  const state = getState();
  const { general: { networksByName },
    wallet: { connectingWallet, accountType, connectingWalletNetworkId }
  } = state;
  dispatch(setBaseNetwork(baseRpc));
  dispatch(setDestinationNetwork(destRpc));
  const switchTo = parseInt(localStorage.getItem('switchTo'), 10);
  if (!connectingWallet || baseRpc?.chainId !== connectingWalletNetworkId) {
    if (switchTo && switchTo !== networksByName.ETHEREUM.chain_id) {
      normalLogin(accountType, baseRpc?.chainId);
      localStorage.removeItem('switchTo');
    }
  }
};

export const updateBannerState = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_BANNER_STATE, payload });
};

export const updateProgressBar = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_PROGRESSBAR_PERCENTAGE, payload: 100 });
  dispatch({ type: UPDATE_PROGRESSBAR, payload });
  dispatch({ type: UPDATE_PROGRESSBAR_PERCENTAGE, payload: 0 });
};

export const updateProgressBarPercentage = (percentage) => (dispatch, getState) => {
  const state = getState();
  const { tx_hash } = state.transactions;
  const { baseNetwork, processingPort, pendingRedeem } = state.bridge;
  let progressBarPercentage;
  if (percentage <= baseNetwork.min_confirmations && !pendingRedeem && tx_hash && !processingPort) {
    progressBarPercentage = (percentage / baseNetwork.min_confirmations) * 100;
  } else {
    progressBarPercentage = (percentage < 100) ? percentage : 100;
  }
  dispatch({ type: UPDATE_PROGRESSBAR_PERCENTAGE, payload: progressBarPercentage });
};
