import styled from 'styled-components/macro';
import colors from '../../styles/colors';

const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  background: ${colors.main_bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Roboto;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);


  .footer-left {
    display: flex;
    flex:2;
    align-items: center;
    p { margin: 12px 0 };
    a {
      text-decoration: underline;
      color: rgba(255, 255, 255, 0.5);
      margin-left: 15px;

      &:hover {
        color: ${colors.aqua_bg};
        cursor: pointer;
      }
    }
  }
  
  .total-value-locked{
    flex:1;
  text-align:center;
    padding: 0 12px 0 12px;
  }
  
  .footer-links {
    display: flex;
    flex:2;
    justify-content:right;

    & > a {
      text-decoration: underline;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: ${colors.aqua_bg};
        cursor: pointer;
      }
    }

    & > a:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  &.mobile {
    height: unset;
    flex-direction: column;
    padding-bottom: 15px;
    box-sizing: border-box;
    position: relative;
    margin-top: auto;

    & > p:first-of-type {
      width: 170px;
      text-align: center;
    }

    a {
      text-decoration: underline;
    }
    
    .total-value-locked{
      padding-top:30px;
    }

    .total-value-locked{
      padding-top:30px;
    }

    .footer-links {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 7px;
      a {
        margin-top: 23px;
      }
    }
  }
`;

const S = {
  FooterContainer,
};

export default S;
