import {
  STAKE_TOKENS_FINISHED,
  CLAIM_TOKENS_FINISHED,
  GET_PORTX_BALACES_LOADING,
  GET_PORTX_BALACES_SUCCESS,
  GET_PORTX_BALACES_FAILURE,
  RESET_PORTX_BALANCE,
  DAPI_DATA_SUCCESS,
  DAPI_DATA_FAIL,
  SET_STAKED_LOADING,
  SET_CLAIMING_LOADING,
  SET_WITHDROW_STAKED_LOADING,
  SET_STAKING_STATS,
  SET_SDK_DATA,
} from '../actionTypes/stakeActionTypes';

import {
  WITHDRAW_TOKENS_FINISHED,
} from '../actionTypes/transactionActionTypes';

const INITIAL_STATE = {
  portXBalances: null,
  portXBalancesError: null,
  portXBalancesLoading: false,
  stakingHistoryData: {},
  stakingStats: {},
  dapiData: [],
  sdkData: {},
  stakingLoading: {
    staking: false,
    stakingComplete: false,
  },
  claimingLoading: {
    claiming: false,
    claimingComplete: false,
  },
  withdrawStakedLoading: {
    Unstake: false,
    unstakingComplete: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;
  switch (type) {
  case SET_SDK_DATA:
    return {
      ...state,
      sdkData: action.payload,
    };
  case SET_STAKED_LOADING:
    return {
      ...state,
      stakingLoading: action.payload,
    };
  case SET_STAKING_STATS:
    return {
      ...state,
      stakingStats: action.payload,
    };
  case SET_WITHDROW_STAKED_LOADING:
    return {
      ...state,
      withdrawStakedLoading: action.payload,
    };
  case SET_CLAIMING_LOADING:
    return {
      ...state,
      claimingLoading: action.payload,
    };
  case DAPI_DATA_SUCCESS:
    return {
      ...state,
      dapiData: action.payload,
    };
  case DAPI_DATA_FAIL:
    return {
      ...state,
      dapiData: [],
    };
  case CLAIM_TOKENS_FINISHED:
    return {
      ...state,
      stakingHistoryData: {},
    };
  case STAKE_TOKENS_FINISHED:
    return {
      ...state,
      stakingHistoryData: {},
    };
  case WITHDRAW_TOKENS_FINISHED:
    return {
      ...state,
      stakingHistoryData: {},
    };
  // portx related
  case GET_PORTX_BALACES_LOADING:
    return {
      ...state,
      portXBalancesLoading: true,
      portXBalancesError: null
    };
  case GET_PORTX_BALACES_SUCCESS:
    return {
      ...state,
      portXBalancesLoading: false,
      portXBalances: action.payload
    };
  case GET_PORTX_BALACES_FAILURE:
    return {
      ...state,
      portXBalancesLoading: false,
      portXBalancesError: action.payload
    };
  case RESET_PORTX_BALANCE:
    return {
      ...state,
      portXBalances: null,
    };
  default:
    return state;
  }
};
