import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import bridgeSelectors from '../../redux/selectors/bridgeSelectors';
import { ReactComponent as PortX } from '../../assets/portX.svg';
import ThreeDotLoader from '../../components/ThreeDotsLoader/TreeDotsLoader';
import { usePortxBalances } from '../../queries/usePortxBalances';
import S from './styled';
import { numFormatter, urlToPassPortx } from '../../services/utilsService';
import generalSelectors from '../../redux/selectors/generalSelectors';

const PortXWidget = () => {
  const { portXBalances, isLoading } = usePortxBalances();
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const networksByName = useSelector(generalSelectors.networksByName);
  const isAmountAvailable = ((Number(portXBalances?.portxTotalAmountConverted) + Number(portXBalances?.portxStakedConverted))) > 0.000001;

  const getContent = () => {
    if (isLoading) {
      return <ThreeDotLoader />;
    }
    if (!(isAmountAvailable) > 0) {
      return 'Get PORTX';
    }
    return numFormatter(Number(portXBalances?.portxTotalAmountConverted) + Number(portXBalances?.portxStakedConverted));
  };

  return (
    <S.PortXwrapper
      className={clsx({ hoverable: isAmountAvailable, loading: isLoading })}
      to={{ pathname: urlToPassPortx(networksByName, isAmountAvailable, baseNetwork) }}
      target={isAmountAvailable ? '_self' : '_blank'}
      rel="noopener noreferrer"
    >
      <S.PortXsvg>
        <PortX />
      </S.PortXsvg>
      <span className="text portx-text">
        {getContent()}
      </span>
      <span className="text portx-text-staking">STAKING</span>
    </S.PortXwrapper>
  );
};

export default PortXWidget;
