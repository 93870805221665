import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import S from './styled';

const TextInput = ({ Icon, RightIcon, autoFocus = false, min, ...rest }) => {
  const inputRef = useRef();

  useEffect(() => {
    if (autoFocus && inputRef?.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [autoFocus]);

  return (
    <S.Wrapper className={clsx({ 'with-icon': !!Icon })}>
      <S.Input min={min} type="text" {...rest} ref={inputRef} />
      {Icon}
      {RightIcon}
    </S.Wrapper>
  );
};

TextInput.propTypes = {
  Icon: PropTypes.node,
  min: PropTypes.string,
  autoFocus: PropTypes.bool,
  RightIcon: PropTypes.node,
};

export default TextInput;
