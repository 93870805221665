import { combineReducers } from 'redux';
import wallet from './walletReducer';
import general from './generalReducer';
import modal from './modalReducer';
import token from './tokenReducer';
import bridge from './bridgeReducer';
import transactions from './transactionReducer';
import myActivity from './myActivityReducer';
import stake from './stakeReducer';

export default combineReducers({
  wallet,
  general,
  modal,
  token,
  bridge,
  transactions,
  myActivity,
  stake,
});
