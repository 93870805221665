import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import colors from '../../styles/colors';

const Wrapper = styled.div`
  position: relative;

  &.with-icon input {
    padding-left: 45px;
  }

  svg {
    position: absolute;
    top: 7px;
    left: 20px;
  }

  input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

const Input = styled(NumberFormat)`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: ${colors.form_inp_bg} !important;
  font: normal normal bold 19px/22px Roboto;
  color: ${colors.white};

  &:disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
  }

  &.dark {
    background-color: ${colors.form_inp_bg};
    color: ${colors.white};
    padding: 0 20px;
    font-size: 16px;

    &::placeholder {
      height: 40px;
      line-height: 40px;
      color: gray;
    }

    &::-webkit-input-placeholder {
      height: 40px;
      line-height: 40px;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      height: 40px;
      line-height: 40px;
    }

    &:-moz-placeholder {
      /* Firefox 19+ */
      height: 40px;
      line-height: 40px;
    }
  }
`;

const NumberInput = ({ Icon, ...rest }) => (
  <Wrapper className={clsx({ 'with-icon': !!Icon })}>
    <Input {...rest} />
    {Icon}
  </Wrapper>
);

NumberInput.propTypes = {
  Icon: PropTypes.node,
};

export default NumberInput;
