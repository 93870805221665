import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CompleteModalTemplate from './components/CompleteModalTemplate';
import { ReactComponent as SuccessUnStakingSVG } from '../../../assets/unstakingComplete.svg';
import S from './styled';

/*                       ACTIONS                           */
import { closeModal, openStakeModal } from '../../../redux/actions/modalActions';
import { claimTokensFinish, setClaimingLoading } from '../../../redux/actions/stakeActions';
import { createNetworkLabel } from '../../../constants/general';
import { useFetchPortxBalances } from '../../../hooks/usePortxBalances';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import { numFormatter } from '../../../services/utilsService';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';

const CompleteClaimModal = ({ claimAmount = 0 }) => {
  const dispatch = useDispatch();
  useFetchPortxBalances();
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const sdkData = useSelector(stakeSelectors.sdkData);

  const handleCloseModalClick = useCallback(async () => {
    dispatch(claimTokensFinish());
    dispatch(setClaimingLoading({ claiming: false, claimingComplete: false }));
    if (sdkData?.isEpochEnded) {
      dispatch(closeModal());
      return;
    }
    dispatch(openStakeModal());
  }, [dispatch, sdkData?.isEpochEnded]);

  return (
    <CompleteModalTemplate
      title="Claim PORTX Complete!"
      logo={<SuccessUnStakingSVG />}
      subTitle1="Claimed"
      subTitle1Value1={numFormatter(claimAmount)}
      subTitle2="Chain"
      subTitle1Value2={createNetworkLabel(baseNetwork)}
      extraRowAboveBtn={sdkData?.isEpochEnded ? '' : <S.ItemTitleAboveBtn>Stake your PORTX to earn rewards</S.ItemTitleAboveBtn>}
      BTNtxt={sdkData?.isEpochEnded ? 'Close' : `Stake ${numFormatter(claimAmount, 4) || 0} PORTX`}
      BTNonClick={handleCloseModalClick}
    />
  );
};

CompleteClaimModal.propTypes = {
  claimAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CompleteClaimModal;
