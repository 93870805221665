import React from 'react';
import _noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import Spinner from '../../Spinner';

/*                       ACTIONS                           */
import { addAllowance } from '../../../redux/actions/transactionActions';
import { stakeTokens } from '../../../redux/actions/stakeActions';
import { setApproveLoading } from '../../../redux/actions/generalActions';
import transactionSelectors from '../../../redux/selectors/transactionSelectors';
import walletSelectors from '../../../redux/selectors/walletSelectors';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import generalSelectors from '../../../redux/selectors/generalSelectors';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';
import { numFormatter } from '../../../services/utilsService';

const BUTTON_OPTIONS = {
  Approve: 'Approve',
  ApproveConfirmWallet: 'ApproveConfirmWallet',
  ApproveLoading: 'ApproveLoading',
  Staking: 'Staking',
  StakingConfirmWallet: 'StakingConfirmWallet',
  StakingLoading: 'StakingLoading',
};

const useButtonOption = () => {
  const dispatch = useDispatch();
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const selectedToken = useSelector(bridgeSelectors.selectedToken);
  const tokenAllowance = useSelector(bridgeSelectors.allowanceForToken);
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const approveLoading = useSelector(generalSelectors.approveLoading);
  const stakingLoading = useSelector(stakeSelectors.stakingLoading);
  const txHash = useSelector(transactionSelectors.txHash);

  const buttonOptions = {
    [BUTTON_OPTIONS.Approve]: {
      text: 'Approve PORTX interaction with your wallet',
      action: () => {
        dispatch(setApproveLoading(true));
        // 0 for the gas range price (relevant to ETH only)
        dispatch(addAllowance(walletAddress, 0, selectedToken, swapAmount, true));
      },
      state: 'approve',
      className: 'primary w-100 action',
    },
    [BUTTON_OPTIONS.ApproveConfirmWallet]: {
      text: <><Spinner />Confirm Tx in your wallet</>,
      action: _noop,
      state: 'ApproveConfirmWallet',
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.ApproveLoading]: {
      text: <><Spinner />Approving PORTX</>,
      action: _noop,
      state: 'approveLoading',
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.Staking]: {
      text: 'Confirm Staking',
      action: () => {
        dispatch(stakeTokens());
      },
      state: 'staking',
      className: 'primary w-100 action',
    },
    [BUTTON_OPTIONS.StakingConfirmWallet]: {
      text: <><Spinner />Confirm Tx in your wallet</>,
      action: _noop,
      state: 'StakingConfirmWallet',
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.StakingLoading]: {
      text: <><Spinner />{`Staking ${numFormatter(swapAmount)} PORTX`}</>,
      action: _noop,
      state: 'stakingLoading',
      className: 'primary w-100 action opacity7',
    },
  };

  const getButtonOption = () => {
    if (approveLoading && !txHash) {
      return BUTTON_OPTIONS.ApproveConfirmWallet;
    }
    if (approveLoading && txHash) {
      return BUTTON_OPTIONS.ApproveLoading;
    }
    if (stakingLoading.staking && !txHash) {
      return BUTTON_OPTIONS.StakingConfirmWallet;
    }
    if (stakingLoading?.staking && txHash) {
      return BUTTON_OPTIONS.StakingLoading;
    }
    if (tokenAllowance?.allowance < swapAmount) {
      return BUTTON_OPTIONS.Approve;
    }

    return BUTTON_OPTIONS.Staking;
  };
  return buttonOptions[getButtonOption()];
};

export default useButtonOption;
