import React from 'react';

import { ThemeProvider } from 'styled-components';
import colors from '../../styles/colors';
import S from './styled';
import { ReactComponent as MaintenanceSVG } from '../../assets/maintenance.svg';

const theme = {
  main_bg: colors.main_bg,
};

const Maintenance = () =>

  (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <div className="maintenance">
          <div className="header-text">
            ChainPort is down for maintenance
          </div>
          <div className="paragraph-text">Hang in there, we will be back soon (Funds are safe!)</div>
          <MaintenanceSVG />
          <div className="paragraph-text">
            Check our
            &nbsp;
            <a href="https://t.me/chainport" target="_blank" rel="noopener noreferrer">
              Telegram chat
            </a>
            &nbsp;
            to follow updates
          </div>
        </div>
      </S.Wrapper>
    </ThemeProvider>
  );
export default Maintenance;
