export const locales = {
  en: () => import('./en.json'),
  'es-ES': () => import('./es-ES.json'),
  'fr-FR': () => import('./fr-FR.json'),
  'ja-JP': () => import('./ja-JP.json'),
  // 'ko-KR': () => import('./ko-KR.json'),
  'tr-TR': () => import('./tr-TR.json'),
  // zh: () => import('./zh.json'),
  'zh-CN': () => import('./zh-CN.json'),
};
