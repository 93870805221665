import styled from 'styled-components';

const ProgressBarOuterWrapper = styled.div`

.progress-icon, .progress-text{
  opacity: 0.5;
 


  &.done{
    opacity: 1;
    transition-delay: 8s;
  }
}

.progress-text{
  width: 15%;
  @media (max-width: 480px) {
    width: 18%;
  }

  @media (max-width: 395px) {
    width: 22%;
  }
  @media (max-width: 370px) {
    width: 30%;
  }
text-align: center;


}

`;

const ProgressBarIconWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin: 0 20px 0 20px;
`;

const ProgressBarTextWrapper = styled.div`
display: flex;
flex-direction: row;
 justify-content: space-between;
margin: 0 0px 30px 0px;
`;

const S =
 {
   ProgressBarOuterWrapper,
   ProgressBarIconWrapper,
   ProgressBarTextWrapper,

 };

export default S;
