import styled from 'styled-components/macro';
import colors from '../../styles/colors';

const ContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
`;

const MailToRef = styled.a`
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    color: ${colors.gray_light};
    text-decoration: underline;

    &:hover {
        color: ${colors.aqua_bg};
        cursor: pointer;
       
      }
`;

const MailToText = styled.span`
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    color: ${colors.gray_light};
`;

const S = {
  ContainerDiv,
  MailToRef,
  MailToText,
};

export default S;
