import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNetworks, setNetworksFromUrl } from '../redux/actions/generalActions';
import bridgeSelectors from '../redux/selectors/bridgeSelectors';
import { useQuery } from '../services/utilsService';
import generalSelectors from '../redux/selectors/generalSelectors';

// sets the networks if baseNetwork and destinationNetwork are undefined

const useSetNetworksOnLoad = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const destinationNetwork = useSelector(bridgeSelectors.destinationNetwork);
  const networksByName = useSelector(generalSelectors.networksByName);
  const baseNetworkFromURL = networksByName[query.get('from')?.toUpperCase()];
  const destionationNetworkFromURL = networksByName[query.get('to')?.toUpperCase()];

  useEffect(() => {
    if (!baseNetwork || !destinationNetwork || baseNetwork?.name === destinationNetwork?.name) {
      // should be used only for initial load of the app
      dispatch(setNetworks());
    }
  }, [baseNetwork, destinationNetwork, dispatch]);

  useEffect(() => {
    if (baseNetworkFromURL && destionationNetworkFromURL) {
      dispatch(setNetworksFromUrl(baseNetworkFromURL, destionationNetworkFromURL));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default useSetNetworksOnLoad;
