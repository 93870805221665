import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import translate from '../../../../services/translate/translate';
import bridgeSelectors from '../../../../redux/selectors/bridgeSelectors';
import { setIsUserInputBalanceMax, setSwapAmount } from '../../../../redux/actions/bridgeActions';
import S from './styled';
import tokenSelectors from '../../../../redux/selectors/tokenSelectors';
import generalSelectors from '../../../../redux/selectors/generalSelectors';
import { numberWithCommas, millionFormatter, isObjectEmpty } from '../../../../services/utilsService';
import NumberInput from '../../../../components/NumberInput';
import walletSelectors from '../../../../redux/selectors/walletSelectors';
import Label from '../../../../components/Label';
import TooltipComponent from '../../../../components/Tooltip';
import { setBalances } from '../../../../redux/actions/tokenActions';
import { getUserStakedBalance } from '../../../../redux/actions/stakeActions';
import BridgeService from '../../../../services/bridge';

const PortAmountInput = ({ headLine, stakingMax, zeroBalanceDisable = true }) => {
  const intl = useIntl();

  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const selectedToken = useSelector(bridgeSelectors.selectedToken);
  const balances = useSelector(tokenSelectors.balances);
  const isWalletConnected = useSelector(walletSelectors.isWalletConnected);
  const isUserInputBalanceMax = useSelector(bridgeSelectors.isUserInputBalanceMax);
  const isMobile = useSelector(generalSelectors.isMobile);
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const networksByName = useSelector(generalSelectors.networksByName);

  const dispatch = useDispatch();

  const balanceFromWallet = useMemo(() => balances?.[selectedToken?.web3_address], [balances, selectedToken]);

  const balance = selectedToken.network_name === networksByName.CARDANO.name ? BridgeService.fromWei(balanceFromWallet, selectedToken.decimals) :
    balanceFromWallet;

  const formattedBalance = useCallback(() => {
    if (balance && balance.length > 14 && !isMobile) {
      return millionFormatter(balance, 8);
    }
    return (
      numberWithCommas(balance, 2, true)
    );
  }, [balance, isMobile]);

  const portAmountText = useMemo(() => {
    if (headLine) {
      return `${headLine}`;
    }
    if (typeof balance !== 'string' && typeof balance !== 'number') {
      return translate('common.enter_amount');
    }
    return (

      <TooltipComponent displayedValue={`${translate('modal.balance', null, null, intl)} (${formattedBalance(balance)})`} tooltipText={numberWithCommas(balance)} isTooltipNumber={true} />
    );
  }, [balance, formattedBalance, headLine, intl]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSetSwapAmount = (amount) => {
    if (amount === '') {
      return dispatch(setSwapAmount(0));
    }
    return dispatch(setSwapAmount(amount ? parseFloat(amount) : ''));
  };

  const onAmountChange = useCallback(async (value) => {
    handleSetSwapAmount(value.floatValue);
    if (isObjectEmpty(balances)) {
      dispatch(setBalances([selectedToken], walletAddress));
    }
  }, [balances, dispatch, handleSetSwapAmount, selectedToken, walletAddress]);

  const onAmountBlur = useCallback(() => {
    if (!swapAmount) return handleSetSwapAmount(0);
  }, [swapAmount, handleSetSwapAmount]);

  const onChange = useCallback(() => {
    if (isUserInputBalanceMax) {
      dispatch(setIsUserInputBalanceMax(false));
    }
  }, [isUserInputBalanceMax, dispatch]);

  const handleSetMaxAmount = async () => {
    dispatch(setIsUserInputBalanceMax(true));
    if (stakingMax) {
      const maxAmount = await dispatch(getUserStakedBalance());
      dispatch(setSwapAmount(maxAmount));
    } else {
      const balanceToPass = selectedToken.network_name === networksByName.CARDANO.name ? BridgeService.fromWei(balanceFromWallet, selectedToken.decimals) :
        balanceFromWallet;

      dispatch(setSwapAmount(balanceToPass || 0));
    }
  };

  return (
    <S.SwapAmount
      className={zeroBalanceDisable ? clsx({ disabled: balance <= 0 }) : ''}
    >
      <Label id="swap_amount" label={portAmountText} />
      <NumberInput
        type="tel"
        disabled={!isWalletConnected || !selectedToken?.web3_address || !balance}
        className="dark"
        id="swap_amount"
        value={swapAmount}
        thousandSeparator
        onChange={onChange}
        onValueChange={onAmountChange}
        onBlur={onAmountBlur}
        allowNegative={false}
        placeholder="0"
        decimalScale={selectedToken.network_name === networksByName.CARDANO.name ? 6 : undefined}
      />
      <S.MaxAmountBtn onClick={handleSetMaxAmount}>MAX</S.MaxAmountBtn>
    </S.SwapAmount>
  );
};

PortAmountInput.propTypes = {
  headLine: PropTypes.string,
  stakingMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zeroBalanceDisable: PropTypes.bool,
};

export default PortAmountInput;
