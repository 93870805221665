import React from 'react';
import styled from 'styled-components/macro';
import translate from '../services/translate/translate';
import config from '../config/config';
import { ReactComponent as ConfirmIcon } from '../assets/confirmIcon.svg';
import { ReactComponent as BurnIcon } from '../assets/burnIcon.svg';
import { ReactComponent as ReleaseIcon } from '../assets/releaseIcon.svg';
import { ReactComponent as ReceiveIcon } from '../assets/receiveIcon.svg';
import { ReactComponent as DepositIcon } from '../assets/depositIcon.svg';
import { ReactComponent as MintIcon } from '../assets/mintIcon.svg';
import { ReactComponent as StakeSVG } from '../assets/stakeSVGaction.svg';
import { ReactComponent as UnstakeSVG } from '../assets/unstakeSVGaction.svg';
import { ReactComponent as ClaimSVG } from '../assets/claimSVGaction.svg';
import ThreeDotsLoader from '../components/ThreeDotsLoader/TreeDotsLoader';

const NetworkLabel = styled.div`
  display: flex;
  align-items: center;
  div{
    display: flex;
    width: 43px;
    justify-content: center;

    img {
      height: 22px;
      margin-right: 6px;
    }
  }

`;

const NetworkLabelMyActivity = styled.div`
  display: flex;
  align-items: center;
  div{
    display: flex;
    justify-content: center;

    span { 
      margin-top: 5px;
    }


    img {
      height: 22px;
      margin-right: 6px;
    }
  }

`;

export const LS_ACCOUNT = '344cg03294173ace0a7d925d8rc9921513e291ad';
export const CARDANO_NETWORK_ID = config.environment === 'production' ? 1 : 0;

export const MESSAGE_BOX_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const createNetworkLabel = (net, ifLabel = true) => !!net && (
  <NetworkLabel className="network-label">
    <div style={{ width: '28px', marginRight: '6px' }}>
      <img src={net.icon} alt={net.label} />
    </div>
    {ifLabel ? <span>{net.label}</span> : null}
  </NetworkLabel>
);

export const createNetworkLabelShortName = (net) => !!net && (
  <NetworkLabelMyActivity className="network-label">
    <div style={{ marginRight: '6px' }}>
      <img src={net.icon} alt={net.label} />
      <span>{net.shortname}</span>
    </div>
  </NetworkLabelMyActivity>
);

export const copyLink = (baseNetwork, destinationNetwork, selectedToken) => {
  if (baseNetwork?.name && destinationNetwork?.name && selectedToken?.web3_address) {
    return navigator.clipboard.writeText(`${window.location.origin}/?from=${baseNetwork?.name}&to=${destinationNetwork?.name}&token=${selectedToken?.web3_address}`);
  }
};

export const redirectToLinkText = (baseNetwork, destinationNetwork, selectedToken, tokens) => {
  const newSelectedToken = tokens.filter((item) => item.symbol === selectedToken.symbol && item.network_name === baseNetwork.name);
  if (baseNetwork?.name && destinationNetwork?.name && selectedToken?.web3_address) {
    const returnValue = {
      path: `${window.location.origin}`,
      search: `?from=${baseNetwork?.name}&to=${destinationNetwork?.name}&token=${newSelectedToken[0]?.web3_address}`
    };
    return returnValue;
  }
};

export const ETHEREUM = 'ETHEREUM';

export const GET_TO_KNOW_CAROUSEL_TEXT = [{ text: translate('porting_fees'), id: 1 },
  { text: translate('modal.port_any_amount'), id: 2 },
  { text: translate('modal.estimated_porting_time'), id: 3 },
  { text: translate('modal.big_portings'), id: 4 },
  { text: translate('modal.any_questions'), id: 5 }
];

export const TokenText = () => (
  <div>
    {translate('expore.token_address_tooltip')}
  </div>
);

export const ADDED_NUM_TO_CONFIRMATIONS = 5;

export const AVALANCHE_CHAIN_IDS = {
  TESTNET: 43113,
  MAINNET: 43114
};

// 2 days
export const GET_TO_KNOW_MODAL_EXPIRATION_DURATION = 1000 * 60 * 60 * 24 * 2;
// 1 hour
export const PERSIST_EXPIRATION_DURATION = 1000 * 60 * 60;

export const DURATION_TABS = {
  DURATION_1M: '1m',
  DURATION_3M: '3m',
  DURATION_1Y: '1y',
};

export const DURATIONS = {
  ONE_MONTH: 30,
  THREE_MONTH: 90,
  YEAR: 365,
};

export const explorerLabels = {
  token: { labelName: translate('modal.token'), id: 1, infoIcon: false, tooltip: '', },
  symbol: { labelName: translate('stats.symbol'), id: 2, infoIcon: false, tooltip: '', },
  token_address: { labelName: translate('modal.token_address'), id: 3, infoIcon: true, tooltip: translate('expore.token_address_tooltip'), },
  decimals: { labelName: translate('stats.decimals'), id: 4, infoIcon: true, tooltip: translate('expore.decimals_tooltip'), },
  source_chain: { labelName: translate('myactivity.source_chain'), id: 5, infoIcon: true, tooltip: translate('expore.original_chain_tooltip'), },
  status: { labelName: translate('stats.status'),
    infoIcon: true,
    tooltip:
    translate('explore.status_tooltip', { strong: (word) => <strong>{word}</strong>, br: () => <br /> }),
  },
  tokens_ported: { labelName: translate('stats.tokens_ported'), id: 7, infoIcon: true, tooltip: translate('expore.tokens_ported_tooltip'), },
  value: { labelName: translate('stats.volume'), id: 8, infoIcon: true, tooltip: translate('expore.value_tooltip') },
  last_price: { labelName: translate('stats.last_price'), id: 9, infoIcon: true, tooltip: translate('expore.last_price_tooltip'), },
  // arbitrage: { labelName: 'Arbitrage', id: 10, infoIcon: true, tooltip: 'Exchange rate differences in the dollar price of the token between the various chains.' },

};

export const explorerExpandableRowLabels = {
  supported_chain: { labelName: translate('modal.target_chain'),
    id: 1,
    infoIcon: false,
    tooltip: '',
  },
  empty_div: { labelName: '', id: 2, infoIcon: false, tooltip: '', width: '14%' },
  cp_token_address: { labelName: translate('stats.chainport_token_address'),
    id: 3,
    infoIcon: true,
    tooltip: translate('expore.cp_token_address_tooltip'),

  },
  tx_to_date: { labelName: translate('stats.tx_to_date'),
    id: 4,
    infoIcon: true,
    tooltip: translate('expore.tx_date_tooltip'),
  },
  holders: { labelName: translate('stats.holders'),
    id: 5,
    infoIcon: true,
    tooltip: translate('expore.holders_tooltip'),
  },
  status: { labelName: translate('stats.status'),
    id: 6,
    infoIcon: true,
    tooltip:
    translate('expore.status_per_chain_per_token_tooltip_02', { strong: (word) => <strong>{word}</strong>, br: () => <br /> }),
  },
  tokens_ported: { labelName: translate('stats.tokens_ported'),
    id: 7,
    infoIcon: true,
    tooltip: translate('expore.ported_tokens_per_chain_tooltip'),
  },
  value: { labelName: translate('stats.value'),
    id: 8,
    infoIcon: true,
    tooltip: translate('expore.value_per_chain_tooltip'),
  },
  last_price:
  { labelName: translate('stats.last_price'),
    id: 9,
    infoIcon: true,
    tooltip: translate('expore.last_price_per_chain_tooltip'),
  }
};

export const explorerMobileExpandableRowLabels = [' supported_chain', ' empty_div', ' cp_token_address', ' last_price', 'tokens_ported', 'value', 'holders', 'tx_to_date', 'status'];
export const MILLION = 1_000_000;
export const BILLION = 1_000_000_000;
export const TRILLION = 1_000_000_000_000;
export const QUADRILLION = 1_000_000_000_000_000;
export const QUINTILLION = 1000000000000000000000000;

export const PAGE_SIZE = 20;

export const PROGRESS_STEP_OPTIONS = [
  { status: 'Confirm', icon: <ConfirmIcon /> },
  { status: 'Deposit', icon: <DepositIcon /> },
  { status: 'Burn', icon: <BurnIcon /> },
  { status: 'Mint', icon: <MintIcon /> },
  { status: 'Release', icon: <ReleaseIcon /> },
  { status: 'Receive', icon: <ReceiveIcon /> },
];

export const eventToAction = (eventName) => {
  if (eventName === 'Withdraw') {
    return <><div style={{ width: '22px', height: '22px', marginRight: '5px' }}><ClaimSVG /></div><div> Rewards claim</div></>;
  }
  if (eventName === 'PORTXWithdrawn') {
    return <><div style={{ width: '22px', height: '22px', marginRight: '5px' }}><UnstakeSVG /></div><div> Unstake</div></>;
  }
  if (eventName === 'PORTXDeposited') {
    return <><div style={{ width: '22px', height: '22px', marginRight: '5px' }}><StakeSVG /></div><div> Stake</div></>;
  }
  return <ThreeDotsLoader />;
};

export const CARDANO_WALLET_ADDRESS_CHAR = config.environment === 'production' ? 103 : 108;

export const EVM_WALLET_ADDRESS_CHAR_NUM = 42;

export const DIFFERENT_TOKEN_URL = { fuse: 'FUSE', telos: 'TELOS' };
