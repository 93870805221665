const INITIAL_STATE = {
  isMenuOpen: false,
  isBannerClosed: false,
  isMobile: window.innerWidth < 768,
  isTablet: window.innerWidth < 1280,

  networkId: null,
  gasPrices: {},
  settledGasPrice: '',
  loading: {
    approve: false,
    withdraw: false,
  },

  nativeCoinRates: {
    ETHEREUM: 1,
    POLYGON: 1,
    BSC: 1,
    FANTOM: 1,
    FUSE: 1,
    MOONRIVER: 1,
    AVALANCHE: 1,
    DOGECHAIN: 1,
    CARDANO: 1,
    MILKOMEDA: 1,
    TELOS: 1,
    OPTIMISM: 1,
    ARBITRUM: 1,

  },
  isAckLoading: false,
  activePage: 0,

  progressBarStep: 0,
  progressBarPercentage: 0,
};

export default INITIAL_STATE;
