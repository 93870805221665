import styled from 'styled-components/macro';
import colors from '../../styles/colors';
import rpcColors from '../../styles/rpcColors';

const Button = styled.button`
  border: none;
  box-sizing: border-box;
  width: 100%;
  min-height: 40px;
  border-radius: 25px;
  cursor: pointer;

  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);

  font: normal normal normal 16px/19px Roboto;

  &.link {
    background-color: transparent;
    min-width: initial;
    min-height: initial;
    color: ${colors.white};
    text-decoration: underline;
  

    &:not(:disabled):hover {
      color: ${colors.light_grey};
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
    }

    &.link-primary {
      color: ${colors.aqua_bg};

      &:not(:disabled):hover {
        color: ${colors.white};
        -webkit-transition: all .35s ease;
        transition: all .35s ease;
      }
    }
  }

  &.dark-navy {
    background-color: ${colors.navy_dark};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.w-100 {
    width: 100%;
  }

  &.w-80 {
    width: 80%;
  }

  &.adjust-left {
    margin-left: 10%;
  }

  &.ethereum-grey{
    background-color: #565C92;
    color:${colors.white};
    &:not(:disabled):hover {
      opacity:0.7;
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
      cursor: pointer;
    }
  };
  
    &.cardano {
      background-color: ${rpcColors.cardano} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.cardano {
      background-color: ${rpcColors.cardano} !important;
      color: rgba(255, 255, 255, 1) !important;
    }
    
    &.ethereum {
      background-color: ${rpcColors.ethereum} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.polygon {
      background-color: ${rpcColors.polygon} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.bsc {
      background-color: ${rpcColors.bsc} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.avalanche {
      background-color: ${rpcColors.avalanche} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.fantom {
      background-color: ${rpcColors.fantom} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.moonriver {
      background-color: ${rpcColors.moonriver} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.fuse {
      background-color: ${rpcColors.fuse} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.arbitrum {
      background-color: ${rpcColors.arbitrum} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.aurora {
      background-color: ${rpcColors.aurora} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.heco {
      background-color: ${rpcColors.heco} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.okex {
      background-color: ${rpcColors.okex} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.dogechain {
      background-color: ${rpcColors.dogechain} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.telos {
      background-color: ${rpcColors.telos} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.milkomeda {
      background-color: ${rpcColors.milkomeda} !important;
      color: rgba(255, 255, 255, 1) !important;
    }
    &.optimism {
      background-color: ${rpcColors.optimism} !important;
      color: rgba(255, 255, 255, 1) !important;
    }

    &.primary {
    background-color: ${colors.aqua_bg};
    color: ${colors.black};
    font-weight: 500;

    &:not(:disabled):hover {
      background-color: ${colors.light_aqua_bg};
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
    }
  }

  &.transparentBackground {
      background-color: transparent;
      color: ${colors.aqua_bg};
      border: 2px solid ${colors.aqua_bg};
      font-weight: 500;

      &:not(:disabled):hover {
      background-color: ${colors.light_aqua_bg};
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
      color: #09032e;
    }
  }
  
  &.portAnyway {
    background-color: transparent;
    color: ${colors.light_aqua_bg};
    font-weight: 500;
    border: 2px solid;
    border-color: ${colors.light_aqua_bg};;

    &:not(:disabled):hover {
      background-color: ${colors.light_aqua_bg};
      color: ${colors.black};
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
     
    }
  }

  &.opacity7 {
    opacity: 0.7;
  }
  &.opacity5 {
    opacity: 0.5;
  }

  &.secondary {
    background-color: transparent;
    color: ${colors.light_aqua_bg};
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    height: 30px !important;
    text-align: center;
    border: 2px solid ${colors.light_aqua_bg};
    border-radius: 50px;

    &:not(:disabled):hover {
      background-color: ${colors.light_aqua_bg};
      color: #09032e;
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
     
    }
    }

  &.connect-mm-to-network{
    background-color:${colors.black};

  }

  &.outlined-primary {
    border: 2px solid ${colors.aqua_bg};
    color: ${colors.aqua_bg};
    background-color: transparent;

    &:not(:disabled):hover {
      background-color: ${colors.aqua_bg};
      -webkit-transition: all .35s ease;
      transition: all .35s ease;

    }

    &.white {
      border: 2px solid ${colors.white};
      color: ${colors.white};
    

      &:not(:disabled):hover {
        background-color: ${colors.white};
        color: ${colors.form_inp_bg};
        -webkit-transition: all .35s ease;
        transition: all .35s ease;

      }
    }
  }


  &.action {
    min-height: 60px;
    line-height: 24px;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export default Button;
