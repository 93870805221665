import styled from 'styled-components';
import colors from '../styles/colors';

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
   background: url('/shadow.png') ${(props) => props.theme.main_bg || 'navy'};
  background-position: center;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: unset;

  


  .handle-warning-banner{
    width: 100%;
  top:40px;
     position: fixed;

    @media only screen and (max-width: 632px) {
      top:94px;
      position: fixed;

  }

 
  }


 

  @media only screen and (max-width: 632px) {
    padding-top: ${(props) => props.isBannerShown ? '150px' : '80px'};

  }
`;

const WalletLink = styled.p`
  text-align: center;
  margin-top: unset;



  &:last-of-type {
    margin-bottom: 70px;
  }
  span {
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    color: ${colors.aqua_bg};
    text-decoration: underline;
    cursor: pointer;

    &:not(:disabled):hover {
      color: ${colors.white};
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
    }
  }

  &.mobile {
    margin-bottom: 16px;
  }
`;

const Main = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  
`;

const S = { Wrapper, WalletLink, Main };

export default S;
