export const SET_SELECTED_TOKEN = 'SET_SELECTED_TOKEN';
export const SET_SWAP_AMOUNT = 'SET_SWAP_AMOUNT';
export const SET_IS_BALANCE_MAX = 'SET_IS_BALANCE_MAX';
export const SET_BASE_NETWORK = 'SET_BASE_NETWORK';
export const SET_DESTINATION_NETWORK = 'SET_DESTINATION_NETWORK';
export const SET_ALLOWANCE_FOR_TOKEN = 'SET_ALLOWANCE_FOR_TOKEN';
export const REFRESH_SELECTED_TOKEN_META = 'REFRESH_SELECTED_TOKEN_META';
export const RESET_SWAP_AMOUNT = 'RESET_SWAP_AMOUNT';
export const GET_PENDING_REDEEM_REQUEST = 'GET_PENDING_REDEEM_REQUEST';
export const GET_PENDING_REDEEM_SUCCESS = 'GET_PENDING_REDEEM_SUCCESS';
export const GET_PENDING_REDEEM_FAIL = 'GET_PENDING_REDEEM_FAIL';
export const UPDATE_REDEEM_REQUEST = 'UPDATE_REDEEM_REQUEST';
export const UPDATE_REDEEM_SUCCESS = 'UPDATE_REDEEM_SUCCESS';
export const UPDATE_REDEEM_FAIL = 'UPDATE_REDEEM_FAIL';
export const GET_USER_PENDING_PORT_REQUEST = 'GET_USER_PENDING_PORT_REQUEST';
export const GET_USER_PENDING_PORT_SUCCESS = 'GET_USER_PENDING_PORT_SUCCESS';
export const GET_USER_PENDING_PORT_FAILED = 'GET_USER_PENDING_PORT_FAILED';
export const CLEAR_SELECTED_TOKEN = 'CLEAR_SELECTED_TOKEN';
export const SET_GAS_RANGE_PRICE = 'SET_GAS_RANGE_PRICE';
export const SET_SELECTED_TOKEN_ON_TARGET_NETWORK = 'SET_SELECTED_TOKEN_ON_TARGET_NETWORK';
