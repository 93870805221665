import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import translate from '../../services/translate/translate';
import S from './styled';

const RangeControl = ({
  value, setValue, gasPrices, amountInUSD, className,
}) => {
  const [inputFocus, setInputFocus] = useState(false);
  const editRangeRef = useRef(null);
  useEffect(() => {
    if (inputFocus) return editRangeRef.current.focus();
  }, [inputFocus]);

  const handleStandard = () => setValue((gasPrices.safeLow + gasPrices.fastest) / 2);
  const handleFast = () => setValue(gasPrices.fastest);
  const handleSlow = () => setValue(gasPrices.safeLow);

  return (
    <S.Container className={className}>
      <S.Header>
        <span>  {translate('modal.set_gas')}</span>
        <span onClick={() => setInputFocus(!inputFocus)}>
          {inputFocus ? (
            <S.RangeEditInput
              ref={editRangeRef}
              onClick={(e) => e.stopPropagation()}
              type="text"
              onBlur={() => {
                setInputFocus(false);
                if (!value)
                  return setValue(gasPrices.safeLow);
              }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          ) : (
            parseFloat(value)
          )}{' '}
          GWei
          <br />
          <span>(~${amountInUSD})</span>
        </span>
      </S.Header>
      <S.RangeInput
        className={clsx({
          fastest: parseInt(value, 10) > gasPrices.fastest,
          slowest: parseInt(value, 10) < gasPrices.safeLow,
        })}
        step="0.1"
        type="range"
        min={
          value < gasPrices.safeLow
            ? value
            : gasPrices.safeLow
        }
        max={gasPrices.fastest}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <S.RangeLevels>
        <S.RangeItem onClick={handleSlow}>{translate('common.slow')}</S.RangeItem>
        <S.RangeItem onClick={handleStandard}>{translate('common.standard')}</S.RangeItem>
        <S.RangeItem onClick={handleFast}>{translate('common.fast')}</S.RangeItem>
      </S.RangeLevels>
    </S.Container>
  );
};

RangeControl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  gasPrices: PropTypes.shape({
    safeLow: PropTypes.number,
    fast: PropTypes.number,
    fastest: PropTypes.number,
  }).isRequired,
  amountInUSD: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default RangeControl;
