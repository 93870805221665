import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import translate from '../../../../services/translate/translate';
import S from '../styled';
import Token from './Token';
import ModalBody from '../../ModalBody';
import { ReactComponent as InvalidSVG } from '../../../../assets/invalid-icon.svg';
import { shortenAddress, trim } from '../../../../services/utilsService';
import Button from '../../../Button';
import ThreeDotsLoader from '../../../ThreeDotsLoader/TreeDotsLoader';
import SearchBar from '../../../SearchBar/SearchBar';

const TokenModal = ({
  closeModal,
  searchString,
  handleSearch,
  tokens,
  balances,
  setSelectedToken,
  handleCreateToken,
  networksByName,
  isMobile,
  setBalances,
  walletAddress,
  // setSelectedTokenOnTargetNetwork,
  blackListedTokens,
  liquidityTokens,
  baseNetwork,

}) => {
  const intl = useIntl();
  const tokensloading = tokens.length === 0;

  const liquidityTokensByNetwork = useMemo(() => {
    const normalizedSearch = trim(searchString || '').toLowerCase();

    return tokens
      .filter((token) => token.network_name === baseNetwork?.name && liquidityTokens.includes(token.web3_address))
      .filter(
        (token) => (trim(token.name).toLowerCase().includes(normalizedSearch) ||
          trim(token.symbol).toLowerCase().includes(normalizedSearch) ||
          trim(token.web3_address).toLowerCase().includes(normalizedSearch))

      ).sort((a, b) => {
        if (a.symbol === 'PORTX') return -1;
        if (b.symbol === 'PORTX') return 1;
        return a.symbol.localeCompare(b.symbol);
      });
  }, [tokens, baseNetwork?.name, searchString, liquidityTokens]);

  const liquidityTokensInSet = useMemo(() => new Set(liquidityTokens), [liquidityTokens]);

  const noneLiquidityTokensByNetwork = useMemo(() => {
    const normalizedSearch = trim(searchString || '').toLowerCase();

    return tokens
      .filter((token) => token.network_name === baseNetwork?.name && !liquidityTokensInSet.has(token.web3_address) && token.symbol !== 'PORTX')
      .filter(
        (token) =>
          trim(token.name).toLowerCase().includes(normalizedSearch) ||
          trim(token.symbol).toLowerCase().includes(normalizedSearch) ||
          trim(token.web3_address).toLowerCase().includes(normalizedSearch)
      );
  }, [tokens, baseNetwork?.name, searchString, liquidityTokensInSet]);

  const tokensByNetwork = useMemo(() => [...liquidityTokensByNetwork, ...noneLiquidityTokensByNetwork], [liquidityTokensByNetwork, noneLiquidityTokensByNetwork]);

  const showCreateToken = useMemo(
    () => (baseNetwork.bridges?.ChainportMainBridge)
    &&
      /^0x[a-fA-F0-9]{40}$/.test(trim(searchString).toLowerCase()) &&
      tokensByNetwork.length === 0,
    [searchString, baseNetwork, tokensByNetwork]
  );

  const onTokenSelect = useCallback(
    (item) => {
      setSelectedToken(item);
      closeModal();
      setBalances([item], walletAddress);
    },
    [setSelectedToken, closeModal, setBalances, walletAddress]
  );
  const TokenRow = (
    { index, style } // eslint-disable-line
  ) => (

    <span key={tokensByNetwork[index]} style={style}>
      <Token
        onTokenSelect={onTokenSelect}
        token={tokensByNetwork[index]}
        balance={balances[tokensByNetwork[index]?.web3_address]}
        isMobile={isMobile}
        networksByName={networksByName}
        blackListedTokens={blackListedTokens}
        liquidityTokens={liquidityTokens}
        isLiquidityToken={liquidityTokensInSet.has(tokensByNetwork[index]?.web3_address)}
      />
    </span>
  );

  return (
    <S.Wrapper>
      <ModalBody
        tokenModal={true}
        title={translate('common.select_token')}
        closeModal={closeModal}
        className="token-modal"
        id="select_token"
      >
        <SearchBar handleSearch={handleSearch} searchString={searchString} placeholderText={translate('common.search_by', null, null, intl)} isBorder={true} autoFocus={true} />

        {showCreateToken && (
          <S.TokenVerificationIndicator
            className={clsx({ invalid: showCreateToken, mobile: isMobile })}
          >
            <InvalidSVG />
            {translate('common.token_not_verfied')}
          </S.TokenVerificationIndicator>
        )}

        {!tokensloading ?
          <S.TokenList className={clsx({ mobile: isMobile })}>
            {showCreateToken
              ? (
                <S.AddTokenContainer>
                  <Button className="primary action" onClick={handleCreateToken}>
                    {`${translate('common.add_token', null, null, intl)} ${shortenAddress(
                      trim(searchString).toLowerCase()
                    )}`}
                  </Button>
                </S.AddTokenContainer>
              ) : (tokensByNetwork.length > 0 && (
                <FixedSizeList
                  height={380}
                  itemCount={tokensByNetwork.length}
                  itemSize={65}
                >
                  { TokenRow}
                </FixedSizeList>
              )) || (
                <p>{translate('common.token_not_found')}</p>
              )}
          </S.TokenList> : <S.LoadingTokensText><ThreeDotsLoader /></S.LoadingTokensText> }

      </ModalBody>
    </S.Wrapper>
  );
};

TokenModal.propTypes = {
  closeModal: PropTypes.func,
  searchString: PropTypes.string,
  walletAddress: PropTypes.string,
  handleSearch: PropTypes.func,
  tokens: PropTypes.array,
  setSelectedToken: PropTypes.func,
  // setSelectedTokenOnTargetNetwork: PropTypes.func,
  setBalances: PropTypes.func,
  handleCreateToken: PropTypes.func,
  balances: PropTypes.object,
  networksByName: PropTypes.object,
  isMobile: PropTypes.bool,
  blackListedTokens: PropTypes.array,
  liquidityTokens: PropTypes.array,
  baseNetwork: PropTypes.object,

};

export default TokenModal;
