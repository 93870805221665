import React from 'react';

const FortmaticIcon = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7525 0.966309H17.3998H9.01194H0.624023V9.35422V17.7421V18.2708V26.0596V26.0948V34.4122H9.01194V26.13V26.0948V18.306V17.7774V9.38946H17.3998H25.7878H34.1757V1.00155H25.7525V0.966309Z"
      fill="#617BFF"
    />
    <path
      d="M17.4346 26.0596H25.752V34.4475H26.0692C28.219 34.4475 30.2631 33.6017 31.7786 32.0862C33.2941 30.5708 34.1399 28.5266 34.1399 26.3768V17.7422H17.4346V26.0596Z"
      fill="#617BFF"
    />
  </svg>
);

export default FortmaticIcon;
