import styled from 'styled-components/macro';

const CookiePrivacyLink = styled.a`
  color: #0deebd;
  font-size: 15px;

  &:hover {
    color: white;
  }
`;

const S = { CookiePrivacyLink };

export default S;
