/* eslint-disable react/jsx-indent */
import React, { useCallback, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TooltipComponent from '../../Tooltip';
import translate from '../../../services/translate/translate';
import ModalBody from '../ModalBody';
import S from './styled';
import BridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import GeneralSelectors from '../../../redux/selectors/generalSelectors';
import TokenSelectors from '../../../redux/selectors/tokenSelectors';
import Button from '../../Button';
import { shortenAddress, numberWithCommas, isObjectEmpty, numFormatter, millionFormatter } from '../../../services/utilsService';
import { setWithdrawLoading, updateProgressBarPercentage } from '../../../redux/actions/generalActions';
import { Mailto } from '../../MailTo/MailTo';
// import InfoIcon from '../../Icons/InfoIcon';
import { createNetworkLabel, DIFFERENT_TOKEN_URL } from '../../../constants/general';
import { ReactComponent as ShevronSVG } from '../../../assets/shevron-left.svg';
import walletSelectors from '../../../redux/selectors/walletSelectors';
import BridgeService from '../../../services/bridge';
import { ReactComponent as RoundYellowWarning } from '../../../assets/roundYellowWarning.svg';
import { toggleModal } from '../../../redux/actions/modalActions';
import ADAIcon from '../../Icons/ADAIcon';

const ConfirmTxModal = ({ handleWithdrawTokens, amountInUSD, closeModal, toggleModal, baseNetwork,
  destinationNetwork, selectedToken, swapAmount, gasRangePrice, isTokenHasLiquidity,
  setWithdrawLoading, isTokenCFB, calculatedPortxFeeObj, updateProgressBarPercentage, isTxNativeTokentoEth }) => {
  const [isPortAnyway, setIsPortAnyway] = useState(isTokenHasLiquidity);
  const [disabled, setDisabled] = useState(false);
  const [isAdaFeeWarningOpen, setIsAdaFeeWarningOpen] = useState(false);

  const networksByName = useSelector(GeneralSelectors.networksByName);
  const nativeCoinRates = useSelector(GeneralSelectors.nativeCoinRates);

  const toggleAdaWarning = useCallback(() => setIsAdaFeeWarningOpen(!isAdaFeeWarningOpen), [isAdaFeeWarningOpen]);

  const modifiedGasLabel = useCallback(() => {
    if (destinationNetwork.id === networksByName.ETHEREUM.id && isTxNativeTokentoEth) {
      return (
        <div className="extra-minting-fee"> + minting fee</div>
      );
    }
    if (destinationNetwork?.id === networksByName.CARDANO.id) {
      return (
        <S.AdaWrapper onClick={toggleAdaWarning}>
          +
          <span className="ada-icon-wrapper">
            <ADAIcon />
          </span>
        ADA Cost
          {isAdaFeeWarningOpen ?
            <ShevronSVG className="arrow-icon rotate" /> : <ShevronSVG className="arrow-icon" />}
        </S.AdaWrapper>);
    }
    return '';
  }, [destinationNetwork, isTxNativeTokentoEth, isAdaFeeWarningOpen, toggleAdaWarning, networksByName]);

  const CONFIRM_MODAL_DATA = useMemo(() => [
    { label: translate('modal.token'), value: selectedToken?.symbol, id: 1, toolTipText: 'Token name to be ported', toolTipShown: true, classname: '', active: true },
    { label: translate('modal.token_address'), value: shortenAddress(selectedToken?.web3_address), id: 2, toolTipText: 'Original token address. The token recieved on the target chain will have different address unique for the target chain', toolTipShown: true, classname: '', active: true },
    { label: translate('modal.amount'), value: numberWithCommas(swapAmount), id: 3, toolTipText: 'The number of tokens to be ported. if the fee is paid in the token, you will recieve the amount less the fee.', toolTipShown: true, classname: '', active: true },
    { label: translate('myactivity.source_chain'), value: <div className="labelSVGcontainer">{createNetworkLabel(baseNetwork)}<ShevronSVG /></div>, id: 4, toolTipText: 'The chain that the token will be porting from', toolTipShown: true, classname: '', active: true },
    { label: translate('modal.target_chain'), value: createNetworkLabel(destinationNetwork), id: 5, toolTipText: 'The chain that the token will be porting to', toolTipShown: true, classname: '', active: true },
    { label: translate('modal.gas_fee'),
      value: `${baseNetwork.id === networksByName.CARDANO.id ? `~0.17 ADA (~$${0.17 * nativeCoinRates.CARDANO})` : `${gasRangePrice} Gwei (~$${amountInUSD})`}`,
      id: 6,
      toolTipText: `The porting process requires only one signature, and there is an extra charge of ~$6 in ${baseNetwork?.native_token_symbol} to cover the gas cost of minting and receiving the ported tokens on the target chain.`,
      toolTipShown: isTxNativeTokentoEth,
      classname: 'gasTx',
      active: true },
    { label:
    <S.AdaWarningTextBox>{translate('modal.extra_ada_fee',
      { sourceChain: baseNetwork.native_token_symbol })}
    </S.AdaWarningTextBox>,
    id: 7,
    active: isAdaFeeWarningOpen },

    { label: 'Porting Fee', value: `${calculatedPortxFeeObj?.is_portx_fee_payment ? `${millionFormatter(BridgeService.fromWei(calculatedPortxFeeObj?.portx_fee_amount_in_wei, selectedToken?.decimals || 18), 8)} PORTX (0.2%)` || 'N/A' : `${millionFormatter(BridgeService.fromWei(calculatedPortxFeeObj?.base_token_fee_amount_in_wei, selectedToken?.decimals || 18), 8)} ${selectedToken?.symbol} (0.3%)`}`, id: 8, toolTipShown: false || 0, classname: 'gasTx', active: true },
  ], [selectedToken?.symbol, selectedToken?.web3_address, selectedToken.decimals, swapAmount, baseNetwork, destinationNetwork, gasRangePrice, amountInUSD, calculatedPortxFeeObj, networksByName, nativeCoinRates, isAdaFeeWarningOpen, isTxNativeTokentoEth]);

  // if (data?.is_portx_fee_payment) {
  //   return data?.portx_fee_amount_in_wei;
  // }
  // return data?.base_token_fee_amount_in_wei;

  const handlePortAnyway = () => {
    setIsPortAnyway(true);
  };

  const onAddressClick = useCallback(
    (e) => {
      e.stopPropagation();
      window.open(
        `${baseNetwork?.explorer_url}${DIFFERENT_TOKEN_URL[baseNetwork?.name.toLowerCase()] ? 'address/' : 'token/'}${
          selectedToken?.web3_address
        }`,
        '_blank',
        'noopener,noreferrer'
      );
    },
    [baseNetwork?.explorer_url, selectedToken?.web3_address, baseNetwork?.name]
  );

  const handleConfirmTxModal = useCallback(() => {
    setWithdrawLoading(true);
    updateProgressBarPercentage(30);
    setDisabled(true);
    handleWithdrawTokens();
    toggleModal();
  }, [setWithdrawLoading, updateProgressBarPercentage, handleWithdrawTokens, toggleModal]);

  return (
    <S.Wrapper>
      <ModalBody closeModal={closeModal} title={translate('modal.confirm_porting')}>
        {CONFIRM_MODAL_DATA.map(({ label, value, id, toolTipText, toolTipShown, active, classname }) => (
          <S.ConfirmTxDetailsWrapper key={id}>
            <S.Label isAdaFeeWarningOpen={isAdaFeeWarningOpen}>
              { active && label}
              { label?.props?.defaultMessage === 'Gas fee' && modifiedGasLabel()}
              {toolTipShown ?
                <TooltipComponent tooltipText={toolTipText} />
                :
                ''}
            </S.Label>
            <div>
              {label === 'Token' &&
                <img src={selectedToken?.token_image} alt="" width="15px" height="15px" />
              }
            </div>
            <S.Value
              className={classname}
              label={label}>
              {label?.props?.defaultMessage === 'Token address' ?
                <S.TokenAddress
                  onClick={onAddressClick}
                >
                  {value}
                </S.TokenAddress>
                : value}
            </S.Value>
          </S.ConfirmTxDetailsWrapper>
        ))}

        {isTokenHasLiquidity ? ''
          :
          <Mailto
            email="hello@chainport.io"
            subject="Add token to ChainPort liquidity pool"
            body=""
            childrenBTN={translate('modal.contact_us')}
            childrenText={translate('modal.add_token_to')}
          /> }

        {isTokenHasLiquidity && isPortAnyway ?
          null :
          <S.NoLiqudityWarning><S.WarningIconWrapper><RoundYellowWarning /></S.WarningIconWrapper><S.LinkWrapper>{translate('modal.notice_text02', { ExploreLink: (word) => <Link to="/explorer"><span onClick={toggleModal} className="explorer-link">{word}</span></Link> })}</S.LinkWrapper>
          </S.NoLiqudityWarning>}
        {(isTokenCFB && !isObjectEmpty(selectedToken) && isTokenHasLiquidity) || (isTokenCFB && !isObjectEmpty(selectedToken) && isPortAnyway) ?
          <S.CFBcontainer>
            {translate('modal.custody_text', { selectedToken: selectedToken?.name })} <a href="https://www.chainport.io/for-business" target="_blank" rel="noopener noreferrer"> {translate('modal.learn_more')}</a>
          </S.CFBcontainer> : null}

        { isTokenHasLiquidity || (!isTokenHasLiquidity && isPortAnyway) ?
          <div className="confirm-button-wrapper">
            <Button
              onClick={handleConfirmTxModal}
              disabled={disabled}
              className="primary w-100 action"> {translate('modal.confirm_porting_to', { swapAmount: numFormatter(swapAmount, 2), selectedTokenSymbol: selectedToken.symbol, destinationNetwork: destinationNetwork.label })}
            </Button>
          </div> :
          <div className="confirm-button-wrapper">
            <Button onClick={handlePortAnyway} className="portAnyway w-100 action">{translate('button.port_anyway')}
            </Button>
          </div> }

        <S.TermsConditionsText>{translate('modal.agree_text_02', { TermsOfServicesLink: (word) =>
          <a href="https://chainport.io/terms-of-services" target="_blank" rel="noopener noreferrer">
            <S.TermsConditionsSpan>{word}</S.TermsConditionsSpan>
          </a> })}
        </S.TermsConditionsText>
      </ModalBody>

    </S.Wrapper>

  );
};

const mapStateToProps = (state) => ({
  baseNetwork: BridgeSelectors.baseNetwork(state),
  selectedToken: BridgeSelectors.selectedToken(state),
  swapAmount: BridgeSelectors.swapAmount(state),
  gasRangePrice: BridgeSelectors.gasRangePrice(state),
  destinationNetwork: BridgeSelectors.destinationNetwork(state),
  networksByChain: GeneralSelectors.networksByChain(state),
  isTokenCFB: TokenSelectors.isTokenCFB(state),
  walletAddress: walletSelectors.walletAddress(state),
});

const mapDispatchToProps = {
  setWithdrawLoading,
  updateProgressBarPercentage,
  toggleModal,

};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmTxModal);

ConfirmTxModal.propTypes = {
  closeModal: PropTypes.func,
  swapAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baseNetwork: PropTypes.object,
  destinationNetwork: PropTypes.object,
  selectedToken: PropTypes.object,
  gasRangePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleWithdrawTokens: PropTypes.func,
  amountInUSD: PropTypes.number,
  isTokenHasLiquidity: PropTypes.bool,
  setWithdrawLoading: PropTypes.func,
  isTokenCFB: PropTypes.bool,
  calculatedPortxFeeObj: PropTypes.object,
  updateProgressBarPercentage: PropTypes.func,
  toggleModal: PropTypes.func,
  isTxNativeTokentoEth: PropTypes.bool,

};
