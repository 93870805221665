import React from 'react';
import CookieConsent from 'react-cookie-consent';
// import S from './styled';
import translate from '../../services/translate/translate';
import S from './styled';

export default (isMobile) => (
  <CookieConsent
    disableButtonStyles={true}
    // onAccept={() => console.log('clicked')}
    // extraCookieOptions={null} // an object to add more options to the cookie
    // cookieName="MyCookieName"
    expires={30} // 30 days
    location="bottom"
    buttonText={translate('common.accept_cookies')}
    buttonClasses="cookieBTN"
    buttonStyle={{ margin: 0 }}
    contentStyle={{ display: 'flex', margin: '15px 0px' }}
    style={{
      fontSize: '15px',
      border: '1px solid black',
      background: '#09032e',
      textAlign: 'center',
      fontFamily: 'Roboto',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '6px 12px',
      marginBottom: isMobile.isMobile ? '80px' : '0px',
    }}
  >
    <span> {translate('common.cookie_policy_02', { CookiePrivacyLink: (word) =>
      <S.CookiePrivacyLink href="https://chainport.io/privacy-policy" rel="noopener noreferrer">{word}</S.CookiePrivacyLink> })}
    </span>
    <div style={{ flex: 1 }} />
  </CookieConsent>
);
