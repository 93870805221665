import { css } from 'styled-components/macro';

const flexItXY = (positionX, positionY) => css`
  display: flex;
  justify-content: ${positionX || 'flex-start'};
  align-items: ${positionY || 'flex-start'};
`;

const centerAbsolute = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const mixins = {
  flexItXY,
  centerAbsolute,
};

export default mixins;
