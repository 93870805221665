import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { WalletConnector } from '@dcentralab/web3-wallet-connector';
import { Header as DUBHeader } from '@dcentralab/unified-frontend';
import translate from '../../services/translate/translate';
import { ReactComponent as ChainportLogo } from '../../assets/chainport_logo.svg';
import srcFlatWhite from '../../constants/flatWhiteRpcs';
import WalletSelectors from '../../redux/selectors/walletSelectors';
import GeneralSelectors from '../../redux/selectors/generalSelectors';
import BridgeSelectors from '../../redux/selectors/bridgeSelectors';
import TransactionSelectors from '../../redux/selectors/transactionSelectors';
import { toggleMenuMode, setActivePage } from '../../redux/actions/generalActions';
import {
  onWalletConnectRequestAction,
  onWalletConnectErrorAction,
  onWalletDisconnectAction,
  onWalletConnectSuccessAction, switchNetwork,
} from '../../redux/actions/walletActions';
import { toggleModal } from '../../redux/actions/modalActions';
import { acknowledgePort, setSelectedToken, setSwapAmount } from '../../redux/actions/bridgeActions';
import { CARDANO_NETWORK_ID } from '../../constants/general';
import colors from '../../styles/colors';
import rpcColors from '../../styles/rpcColors';

import aboutIcon from '../../assets/about.svg';
import helpIcon from '../../assets/help.svg';
import securityIcon from '../../assets/security.svg';
import auditIcon from '../../assets/audit.svg';
import portxFlatWhite from '../../assets/flatWhiteRpc/portxFlatWhite.svg';
import twitterIcon from '../../assets/twitter.svg';
import telegramIcon from '../../assets/telegram.svg';
import docIcon from '../../assets/documentation.svg';
import statusIcon from '../../assets/appstatus.svg';
import termsIcon from '../../assets/legal.svg';
import PortXWidget from './PortXWidget';
import { useQuery } from '../../services/utilsService';
import { MULTPLE_ADDRESSES_TYPE } from '../../constants/wallet';

const StyledHeader = styled(DUBHeader)`
  display: flex;
  //top: 200px;
  padding: 0 15px;
  min-height: 80px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.form_inp_bg};
  background: ${colors.main_bg};

  .web3-wc_connect-wallet-button {
    border-radius: 500px;
    min-height: 40px;
    border: none;
    width: 100%;
    background: ${(props) => (props.isWalletConnected ? `${colors.transparent}` : `${colors.dark_gray}`)};
    color: ${colors.white};
    overflow: visible;

    &:hover {
      opacity:0.7;
      background: ${(props) => (props.isWalletConnected ? `${colors.transparent}` : '#555')};
    }
  }

 
  
  .duf-header-container {
    max-width: 100%;
  }

  .duf-header-logo-text {
    padding: 2px 7px;
    border: 1px solid rgb(47, 246, 211);
    border-radius: 25px;
    color: rgb(47, 246, 211);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
  }

  /* duf-networkSelector-menu active rtl */

  .duf-header-links {
    a {
      font-weight: 400;
      text-decoration:none;
      color: ${colors.gray_light};

      &:hover {
        color: ${colors.white};
        -webkit-transition: all .35s ease;
        transition: all .35s ease;
      }
      &.active {
        color: ${colors.white};
      }
    }
  }


  .web3-wc_connect-wallet-button.connected{
    border-radius: 500px;
    border: 1px solid white;
    background: ${colors.transparent};
    
  }

  .web3-wc_connect-wallet-button.connected.withBalance {
    border-radius: 500px;
    justify-content: center;
    border: 1px solid white;
    background: #001B43;
    width: 113%;
    margin-top:-1px;
    padding: 6px 9px 6px 9px;

    @media screen and (max-width: 834px) {
      width: 107%;
    }
  } 




  .web3-wc_modal_header{
    h3 {
      font-size: 24px !important;
    }
  }

  .duf-networkSelector-button {
    // pointer-events: ${(props) => (props.isPortProcessing ? 'none' : 'null')};
    display: ${(props) => (props.isWalletConnected ? 'null' : 'none')};
    background: ${(props) => (props.baseNetwork?.name ? `${rpcColors[props.baseNetwork.name.toLowerCase()]}` : `${colors.gray_extra_light}`)} !important;
    .duf-networkSelector-buttonContent {
      white-space: nowrap;
      background: none;
      width: ${({ theme }) => (theme.isMobile ? '50px' : '')};
      img {
        position: ${({ theme }) => (theme.isMobile ? 'absolute' : 'none')};
      }
      .duf-networkSelector-arrow {
        width: 0;
      }
      @media screen and (max-width: 790px) {
        .duf-networkSelector-chainName{
          display: none;
        }
      }
    }
  }

  .duf-networkSelector-menu.active.rtl {
    background: ${colors.dark_bg};
    margin-left: -10px;
    li:last-child {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: -23px;
    }
  }

  .duf-contextMenu-menu {
    background: ${colors.dark_bg};
  }

  @media screen and (max-width: 1112px) {
    .duf-header-wrapper__right {
      background: ${colors.dark_bg};
      &.checked {
        top: ${(props) => (props.isBannerShown ? '120px' : '80px')};
      }
    }
  
  }

    .duf-header-wallet-container {
      border-top: 1px solid ${colors.form_inp_bg};
      background: ${colors.main_bg};
    }


 
 
  @media screen and (max-width: 632px) {
    .duf-header-wrapper__right {
      &.checked {
        top: ${(props) => (props.isBannerShown ? '174px' : '80px')};

      }
    }
  }
`;

const Header = ({
  isMenuOpen,
  toggleMenuMode,
  networks,
  networksByChain,
  onWalletConnectRequestAction,
  onWalletConnectErrorAction,
  onWalletDisconnectAction,
  onWalletConnectSuccessAction,
  setSelectedToken,
  setSwapAmount,
  acknowledgePort,
  processingPort,
  pendingRedeem,
  txHash,
  setActivePage,
  account,
  switchNetwork,
  toggleModal,
  networksByName,
  isBannerShown,
  walletType,
  walletBalance,
  connectingWalletProvider,
  // approveLoading,
  // eslint-disable-next-line no-unused-vars
  isWalletConnected, // this is used in the styledHeader component
  baseNetwork,
  network
}) => {
  const history = useHistory();
  const intl = useIntl();
  const query = useQuery();
  const baseNetworkFromURL = networksByName[query.get('from')?.toUpperCase()];

  const modifiedNetworks = useMemo(() => {
    const newArr = [...networks];
    const objIndex = newArr.findIndex(((obj) => obj.blockchainType === 'cardano'));
    newArr[objIndex].networkId = CARDANO_NETWORK_ID;
    const modifiedArr = newArr.map((item) => ({ ...item, btnLogo: srcFlatWhite[item.name.toLowerCase()] }));
    return modifiedArr;
  }, [networks]);
  // eslint-disable-next-line no-param-reassign

  const onNetworkSelect = useCallback((netId, blockchainType) => {
    const network = blockchainType === 'cardano' ? networksByName.CARDANO : networksByChain[netId];
    if (network) {
      setSelectedToken({});
      switchNetwork({
        networkId: netId,
        rpcUrl: network.rpc,
        blockchainType: network.blockchain_type,
      });
    }
  }, [networksByChain, networksByName.CARDANO, setSelectedToken, switchNetwork]);

  const handleOnLogoClick = useCallback(() => {
    if (isMenuOpen) {
      toggleMenuMode();
    }
    toggleModal();
    if (processingPort?.target_tx_status) {
      acknowledgePort(processingPort);
      history.push('/');
      return null;
    }
    if (processingPort || pendingRedeem || txHash) {
      return null;
    }
    setSelectedToken({});
    setSwapAmount(0);
    setActivePage(0);
  }, [acknowledgePort, history, isMenuOpen, pendingRedeem, processingPort, setActivePage, setSelectedToken, setSwapAmount, toggleMenuMode, toggleModal, txHash]);

  const logo = useMemo(() => ({
    img: <ChainportLogo />,
    text: 'App',
    to: '/',
    onClick: handleOnLogoClick,
  }), [handleOnLogoClick]);

  const links = useMemo(() => [
    { text: translate('modal.port'), to: '/', onClick: () => toggleModal() },
    { text: translate('modal.explore'), to: '/explorer', onClick: () => toggleModal() },
    { text: translate('common.stats'), to: '/stats', onClick: () => toggleModal() },
    { text: translate('common.my_activity'), to: '/my-activity', onClick: () => toggleModal() },
  ], [toggleModal]);

  const menuLinks = useMemo(() => ([
    { icon: aboutIcon, text: translate('header.about'), href: 'https://chainport.io/' },
    // { icon: helpIcon, text: translate('header.help'), onClick: () => window.Intercom('show') },
    { icon: helpIcon, text: translate('header.help'), onClick: () => window.$crisp.push(['do', 'chat:open']) },
    { icon: docIcon, text: translate('header.documentation'), href: 'https://docs.chainport.io/' },
    { icon: securityIcon, text: translate('header.security'), href: 'https://www.chainport.io/security' },
    { icon: portxFlatWhite, text: translate('header.portx_token'), href: 'https://www.chainport.io/token' },
    { icon: twitterIcon, text: translate('header.twitter'), href: 'https://twitter.com/chain_port' },
    { icon: telegramIcon, text: translate('header.telegram'), href: 'https://t.me/chainport' },
    { icon: statusIcon, text: translate('common.app_status'), href: 'https://status.chainport.io/' },
    { icon: termsIcon, text: translate('header.terms'), href: 'https://www.chainport.io/terms-of-services' },
    { icon: auditIcon, text: translate('header.audit'), href: 'https://www.certik.com/projects/chainport' },
  ]), []);

  const networkSelectorProps = useMemo(() => ({
    networks: modifiedNetworks,
    networkId: network,
    account,
    onNetworkSelect,
    blockchainType: baseNetwork?.blockchainType,
  }), [modifiedNetworks, network, account, onNetworkSelect, baseNetwork]);

  const networkIdToPass = useMemo(() => {
    if (baseNetworkFromURL?.chainId) {
      return baseNetworkFromURL?.chainId;
    }
    if (baseNetwork?.blockchainType === 'cardano') {
      return CARDANO_NETWORK_ID;
    }
    return baseNetwork?.chainId;
  }, [baseNetworkFromURL, baseNetwork]);

  // const isPortProcessing = useMemo(() => !!((
  //   processingPort || approveLoading || txHash)),
  // [processingPort, approveLoading, txHash]);

  const externalBalanceToPass = useMemo(() => {
    if (MULTPLE_ADDRESSES_TYPE[walletType]) {
      return walletBalance;
    }
    return false;
  }, [walletBalance, walletType]);

  const connectingWalletProviderToPass = useMemo(() => {
    if (MULTPLE_ADDRESSES_TYPE[walletType] && connectingWalletProvider) {
      return false;
    }
    return true;
  }, [connectingWalletProvider, walletType]);

  return (

    <StyledHeader
      logo={logo}
      links={links}
      menuLinks={menuLinks}
      networkSelectorProps={networkSelectorProps}
      isWalletConnected={isWalletConnected}
      className={isBannerShown && 'handle-warning-banner'}
      isBannerShown={isBannerShown}
      baseNetwork={baseNetwork}
      blockchainType={baseNetwork?.blockchain_type}
      walletComponent={(
        <WalletConnector
          networkId={networkIdToPass}
          rpcUrl={baseNetworkFromURL?.rpc ? baseNetworkFromURL.rpc : baseNetwork?.rpc}
          onConnect={onWalletConnectSuccessAction}
          onConnectRequest={onWalletConnectRequestAction}
          onConnectError={onWalletConnectErrorAction}
          onDisconnect={onWalletDisconnectAction}
          showBalance={connectingWalletProviderToPass}
          externalBalance={externalBalanceToPass}
          onClick={toggleModal}
          intl={intl}
          blockchainType={baseNetwork?.blockchain_type}
        />
      )}
    >
      {isWalletConnected && <PortXWidget />}
    </StyledHeader>
  );
};

Header.propTypes = {
  isMenuOpen: PropTypes.bool,
  toggleMenuMode: PropTypes.func,
  networksByChain: PropTypes.object,
  baseNetwork: PropTypes.object,
  onWalletConnectRequestAction: PropTypes.func.isRequired,
  onWalletConnectErrorAction: PropTypes.func.isRequired,
  onWalletDisconnectAction: PropTypes.func.isRequired,
  onWalletConnectSuccessAction: PropTypes.func.isRequired,
  setSelectedToken: PropTypes.func,
  setSwapAmount: PropTypes.func,
  acknowledgePort: PropTypes.func,
  processingPort: PropTypes.object,
  pendingRedeem: PropTypes.object,
  txHash: PropTypes.string,
  setActivePage: PropTypes.func,
  account: PropTypes.string,
  networks: PropTypes.array,
  switchNetwork: PropTypes.func.isRequired,
  isWalletConnected: PropTypes.bool,
  toggleModal: PropTypes.func,
  isBannerShown: PropTypes.bool,
  // approveLoading: PropTypes.bool,
  networksByName: PropTypes.object,
  network: PropTypes.number,
  walletType: PropTypes.string,
  walletBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  connectingWalletProvider: PropTypes.bool,

};

const mapStateToProps = (state) => ({
  isWalletConnected: WalletSelectors.isWalletConnected(state),
  isMenuOpen: GeneralSelectors.isMenuOpen(state),
  network: WalletSelectors.network(state),
  networksByChain: GeneralSelectors.networksByChain(state),
  networksByName: GeneralSelectors.networksByName(state),
  baseNetwork: BridgeSelectors.baseNetwork(state),
  processingPort: BridgeSelectors.processingPort(state),
  pendingRedeem: BridgeSelectors.pendingRedeem(state),
  txHash: TransactionSelectors.txHash(state),
  networks: GeneralSelectors.networks(state),
  account: WalletSelectors.walletAddress(state),
  isBannerShown: GeneralSelectors.isBannerShown(state),
  // approveLoading: GeneralSelectors.approveLoading(state),
  walletType: WalletSelectors.walletType(state),
  walletBalance: WalletSelectors.walletBalance(state),
  connectingWalletProvider: WalletSelectors.connectingWalletProvider(state),

});

const mapDispatchToProps = {
  toggleMenuMode,
  onWalletConnectRequestAction,
  onWalletConnectErrorAction,
  onWalletDisconnectAction,
  onWalletConnectSuccessAction,
  setSwapAmount,
  setSelectedToken,
  acknowledgePort,
  setActivePage,
  switchNetwork,
  toggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
