/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import ModalBody from '../ModalBody';
import Button from '../../Button';
import S from './styled';
import { shortenAddress, numFormatter } from '../../../services/utilsService';
import TreeDotsLoader from '../../ThreeDotsLoader/TreeDotsLoader';
import translate from '../../../services/translate/translate';

/*                       ACTIONS                           */
import { closeModal } from '../../../redux/actions/modalActions';
import { createNetworkLabel } from '../../../constants/general';
import useButtonOption from './useButtonOption';
import Warning from '../../Warning';
import { ReactComponent as YellowWarningSVG } from '../../../assets/yellowWarning.svg';
import { ReactComponent as Claim } from '../../../assets/claim.svg';
import { ReactComponent as AwaySVG } from '../../../assets/away-icon.svg';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';
import transactionSelectors from '../../../redux/selectors/transactionSelectors';
import generalSelectors from '../../../redux/selectors/generalSelectors';
import useTransactionFee from '../../../hooks/useTransactionFee';
import walletSelectors from '../../../redux/selectors/walletSelectors';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';

const ClaimModal = () => {
  const dispatch = useDispatch();

  const ethTransactionFee = useTransactionFee(21000, 35);
  const isWalletConnected = useSelector(walletSelectors.isWalletConnected);
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const selectedToken = useSelector(bridgeSelectors.selectedToken);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const claimingLoading = useSelector(stakeSelectors.claimingLoading);
  const txHash = useSelector(transactionSelectors.txHash);
  const networksByName = useSelector(generalSelectors.networksByName);
  const sdkData = useSelector(stakeSelectors.sdkData);
  const isMobile = useSelector(generalSelectors.isMobile);

  const polyNet = networksByName.POLYGON;

  const buttonOption = useButtonOption(numFormatter(sdkData?.totalPendingAndBalance, 4));

  const onAddressClick = useCallback((e, isToken) => {
    e.stopPropagation();
    const url = isToken
      ? `${baseNetwork?.explorer_url}token/${selectedToken?.web3_address}`
      : `${baseNetwork?.explorer_url}address/${walletAddress}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }, [baseNetwork?.explorer_url, selectedToken?.web3_address, walletAddress]);

  return (
    <S.Wrapper isCloseEnable={!claimingLoading?.claiming}>
      <ModalBody showHeader={true} closeModal={() => { dispatch(closeModal()); }} title={<S.Title><Claim />Claim PORTX</S.Title>} subTitle="Staking rewards">
        {!ethTransactionFee?.isEnoughBalance && isWalletConnected && !txHash && (
          <S.WarningWrapper>
            <Warning isMobile={isMobile} classes="error-info">
              <p style={{ fontSize: '14px' }}>
                {translate('modal.no_funds', { tokenname: 'Matic', network: baseNetwork.label_name })}
              </p>
            </Warning>
          </S.WarningWrapper>
        )}
        <S.DetailsWrapper>
          <S.ColumDetailsWrapper>
            <S.ColumnWrapper>
              <>
                <S.ItemTitle>Amount</S.ItemTitle>
                <S.Value className="lightGreen">{sdkData?.totalPendingAndBalance ? <>{numFormatter(sdkData?.totalPendingAndBalance)} PORTX</> : <TreeDotsLoader />}</S.Value>
              </>
            </S.ColumnWrapper>
            <S.ColumnWrapper>
              <S.ItemTitle>Token address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, true)}>{shortenAddress(selectedToken?.web3_address || 'ab')}</S.LinkTo></S.Value>
            </S.ColumnWrapper>
          </S.ColumDetailsWrapper>

          <S.ColumDetailsWrapper>
            <S.ColumnWrapper>
              <><S.ItemTitle>Stake on</S.ItemTitle><S.Value>{createNetworkLabel(baseNetwork)}</S.Value></>
            </S.ColumnWrapper>
            <S.ColumnWrapper>
              <S.ItemTitle>Wallet address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, false)}>{shortenAddress(walletAddress)}</S.LinkTo></S.Value>
            </S.ColumnWrapper>
          </S.ColumDetailsWrapper>
        </S.DetailsWrapper>
        {buttonOption.state === 'approve' || buttonOption.state === 'approveLoading' ?
          <S.YellowWarningWrapper>
            <S.SVGwarningWrapper>
              <YellowWarningSVG />
            </S.SVGwarningWrapper>
            <S.YellowWarning>In the first interaction with PORTX, per chain, one more signature must be made to allow the wallet interact with the token.</S.YellowWarning>
          </S.YellowWarningWrapper>
          :
          buttonOption.state === 'ClaimModal' ?
            <S.YellowWarningWrapper>
              <S.SVGwarningWrapper>
                <YellowWarningSVG />
              </S.SVGwarningWrapper>
              <S.YellowWarning>Notice - After staking, fees for porting are paid with PORTX. This will be changeable in the next version of ChainPort.</S.YellowWarning>
            </S.YellowWarningWrapper>
            :
            ''
        }
        <S.ButtonWrapper>
          <Button
            className={buttonOption.className}
            onClick={buttonOption.action}
            disabled={!sdkData?.totalPendingAndBalance}
          > {buttonOption.text}
          </Button>
        </S.ButtonWrapper>
        {txHash && (
          <S.CheckTx href={`${polyNet?.explorer_url}tx/${txHash}`} target="_blank" rel="noopener noreferrer">
            {translate('modal.check_tx')}
            <AwaySVG />
          </S.CheckTx>
        )}
      </ModalBody>
    </S.Wrapper>
  );
};

export default ClaimModal;
