import { toast } from 'react-toastify';
import axiosRetry from 'axios-retry';
import BridgeService from '../../services/bridge';
import GeneralSelectors from '../selectors/generalSelectors';
import TransactionSelectors from '../selectors/transactionSelectors';
import { getBlockConfirmations, isObjectEmpty, wait, setExpirationInLS, stakingContract } from '../../services/utilsService';
import { buildCardanoTx, signTx, getCardanoTxStatus } from '../../services/cardanoService';
import {
  fetchPendingPort,
  fetchPendingRedeem,
  refreshSelectedTokenMeta,
  setAllowanceForToken,
  waitForProcessingPort,
  waitForRedeemSignature,
} from './bridgeActions';
import { setApproveLoading, setWithdrawLoading, setAllowanceLoading, updateProgressBar, updateProgressBarPercentage } from './generalActions';
import { getTokenOrigin, checkTokenIsNative } from './tokenActions';
import { BLACKLISTED_TOKEN } from '../../components/Toasts/toastMessages';
import axios from '../../services/axios';
import {
  RESET_TX_HASH,
  SET_TX_HASH,
  SET_PROCESSING_LOADER,
  UPDATE_BLOCK_CONFIRMATIONS,
  START_INTERVAL_FOR_BLOCK_CONFIRMATIONS,
  CLEAR_INTERVAL_FOR_BLOCK_CONFIRMATIONS,
  SET_PORT_TX,
  SET_REDEEM_TX,
  SET_IS_REDEEM_ON_GOING,
  SET_PORT_TIMEOUT_ID,
  DEPOSIT_TOKENS_FINISHED,
  WITHDRAW_TOKENS_FINISHED,
  ADD_ALLOWANCE_FINISHED,
  CLEAR_TX_STATE,
  SET_CURRENT_TX_NONCE,
  SET_CURRENT_TX_BLOCK,
  SET_PORTX_FEE,
  UPDATE_CARDANO_TX_HASH,

} from '../actionTypes/transactionActionTypes';
import {
} from '../actionTypes/bridgeActionTypes';
import translate from '../../services/translate/translate';
import BridgeSelectors from '../selectors/bridgeSelectors';

export const clearTransactionState = () => (dispatch) => {
  dispatch({ type: CLEAR_TX_STATE });
};

export const clearCanceledTransaction = () => ({
  type: DEPOSIT_TOKENS_FINISHED
});

export const setCurrentTxBlock = (currentTxBlock) => ({
  type: SET_CURRENT_TX_BLOCK,
  payload: currentTxBlock
});

export const setCurrentTxNonce = (currentTxNonce) => ({
  type: SET_CURRENT_TX_NONCE,
  payload: currentTxNonce
});

export const setPortTimeoutId = (id) => ({
  type: SET_PORT_TIMEOUT_ID,
  payload: id
});

export const setIsRedeemOnGoing = (bool) => ({
  type: SET_IS_REDEEM_ON_GOING,
  payload: bool
});

export const setRedeemTx = (redeemTx) => ({
  type: SET_REDEEM_TX,
  payload: redeemTx
});

export const setPortTx = (portTx) => ({
  type: SET_PORT_TX,
  payload: portTx
});

export const setTXHash = (hash) => ({
  type: SET_TX_HASH,
  payload: hash
});

export const resetTXHash = () => ({
  type: RESET_TX_HASH,
});

export const updateBlockConfirmations = (blockConfirmations) => (dispatch) => {
  dispatch({
    type: UPDATE_BLOCK_CONFIRMATIONS,
    payload: blockConfirmations,
  });
};

export const setPortTransaction = (key, walletAddress, tx) => (dispatch, getState) => {
  const state = getState().transactions;
  const existingTransactions = TransactionSelectors.portTx(state);
  let newTransaction = {};
  if (existingTransactions) {
    const parsedTransaction = existingTransactions;
    if (parsedTransaction[key]) {
      newTransaction = {
        ...parsedTransaction,
        [key]: {
          ...parsedTransaction[key],
          [walletAddress]: tx
        }
      };
    } else {
      newTransaction = {
        ...parsedTransaction,
        [key]: {
          [walletAddress]: tx
        }
      };
    }
  } else {
    newTransaction = {
      [key]: {
        [walletAddress]: tx,
      }
    };
  }
  dispatch(setPortTx(newTransaction));
};

// eslint-disable-next-line no-unused-vars
export const removePortTransaction = (key, walletAddress) => (dispatch, getState) => {
  const state = getState().transactions;
  const existingTransactions = TransactionSelectors.portTx(state);
  if (existingTransactions) {
    if (existingTransactions[key]) {
      dispatch(setPortTx({}));
      // leave those commented in case we wanna support multi wallet (might be new UI soon)
      // delete parsedExistingTransaction[key][walletAddress];
      // localStorage.setItem('transactions', JSON.stringify(parsedExistingTransaction));
    }
  }
};

export const startIntervalForBlockConfirmations = (tx) => (dispatch, getState) => {
  const state = getState();
  const networksByName = GeneralSelectors.networksByName(state);
  const baseNetwork = BridgeSelectors.baseNetwork(state);
  const { id } = baseNetwork;
  const interval = (id === networksByName.ETHEREUM.id) ? 13000 : 6000;
  const intervalId = setInterval(async () => {
    let blockConfirmation;
    if (id !== networksByName.CARDANO.id) {
      blockConfirmation = await getBlockConfirmations(tx, id);
    } else {
      const resp = await getCardanoTxStatus(tx);
      blockConfirmation = resp?.burn_tx?.block_confirmations;
    }

    const state = getState();
    const { transactions } = state;
    const { interval_id } = transactions;
    if (interval_id) {
      dispatch(updateBlockConfirmations(blockConfirmation));
      if (blockConfirmation <= baseNetwork.min_confirmations) {
        dispatch(updateProgressBarPercentage(blockConfirmation));
      }
    }
  }, interval);

  dispatch({
    type: START_INTERVAL_FOR_BLOCK_CONFIRMATIONS,
    payload: intervalId,
  });
};

export const clearIntervalForBlockConfirmations = () => (dispatch, getState) => {
  const state = getState();
  const { interval_id } = state.transactions;

  clearInterval(interval_id);
  dispatch({
    type: CLEAR_INTERVAL_FOR_BLOCK_CONFIRMATIONS
  });

  dispatch(updateBlockConfirmations(0));
};

export const pollCardanoTx = (tx) => async (dispatch, getState) => {
  const state = getState();
  const { general: { progressBarStep } } = state;
  let result = await getCardanoTxStatus(tx);
  while (!result?.burn_tx?.tx_hash) {
    const blockConfirmations = result?.deposit_tx?.block_confirmations;
    dispatch(updateBlockConfirmations(blockConfirmations));

    if (blockConfirmations < 15) {
      // handle when blockconfirmations jumps to greater than 10 and skips 10
      const blockConfirmationsToPass = blockConfirmations > 10 ? 10 : blockConfirmations;
      dispatch(updateProgressBarPercentage(blockConfirmationsToPass));
    }

    dispatch({
      type: UPDATE_CARDANO_TX_HASH,
      payload: { cardanoDepositTx: result?.deposit_tx?.tx_hash, cardanoBurnTx: '' },
    });

    // TODO: change the polling interval in production?
    // eslint-disable-next-line no-await-in-loop
    await wait(16000);
    // eslint-disable-next-line no-await-in-loop
    result = await getCardanoTxStatus(tx);
  }
  // updateProgressBar hardcoded

  if (progressBarStep < 3) {
    dispatch(updateProgressBar(2));
  }
  dispatch({
    type: UPDATE_CARDANO_TX_HASH,
    payload: { cardanoDepositTx: result.deposit_tx.tx_hash, cardanoBurnTx: result.burn_tx.tx_hash },
  });
  return result;
};

export const pollCardanoBurnTx = (tx) => async (dispatch) => {
  let result = await getCardanoTxStatus(tx);
  while (result?.burn_tx?.block_confirmations < 12) {
    const burnBlockConfirmations = result?.burn_tx?.block_confirmations;
    dispatch(updateBlockConfirmations(burnBlockConfirmations));

    if (burnBlockConfirmations < 12) {
      // handle when blockconfirmations jumps to greater than 10 and skips 10
      const blockConfirmationsToPass = burnBlockConfirmations > 10 ? 10 : burnBlockConfirmations;
      dispatch(updateProgressBarPercentage(blockConfirmationsToPass));
    }
    // eslint-disable-next-line no-await-in-loop
    await wait(22000);
    // eslint-disable-next-line no-await-in-loop
    result = await getCardanoTxStatus(result?.deposit_tx?.tx_hash);
  } return result;
};

export const getReceiptMined = (isTransactionMined, currentTxBlock, currentTxNonce, walletAddress, tx) => async (dispatch) => {
  let blockNumber = currentTxBlock;
  if (!blockNumber) {
    blockNumber = await window._web3.eth.getBlockNumber();
    dispatch(setCurrentTxBlock(blockNumber));
  }
  let walletNonce = currentTxNonce;
  if (!walletNonce) {
    walletNonce = await BridgeService.getTxCount(walletAddress);
    // save the wallet nonce
    dispatch(setCurrentTxNonce(walletNonce));
  }
  let receipt;
  let portTimeout;
  if (!isTransactionMined) {
  // start the set timeout counter that tracks after the the txHash to track long waiting
    portTimeout = window.setTimeout(() => {
      toast.info('This operation takes longer than expected. Please check again in 10 minutes and if not resolved, please open a support ticket from the chatbox below', { autoClose: false });
    }, 300_000);
  }
  receipt = await BridgeService.getTransactionReceiptMined(tx, walletAddress, walletNonce, blockNumber);
  if (!isTransactionMined) {
    clearTimeout(portTimeout);
  }
  if (receipt === 'CLEAR') {
    dispatch(clearCanceledTransaction());
    dispatch(clearIntervalForBlockConfirmations());
    dispatch(updateProgressBar(0));
    toast.success(translate('common.tx_canceled'));
    return null;
  }
  if (typeof receipt === 'string' && !(receipt === 'CLEAR')) {
    const tx = receipt;
    dispatch(setTXHash(tx));
    const nextNonce = await BridgeService.getTxCount(walletAddress);
    receipt = await BridgeService.getTransactionReceiptMined(tx, walletAddress, nextNonce);
    toast.success(translate('common.tx_sped_up'));
  }
  return receipt;
};

export const refreshAllowance = (walletAddress, selectedToken, isStaking = false) => async (dispatch, getState) => {
  dispatch(setAllowanceLoading(true));
  const state = getState();
  const { general } = state;
  const { networksByName } = general;
  const tokenNetworkId = networksByName[selectedToken.network_name].id;
  // dispatch  checkTokenIsNative here to wait for the api response
  const tokenIsNative = await dispatch(checkTokenIsNative(tokenNetworkId, selectedToken.web3_address));
  let contractAddress;
  if (isStaking) {
    contractAddress = stakingContract();
  } else {
    contractAddress = tokenIsNative
      ? networksByName[selectedToken.network_name].bridges.ChainportMainBridge
      : networksByName[selectedToken.network_name].bridges.ChainportSideBridge;
  }
  if (!contractAddress) {
    toast.info(BLACKLISTED_TOKEN);
    dispatch(setAllowanceLoading(false));
    return;
  }
  return BridgeService.getAllowance(
    selectedToken.web3_address,
    walletAddress,
    contractAddress,
    selectedToken.chain_id
  )
    .then((allowanceWei) => ({ allowance: BridgeService.fromWei(allowanceWei, selectedToken?.decimals), allowanceWei }))
    .then(({ allowance, allowanceWei }) => {
      dispatch(setAllowanceForToken({
        allowance: Number(allowance),
        token_address: selectedToken.web3_address,
        networkAddress:
        contractAddress,
      }));
      dispatch(setAllowanceLoading(false));
      return allowanceWei;
    });
};

export const depositTokens = () => async (dispatch, getState) => {
  const state = getState();
  // making sure that the redeemOnGoing bol is false
  dispatch(setIsRedeemOnGoing(false));
  const {
    wallet: { account: walletAddress, recipientWalletAddress },
    bridge: { selected_token, swapAmount, gasRangePrice, destinationNetwork, isUserInputBalanceMax, baseNetwork },
    token: { balancesWei },
    general: { withdrawLoading, progressBarStep },
    transactions: { tx_hash, portTx, block_confirmations, interval_id, currentTxBlock, currentTxNonce },
  } = state;
  let newSwapAmount;

  if (isUserInputBalanceMax) {
    newSwapAmount = balancesWei[selected_token.web3_address];
  } else {
    newSwapAmount = BridgeService.toWei(swapAmount, selected_token.decimals);
  }

  if (withdrawLoading && !tx_hash) {
    dispatch(setWithdrawLoading(false));
  }
  // if the value is null he will be changed later, if not use the old tx hash
  let tx = tx_hash;
  try {
    if (!tx_hash && isObjectEmpty(portTx)) {
      // after a refresh it might have not correct values
      if (block_confirmations > 0) {
        dispatch(updateBlockConfirmations(0));
      }
      const extraGasFee = await BridgeService.gasFeesPerNetwork(destinationNetwork.id, baseNetwork.id, 'depositTokens');
      if (destinationNetwork.blockchainType !== 'cardano') {
        tx = await BridgeService.depositTokens(
          baseNetwork.id,
          selected_token.web3_address,
          newSwapAmount,
          destinationNetwork.id,
          { from: walletAddress, gasPrice: (gasRangePrice * 10 ** 9).toFixed() },
          extraGasFee
        );
      } else {
        tx = await BridgeService.nonEVMDepositTokens(
          baseNetwork.id,
          selected_token.web3_address,
          newSwapAmount,
          destinationNetwork.id,
          recipientWalletAddress,
          { from: walletAddress, gasPrice: (gasRangePrice * 10 ** 9).toFixed() },
          extraGasFee
        );
      }
      // start of the transaction, returns the tx hash
      setExpirationInLS('expiryPersist');
      // saving the new tx hash to redux state in "transactions.txHash"
      dispatch(setTXHash(tx));
      dispatch(updateProgressBar(progressBarStep + 1));

      // saving the new tx hash to redux state in "transactions.portTx"
      dispatch(setPortTransaction('depositTokens', walletAddress, tx));
    }

    const receipt = await dispatch(getReceiptMined(interval_id, currentTxBlock, currentTxNonce, walletAddress, tx));
    if (!receipt) {
      return;
    }
    if (receipt) {
      tx = receipt.transactionHash;
    }
    dispatch(startIntervalForBlockConfirmations(receipt.transactionHash));
    if (receipt.status) {
      await dispatch(waitForProcessingPort(walletAddress));
    }
    dispatch(clearIntervalForBlockConfirmations());
    dispatch(setPortTx({}));
    if (selected_token && walletAddress) {
      dispatch(refreshSelectedTokenMeta(selected_token, walletAddress)); // THIS THROW ABI AND ADDRESS ERROR
    }
    dispatch({ type: DEPOSIT_TOKENS_FINISHED });
  } catch (e) {
    console.warn(e);
    toast.error(e.message);
    dispatch(setWithdrawLoading(false));
    dispatch(clearIntervalForBlockConfirmations());
  }
};

export const withdrawTokens = () => async (dispatch, getState) => {
  const state = getState();
  const {
    wallet: { account: walletAddress, recipientWalletAddress },
    bridge: { selected_token, swapAmount, destinationNetwork, isUserInputBalanceMax, baseNetwork },
    token: { balancesWei },
    general: { networksByName, progressBarStep },
    transactions: { tx_hash, portTx, interval_id, currentTxBlock, currentTxNonce } } = state;

  // vars
  const originNetworkId = await dispatch(getTokenOrigin(networksByName[selected_token.network_name]?.id, selected_token.web3_address, 'getTokenOrigin'));
  let newSwapAmount;

  if (isUserInputBalanceMax) {
    newSwapAmount = balancesWei[selected_token.web3_address];
  } else {
    newSwapAmount = BridgeService.toWei(swapAmount, selected_token.decimals);
  }

  let tx = tx_hash;
  // check why tx hash not canceled the rest if MM not opened
  try {
    if (!tx_hash && isObjectEmpty(portTx)) {
      // check if tx is from Cardano
      if (baseNetwork.blockchainType === 'cardano') {
        // toWei func is in this case used to convert the amount to Lovelace TODO change the name of the func
        const swapAmountInLovelace = BridgeService.toWei(swapAmount, selected_token.decimals);
        const cborTxToSign = await buildCardanoTx(
          walletAddress,
          swapAmountInLovelace,
          recipientWalletAddress,
          destinationNetwork.id,
          selected_token.web3_address);
        tx = await signTx(cborTxToSign);
        dispatch(updateProgressBar(progressBarStep + 1));
        dispatch(setTXHash(tx));
        dispatch(setPortTransaction('cardanoBurnTokens', walletAddress, tx));
        // why is this needed?
        if (!tx) {
          toast.error('Transaction was not signed, please refresh and try again.');
          // TODO : clear the state progressbar and confirm loading
          setWithdrawLoading(false);
          updateProgressBar(0);
          updateProgressBarPercentage(0);
        }

        setExpirationInLS('expiryPersist');
        // check if tx is a burn or crosschain transaction
      } else if (originNetworkId === destinationNetwork?.id) {
        tx = await BridgeService.burnTokens(
          baseNetwork?.id,
          selected_token.web3_address,
          newSwapAmount,
          { from: walletAddress }
        );
        dispatch(setPortTransaction('burnTokens', walletAddress, tx));
        setExpirationInLS('expiryPersist');
        dispatch(setTXHash(tx));
        dispatch(updateProgressBar(progressBarStep + 1));
      } else if (!tx_hash && isObjectEmpty(portTx)) {
        const extraGasFee = await BridgeService.gasFeesPerNetwork(destinationNetwork.id, baseNetwork.id, 'crossChainTransfer');
        if (destinationNetwork.blockchainType !== 'cardano') {
          tx = await BridgeService.crossChainTransfer(
            baseNetwork?.id,
            selected_token.web3_address,
            newSwapAmount,
            destinationNetwork?.id,
            { from: walletAddress },
            extraGasFee
          );
        } else {
          tx = await BridgeService.nonEVMCrossChainTransfer(
            baseNetwork?.id,
            selected_token.web3_address,
            newSwapAmount,
            destinationNetwork?.id,
            recipientWalletAddress,
            { from: walletAddress },
            extraGasFee

          );
        }
        dispatch(setPortTransaction('crossChain', walletAddress, tx));
        setExpirationInLS('expiryPersist');
        dispatch(setTXHash(tx));
        dispatch(updateProgressBar(progressBarStep + 1));
      }
    }

    let transactionStatus;

    if (baseNetwork.blockchainType === 'cardano') {
      const cardanoTxObj = await dispatch(pollCardanoTx(tx));
      const { burn_tx, deposit_tx } = cardanoTxObj;
      transactionStatus = burn_tx.tx_hash;
      await dispatch(pollCardanoBurnTx(deposit_tx?.tx_hash));
      // this one is needed?
      // dispatch(setTXHash(tx));
    }
    if (baseNetwork.blockchainType !== 'cardano') {
      const receipt = await dispatch(getReceiptMined(interval_id, currentTxBlock, currentTxNonce, walletAddress, tx));
      if (!receipt) {
        return;
      }
      if (receipt) {
        tx = receipt.transactionHash;
        // block confirmations for Cardano will be returned in the getCardanotxStatus()
        dispatch(startIntervalForBlockConfirmations(tx));
        transactionStatus = tx;
      }
    }

    if (transactionStatus) {
      if (originNetworkId === destinationNetwork?.id) {
        await dispatch(waitForRedeemSignature(walletAddress));
      } else {
        await dispatch(waitForProcessingPort(walletAddress));
      }
    }

    dispatch(clearIntervalForBlockConfirmations());

    dispatch({ type: WITHDRAW_TOKENS_FINISHED });
    dispatch(setWithdrawLoading(false));
    if (baseNetwork.blockchainType !== 'cardano') {
      dispatch(refreshSelectedTokenMeta(selected_token, walletAddress)); // from here their is a throw of "wrong abi \ address"
    }
  } catch (e) {
    toast.error(e.message);
    dispatch(setWithdrawLoading(false));
    dispatch(clearIntervalForBlockConfirmations());
  }
};

export const addAllowance = (walletAddress, gasRangePrice, selectedToken, swapAmount, isStaking = false) => async (dispatch, getState) => {
  const state = getState();
  const { bridge: { selected_token },
    general: { networksByName },
    token: { tokensForApprovalByExactUserBalance },
    transactions: { tx_hash, portTx, interval_id, currentTxBlock, currentTxNonce }
  } = state;
  const tokenNetworkId = networksByName[selectedToken.network_name].id;

  const swapAmountWei = BridgeService.toWei(swapAmount, selectedToken?.decimals);
  // if token is native pass the main bridge contract address to approve()
  const isNativeToken = await dispatch(checkTokenIsNative(tokenNetworkId, selectedToken.web3_address));
  let contractAddress;
  if (isStaking) {
    contractAddress = stakingContract();
  } else {
    contractAddress = isNativeToken
      ? networksByName[selectedToken.network_name].bridges.ChainportMainBridge
      : networksByName[selectedToken.network_name].bridges.ChainportSideBridge;
  }

  // calculate gas price for eth
  dispatch(setApproveLoading(true));
  const txMeta = { from: walletAddress };
  if (selected_token.network_name === networksByName.ETHEREUM.name) {
    txMeta.gasPrice = (gasRangePrice * 10 ** 9).toFixed();
  }
  const amountWei = (tokensForApprovalByExactUserBalance.includes(selectedToken?.web3_address)) ? swapAmountWei : BridgeService.getMaxNumberWei();
  let tx = tx_hash;
  try {
    if (!tx_hash && isObjectEmpty(portTx)) {
      tx = await BridgeService.approve(
        selected_token.chain_id,
        contractAddress,
        selected_token.web3_address,
        amountWei,
        txMeta
      );

      dispatch(setTXHash(tx));
    }
    const receipt = await dispatch(getReceiptMined(interval_id, currentTxBlock, currentTxNonce, walletAddress, tx));
    if (!receipt) {
      return;
    }
    if (receipt) {
      tx = receipt.transactionHash;
    }

    if (receipt.status) {
      let counter = 0;
      let allowanceWei;
      /* eslint-disable no-await-in-loop */

      // check if amount was approved
      do {
        counter += 1;
        if (counter > 1) {
          await wait(500);
        }
        if (isStaking) {
          allowanceWei = await dispatch(refreshAllowance(walletAddress, selected_token, true));
        } else {
          allowanceWei = await dispatch(refreshAllowance(walletAddress, selected_token));
        }
      } while (amountWei !== allowanceWei && counter < 10);
      if (counter === 10 && amountWei !== allowanceWei) {
        toast.error('Allowance Tx is not yet approved. Please try to refresh your page');
        dispatch(setApproveLoading(false));
        dispatch(resetTXHash());
      } else {
        toast.success(translate('common.allowance'));
        dispatch(setApproveLoading(false));
        dispatch(resetTXHash());
      }
      dispatch({ type: ADD_ALLOWANCE_FINISHED });
    }
  } catch (e) {
    console.warn(e);
    toast.error(e.message);
    dispatch(setApproveLoading(false));
    dispatch(resetTXHash());
  } finally {
    dispatch(setApproveLoading(false));
    dispatch(resetTXHash());
  }
};

const waitForPort = (walletAddress, transactions) => (dispatch) => {
  let timer;
  const { depositTokens } = transactions;
  const interval = setInterval(() => {
    dispatch(fetchPendingPort(walletAddress, 'ROUTES')).then((port) => {
      if (timer) {
        clearTimeout(timer);
      }
      if (interval) {
        clearInterval(interval);
      }
      dispatch(setWithdrawLoading(false));
      dispatch(setPortTx({}));
      if (port?.id && !port?.target_tx_status) {
        dispatch(waitForProcessingPort(walletAddress));
      }
    });
  }, 1000 * 10);

  timer = setTimeout(async () => {
    if (interval) {
      clearInterval(interval);
      clearTimeout(timer);
      const transaction = await window._web3.eth.getTransaction(depositTokens[walletAddress]);
      if (transaction) {
        // TODO make toaster for this
        console.error('Transaction is pending');
      } else {
        console.error('Something wrong with port');
      }
    }
  }, 1000 * 60 * 5);
};

// TODO check if this func is in use
const waitForWithdraw = (walletAddress, transactions) => (dispatch) => {
  let timer;
  const { burnTokens } = transactions;

  const interval = setInterval(() => {
    dispatch(fetchPendingRedeem(walletAddress)).then(() => {
      if (timer) {
        clearTimeout(timer);
      }
      if (interval) {
        clearInterval(interval);
      }
    });
  }, 1000 * 10);

  timer = setTimeout(async () => {
    if (interval) {
      clearInterval(interval);
      clearTimeout(timer);
      const transaction = await window._web3.eth.getTransaction(burnTokens[walletAddress]);
      if (transaction) {
        // TODO make toaster for this
        console.error('Transaction is  still pending');
      } else {
        console.error('Something wrong with port');
      }
    }
  }, 1000 * 60 * 5);
};

export const waitForTransaction = (walletAddress) => (dispatch, getState) => {
  const state = getState();

  const transactionsJSON = TransactionSelectors.portTx(state);
  // TODO this one is not really exacuted
  const cardanoDepositTx = TransactionSelectors.cardanoDepositTx(state);
  const isRedeemOnGoing = TransactionSelectors.isRedeemOnGoing(state);
  if (cardanoDepositTx && !isRedeemOnGoing) {
    dispatch(pollCardanoTx(cardanoDepositTx));
    dispatch(setWithdrawLoading(true));
  }
  if (transactionsJSON) {
    const transactions = transactionsJSON;
    const { depositTokens, burnTokens, crossChain, cardanoBurnTokens } = transactions;
    if (depositTokens?.[walletAddress]) {
      dispatch(setTXHash(depositTokens[walletAddress]));
      dispatch(setWithdrawLoading(true));
      dispatch(waitForPort(walletAddress, transactions));
    } else if (burnTokens?.[walletAddress]) {
      dispatch(setTXHash(burnTokens[walletAddress]));
      dispatch(setWithdrawLoading(true));
      dispatch(waitForWithdraw(walletAddress, transactions));
    } else if (crossChain?.[walletAddress]) {
      dispatch(setTXHash(crossChain[walletAddress]));
      dispatch(setWithdrawLoading(true));
      dispatch(waitForPort(walletAddress, transactions));
    } else if (cardanoBurnTokens?.[walletAddress]) {
      dispatch(setTXHash(cardanoBurnTokens[walletAddress]));
      dispatch(setWithdrawLoading(true));
      dispatch(pollCardanoTx(cardanoBurnTokens[walletAddress]));
    }
  }
};

export const checkPendingTransactionsForAddress = (walletAddress) => (dispatch) => {
  dispatch({ type: SET_PROCESSING_LOADER, payload: true });
  Promise.all([
    dispatch(fetchPendingPort(walletAddress)),
    dispatch(fetchPendingRedeem(walletAddress)),
  ]).finally(() => {
    dispatch({ type: SET_PROCESSING_LOADER, payload: false });
  });
};

export const calculatePortxFee = (swapAmount, selectedToken, networkId, web3Address) => async (dispatch) => {
  const swapAmountConverted = BridgeService.toWei(swapAmount, selectedToken?.decimals);
  try {
    axiosRetry(axios, {
      retries: 3, // number of retries
      retryDelay: (retryCount) => retryCount * 2000
    });
    const { data } = await axios.get('/fees/estimate', {
      params: { network_id: networkId, token_id: selectedToken.id, amount_in_wei: parseFloat(swapAmountConverted, 10), web3_address: web3Address }
    });
    const isPortx = data?.is_portx_fee_payment;
    const modifiedData = {
      ...data,
      feeFromWei: isPortx ? parseFloat(BridgeService.fromWei(data.portx_fee_amount_in_wei, selectedToken?.decimals || 18), 10) : parseFloat(BridgeService.fromWei(data.base_token_fee_amount_in_wei, selectedToken?.decimals || 18), 10),
      amountBeforeFee: swapAmount,
    };
    dispatch({ type: SET_PORTX_FEE, payload: modifiedData });
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const clearCalculatePortxFee = () => (dispatch) => {
  dispatch({ type: SET_PORTX_FEE, payload: {} });
};
