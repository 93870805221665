import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ReactComponent as Circle } from '../../assets/circle.svg';
import S from './styled';

const ProgressBar = ({ progressStepsLength, progressBarStep, progressBarPercentage }) => (
  <S.ProgressBarWrapper>
    {Array.from(Array(progressStepsLength)).map((x, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <S.ProgessBarLineAndCircleWrapper key={index}>
        <Circle className={clsx('circle', { done: index <= progressBarStep, current: index === progressBarStep })} />
        <S.ProgressBarLine className="line" barlength={progressStepsLength}>
          <S.ProgressBarLineInner
            className={clsx({ active: index <= progressBarStep })}
            barCompleted={index < progressBarStep ? 100 : progressBarPercentage}
          />
        </S.ProgressBarLine>
      </S.ProgessBarLineAndCircleWrapper>))}

  </S.ProgressBarWrapper>
);

ProgressBar.propTypes = {
  progressStepsLength: PropTypes.number.isRequired,
  progressBarStep: PropTypes.number.isRequired,
  progressBarPercentage: PropTypes.number.isRequired,
};

export default ProgressBar;
