import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import bridgeSelectors from '../redux/selectors/bridgeSelectors';
import tokenSelectors from '../redux/selectors/tokenSelectors';
import walletSelectors from '../redux/selectors/walletSelectors';

export default (gas = 21000, gasRangePrice = 1) => {
  const walletBalance = useSelector(walletSelectors.walletBalance);
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const selectedToken = useSelector(bridgeSelectors.selectedToken);
  const balances = useSelector(tokenSelectors.balances);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  // need to check for Cardano

  const fee = baseNetwork?.blockchain_type === 'cardano' ? 3.18 : (gas * (gasRangePrice * 10 ** 9) * 10 ** -18);
  return useMemo(() => ({
    fee,
    isEnoughBalance: fee <= parseFloat(walletBalance),
    isEnoughTokens: balances[selectedToken?.web3_address] >= swapAmount,
  }), [walletBalance, swapAmount, selectedToken, balances, fee]);
};
