import styled from 'styled-components/macro';
import colors from '../../styles/colors';

const Loader = styled.div`
  &.loader,
  &.loader:after {
    border-radius: 50%;
    width: 67px;
    height: 67px;
  }
  &.loader {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 4px solid rgba(255, 255, 255, 0.15);
    border-right: 4px solid rgba(255, 255, 255, 0.15);
    border-bottom: 4px solid rgba(255, 255, 255, 0.15);
    border-left: 4px solid ${colors.aqua_bg};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 3s infinite linear;
    animation: load8 3s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
