import Axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '../config/config';
import { EVM_WALLET_ADDRESS_CHAR_NUM, CARDANO_WALLET_ADDRESS_CHAR } from '../constants/general';
import { MULTPLE_ADDRESSES_TYPE } from '../constants/wallet';

const axios = Axios.create({ baseURL: config.baseUrl });

export const buildCardanoTx = async (walletAddress, lovelaceAmount, targetWeb3Address, targetNetworkId, assetFingerprint) => {
  try {
    axiosRetry(axios, {
      retries: 3, // number of retries
      retryDelay: (retryCount) => retryCount * 2000
    });

    const resp = await axios('/cardano/build_tx', { params:
      { cardano_user_address: walletAddress,
        lovelace_amount: lovelaceAmount,
        target_network_id: targetNetworkId,
        target_web3_address: targetWeb3Address,
        asset_fingerprint: assetFingerprint,
      } });
    return resp.data.tx;
  } catch (error) {
    console.log(error);
  }
};

export const signTx = async (cborHex) => {
  // eslint-disable-next-line no-unused-vars
  const walletData = JSON.parse(localStorage.getItem('walletData'));
  let encodedTxVkeyWitnesses;
  if (walletData.walletName === MULTPLE_ADDRESSES_TYPE[walletData?.walletName]) {
    const WalletObject = await window.cardano[walletData?.walletName].enable();
    encodedTxVkeyWitnesses = await WalletObject.signTx(cborHex);
  } else {
    encodedTxVkeyWitnesses = await window.cardano.signTx(cborHex);
  }
  try {
    const resp = await axios('/cardano/sign_tx', { params:
    { transaction_cbor: cborHex, transaction_witness_set_cbor: encodedTxVkeyWitnesses
    } });
    return resp.data.tx_hash;
  } catch (error) {
    console.log(error);
  }
};

// {"deposit_tx": {"tx_hash": None, "block_number": None}, "burn_tx": {"tx_hash": None, "block_number": None}}

export const getCardanoTxStatus = async (txHash) => {
  try {
    const resp = await axios('/cardano/tx_status', { params:
    { cardano_deposit_hash: txHash
    } });
    return resp.data;
  } catch (error) {
    console.log(error);
  }
};

export const validCharactersForAddress = (blockchainType) => {
  if (blockchainType === 'cardano') {
    return EVM_WALLET_ADDRESS_CHAR_NUM;
  }
  return CARDANO_WALLET_ADDRESS_CHAR;
};

export const getCardanoSingleWalletBalance = async (walletAddress) => {
  try {
    const resp = await axios('/cardano/account_balance', { params: { cardano_user_address: walletAddress } });
    return resp.data.ada;
  } catch (error) {
    console.log(error);
  }
};
