import styled from 'styled-components/macro';
import colors from '../../../styles/colors';

const Wrapper = styled.div`
font-family: ROBOTO;

  .modal-body {
    .modal-header {
      .modal-title {
        text-align: center !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding-right: ${({ theme }) => theme.isMobile ? '2rem' : ''};
      }
    }
    /* max-width: 410px; */
    min-width: 300px;
    /* padding: ${({ theme }) => theme.isMobile ? '0px 32px' : ''}; */
    margin: 0 auto;
    .icon-close {
      display: ${(props) => props.isCloseEnable ? '' : 'none'};
    }
  }

  .modal-body-content {
    padding: 0 !important;
  }

  .loader {
    margin-bottom: 18px;
  }

  button {
    min-width: 190px;
  }

  .result-icon {
    margin: 25px auto 20px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const RowWrapper = styled.div`
  min-height: 50px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 2rem;
  &:first-of-type {
    padding: 1.5rem 2rem 0.75rem 2rem;
  }
  &:nth-of-type(5) { // last-of-type not working
    padding: 0.75rem 2rem 1.5rem 2rem;
  }
`;

const ItemTitle = styled.div`
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
`;

const Value = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 700;
`;

const borderDividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0rem;
`;

const BorderDivider = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  width: 100%;
  height: 1px;
`;

const YellowWarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: ${({ theme }) => theme.isMobile ? '0rem 3rem' : '0rem 2rem'};
  svg {
    height: 19px;
    width: 20px;
  }
`;

const YellowWarning = styled.div`
  color: rgba(243, 186, 47, 1);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 0.5rem;
`;

const SVGwarningWrapper = styled.div`
  width: 50px;
  padding-right: 1rem;
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  padding: 0rem 2rem 1rem 2rem;
`;

const LinkTo = styled.div`
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
  :hover{
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`;

const CheckTx = styled.a`
  transform: translateY(-15px);
  text-decoration: underline;
  color: ${colors.aqua_bg}; 
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  -webkit-transition: all .5s ease;
  transition: all .5s ease;

  svg g, svg path {
    stroke: ${colors.aqua_bg}
  }

  &:not(:disabled):hover {
    color: ${colors.white};
    -webkit-transition: all .35s ease;
    transition: all .35s ease;

    svg g, svg path {
      stroke: ${colors.white}
    }

    path {
      stroke: ${colors.white}
    }
  }

  & > svg {
    margin-left: 5px;
 g{
  opacity: 1;
 }
    path {
      stroke: ${colors.aqua_bg}
    
    }
  }
`;

const S = {
  Wrapper,
  RowWrapper,
  ItemTitle,
  Value,
  borderDividerWrapper,
  BorderDivider,
  YellowWarningWrapper,
  YellowWarning,
  SVGwarningWrapper,
  ButtonWrapper,
  LinkTo,
  CheckTx,
};

export default S;
