import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../redux/actions/modalActions';
import ModalBody from '../ModalBody';
import Button from '../../Button';
import S from './styled';
import PortAmountInput from '../../../modules/exchange/components/PortAmountInput/index';
import useButtonOption from './useButtonOption';
import { shortenAddress, numFormatter } from '../../../services/utilsService';
import usePortxBalances from '../../../hooks/usePortxBalances';
import Warning from '../../Warning';
import translate from '../../../services/translate/translate';
import generalSelectors from '../../../redux/selectors/generalSelectors';
import transactionSelectors from '../../../redux/selectors/transactionSelectors';
import useTransactionFee from '../../../hooks/useTransactionFee';
import { setSwapAmount } from '../../../redux/actions/bridgeActions';
import { ReactComponent as Unstake } from '../../../assets/unstake.svg';
import { ReactComponent as YellowWarningSVG } from '../../../assets/yellowWarning.svg';
import { ReactComponent as AwaySVG } from '../../../assets/away-icon.svg';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import walletSelectors from '../../../redux/selectors/walletSelectors';

const UnstakeModal = ({ initialSwapAmount = 0, msgDisplayDisable = false }) => {
  const dispatch = useDispatch();
  const buttonOption = useButtonOption();
  const [{ data: portxBalance }] = usePortxBalances();
  const ethTransactionFee = useTransactionFee(21000, 35);

  const isWalletConnected = useSelector(walletSelectors.isWalletConnected);
  const withdrawStakedLoading = useSelector(stakeSelectors.withdrawStakedLoading);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const selectedToken = useSelector(bridgeSelectors.selectedToken);
  const isMobile = useSelector(generalSelectors.isMobile);
  const txHash = useSelector(transactionSelectors.txHash);
  const networksByName = useSelector(generalSelectors.networksByName);

  const polyNet = networksByName.POLYGON;

  const onAddressClick = useCallback((e, isToken) => {
    e.stopPropagation();
    const url = isToken
      ? `${baseNetwork?.explorer_url}token/${selectedToken?.web3_address}`
      : `${baseNetwork?.explorer_url}address/${walletAddress}`;
    window.open(url, '_blank',
      'noopener, noreferrer');
  }, [baseNetwork?.explorer_url, selectedToken?.web3_address, walletAddress]);

  const handleMsgDisplay = () => {
    if (msgDisplayDisable) {
      return '';
    }
    if (!ethTransactionFee?.isEnoughBalance && isWalletConnected && !txHash) {
      return (
        <S.WarningWrapper>
          <Warning isMobile={isMobile} classes="error-info">
            <p style={{ fontSize: '14px' }}>
              {translate('modal.no_funds', { tokenname: 'Matic', network: baseNetwork.label_name })}
            </p>
          </Warning>
        </S.WarningWrapper>
      );
    }
    return (
      <S.YellowWarningWrapper>
        <S.SVGwarningWrapper>
          <YellowWarningSVG />
        </S.SVGwarningWrapper>
        <S.YellowWarning>Please note - Staked PORTX allow you to pay discounted fees and earn rewarded regularly.<br />
          <span>
            Are you sure you want to unstake your PORTX?
          </span>
        </S.YellowWarning>
      </S.YellowWarningWrapper>);
  };

  useEffect(() => {
    if (initialSwapAmount > 0) {
      return;
    }
    if (swapAmount > 0 && !withdrawStakedLoading?.Unstake && !withdrawStakedLoading?.unstakingComplete) {
      dispatch(setSwapAmount(0));
    }
    return () => {
      if (swapAmount > 0 && !withdrawStakedLoading?.Unstake && !withdrawStakedLoading?.unstakingComplete) {
        dispatch(setSwapAmount(0));
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper isCloseEnable={!withdrawStakedLoading?.unstaking}>
      <ModalBody showHeader={true} closeModal={() => { dispatch(closeModal()); }} title={<><Unstake />Unstake Funds</>}>
        {withdrawStakedLoading.unstaking ?
          <S.DetailsWrapper>
            <S.ColumDetailsWrapper>
              <S.ColumnWrapper>
                <S.ItemTitle>Unstake</S.ItemTitle><S.ValuePORTX>{`${numFormatter(swapAmount)} PORTX`}</S.ValuePORTX>
              </S.ColumnWrapper>
              <S.ColumnWrapper>
                <S.ItemTitle>Token address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, true)}>{shortenAddress(selectedToken?.web3_address || 'ab')}</S.LinkTo></S.Value>
              </S.ColumnWrapper>
            </S.ColumDetailsWrapper>

            <S.ColumDetailsWrapper>
              <S.ColumnWrapper>
                <S.ItemTitle>New staked amount</S.ItemTitle><S.Value25>{`${numFormatter((parseFloat(portxBalance?.portxStakedConverted, 10) - parseFloat(swapAmount, 10)))} PORTX`}</S.Value25>
              </S.ColumnWrapper>
              <S.ColumnWrapper>
                <S.ItemTitle>Wallet address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, false)}>{shortenAddress(walletAddress)}</S.LinkTo></S.Value>
              </S.ColumnWrapper>
            </S.ColumDetailsWrapper>
          </S.DetailsWrapper>
          :
          <>
            {handleMsgDisplay()}
            <S.PortAmountWrapper><PortAmountInput headLine="Enter amount to withdraw" stakingMax={numFormatter(portxBalance?.portxStakedConverted)} zeroBalanceDisable={false} /></S.PortAmountWrapper><S.CurrentStakedWrapper><S.ItemTitleAmountInput>Current Staked amount</S.ItemTitleAmountInput><S.MiniValue>{`${numFormatter(portxBalance?.portxStakedConverted, 10)} PORTX`}</S.MiniValue></S.CurrentStakedWrapper>
          </>
        }
        <S.ButtonWrapper>
          <Button
            className={buttonOption.className}
            onClick={buttonOption.action}
            disabled={buttonOption.disabled}
          > {buttonOption.text}
          </Button>
        </S.ButtonWrapper>
        {txHash && (
          <S.CheckTx href={`${polyNet?.explorer_url}tx/${txHash}`} target="_blank" rel="noopener noreferrer">
            {translate('modal.check_tx')}
            <AwaySVG />
          </S.CheckTx>
        )}
      </ModalBody>
    </S.Wrapper>
  );
};

UnstakeModal.propTypes = {
  initialSwapAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  msgDisplayDisable: PropTypes.bool,
};

export default UnstakeModal;
