import React from 'react';
import { FormattedMessage } from 'react-intl';
import en from './en.json';

const HTML_REGEX = /<\/?[\w\s="/.':;#-/]+>/i;

/**
 * Takes a missing translation id and transforms it to a readable format
 * e.g. Common.read_more_here -> Read more here
 *
 * @param id {String}
 * @return {string}
 */
const fallbackDefaultMessage = (id) => {
  const withoutDots = id.substring(id.lastIndexOf('.') + 1, id.length);
  const withoutUnderscore = withoutDots.replace(/_/g, ' ');
  return withoutUnderscore.charAt(0).toUpperCase() + withoutUnderscore.slice(1);
};

const translate = (idParam, values, tagName, intl) => {
  let id = idParam;
  let defaultMessage = en[id];
  if (!id) {
    id = 'empty-id';
    defaultMessage = '';
  } else if (id && !defaultMessage) defaultMessage = fallbackDefaultMessage(id);
  const defaultMessageHasHtml = HTML_REGEX.test(defaultMessage);
  // Return JSX element
  // The default html element values for this is in index.jsx
  if (tagName || defaultMessageHasHtml) {
    const propsForIntlFormattedMessage = { id, defaultMessage };
    if (values) propsForIntlFormattedMessage.values = values;
    if (tagName) propsForIntlFormattedMessage.tagName = tagName;

    // The spread is used to skip the react-intl static analysis syntax
    // because that functionality is replaced and extended by our scripts
    // that check translation files and translation ids usage in components
    return intl?.formatMessage
      ? intl.formatMessage({ id: propsForIntlFormattedMessage.id }, propsForIntlFormattedMessage.values)
      : (
        <FormattedMessage {...propsForIntlFormattedMessage} />
      );
  }
  // Returns just strings
  // return langManager.intl.formatMessage({ id, defaultMessage }, values);
  return intl?.formatMessage
    ? intl.formatMessage({ id, defaultMessage }, values)
    : <FormattedMessage id={id} defaultMessage={defaultMessage} values={values} />;
  // return intl.formatMessage({ id, defaultMessage }, values);
  // return langManager.intl.formatMessage({ id }, values);
};

export default translate;
