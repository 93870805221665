export const WALLET_MODAL = 'WALLET_MODAL';
export const TOKEN_MODAL = 'TOKEN_MODAL';
export const CONFIRM_DEPOSIT_MODAL = 'CONFIRM_DEPOSIT_MODAL';
export const CONFIRM_WITHDRAW_MODAL = 'CONFIRM_WITHDRAW_MODAL';
export const CONFIRM_TX_MODAL = 'CONFIRM_TX_MODAL';
export const UNSTAKE_MODAL = 'UNSTAKE_MODAL';
export const STAKE_MODAL = 'STAKE_MODAL';
export const CLAIM_MODAL = 'CLAIM_MODAL';
export const COMPLETE_STAKE_MODAL = 'COMPLETE_STAKE_MODAL';
export const COMPLETE_UNSTAKE_MODAL = 'COMPLETE_UNSTAKE_MODAL';
export const COMPLETE_CLAIM_MODAL = 'COMPLETE_CLAIM_MODAL';
