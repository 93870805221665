import styled, { css } from 'styled-components/macro';
import colors from '../../../styles/colors';
import mixins from '../../../styles/mixins';

const topography = css`
  font: normal normal normal 16px/19px Roboto;
  color: ${colors.white};
  opacity: 0.5;

  ${mixins.flexItXY('flex-start', 'center')}

  &.head {
    font: normal normal bold 18px/21px Roboto;
  }

  &.white {
    opacity: 1;
  }

  &.mobile {
    text-align: left;
  }

  &.token-link {
    justify-content: flex-end;
    &:hover {
      text-decoration: underline;
      color: ${colors.aqua_bg};
      opacity:1;
  
      path {
        stroke: ${colors.aqua_bg}
      }
    }
  }

  svg {
    margin-left: 5px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .modal-body {
    width: 100%;
    .modal-body-content {
      padding: 0;
    }
  }
`;

const TokenList = styled.div`
  margin: 10px 0 20px;
  max-height: 3800px;
  overflow-y: auto;

  & > div {
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .token-list-item {
    display: grid;
    grid-template-columns: 73px 2fr 1fr min-content;
    padding: 15px 23px 15px 28px;
    cursor: pointer;
    align-items: center;

    ${({ theme }) => theme.isMobile && css`
    grid-template-columns: 60px 2fr 1fr min-content;
  `};

   
    .token-verified {
      fill: #2ff6d3;
    }

    .token-unverified {
      fill: #ff6767;
    }

    &:hover {
      background-color: ${colors.navy_dark};
    }

    & > .token-info {
      
      .overflow-visible {
        overflow: visible;
      }

      ${mixins.flexItXY('center', 'flex-start')}
      flex-direction: column;

      & > div:first-of-type {
        margin-bottom: 3px;
      }

      & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        max-width: 200px;
        display: block;

        @media (max-width: 325px){
          width: 70px;
        }

        &:last-of-type {
        width: 110px;
        opacity: 0.5;


        @media (max-width: 325px){
          width: 70px;
        }
      }}
    }

    .topography {
      ${topography};
    }
  }
`;

const Token = styled.div`
opacity:
${
  (props) => (props.blacklisted ? 0.5 :
    1)
};
`;
const TokenListItem = styled.div`
  display: grid;
  grid-template-columns: 35px 45px 1fr min-content;
  padding: 10px 35px 10px 30px;
  column-gap: 12px;
  cursor: pointer;
  align-items: center;

  .token-verified {
    fill: #2ff6d3;
  }

  .token-unverified {
    fill: #ff6767;
  }

  &:hover {
    background-color: ${colors.navy_dark};
  }

  & > .token-info {
    .overflow-visible {
        overflow: visible;
      }

    ${mixins.flexItXY('center', 'flex-start')}
    flex-direction: column;

    & > div:first-of-type {
      margin-bottom: 3px;
    }
  }
`;

// const TokenImage = styled.div`
//   width: 45px;
//   height: 45px;
//   border-radius: 50%;
//   background-color: ${colors.navy_dark};
//   background-image: url(${(props) => props.background});
//   background-size: 80%;
//   background-position: center;
//   background-repeat: no-repeat;
// `;

const LoadingTokensText = styled.div`
padding-top: 100px;
padding-bottom:160px;

`;

const TokenImage = styled.div.attrs((props) => ({
  style: {
    backgroundImage: `url(${props.background})`,
  },
}))`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${colors.navy_dark};
  background-size: 45px;

  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  & > svg {
    position: absolute;
    top: -5px;
    right: -5px;
  }
`;

const Typography = styled.div`
  ${topography};
`;

const CreateToken = styled.div`
  ${mixins.flexItXY('center', 'center')}
  padding: 20px;
  color: ${colors.white};
  opacity: 0.8;
  cursor: pointer;
  background: ${colors.aqua_bg};
  width: 80%;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
`;

const TokenVerificationIndicator = styled.div`
  margin: 30px 30px 20px 30px;
  /* background: #038500; */
  border-radius: 5px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;

  &.invalid {
    color: #ff6767;

    svg {
      fill: #ff6767;
    }
  }

  svg {
    margin-right: 6px;
  }

  &.mobile {
    padding: 15px 20px;
    text-align: left;
    svg {
      width: 50px;
    }
  }
`;

const AddTokenContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const S = {
  AddTokenContainer,
  TokenVerificationIndicator,
  CreateToken,
  TokenList,
  Wrapper,
  TokenListItem,
  TokenImage,
  Typography,
  Token,
  LoadingTokensText
};

export default S;
