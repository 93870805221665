export const WITHDRAW_TOKENS = 'WITHDRAW_TOKENS';
export const SET_TX_HASH = 'SET_TX_HASH';
export const RESET_TX_HASH = 'RESET_TX_HASH';
export const SET_PROCESSING_LOADER = 'SET_PROCESSING_LOADER';
export const UPDATE_BLOCK_CONFIRMATIONS = 'UPDATE_BLOCK_CONFIRMATIONS';
export const START_INTERVAL_FOR_BLOCK_CONFIRMATIONS = 'START_INTERVAL_FOR_BLOCK_CONFIRMATIONS';
export const CLEAR_INTERVAL_FOR_BLOCK_CONFIRMATIONS = 'CLEAR_INTERVAL_FOR_BLOCK_CONFIRMATIONS';
export const SET_PORT_TX = 'SET_PORT_TX';
export const SET_REDEEM_TX = 'SET_REDEEM_TX';
export const SET_CURRENT_REDEEM = 'SET_CURRENT_REDEEM';
export const SET_IS_REDEEM_ON_GOING = 'SET_IS_REDEEM_ON_GOING';
export const SET_PORT_TIMEOUT_ID = 'SET_PORT_TIMEOUT_ID';
export const DEPOSIT_TOKENS_FINISHED = 'DEPOSIT_TOKENS_FINISHED';
export const WITHDRAW_TOKENS_FINISHED = 'WITHDRAW_TOKENS_FINISHED';
export const HANDLE_PORT_OUT_TOKENS_FINISHED = 'HANDLE_PORT_OUT_TOKENS_FINISHED';
export const CLEAR_TX_STATE = 'CLEAR_TX_STATE';
export const SET_CURRENT_TX_NONCE = 'SET_CURRENT_TX_NONCE';
export const SET_CURRENT_TX_BLOCK = 'SET_CURRENT_TX_BLOCK';
export const ADD_ALLOWANCE_FINISHED = 'ADD_ALLOWANCE_FINISHED';
export const SET_PORTX_FEE = 'SET_PORTX_FEE';
export const START_INTERVAL_FOR_CARDANO_TX_STATUS = 'START_INTERVAL_FOR_CARDANO_TX_STATUS';
export const UPDATE_CARDANO_TX_HASH = 'UPDATE_CARDANO_TX_HASH';
