import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Tooltip,
} from 'react-tippy';
import InfoIcon from '../Icons/InfoIcon';
import S from './styled';

const TooltipComponent = ({ tooltipText = '', className, displayedValue = '', theme = 'chainport' }) => (
  <S.Wrapper>
    <Tooltip
      useContext={true}
      arrow={true}
      className={clsx('tooltip-text', className)}
      html={tooltipText}
      theme={theme}
      position="top"
    >
      {displayedValue || <InfoIcon /> }
    </Tooltip>
  </S.Wrapper>
);

TooltipComponent.propTypes = {
  tooltipText: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  displayedValue: PropTypes.node,
  theme: PropTypes.string,
};

export default TooltipComponent;
