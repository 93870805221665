export const TOGGLE_MENU_MODE = 'TOGGLE_MENU_MODE';
export const RESIZE_WINDOW = 'RESIZE_WINDOW';
export const SET_NETWORK_ID = 'SET_NETWORK_ID';
export const SET_APPROVE_LOADING = 'SET_APPROVE_LOADING';
export const SET_WITHDRAW_LOADING = 'SET_WITHDRAW_LOADING';
// export const SET_APP_VERSIONS = 'SET_APP_VERSIONS';
export const GET_GAS_PRICES_REQUEST = 'GET_GAS_PRICES_REQUEST';
export const GET_GAS_PRICES_SUCCESS = 'GET_GAS_PRICES_SUCCESS';
export const GET_GAS_PRICES_FAIL = 'GET_GAS_PRICES_FAIL';
export const SET_GAS_PRICE = 'SET_GAS_PRICE';
export const SET_NATIVE_COIN_RATES = 'SET_NATIVE_COIN_RATES';
export const GET_APP_META = 'GET_APP_META';
export const GET_TVL = 'GET_TVL';
export const SET_ACKNOWLEDGE_LOADING = 'SET_ACKNOWLEDGE_LOADING';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_ALLOWANCE_LOADING = 'SET_ALLOWANCE_LOADING';
export const SET_WITHDROW_STAKED_LOADING = 'SET_WITHDROW_STAKED_LOADING';
export const SET_STAKED_LOADING = 'SET_STAKED_LOADING';
export const SET_CLAIMING_LOADING = 'SET_CLAIMING_LOADING';
export const UPDATE_BANNER_STATE = 'UPDATE_BANNER_STATE';
export const UPDATE_PROGRESSBAR = 'UPDATE_PROGRESSBAR';
export const UPDATE_PROGRESSBAR_PERCENTAGE = 'UPDATE_PROGRESSBAR_PERCENTAGE';
