export const VERIFY_TOKEN_AMOUNT = 'Our security system is adding more tokens to the ChainPort bridge contract, please note that you will be able to claim your tokens after the rebalance is completed in 30-60 minutes -- this does not require any action from your side, and you will be able to claim your tokens normally after the rebalance is finished.';
export const BLACKLISTED_TOKEN = 'This token is not open for ports on ChainPort, yet...';

export const NON_LIQUIDITY_TOKEN = 'Please note that this token has no liquidity on the target chain.';

export const FROOZEN_POLYGON = 'Due to Polygons hardfork, bridges to and from Polygon are temporarily disabled.';

export const NATIVE_CARDANO_ASSET = 'Currently we do not support porting native Cardano assets.';

export const CARDANO_PORT_LIMIT = 'Cardano transaction amount should be more than 1';
