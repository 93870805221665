import { toast } from 'react-toastify';
import Axios from 'axios';
import BridgeService from '../../services/bridge';
import { formatDateShort, formatDateShortEpoch, getDurationDatesUnix, polyExplorer, setExpirationInLS } from '../../services/utilsService';
import { setSwapAmount } from './bridgeActions';
import { setTXHash, getReceiptMined } from './transactionActions';
import { setBalances } from './tokenActions';
import {
  STAKE_TOKENS_FINISHED,
  CLAIM_TOKENS_FINISHED,
  GET_PORTX_BALACES_LOADING,
  GET_PORTX_BALACES_FAILURE,
  GET_PORTX_BALACES_SUCCESS,
  RESET_PORTX_BALANCE,
  DAPI_DATA_SUCCESS,
  DAPI_DATA_FAIL,
  SET_STAKED_LOADING,
  SET_CLAIMING_LOADING,
  SET_WITHDROW_STAKED_LOADING,
  SET_STAKING_STATS,
  SET_SDK_DATA,
} from '../actionTypes/stakeActionTypes';
import {
} from '../actionTypes/bridgeActionTypes';
import { openCompleteClaimModal, openCompleteStakeModal, openCompleteUnStakeModal } from './modalActions';
import BridgeSelectors from '../selectors/bridgeSelectors';
import stakeSelectors from '../selectors/stakeSelectors';
import config from '../../config/config';
// eslint-disable-next-line no-unused-vars
import { eventToAction } from '../../constants/general';

const { baseUrl: baseURL } = config;
const { dapiBaseUrl: dapiBaseURL } = config;
const axios = Axios.create({ baseURL });
const axiosDapi = Axios.create({ baseURL: `${dapiBaseURL}/event_polling/contracts_events_info` });

export const setSdkData = (sdkData) => ({
  type: SET_SDK_DATA,
  payload: sdkData
});

export const setStakingStats = (stakingStats) => ({
  type: SET_STAKING_STATS,
  payload: stakingStats
});

export const setStakingLoading = ({ staking, stakingComplete = false }) => (dispatch) => {
  dispatch({
    type: SET_STAKED_LOADING,
    payload: { staking, stakingComplete },
  });
};

export const setClaimingLoading = ({ claiming, claimingComplete = false }) => (dispatch) => {
  dispatch({
    type: SET_CLAIMING_LOADING,
    payload: { claiming, claimingComplete },
  });
};

export const setUnstakingLoading = ({ unstaking, unstakingComplete = false }) => (dispatch) => {
  dispatch({
    type: SET_WITHDROW_STAKED_LOADING,
    payload: { unstaking, unstakingComplete },
  });
};

export const resetPortxBalance = () => (dispatch) => {
  dispatch({ type: RESET_PORTX_BALANCE });
};

export const stakeTokensFinish = () => (dispatch) => {
  dispatch({ type: STAKE_TOKENS_FINISHED });
};

export const claimTokensFinish = () => (dispatch) => {
  dispatch({ type: CLAIM_TOKENS_FINISHED });
};

export const getUserStakedBalance = () => async (dispatch, getState) => {
  const state = getState();
  const { wallet: { account: walletAddress } } = state;
  const stakedBalanceWei = await BridgeService.getUserStakedBalance(
    walletAddress,
    { from: walletAddress, gasPrice: (0 * 10 ** 9).toFixed() } /* , eventArray */
  );
  const stakedBalanceFromWei = BridgeService.fromWei(stakedBalanceWei, 18);
  return stakedBalanceFromWei;
};

export const getPortXBalances = (walletAddress) => async (dispatch, getState) => {
  const { loading } = stakeSelectors.portXBalances(getState());
  const { blockchain_type } = BridgeSelectors.baseNetwork(getState());
  if (loading) return;
  dispatch({ type: GET_PORTX_BALACES_LOADING });
  try {
    const {
      data,
    } = await axios('/fees/portx_balances', {
      params: { address: walletAddress, blockchain_type },
    });
    // const modifiedData = { // MOCK
    //   portx_staked: 10,
    //   portx_total_wei: 0,
    //   portx_total_usd: 0,
    //   portx_usd_price: 0,
    //   portx_decimals: 0,
    //   portxStakedConverted: 10,
    //   portxTotalAmountConverted: 0,
    //   portx_balances_by_network: {
    //     ethereum: 0,
    //     polygon: 100,
    //   }
    // };
    const modifiedData = {
      ...data,
      portxStakedConverted: BridgeService.fromWei(data?.portx_staked, parseFloat(data?.portx_decimals, 10)),
      portxTotalAmountConverted: BridgeService.fromWei(data?.portx_total_wei, parseFloat(data?.portx_decimals, 10)),
    };
    dispatch({ type: GET_PORTX_BALACES_SUCCESS, payload: modifiedData });
  } catch (err) {
    console.log(err);

    dispatch({ type: GET_PORTX_BALACES_FAILURE, payload: err });
  }
};

export const stakeTokens = () => async (dispatch, getState) => {
  const state = getState();
  const {
    token: { balancesWei },
    wallet: { account: walletAddress },
    bridge: { selected_token, swapAmount, baseNetwork, isUserInputBalanceMax },
    transactions: { tx_hash, interval_id, currentTxBlock, currentTxNonce },
    general: { networksByName },
  } = state;
  const polyNet = networksByName.POLYGON;
  // check if the rpc valid
  if (baseNetwork?.name !== polyNet?.name) {
    return toast.error('Staking only available on Polygon network');
  }

  dispatch(setStakingLoading({ staking: true, stakingComplete: false }));

  let newSwapAmount;

  if (isUserInputBalanceMax) {
    newSwapAmount = balancesWei[selected_token.web3_address];
  } else {
    newSwapAmount = BridgeService.toWei(swapAmount, selected_token.decimals);
  }
  // if the value is null he will be changed later, if not use the old tx hash
  let tx = tx_hash;
  try {
    if (!tx_hash) {
      // start of the transaction, returns the tx hash
      tx = await BridgeService.stakeTokens(
        newSwapAmount,
        baseNetwork.id,
        { from: walletAddress } /* , eventArray */
      );
      setExpirationInLS('expiryPersist');
      // saving the new tx hash to redux state in "transactions.txHash"
      dispatch(setTXHash(tx));
    }

    const receipt = await dispatch(getReceiptMined(interval_id, currentTxBlock, currentTxNonce, walletAddress, tx));
    if (!receipt) {
      dispatch(setStakingLoading({ staking: false, stakingComplete: false }));
      return;
    }
    if (receipt) {
      tx = receipt.transactionHash;
    }
    await receipt.status;
    dispatch(setBalances([selected_token], walletAddress));
    dispatch(getPortXBalances(walletAddress));
    dispatch(setStakingLoading({ staking: false, stakingComplete: true }));
    dispatch(openCompleteStakeModal());
  } catch (e) {
    console.warn(e);
    toast.error(e.message);
    dispatch(setStakingLoading({ staking: false, stakingComplete: false }));
  }
};

export const claimTokens = (claimAmount = 0) => async (dispatch, getState) => {
  dispatch(setClaimingLoading({ claiming: true, claimingComplete: false }));
  const state = getState();
  const {
    wallet: { account: walletAddress },
    bridge: { selected_token, baseNetwork },
    transactions: { tx_hash, interval_id, currentTxBlock, currentTxNonce },
  } = state;

  // if the value is null he will be changed later, if not use the old tx hash
  let tx = tx_hash;

  try {
    // start of the transaction, returns the tx hash
    if (!tx_hash) {
      tx = await BridgeService.withdrawRewards(
        walletAddress,
        baseNetwork.id,
        baseNetwork.chain_id,
        { from: walletAddress },
      );

      setExpirationInLS('expiryPersist');
      // saving the new tx hash to redux state in "transactions.txHash"
      dispatch(setTXHash(tx));
    }

    const receipt = await dispatch(getReceiptMined(interval_id, currentTxBlock, currentTxNonce, walletAddress, tx));
    if (!receipt) {
      dispatch(setStakingLoading({ staking: false, stakingComplete: false }));
      return;
    }
    if (receipt) {
      tx = receipt.transactionHash;
    }
    await receipt.status;

    dispatch(setSwapAmount(claimAmount));
    dispatch(setBalances([selected_token], walletAddress));
    dispatch(getPortXBalances(walletAddress));
    dispatch(setClaimingLoading({ claiming: false, claimingComplete: true }));
    dispatch(openCompleteClaimModal(claimAmount));
  } catch (e) {
    console.warn(e);
    toast.error(e.message);
    dispatch(setClaimingLoading({ claiming: false, claimingComplete: false }));
  }
};

export const withdrawStakedTokens = (fixedSwapAmount = 0) => async (dispatch, getState) => {
  const state = getState();
  const {
    wallet: { account: walletAddress },
    bridge: { selected_token, swapAmount, baseNetwork, isUserInputBalanceMax },
    transactions: { tx_hash, interval_id, currentTxBlock, currentTxNonce },
    general: { networksByName },
    stake: { portXBalances }
  } = state;
  // check if the rpc valid
  const polyNet = networksByName.POLYGON;
  if (baseNetwork?.name !== polyNet?.name) {
    return toast.error('Staking only available on Polygon network');
  }

  dispatch(setUnstakingLoading({ unstaking: true, unstakingComplete: false }));

  let newSwapAmount;

  if (isUserInputBalanceMax) {
    newSwapAmount = portXBalances?.portx_staked;
  } else {
    newSwapAmount = BridgeService.toWei(fixedSwapAmount > 0 ? fixedSwapAmount : swapAmount, selected_token.decimals);
  }
  // if the value is null he will be changed later, if not use the old tx hash
  let tx = tx_hash;
  try {
    if (!tx_hash) {
      // start of the transaction, returns the tx hash
      tx = await BridgeService.withdrawStakedTokens(
        newSwapAmount,
        baseNetwork.id,
        { from: walletAddress } /* , eventArray */
      );
      setExpirationInLS('expiryPersist');
      // saving the new tx hash to redux state in "transactions.txHash"
      dispatch(setTXHash(tx));
    }

    const receipt = await dispatch(getReceiptMined(interval_id, currentTxBlock, currentTxNonce, walletAddress, tx));
    if (!receipt) {
      dispatch(setUnstakingLoading({ unstaking: false, unstakingComplete: false }));
      return;
    }
    if (receipt) {
      tx = receipt.transactionHash;
    }
    await receipt.status;
    dispatch(setBalances([selected_token], walletAddress));
    dispatch(getPortXBalances(walletAddress));
    dispatch(setUnstakingLoading({ unstaking: false, unstakingComplete: true }));
    dispatch(openCompleteUnStakeModal());
  } catch (e) {
    console.warn(e);
    toast.error(e.message);
    dispatch(setUnstakingLoading({ unstaking: false, unstakingComplete: false }));
  }
};

export const statsForUser = (walletAddress, decimals = 18) => async (dispatch) => {
  const currentDate = Date.now();
  const statsArray = await BridgeService.getStatsForUser(
    walletAddress,
    { from: walletAddress }
  );
  const ATHval = await BridgeService.ATHStake(
    walletAddress,
    { from: walletAddress }
  );
  const epochId = await BridgeService.epochId();
  const startTime = await BridgeService.startTime(
    epochId,
  );
  const endTime = await BridgeService.endTime(
    epochId,
  );
  const minTimeToStake = await BridgeService.minTimeToStake(
    epochId,
  );
  const totalRewards = await BridgeService.totalRewards(
    epochId,
  );
  const noOfUsers = await BridgeService.noOfUsers(
    epochId,
  );
  const totalDeposits = await BridgeService.totalDeposits(
    epochId,
  );
  const rewardPerSecond = await BridgeService.rewardPerSecond(
    epochId,
  );
  const returnTx = {
    totalStakedCurrently: BridgeService.fromWei(statsArray[0], decimals),
    totalEarnedForLifeTime: BridgeService.fromWei(statsArray[1], decimals),
    currentPendingAmount: BridgeService.fromWei(statsArray[2], decimals), // this is the locked rewards
    currentBalanceToWithdraw: BridgeService.fromWei(statsArray[3], decimals), // this is the avaib to withdraw rewards
    allTimeHighStake: BridgeService.fromWei(ATHval, decimals),
    epochId,
    duration: getDurationDatesUnix(startTime, endTime),
    startTime: formatDateShortEpoch(startTime),
    endTime: formatDateShortEpoch(endTime),
    isEpochEnded: (currentDate / 1_000) > endTime, // current date in milli vs end time in seconds
    minTimeToStake,
    totalRewards: BridgeService.fromWei(totalRewards),
    totalPendingAndBalance: (Number(BridgeService.fromWei(statsArray[2], decimals), 10) + Number(BridgeService.fromWei(statsArray[3], decimals), 10)),
    noOfUsers,
    totalDeposits: BridgeService.fromWei(totalDeposits, decimals),
    rewardPerSecond: BridgeService.fromWei(rewardPerSecond, decimals),
  };
  dispatch(setSdkData(returnTx));
  // * 0 = totalStakedCurrently
  // * 1 = totalEarnedForLifeTime
  // * 2 = currentPendingAmount
  // * 3 = currentBalanceToWithdraw
  return returnTx;
};

export const fetchDapiData = () => async (dispatch, getState) => {
  const state = getState();
  const {
    stake: { dapiData },
    wallet: { account },
  } = state;
  if (dapiData?.length <= 0) {
    try {
      const res = await axiosDapi({
        headers: {
          Authorization: `Chainport-FE,${config.dapiKey}`,
        },
        params: {
          collection_name: 'Chainport', contract_addresses: `${window.CONFIG.perpetual_contract},${window.CONFIG.fee_manager}`, user_web3_addresses: account, event_names: 'PORTXDeposited,PORTXWithdrawn,Withdraw', user_name: 'Chainport-FE'
        }
      });
      const modifyRes = res.data.map(({ id, block_collation_time, amount, rewardAmount, event_name, transaction_hash, ...rest }) =>
        ({
          ...rest,
          id,
          formatedDate: formatDateShort(block_collation_time),
          formatedAmount: BridgeService.fromWei(amount, 18),
          formatedRewardAmount: BridgeService.fromWei(rewardAmount, 18),
          formatedAction: eventToAction(event_name),
          polyExplorer: polyExplorer(transaction_hash)
        })).reverse();
      dispatch({
        type: DAPI_DATA_SUCCESS,
        payload: modifyRes,
      });
    } catch (err) {
      console.log(err.response);
      dispatch({
        type: DAPI_DATA_FAIL
      });
    }
  }
};

export const fetchStakingStats = () => async (dispatch, getState) => {
  const state = getState();
  const { wallet: { account: user_web3_address } } = state;
  let data1 = {};
  let data2 = {};
  let totalAmountStaked = -1;
  if (user_web3_address) {
    data1 = await axios('/user/staking_stats', {
      params: { user_web3_address },
    });
  }
  data2 = await axios('/stats/portx');
  totalAmountStaked = await BridgeService.getTotalAmountDeposited(
    { from: user_web3_address }
  );
  const { staked_portx_out_of_circulating_supply } = data2.data;
  dispatch(setStakingStats({
    ...data1.data,
    ...data2.data,
    totalAmountStaked: BridgeService.fromWei(totalAmountStaked),
    staked_portx_out_of_circulating_supply_percentage: parseFloat(staked_portx_out_of_circulating_supply, 10) * 100,
  }));
};
