import styled from 'styled-components/macro';
import colors from '../../styles/colors';

const Wrapper = styled.div`
  position: relative;


 

  &.with-icon input {
    padding-left: 45px;
  }

  svg {
    position: absolute;
    top: 7px;
    left: 20px;

  }



  input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;

  font: normal normal bold 19px/22px Roboto;

  &:disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
  }

  &.recipient-input { 
    background-color: ${colors.form_inp_bg};
    color: ${colors.white};
    padding:0 34px 0 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;  
     



    &::placeholder {
      height: 40px;
      line-height: 40px;
      font: normal normal normal  14px  Roboto;
    color: ${colors.gray_light};
    }

  }

  &.dark {
    background-color: ${colors.form_inp_bg};
    color: ${colors.white};
    padding: 0 50px;
    font-size: 16px;

    &::placeholder {
      height: 40px;
      line-height: 40px;
      font: normal normal normal 16px/19px Roboto;
    color: ${colors.gray_light};
    }

    &::-webkit-input-placeholder {
      height: 40px;
      line-height: 40px;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      height: 40px;
      line-height: 40px;
    }

    &:-moz-placeholder {
      /* Firefox 19+ */
      height: 40px;
      line-height: 40px;
    }
  }
`;

const S = { Input, Wrapper };

export default S;
