import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import { LangProvider } from '@dcentralab/unified-frontend';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './styles/index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import createStore from './redux/store';
import { setupWeb3 } from './services/web3Service';
import { getAppMeta, getConfigFromLoaclStorage } from './services/utilsService';
import { refreshAppMeta } from './redux/actions/generalActions';
import Maintenance from './modules/Maintenance';
import config from './config/config';
import { locales } from './services/translate/locales';
import { PERSIST_EXPIRATION_DURATION } from './constants/general';

const loadDefaultLocale = async () => {
  const languages = navigator?.languages ?? [];
  const defaultLocale = languages.find((lang) => locales[lang]);
  if (defaultLocale) {
    return locales[defaultLocale]();
  }

  return Promise.resolve();
};

const clearPersist = (key) => {
  if (localStorage.getItem('persist:root')) {
    const objectFromLocalStorage = JSON.parse(localStorage.getItem(key));
    const prevDateFromLocalStorage = objectFromLocalStorage?.timestamp;
    const currentDate = new Date().getTime().toString();
    if (currentDate - prevDateFromLocalStorage > PERSIST_EXPIRATION_DURATION) {
      return true;
    }
    return false;
  }
};

const getAppConfig = async () => {
  const config = getConfigFromLoaclStorage();
  const versionControl = '1.3';
  // manual version control
  if (localStorage.getItem('version') !== versionControl || !localStorage.getItem('version')) {
    localStorage.clear();
    localStorage.setItem('version', versionControl);
  }
  const shouldClearPersist = clearPersist('expiryPersist');
  if (shouldClearPersist) {
    localStorage.removeItem('persist:root');
  }

  try {
    await loadDefaultLocale();

    if (config.general && config.abis) {
      window.CONFIG = { ...config.general, abis: config.abis, maintenance: config.maintenance };
      return Promise.resolve({ general: config.general, maintenance: config.maintenance });
    }

    return getAppMeta();
  } catch (err) {
    return getAppMeta();
  }
};

getAppConfig().then((data) => {
  const { general, maintenance } = data;
  const { store, persistor } = createStore({ general });
  store.dispatch(refreshAppMeta());
  window.store = store;
  setupWeb3();

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
  }

  Sentry.init({
    dsn: 'https://18db5bb1c9be4f109eb63c9830e722c7@o308676.ingest.sentry.io/5728342',
    integrations: [new Integrations.BrowserTracing()],
    environment: config.environment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production

    tracesSampleRate: 1.0,
  });

  window.store = store;

  const defaultRichTextElements = {
    b: (chunks) => <b>{chunks}</b>,
    br: () => <br />,
    span: (chunks) => <span>{chunks}</span>,
  };

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <LangProvider locales={locales} defaultRichTextElements={defaultRichTextElements}>
          {maintenance ? <Maintenance /> : <App />}
        </LangProvider>
      </PersistGate>
    </Provider>, document.getElementById('root'));
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = App.default;
    ReactDOM.render(
      <NextApp />,
      document.getElementById('root')
    );
  });
}
