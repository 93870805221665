import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPortXBalances } from '../redux/actions/stakeActions';
import bridgeSelectors from '../redux/selectors/bridgeSelectors';
import generalSelectors from '../redux/selectors/generalSelectors';
import stakeSelector from '../redux/selectors/stakeSelectors';
import walletSelectors from '../redux/selectors/walletSelectors';
import useSDKdata from './useSDKdata';
import useIsMounted from './useMountRef';

const usePortxBalances = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const portXBalances = useSelector(stakeSelector.portXBalances);
  const networksByName = useSelector(generalSelectors.networksByName);
  const network = useSelector(walletSelectors.network);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const connectingWallet = useSelector(walletSelectors.connectingWallet);
  const { fetchFromSDK } = useSDKdata();

  const polyNet = networksByName.POLYGON;

  const fetchBalances = useCallback(() => {
    if (!connectingWallet) {
      dispatch(getPortXBalances(walletAddress));
    }
  }, [dispatch, walletAddress, connectingWallet]);

  useEffect(() => {
    if (!isMounted || !walletAddress || !polyNet) return;
    if (!portXBalances.data && portXBalances.loading !== true && !portXBalances.error) {
      fetchBalances();
      if (walletAddress && polyNet) {
        fetchFromSDK();
      }
    }
  }, [walletAddress, portXBalances, polyNet, fetchBalances, network, baseNetwork, fetchFromSDK]);

  return [portXBalances, fetchBalances];
};

export const useFetchPortxBalances = () => {
  const isWalletConnected = useSelector(walletSelectors.isWalletConnected);
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const networksByName = useSelector(generalSelectors.networksByName);
  const isMounted = useIsMounted();
  const [, fetchBalances] = usePortxBalances();
  const { fetchFromSDK } = useSDKdata();
  const polyNet = networksByName.POLYGON;

  useEffect(() => {
    if (!isMounted) return;
    if (isWalletConnected && walletAddress) {
      fetchBalances();
      if (polyNet) {
        fetchFromSDK();
      }
    }
  }, [fetchBalances, fetchFromSDK, isWalletConnected, polyNet, walletAddress]);
};

export default usePortxBalances;
