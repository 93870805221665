import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import SButton from './styled';

const Button = ({ children, loading, ...rest }) => (
  <SButton disabled={loading || rest.disabled} {...rest}>
    {loading ? <Spinner /> : children}
  </SButton>
);

Button.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default Button;
