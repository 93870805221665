import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 100%;

  &.hide {
    display: none;
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    background: ${({ theme }) => theme.gray_extra_light};
    outline: none;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 5px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      background: ${({ theme }) => theme.gray_extra_light};
      cursor: pointer;
      border-radius: 50%;
      border: 6px solid ${({ theme }) => theme.aqua_bg};
      box-shadow: 0px 0px 15px rgba(47, 246, 211, 0.6);
      position: relative;
      outline: none;
    }

    &::-moz-range-thumb {
      width: 13px;
      height: 13px;
      background: ${({ theme }) => theme.gray_extra_light};
      cursor: pointer;
      border-radius: 50%;
      border: 6px solid ${({ theme }) => theme.aqua_bg};
      box-shadow: 0px 0px 15px rgba(47, 246, 211, 0.6);
      position: relative;
      outline: none;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Roboto';
  padding: 0 2px;

  span:last-of-type {
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;

    & > span {
      margin-left: 5px;
    }
  }
`;

const RangeEditInput = styled.input`
  border-radius: 5px;
  border: none;
  outline: none;
  width: 50px !important;
  height: 20px !important;
  margin-right: 10px;
  padding-left: 10px;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const RangeInput = styled.input`
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: ${({ theme }) => theme.gray_extra_light};
  position: relative;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  opacity: 0.75;

  &.slowest {
    padding-left: 15px;
  }

  &.fastest {
    padding-right: 15px;
  }

  ${({ theme }) => theme.isMobile && css`
    padding-left: 17px;
    padding-right: 17px;

    &.slowest {
      padding-left: 0;
    }

    &.fastest {
      padding-right: 0;
    }
  `}
`;

const RangeLevels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-family: 'Roboto';
  margin-bottom: 20px;

  ${({ theme }) => theme.isMobile && css`
    padding-left: 15px;
    padding-right: 15px;
  `}
`;

const RangeItem = styled.span`
 cursor:pointer;
  transition: all 0.2s ease-in;
  &:hover{
    opacity:0.5;
  }
`;

const S = {
  Container,
  Header,
  RangeEditInput,
  RangeInput,
  RangeLevels,
  RangeItem,
};

export default S;
