import React, { Suspense, useEffect, lazy } from 'react';
import { Switch, useLocation, Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import d from 'debounce';
import translate from '../services/translate/translate';
import Header from '../modules/header';
import Footer from '../modules/footer';
import ChainportRoute from './ChainportRoute';
import {
  resizeWindow,
  setNetworkId,
  setWithdrawLoading,
} from '../redux/actions/generalActions';

import WalletSelectors from '../redux/selectors/walletSelectors';

import S from './styled';
import ModalRoot from './Modals/ModalRoot';
import ContainerExchangePortInView from '../modules/exchange/containers/ContainerExchangePortInView';
import { checkPendingTransactionsForAddress, setTXHash, waitForTransaction } from '../redux/actions/transactionActions';
import GetToKnowModal from '../modules/getToKnow/index';
import TokenSelectors from '../redux/selectors/tokenSelectors';
import VerifyBanner from './VerifyBanner';
import LanguageSwitch from './LanguageSwitch';
import generalSelectors from '../redux/selectors/generalSelectors';

// import ToastBlock, { toastOptions } from './Toasts/ToastBlock';
// import { FROOZEN_POLYGON } from './Toasts/toastMessages';

const ContainerExchangeView = lazy(() => import('../modules/exchange/containers'));
const ContainerExplorerView = lazy(() => import('../modules/explorer/containers/index'));
const ContainerMyActivityView = lazy(() => import('../modules/myActivity/containers/index'));
const ContainerStatsView = lazy(() => import('../modules/stats/container/index'));
const ContainerStakePortX = lazy(() => import('../modules/staking'));

const Routes = ({
  // listenToAccChange,
  resizeWindow,
  walletAddress,
  waitForTransaction,
  checkPendingTransactionsForAddress,
  isBannerShown,
  // fetchTokens,
  // tokens,
}) => {
  // const [offset, setOffset] = React.useState(null);
  const location = useLocation();

  /*
  useEffect(() => {
    // Sets handlers on events like account or chain changed
    listenToAccChange();
  }, [listenToAccChange]);
   */

  useEffect(() => {
    // Updates redux store value for window width
    const handleWindowResize = d.debounce(() => {
      resizeWindow();
    }, 300);

    window.addEventListener('resize', handleWindowResize);
    // toast(<ToastBlock content={FROOZEN_POLYGON} />, toastOptions);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [resizeWindow]);

  useEffect(() => {
    if (walletAddress) {
      // Checks if there are pending transaction in Local Storage.
      // If yes - update redux store and starts waiting for response
      waitForTransaction(walletAddress);
      // Asks backend if there are any pending redeem or port process
      checkPendingTransactionsForAddress(walletAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  return (
    <S.Wrapper
      isBannerShown={isBannerShown}
    >
      <VerifyBanner />
      <Header />
      <S.Main>
        <Suspense fallback="">
          <Switch>
            <ChainportRoute
              exact
              path="/"
              component={ContainerExchangeView}
            />
            <ChainportRoute
              exact
              path="/portin"
              component={ContainerExchangePortInView}
            />
            <ChainportRoute
              exact
              path="/explorer"
              component={ContainerExplorerView}
            />
            <ChainportRoute
              exact
              path="/my-activity"
              component={ContainerMyActivityView}
            />
            <ChainportRoute
              exact
              path="/stats"
              component={ContainerStatsView}
            />
            <Route
              exact
              path="/stakeportx"
              component={ContainerStakePortX}
            />
            <Redirect to="/" />
          </Switch>
        </Suspense>
        <ModalRoot />
        {location.pathname === '/' && (
          <>
            {!walletAddress && (
              <S.WalletLink>
                {translate('common.no_wallet')}
                <a
                  href="https://ethereum.org/en/wallets/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> {translate('common.download')}</span>
                </a>
              </S.WalletLink>
            )}

            <GetToKnowModal />
            <LanguageSwitch />
            <S.WalletLink>
              <a
                href="https://chainport.io/how-it-works"
                target="_blank"
                rel="noopener noreferrer"><span> {translate('common.about_chainport')} </span>
              </a>
            </S.WalletLink>
          </>
        )}
      </S.Main>
      <Footer />
    </S.Wrapper>
  );
};

Routes.propTypes = {
  // listenToAccChange: PropTypes.func.isRequired,
  resizeWindow: PropTypes.func.isRequired,
  walletAddress: PropTypes.string,
  waitForTransaction: PropTypes.func,
  checkPendingTransactionsForAddress: PropTypes.func,
  isBannerShown: PropTypes.bool,
  // fetchTokens: PropTypes.func,
  // tokens: PropTypes.array,
};

const mapStateToProps = (state) => ({
  walletAddress: WalletSelectors.walletAddress(state),
  tokens: TokenSelectors.tokens(state),
  isBannerShown: generalSelectors.isBannerShown(state),

});

const mapDispatchToProps = {
  // listenToAccChange,
  resizeWindow,
  setNetworkId,
  setTXHash,
  setWithdrawLoading,
  waitForTransaction,
  checkPendingTransactionsForAddress,

};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
