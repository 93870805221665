import { TOGGLE_MODAL } from '../actionTypes/modalActionTypes';
import {
  CONFIRM_DEPOSIT_MODAL,
  CONFIRM_WITHDRAW_MODAL,
  TOKEN_MODAL,
  WALLET_MODAL,
  CONFIRM_TX_MODAL,
  UNSTAKE_MODAL,
  STAKE_MODAL,
  CLAIM_MODAL,
  COMPLETE_UNSTAKE_MODAL,
  COMPLETE_STAKE_MODAL,
  COMPLETE_CLAIM_MODAL,
} from '../../components/Modals/modalTypes';
import { stakeTokensFinish, setClaimingLoading, setUnstakingLoading, setStakingLoading, fetchStakingStats } from './stakeActions';

/**
 * Dispatches action to toggle modal.
 *
 * @param {String} modalType
 * @param {Object} modalProps
 * @param {Boolean} action - to close or to open
 */
export const toggleModal = (modalType, modalProps, action) => (dispatch) => {
  if (modalType !== '') {
    window.scrollTo(0, 0);
  }
  dispatch({
    type: TOGGLE_MODAL,
    payload: { modalType, modalProps, action },
  });
};

/**
 * Closes the modal that is currently open
 */
export const closeModal = () => (dispatch) => {
  dispatch(setUnstakingLoading({ unstaking: false, unstakingComplete: false }));
  dispatch(setStakingLoading({ staking: false, stakingComplete: false }));
  dispatch(setClaimingLoading({ claiming: false, claimingComplete: false }));
  dispatch(fetchStakingStats());
  dispatch(stakeTokensFinish());
  dispatch({
    type: TOGGLE_MODAL,
    payload: { modalType: '', modalProps: {}, action: false },
  });
};

export const openWalletModal = (resolveOnUnlock) => (dispatch) => {
  let resolve;
  let reject;

  const modalPromise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  const modalProps = { width: 530 };

  if (resolveOnUnlock) {
    modalProps.resolve = resolve;
    modalProps.reject = reject;
  }

  dispatch(toggleModal(WALLET_MODAL, modalProps, true));

  if (resolveOnUnlock) return modalPromise;
};

export const openTokenModal = () => (dispatch) => {
  const modalProps = { width: 530, className: 'aligned-top' };
  dispatch(toggleModal(TOKEN_MODAL, modalProps, true));
};

export const openUnstakeModal = (initialSwapAmount = 0, msgDisplayDisable = false) => (dispatch) => {
  const modalProps = { width: 480, className: 'aligned-top-100-isMobile-40', initialSwapAmount, msgDisplayDisable };
  dispatch(toggleModal(UNSTAKE_MODAL, modalProps, true));
};

export const openCompleteUnStakeModal = () => (dispatch) => {
  const modalProps = { width: 530, className: 'aligned-top-25' };
  dispatch(toggleModal(COMPLETE_UNSTAKE_MODAL, modalProps, true));
};

export const openStakeModal = () => (dispatch) => {
  const modalProps = { width: 480, className: 'aligned-top-40-isMobile-20' };
  dispatch(toggleModal(STAKE_MODAL, modalProps, true));
};

export const openCompleteStakeModal = () => (dispatch) => {
  const modalProps = { width: 530, className: 'aligned-top-25' };
  dispatch(toggleModal(COMPLETE_STAKE_MODAL, modalProps, true));
};

export const openClaimModal = () => (dispatch) => {
  const modalProps = { width: 480, className: 'aligned-top-40-isMobile-20' };
  dispatch(toggleModal(CLAIM_MODAL, modalProps, true));
};

export const openCompleteClaimModal = (claimAmount) => (dispatch) => {
  const modalProps = { width: 530, className: 'aligned-top-25', claimAmount };
  dispatch(toggleModal(COMPLETE_CLAIM_MODAL, modalProps, true));
};

export const openConfirmDepositModal = () => (dispatch) => {
  const modalProps = { width: 380 };
  dispatch(toggleModal(CONFIRM_DEPOSIT_MODAL, modalProps, true));
};

export const openConfirmWithdrawModal = () => (dispatch) => {
  const modalProps = { width: 380 };
  dispatch(toggleModal(CONFIRM_WITHDRAW_MODAL, modalProps, true));
};

export const openConfirmTxModal = (handleWithdrawTokens, amountInUSD, isTokenHasLiquidity, calculatedPortxFeeObj, isTxNativeTokentoEth) => (dispatch) => {
  window.history.replaceState({ additionalInformation: '' }, 'ChainPort', window.location.origin); // make sure to delete the web url querys
  const modalProps = { width: 475, handleWithdrawTokens, amountInUSD, isTokenHasLiquidity, calculatedPortxFeeObj, isTxNativeTokentoEth };
  dispatch(toggleModal(CONFIRM_TX_MODAL, modalProps, true));
};

window.openConfirmDepositModal = openConfirmDepositModal;
window.openConfirmWithdrawModal = openConfirmWithdrawModal;
