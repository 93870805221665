import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import mixins from '../../styles/mixins';
import colors from '../../styles/colors';
import Button from '../../components/Button';

const Wrapper = styled.header`
  min-height: 80px;
  ${mixins.flexItXY('space-between', 'center')}
  border-bottom: 1px solid ${colors.form_inp_bg};
  padding: 0 20px 0 20px;
  position: fixed;
  width: 100%;
  top: 0;
  background: ${colors.main_bg};
  box-sizing: border-box;
  z-index: 1;

  .web3-wc_connect-wallet-button {
    border-radius: 500px;
    min-height: 40px;
    border: none;
    background: ${colors.dark_gray};
    color: ${colors.white};
    overflow: visible;

    @media (max-width: 700px)  {
      width: 100%;
    }
    &:hover {
      opacity:0.7;
      background: #555;
    }
  }

  .web3-wc_connect-wallet-button .web3-wc_status {
    height: 0px;
    width: 0px;
    margin-left: 0px;
  }

  .web3-wc_connect-wallet-button_connected {
    border-radius: 500px;
    justify-content: center;
    border: 1px solid white;
    background: #001B43;
    width: 111%;
    margin-left:-10px;
    margin-top:-1px;

    @media (max-width: 700px)  {
      width: 107%;
      margin-left:-11px;
      margin-top:-1px;
    }
  }

  .duf-contextMenu {
    margin-left: 20px;

    .dub-contextMenu-menu {
      top: calc(100% + 20px);
      background: #001B43;
    }
  }

  @media screen and (max-width: 766px) {
    .duf-contextMenu {
      margin-left: 0;
      order: 1;
    }
  }

`;

const WalletButton = styled(Button)`
  border-radius: 50px;

  &.header {
    height: 40px;
  }

  &.modal {
    height: 60px;
    margin-bottom: 20px;
    background: ${colors.dark_gray};
    border: none !important;


    &.outlined-primary:not(:disabled):hover {
      background-color: #555;
      color: inherit;
    }
  }

  &.header-wallet-btn {
    background: ${colors.dark_gray};
    border: none !important;
    color: ${colors.white};

    &.outlined-primary:not(:disabled):hover {
      background-color: #555;
      color: inherit;
    }
  }
`;

const BtnContent = styled.span`
  font: normal normal 500 16px/19px Roboto;
  text-transform: capitalize;
`;

const WalletBtnContent = styled.span`
  font: normal normal 500 18px Roboto;
  text-transform: capitalize;
  &.fontSize16 {
    font: normal normal 500 16px Roboto;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  a:not(:first-of-type) {
    text-decoration: none;
    color: ${colors.white};
    opacity: 0.5;
    font: normal normal 500 16px/19px Roboto;
    margin-top: 5px;

    &.active {
      opacity: 1;
    }
  }

  @media (max-width: 766px) {
    flex: 1;
    justify-content: center;
    margin-left: 60px;
    order: 2;
  }

  @media (max-width: 280px) {
    flex: 4;
    justify-content: center;
    margin-left: 5%;
  }
`;

const WalletIsConnectWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 23px;
    height: 23px;
    margin-top: unset;
  }

  .short-address {
    margin-right: 20px;
  }
`;

const FromToken = styled.div`
  font: normal normal bold 16px Roboto;
  color: ${colors.white};
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 25px;
  margin-right: 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor:pointer;
  transition: all 420ms cubic-bezier(0.165, 0.84, 0.44, 1);

  pointer-events: ${(props) => props.isWithdrawLoading ?
    'none' : 'auto'};

  &:hover {

    opacity:0.7;


  }

  svg, img {
    margin-right: 6px;
    width: unset;
    max-height: 23px;
    //height: unset;
    margin-top: unset;
  }
`;

// const Inline = styled.div`
//   display: flex;
//   flex-grow: 1;
//   justify-content: flex-end;

//   .duf-networkSelector {
//     margin-right: 20px;

//     .duf-networkSelector-button {
//       background: transparent;
//     }

//     .duf-networkSelector-menu {
//       top: calc(100% + 20px);
//       background: #001B43;

//     }
//     li {
//       background:pink !important;
//     }
//   }
// `;

const NavTextWrapper = styled.div`
margin-right: 30px;
margin-top: 22px;

a{
  text-decoration:none;
  margin-right: 40px;
}

`;

const ExploreSpan = styled.div`
font-size: 18px;
text-decoration:none;
color: ${colors.gray_light};
    &:hover {
      color: ${colors.white};
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
    }
    &.active {
      color: ${colors.white};
    }
`;

const PendingButton = styled.button`
  height: 40px;
  border-radius: 25px;
  background: ${colors.aqua_bg};
  color: #00132f;
  font-size: 18px;
  line-height: 21px;
  width: 180px;
  margin-right: 20px;
  outline: none;
  border: none;
  height: 44px;

  &:disabled {
    opacity: 0.5;
  }
`;

const TagWrapper = styled.div`
margin-top: 5px;
margin-left: 10px;
margin-right: 30px;
`;

const Tag = styled.div`
margin-top:2px;
padding: 2px 7px 2px 7px;
  border: 1px solid ${colors.aqua_bg};
  border-radius: 25px;
  color: ${colors.aqua_bg};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
`;

const Logo = styled.span`
  margin-top: 11px;
`;

const PortXwrapper = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  width: 8.5rem;
  height: 40px;
  border-radius: 25px;
  padding: 12px 8px;
  background: var(--duf-ns-button-bg, #ffffff1a);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.5s;

  .text {
    line-height: 18.75px;
    font-family: Roboto;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
  }

  .portx-text {
    &-staking {
      display: none;
    }
  }
  &.loading .portx-text {
    margin-left: 1rem;
  }
  &:not(.hoverable):not(.loading) {
    width: 9rem;
  }
  &.hoverable:hover {
    width: 8rem;
    .portx-text {
      display: none;

      &-staking {
        animation: fadeInLeft 0.5s;
        color: ${({ theme }) => theme.aqua_bg};
        display: inline;
      }
    }
  }

  @keyframes fadeInLeft {
   0% {
      opacity: 0;
      transform: translateX(10px);
      width: 100%;
   }
   100% {
      opacity: 1;
      transform: translateX(2px);
      width: 100%;
   }
}
`;

const PortXsvg = styled.span`
  line-height: 1px;
  margin-right: 10px;
  svg {
    width: 29px;
    height: 29px;
  }
`;

const PortXText = styled.span`
  line-height: 18.75px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
`;

const S = {
  PendingButton,
  Wrapper,
  BtnContent,
  Nav,
  WalletIsConnectWrapper,
  FromToken,
  // Inline,
  WalletButton,
  NavTextWrapper,
  ExploreSpan,
  TagWrapper,
  Tag,
  Logo,
  WalletBtnContent,
  PortXwrapper,
  PortXsvg,
  PortXText,
};

export default S;
