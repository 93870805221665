import styled from 'styled-components/macro';

const Wrapper = styled.div`
font-family: ROBOTO;
  .modal-body {
    .modal-header {
      .modal-title {
        text-align: center !important;
        display: flex;
        justify-content: center;
        gap: 6px;
      }
    }
    max-width: 530px;
    /* padding: ${({ theme }) => theme.isMobile ? '0px 32px' : ''}; */
    margin: 0 auto;
  }

  .loader {
    margin-bottom: 18px;
  }

  button {
    min-width: 190px;
  }

  .result-icon {
    margin: 25px auto 20px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const LogoWrapper = styled.div`
min-height: 120px;
display: flex;
justify-content: center;
  svg {
  }
  border-bottom: 1px solid rgba(255, 255, 255, .1);
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
`;

const DetailsWrapper = styled.div`
  padding: 1.25rem 3rem 1.25rem 3rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 165px;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
`;

const ColumDetailsWrapper = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
  &:nth-child(2) {
    padding-left: 5rem;
  }
`;

const ColumnWrapper = styled.div`
  height: 100%;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
`;

const ItemTitle = styled.div`
  color: rgba(255,255,255,0.5);
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
`;

const ItemTitleAboveBtn = styled.div`
  color: rgba(255,255,255,0.75);
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
`;

const Value = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
`;

const PortxBalanceWrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, .1);

  div {
    margin: 1rem;
  }
`;

const MiniValue = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 2rem 0rem 2rem;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LinkTo = styled.div`
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
  :hover{
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
  font-weight: 400;
`;

const ExplorerLink = styled.a`
  color: rgba(47, 246, 211, 1);
  font-size: 16px;
  font-weight: 400;
  svg {
    path {
      stroke: rgba(47,246,211,1);
      &:first-of-type {
        stroke-width: 3%;
      }
    }
  }

  :hover {
    text-decoration: underline;
    cursor: pointer;
    svg {
      height: 12px;
    }
  }
`;

const S = {
  Wrapper,
  LogoWrapper,
  DetailsWrapper,
  ColumDetailsWrapper,
  ColumnWrapper,
  ItemTitle,
  ItemTitleAboveBtn,
  Value,
  LinkTo,
  PortxBalanceWrapper,
  MiniValue,
  ButtonWrapper,
  ExplorerLink,
  AbsoluteWrapper,
};

export default S;
