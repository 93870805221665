import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import CloseIcon from '../Icons/CloseIcon';
import { ReactComponent as ShevronSVG } from '../../assets/shevron-left.svg';
import TooltipComponent from '../Tooltip';
import { TokenText } from '../../constants/general';

const ModalBody = ({
  children,
  id,
  title,
  subTitle = '',
  showHeader = true,
  closeModal,
  tokenModal,
  text,
  className

}) => (
  <div className="modal-body">
    {showHeader && (
      <div className={clsx('modal-header', className, { tokenModal })}>
        <div className="modal-title">{title}
          {subTitle ? <div className="modal-sub-title">{subTitle}</div> : ''}
          {id === 'select_token' ?
            <TooltipComponent tooltipText={<TokenText />} /> : null}
        </div>
        <span onClick={closeModal} className="icon-close">
          {tokenModal ? <ShevronSVG /> : <CloseIcon size={16} />}
        </span>
        <p className="modal-text">{text}</p>
      </div>
    )}
    <div className="modal-body-content">{children}</div>
  </div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showHeader: PropTypes.bool,
  tokenModal: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  subTitle: PropTypes.string,
};

export default ModalBody;
