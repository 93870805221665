import styled from 'styled-components/macro';
import colors from '../../styles/colors';
import mixins from '../../styles/mixins';

const Search = styled.section`
${mixins.flexItXY('center', 'center')}
padding: 30px;

border-bottom:${
  (props) => props.isBorder ? `1px solid ${colors.gray_extra_light}` :
    'none'}};

& > div {
  width: 100%;

  svg {
    top: 11px;
  }


  .dark {
    box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
    border-radius: 20px;
  }
  .close-icon {
    position: absolute;
    top: 2px;
    right: 30px;
    cursor: pointer;
  }
}
`;

const S = {
  Search,

};

export default S;
