import React, {
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import translate from '../../../services/translate/translate';
import { ReactComponent as AwaySVG } from '../../../assets/away-icon.svg';

import S from './styled';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { getNetworkErrorMessage, isObjectEmpty, numberWithCommas } from '../../../services/utilsService';
import Warning from '../../../components/Warning';
import TxProgressBar from '../../../components/TxProgressBar';
import { WALLET_TYPES } from '../../../constants/wallet';

const ExchangePortInView = ({
  isMobile,
  networkId,
  handlePortOutTokens,
  withdrawLoading,
  walletAddress,
  redeem,
  setGasRangePrice,
  gasPrices,
  gasRangePrice,
  ethTransactionFee,
  txHash,
  isWalletConnected,
  connectMMToNetwork,
  txHashStatus,
  networksById,
  processingPort,
  nativeCoinRates,
  networksByName,
  baseNetwork,
}) => {
  const switchNetwork = useMemo(
    () => {
      // networkId != null -> networkId is not undefined or null. if networkId is 0 then it will be true (without it it will be false)
      const walletData = JSON.parse(localStorage.getItem('walletData'));
      if (walletData && !WALLET_TYPES[walletData?.walletName]) {
        return true;
      }
      if ((networkId != null) && networkId !== networksById[redeem.target_network_id]?.chain_id) {
        return true;
      }
      return false;
    }, [networkId, networksById, redeem]
  );

  useEffect(() => {
    if (!isObjectEmpty(gasPrices) && !processingPort) {
      const initialGasValue = (gasPrices.safeLow + gasPrices.fastest) / 2;
      return setGasRangePrice(initialGasValue || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gasPrices]);

  const BtnContent = useMemo(() => {
    if (withdrawLoading && isWalletConnected) {
      return (
        <S.BtnContent className="loading">
          <Spinner /> { txHash
            ? <span>{translate('modal.releasing', { redeemAmount: numberWithCommas(redeem?.amount, 3, true), targetSymbol: redeem?.target_token_symbol })}</span>
            : translate('modal.confirm_tx_in_wallet')}
        </S.BtnContent>
      );
    }
    if (!walletAddress || switchNetwork) {
      return <S.BtnContent className="connect-mm-to-network"> {translate('modal.switch_your_wallet_to', { targetNetworkName: networksById[redeem?.target_network_id].label_name })}</S.BtnContent>;
    }
    return <S.BtnContent> {translate('modal.release', { number: numberWithCommas(redeem?.amount, 3, true), token: redeem?.target_token_symbol, network: networksById[redeem?.target_network_id].label_name })}</S.BtnContent>;
  }, [withdrawLoading, isWalletConnected, walletAddress, switchNetwork, redeem?.amount, redeem?.target_token_symbol, redeem?.target_network_id, networksById, txHash]);

  return (
    <S.Wrapper>
      <S.Header>{translate('common.port_your_tokens')}
      </S.Header>
      <S.Grid
        className={clsx('withdraw-section', {
          mobile: isMobile,
        })}
      >
        {!ethTransactionFee?.isEnoughBalance && isWalletConnected && !txHash && (
          <Warning isMobile={isMobile}>
            <div>
              {translate('modal.no_funds', { tokenname: networksById[redeem.target_network_id]?.native_token_symbol, network: networksById[redeem.target_network_id]?.label_name })}
            </div>
          </Warning>
        )}
        <S.TransactionInfo className={clsx('portout', { mobile: isMobile })}>
          <span>
            <p>{translate('modal.from')}</p>
            {networksById[redeem?.base_network_id]?.label}
          </span>
          <span>
            <img src="./chainport-logo.png" alt="chainport-logo" />
          </span>
          <span>
            <p>{translate('modal.to')}</p>
            {networksById[redeem?.target_network_id]?.label}
          </span>
        </S.TransactionInfo>
        {isWalletConnected && (redeem?.target_network === networksByName.ETHEREUM.name) && !switchNetwork ?
          <S.RangeContainer
            value={gasRangePrice}
            setValue={setGasRangePrice}
            gasPrices={gasPrices}
            amountInUSD={(nativeCoinRates.ETHEREUM * ethTransactionFee?.fee).toFixed(2)}
          /> : null}

        <S.WithdrawBodyBottom>
          <S.WithdrawTokenPreview>
            {`${numberWithCommas(redeem?.amount, 10, true)} ${redeem?.target_token_symbol}`}
          </S.WithdrawTokenPreview>
          {redeem && <TxProgressBar />}
          {!walletAddress || switchNetwork ?
            <Button className="ethereum-grey w-100 action" onClick={connectMMToNetwork}> {BtnContent}</Button>
            :
            <Button
              className={`primary w-100 action ${baseNetwork?.name.toLowerCase()}`}
              disabled={
                withdrawLoading ||
              !walletAddress ||
              !ethTransactionFee?.isEnoughBalance
              }
              onClick={handlePortOutTokens}
            >
              {BtnContent || 'loading'}
            </Button>
          }
          {(!walletAddress || switchNetwork) && (
            <Warning strict={false}>
              <p>
                {getNetworkErrorMessage(
                  redeem?.target_network_id
                )}
              </p>
            </Warning>
          )}
        </S.WithdrawBodyBottom>
      </S.Grid>
      {txHash && (
        <S.CheckTx href={txHashStatus} target="_blank" rel="noopener noreferrer">
          {translate('modal.check_tx')}
          <AwaySVG />
        </S.CheckTx>
      )}
    </S.Wrapper>
  );
};

ExchangePortInView.propTypes = {
  networkId: PropTypes.number,
  txHash: PropTypes.string,
  txHashStatus: PropTypes.string,
  redeem: PropTypes.object,
  handlePortOutTokens: PropTypes.func,
  withdrawLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  walletAddress: PropTypes.string,
  setGasRangePrice: PropTypes.func,
  gasPrices: PropTypes.object,
  gasRangePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ethTransactionFee: PropTypes.shape({
    fee: PropTypes.number,
    isEnoughBalance: PropTypes.bool,
    isEnoughTokens: PropTypes.bool,
  }),
  isWalletConnected: PropTypes.bool,
  connectMMToNetwork: PropTypes.func,
  networksById: PropTypes.object,
  processingPort: PropTypes.object,
  nativeCoinRates: PropTypes.object,
  networksByName: PropTypes.object,
  baseNetwork: PropTypes.object,

};

export default ExchangePortInView;
