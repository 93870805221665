import styled from 'styled-components/macro';
import colors from '../../../styles/colors';

const Wrapper = styled.div`
font-family: ROBOTO;

  .modal-body {
    .modal-header {
      .modal-title {
        text-align: center !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .modal-sub-title {
      font-weight: 400;
      font-size: 14px;
    }
    /* max-width: 410px; */
    min-width: 300px;
    /* padding: ${({ theme }) => theme.isMobile ? '0px 32px' : ''}; */
    margin: 0 auto;
    .icon-close {
      display: ${(props) => props.isCloseEnable ? '' : 'none'};
    }
  }

  .modal-body-content {
    padding: 0 !important;
  }

  .loader {
    margin-bottom: 18px;
  }

  button {
    min-width: 190px;
  }

  .result-icon {
    margin: 25px auto 20px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-right: ${({ theme }) => theme.isMobile ? '2rem' : ''};
`;

const DetailsWrapper = styled.div`
  padding: 1.25rem 3rem 1.25rem 3rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 165px;
  justify-content: space-around;
  align-items: center;
`;

const ColumDetailsWrapper = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
  &:nth-child(2) {
    padding-left: 5rem;
  }
`;

const ColumnWrapper = styled.div`
  height: 100%;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
`;

const ItemTitle = styled.div`
  color: rgba(255,255,255,0.5);
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
`;

const Value = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;

  &.lightGreen {
    color: rgba(47, 246, 211, 1);
  }
`;

const borderDividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0rem;
`;

const BorderDivider = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  width: 100%;
  height: 1px;
`;

const YellowWarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: ${({ theme }) => theme.isMobile ? '0rem 3rem' : '0rem 2rem'};
  svg {
    height: 19px;
    width: 20px;
  }
`;

const YellowWarning = styled.div`
  color: rgba(243, 186, 47, 1);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 0.5rem;
`;

const SVGwarningWrapper = styled.div`
  width: 50px;
  padding-right: 1rem;
`;

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  .error-info {
    margin-bottom: -10px;
  }
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  padding: 0rem 2rem 1rem 2rem;
`;

const LinkTo = styled.div`
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
  :hover{
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`;

const CheckTx = styled.a`
  transform: translateY(-15px);
  text-decoration: underline;
  color: ${colors.aqua_bg}; 
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  -webkit-transition: all .5s ease;
  transition: all .5s ease;

  svg g, svg path {
    stroke: ${colors.aqua_bg}
  }

  &:not(:disabled):hover {
    color: ${colors.white};
    -webkit-transition: all .35s ease;
    transition: all .35s ease;

    svg g, svg path {
      stroke: ${colors.white}
    }

    path {
      stroke: ${colors.white}
    }
  }

  & > svg {
    margin-left: 5px;
 g{
  opacity: 1;
 }
    path {
      stroke: ${colors.aqua_bg}
    
    }
  }
`;

const S = {
  Wrapper,
  DetailsWrapper,
  ColumDetailsWrapper,
  ColumnWrapper,
  ItemTitle,
  Value,
  borderDividerWrapper,
  BorderDivider,
  YellowWarningWrapper,
  YellowWarning,
  SVGwarningWrapper,
  WarningWrapper,
  ButtonWrapper,
  LinkTo,
  Title,
  CheckTx,
};

export default S;
