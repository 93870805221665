import { createSelector } from 'reselect';
import { createNetworkLabel } from '../../constants/general';

const isMenuOpen = (state) => state.general.isMenuOpen;
const isMobile = (state) => state.general.isMobile;
const isTablet = (state) => state.general.isTablet;
const networkId = (state) => state.general.networkId;
const approveLoading = (state) => state.general.loading.approve;
const withdrawLoading = (state) => state.general.loading.withdraw;
const allowanceLoading = (state) => state.general.loading.allowance;
const gasPrices = (state) => state.general.gasPrices;
const settledGasPrice = (state) => state.general.settledGasPrice;
const networksById = (state) => state.general.networksById;
const networksByChain = (state) => state.general.networksByChain;
const networksByName = (state) => state.general.networksByName;
const networks = (state) => state.general.networks;
const nativeCoinRates = (state) => state.general.nativeCoinRates;
const sortedNetworks = (state) => state.general.sortedNetworks;
const isBannerShown = (state) => state.general.isBannerShown;

// const productVersion = (state) => state.general.versions?.product;
const tvl = (state) => state.general.tvl;
const feeManager = (state) => state.general.fee_manager;
const perpetualContract = (state) => state.general.perpetual_contract;
const portxPolyAddress = (state) => state.general.portx_poly_address;
const tokensWithLiqudity = (state) => state.general.tokensWithLiqudity;
const isAckLoading = (state) => state.general.isAckLoading;
const activePage = (state) => state.general.activePage;
const progressBarStep = (state) => state.general.progressBarStep;
const progressBarPercentage = (state) => state.general.progressBarPercentage;

const networksSelector = createSelector(networks, (nets) =>
  [...nets].map((net) => ({
    ...net,
    label: createNetworkLabel(net),
  }))
);

const addLabelsToNetworkObjects = (nets) =>
  Object.keys(nets).reduce((acc, key) => {
    acc[key] = { ...nets[key] };
    acc[key].label_name = acc[key].label;
    acc[key].label = createNetworkLabel(acc[key]);
    return acc;
  }, {});

const networksByIdSelector = createSelector(
  networksById,
  addLabelsToNetworkObjects
);
const networksByChainSelector = createSelector(
  networksByChain,
  addLabelsToNetworkObjects
);
const networksByNameSelector = createSelector(
  networksByName,
  addLabelsToNetworkObjects
);

export default {
  isMenuOpen,
  isMobile,
  isTablet,
  networkId,
  approveLoading,
  withdrawLoading,
  gasPrices,
  settledGasPrice,
  allowanceLoading,
  tvl,
  feeManager,
  perpetualContract,
  portxPolyAddress,
  tokensWithLiqudity,
  isAckLoading,
  activePage,
  nativeCoinRates,
  sortedNetworks,
  isBannerShown,
  progressBarStep,
  progressBarPercentage,

  networksById: networksByIdSelector,
  networksByChain: networksByChainSelector,
  networksByName: networksByNameSelector,
  networks: networksSelector,
};
