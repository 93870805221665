import Web3 from 'web3';
import { web3WalletConnector } from './walletService';

export const setupWeb3 = (provider, actionType) => {
  const rpcProvider = provider || window?.CONFIG?.networksByName?.ETHEREUM.rpc;
  if (!provider && actionType !== 'onWalletConnectErrorAction') {
    web3WalletConnector.disconnect();
  }
  window._web3 = new Web3(rpcProvider);
};
