import SafePalIcon from '../components/Icons/ProviderIcons/SafePalIcon';
import TokenPocketIcon from '../components/Icons/ProviderIcons/TokenPocketIcon';
import MetaMaskIcon from '../components/Icons/ProviderIcons/MetaMaskIcon';
import Fortmatic from '../components/Icons/ProviderIcons/FortmaticIcon';
import WalletConnect from '../components/Icons/ProviderIcons/WalletConnectIcon';
import StatusIcon from '../components/Icons/ProviderIcons/StatusIcon';
import imTokenIcon from '../components/Icons/ProviderIcons/imTokenIcon';
import TrustIcon from '../components/Icons/ProviderIcons/TrustIcon';
import CoinbaseIcon from '../components/Icons/ProviderIcons/CoinbaseIcon';
import TokenaryIcon from '../components/Icons/ProviderIcons/TokenaryIcon';
import OperaIcon from '../components/Icons/ProviderIcons/OperaIcon';
import MathWalletIcon from '../components/Icons/ProviderIcons/MathWalletIcon';
import BrowserIcon from '../components/Icons/ProviderIcons/BrowserIcon';
import { getInPageProviderName } from '../services/walletService';

export const WALLET_TYPES = {
  inPageProvider: 'inPageProvider',
  ledger: 'ledger',
  trezor: 'trezor',
  walletConnect: 'walletConnect',
  portis: 'portis',
  fortmatic: 'fortmatic',
  coinbase: 'coinbase',
  binance: 'binance',
  clover: 'clover',
};

export const inPageProviderIconSwitcher = (providerName) => {
  switch (providerName) {
  case 'MetaMask':
    return MetaMaskIcon;

  case 'Status':
    return StatusIcon;

  case 'imToken':
    return imTokenIcon;

  case 'Trust':
    return TrustIcon;

  case 'Coinbase':
    return CoinbaseIcon;

  case 'Tokenary':
    return TokenaryIcon;

  case 'Opera':
    return OperaIcon;

  case 'Math Wallet':
    return MathWalletIcon;

  case 'SafePal':
    return SafePalIcon;

  case 'TokenPocket':
    return TokenPocketIcon;

  case 'WalletConnect':
    return WalletConnect;

  case 'Fortmatic':
    return Fortmatic;

  default:
    return BrowserIcon;
  }
};

const providerNameToValue = (providerName) => {
  switch (providerName) {
  case 'MetaMask':
    return 'MetaMask';

  case 'Status':
    return 'Status';

  case 'imToken':
    return 'imToken';

  case 'Trust':
    return 'Trust';

  case 'Coinbase':
    return 'Coinbase';

  case 'Tokenary':
    return 'Tokenary';

  case 'Opera':
    return 'Opera';

  case 'Math Wallet':
    return 'MathWallet';

  case 'SafePal':
    return 'SafePal';

  case 'TokenPocket':
    return 'TokenPocket';

  case 'Fortmatic':
    return 'Fortmatic';

  case 'WalletConnect':
    return 'WalletConnect';

  default:
    return 'Browser';
  }
};

export const getSupportedWallets = () => {
  const providerName = getInPageProviderName();
  return [
    {
      label: providerName,
      value: providerNameToValue(providerName),
      Icon: inPageProviderIconSwitcher(providerName),
      connectionType: WALLET_TYPES.inPageProvider,
      mobile: true,
    },
    {
      label: 'WalletConnect',
      value: providerNameToValue('WalletConnect'),
      Icon: inPageProviderIconSwitcher('WalletConnect'),
      connectionType: WALLET_TYPES.inPageProvider,
      mobile: true,
    },
    {
      label: 'Fortmatic',
      value: providerNameToValue('Fortmatic'),
      Icon: inPageProviderIconSwitcher('Fortmatic'),
      connectionType: WALLET_TYPES.inPageProvider,
      mobile: true,
    },
    {
      label: 'Portis',
      value: providerNameToValue('Portis'),
      Icon: inPageProviderIconSwitcher('Portis'),
      connectionType: WALLET_TYPES.inPageProvider,
      mobile: true,
    }
  ];
};

export const WALLET_KEYS_TO_NAME = {
  metamask: 'MetaMask',
  trust: 'Trust',
  mathWallet: 'Math Wallet',
  safePal: 'SafePal',
  tokenPocket: 'TokenPocket',
  binance: 'Binance Chain Wallet',
  fortmatic: 'Fortmatic',
  portis: 'Portis',
};

export const MULTPLE_ADDRESSES_TYPE = {
  eternl: 'eternl',
  yoroi: 'yoroi',
  daedalus: 'daedalus',
};
