import { createSelector } from 'reselect';

const stakeSelector = (state) => state.stake;
const stakingHistoryData = (state) => state.stake.stakingHistoryData;
const dapiData = (state) => state.stake.dapiData;
const stakingLoading = (state) => state.stake.stakingLoading;
const claimingLoading = (state) => state.stake.claimingLoading;
const withdrawStakedLoading = (state) => state.stake.withdrawStakedLoading;
const stakingStats = (state) => state.stake.stakingStats;
const sdkData = (state) => state.stake.sdkData;

const portXBalances = createSelector(stakeSelector, (stake) => ({
  data: stake.portXBalances,
  error: stake.portXBalancesError,
  loading: stake.portXBalancesLoading ?? false,
}));

export default {
  stakingHistoryData,
  portXBalances,
  dapiData,
  stakingLoading,
  claimingLoading,
  withdrawStakedLoading,
  stakingStats,
  sdkData,
};
