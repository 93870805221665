import {
  RESET_TX_HASH,
  SET_TX_HASH,
  SET_PROCESSING_LOADER,
  UPDATE_BLOCK_CONFIRMATIONS,
  START_INTERVAL_FOR_BLOCK_CONFIRMATIONS,
  CLEAR_INTERVAL_FOR_BLOCK_CONFIRMATIONS,
  SET_PORT_TX,
  SET_REDEEM_TX,
  SET_IS_REDEEM_ON_GOING,
  SET_PORT_TIMEOUT_ID,
  DEPOSIT_TOKENS_FINISHED,
  WITHDRAW_TOKENS_FINISHED,
  HANDLE_PORT_OUT_TOKENS_FINISHED,
  CLEAR_TX_STATE,
  SET_CURRENT_TX_NONCE,
  SET_CURRENT_TX_BLOCK,
  ADD_ALLOWANCE_FINISHED,
  SET_PORTX_FEE,
  UPDATE_CARDANO_TX_HASH

} from '../actionTypes/transactionActionTypes';

import {
  STAKE_TOKENS_FINISHED,
  CLAIM_TOKENS_FINISHED,
} from '../actionTypes/stakeActionTypes';

const INITIAL_STATE = {
  tx_hash: null,
  block_confirmations: 0,
  interval_id: null,
  portTimeoutId: null,
  portTx: {},
  redeemTx: null,
  isRedeemOnGoing: false,
  currentTxNonce: 0,
  currentTxBlock: 0,
  portxFee: {},
  cardanoDepositTx: null,
  cardanoBurnTx: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;
  switch (type) {
  case SET_PORTX_FEE:
    return {
      ...state,
      portxFee: action.payload,
    };
  case CLAIM_TOKENS_FINISHED:
    return {
      ...state,
      tx_hash: null,
      block_confirmations: 0,
      portTimeoutId: null,
      portTx: {},
      redeemTx: null,
      isRedeemOnGoing: false,
      currentTxNonce: 0,
      currentTxBlock: 0,
      // interval_id: null,
    };
  case STAKE_TOKENS_FINISHED:
    return {
      ...state,
      tx_hash: null,
      block_confirmations: 0,
      portTimeoutId: null,
      portTx: {},
      redeemTx: null,
      isRedeemOnGoing: false,
      currentTxNonce: 0,
      currentTxBlock: 0,
      // interval_id: null,
    };
  case SET_CURRENT_TX_NONCE:
    return {
      ...state,
      currentTxNonce: action.payload,
    };
  case SET_CURRENT_TX_BLOCK:
    return {
      ...state,
      currentTxBlock: action.payload,
    };
  case CLEAR_TX_STATE:
    return {
      ...state,
      tx_hash: null,
      block_confirmations: 0,
      interval_id: null,
      portTimeoutId: null,
      portTx: {},
      redeemTx: null,
      isRedeemOnGoing: false,
      setCurrentTxNonce: 0,
      currentTxBlock: 0,
      cardanoDepositTx: null,
      cardanoBurnTx: null,

    };
  case HANDLE_PORT_OUT_TOKENS_FINISHED:
    return {
      ...state,
      // tx_hash: null,
      block_confirmations: 0,
      // interval_id: null,
      portTimeoutId: null,
      portTx: {},
      redeemTx: null,
      isRedeemOnGoing: false,
      currentTxNonce: 0,
      currentTxBlock: 0,
    };
  case WITHDRAW_TOKENS_FINISHED:
    return {
      ...state,
      tx_hash: null,
      block_confirmations: 0,
      portTimeoutId: null,
      portTx: {},
      redeemTx: null,
      isRedeemOnGoing: true, // notice its true and not false
      // interval_id: null,
      currentTxNonce: 0,
      currentTxBlock: 0,

    };
  case DEPOSIT_TOKENS_FINISHED:
    return {
      ...state,
      tx_hash: null,
      block_confirmations: 0,
      portTimeoutId: null,
      portTx: {},
      redeemTx: null,
      isRedeemOnGoing: false,
      currentTxNonce: 0,
      currentTxBlock: 0,
      // interval_id: null,
    };
  case ADD_ALLOWANCE_FINISHED:
    return {
      ...state,
      tx_hash: null,
      block_confirmations: 0,
      portTimeoutId: null,
      portTx: {},
      redeemTx: null,
      // interval_id: null,

      isRedeemOnGoing: false,
      currentTxNonce: 0,
      currentTxBlock: 0,
      // interval_id: null,
    };
  case SET_PORT_TIMEOUT_ID:
    return {
      ...state,
      portTimeoutId: action.payload
    };
  case SET_IS_REDEEM_ON_GOING:
    return {
      ...state,
      isRedeemOnGoing: action.payload
    };
  case SET_REDEEM_TX:
    return {
      ...state,
      redeemTx: action.payload
    };
  case SET_TX_HASH:
    return {
      ...state,
      tx_hash: action.payload
    };
  case RESET_TX_HASH:
    return {
      ...state,
      tx_hash: null
    };
  case SET_PROCESSING_LOADER: {
    return { ...state, processingLoader: action.payload };
  }
  case UPDATE_BLOCK_CONFIRMATIONS: {
    return {
      ...state,
      block_confirmations: action.payload,
    };
  }
  case START_INTERVAL_FOR_BLOCK_CONFIRMATIONS: {
    return {
      ...state,
      interval_id: action.payload
    };
  }
  case CLEAR_INTERVAL_FOR_BLOCK_CONFIRMATIONS: {
    return {
      ...state,
      interval_id: null
    };
  }
  case SET_PORT_TX: {
    return {
      ...state,
      portTx: action.payload
    };
  }
  case UPDATE_CARDANO_TX_HASH: {
    return {
      ...state,
      cardanoDepositTx: action.payload.cardanoDepositTx,
      cardanoBurnTx: action.payload.cardanoBurnTx,
    };
  }
  default:
    return state;
  }
};
