import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import ModalBody from '../../ModalBody';
import Button from '../../../Button';
import S from '../styled';
import { shortenAddress } from '../../../../services/utilsService';
import { ReactComponent as RedirectSVG } from '../../../../assets/redirect.svg';

/*                       ACTIONS                           */
import { closeModal } from '../../../../redux/actions/modalActions';

import usePortxBalances from '../../../../hooks/usePortxBalances';
import useSDKdata from '../../../../hooks/useSDKdata';

const CompleteModalTemplate = ({ title, logo, subTitle1, subTitle1Value1, subTitle2, subTitle1Value2, extraRow = '', extraRowAboveBtn = '', BTNtxt, BTNonClick }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [portXBalances] = usePortxBalances();
  // eslint-disable-next-line no-unused-vars
  const { sdkData } = useSDKdata();

  const walletAddress = useSelector((state) => state.wallet.account);
  const selectedToken = useSelector((state) => state.bridge.selected_token);
  const baseNetwork = useSelector((state) => state.bridge.baseNetwork);
  const txHash = useSelector((state) => state.transactions.tx_hash);

  const onAddressClick = useCallback((e, isToken) => {
    e.stopPropagation();
    const url = isToken
      ? `${baseNetwork?.explorer_url}token/${selectedToken?.web3_address}`
      : `${baseNetwork?.explorer_url}address/${walletAddress}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }, [baseNetwork?.explorer_url, selectedToken?.web3_address, walletAddress]);

  return (
    <S.Wrapper>
      <ModalBody showHeader={true} closeModal={() => { dispatch(closeModal()); }} title={title}>

        <S.LogoWrapper>
          {logo}
        </S.LogoWrapper>

        <S.DetailsWrapper>
          <S.ColumDetailsWrapper>
            <S.ColumnWrapper>
              <><S.ItemTitle>{subTitle1}</S.ItemTitle><S.Value>{`${subTitle1Value1} PORTX`}</S.Value></>
            </S.ColumnWrapper>
            <S.ColumnWrapper>
              <S.ItemTitle>Token address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, true)}>{shortenAddress(selectedToken?.web3_address || 'ab')}</S.LinkTo></S.Value>
            </S.ColumnWrapper>
          </S.ColumDetailsWrapper>

          <S.ColumDetailsWrapper>
            <S.ColumnWrapper>
              <><S.ItemTitle>{subTitle2}</S.ItemTitle><S.Value>{subTitle1Value2}</S.Value></>
            </S.ColumnWrapper>
            <S.ColumnWrapper>
              <S.ItemTitle>Wallet address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, false)}>{shortenAddress(walletAddress)}</S.LinkTo></S.Value>
            </S.ColumnWrapper>
          </S.ColumDetailsWrapper>
        </S.DetailsWrapper>
        {extraRow || ''
        }
        <S.ButtonWrapper>
          {extraRowAboveBtn || ''
          }
          <Button
            className="transparentBackground w-100 action"
            onClick={BTNonClick}
          > {BTNtxt}
          </Button>
        </S.ButtonWrapper>
        <S.ExplorerLink
          href={`${baseNetwork?.explorer_url}/tx/${txHash}`}
          target="_blank"
          rel="noopener noreferrer">View Tx on PolygonScan <RedirectSVG />
        </S.ExplorerLink>
      </ModalBody>
    </S.Wrapper>
  );
};

CompleteModalTemplate.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.node,
  subTitle1: PropTypes.string,
  subTitle1Value1: PropTypes.node,
  subTitle2: PropTypes.string,
  subTitle1Value2: PropTypes.node,
  extraRow: PropTypes.node,
  extraRowAboveBtn: PropTypes.node,
  BTNtxt: PropTypes.string,
  BTNonClick: PropTypes.func,
};

export default CompleteModalTemplate;
