import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import S from './styled';
import generalSelectors from '../../redux/selectors/generalSelectors';

import { PROGRESS_STEP_OPTIONS } from '../../constants/general';
import bridgeSelectors from '../../redux/selectors/bridgeSelectors';
import tokenSelectors from '../../redux/selectors/tokenSelectors';
import ProgressBar from '../ProgressBar';

const DEPOSIT_STEPS = ['confirm', 'deposit', 'mint', 'receive'];
const CROSSCHAIN_STEPS = ['confirm', 'burn', 'mint', 'receive'];
const BURN_STEPS = ['confirm', 'burn', 'release', 'receive'];
const CARDANO_TO_EVM_MAINCHAIN_STEPS = ['confirm', 'deposit', 'burn', 'release', 'receive'];
const CARDANO_TO_EVM_SIDECHAIN_STEPS = ['confirm', 'deposit', 'burn', 'mint', 'receive'];

const TxProgressBar = () => {
  const progressBarPercentage = useSelector(generalSelectors.progressBarPercentage);
  const progressBarStep = useSelector(generalSelectors.progressBarStep);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const networksByName = useSelector(generalSelectors.networksByName);
  const destinationNetwork = useSelector(bridgeSelectors.destinationNetwork);
  const isTokenNative = useSelector(tokenSelectors.isTokenNative);
  const pendingRedeem = useSelector(bridgeSelectors.pendingRedeem);
  const originNetworkId = useSelector(tokenSelectors.originNetworkId);

  const progressSteps = useMemo(() => {
    const newProgressSteps = [];
    let steps;
    if (isTokenNative) {
      steps = DEPOSIT_STEPS;
    } else if (baseNetwork?.id === networksByName?.CARDANO?.id && originNetworkId !== destinationNetwork?.id) {
      steps = CARDANO_TO_EVM_SIDECHAIN_STEPS;
    } else if (baseNetwork?.id === networksByName?.CARDANO?.id || pendingRedeem?.base_network_id === networksByName?.CARDANO?.id) {
      steps = CARDANO_TO_EVM_MAINCHAIN_STEPS;
    } else if (originNetworkId === destinationNetwork?.id || pendingRedeem) {
      steps = BURN_STEPS;
    } else {
      steps = CROSSCHAIN_STEPS;
    }
    const depositSteps = PROGRESS_STEP_OPTIONS.filter((step) => steps.includes(step.status.toLowerCase()));
    newProgressSteps.push(...depositSteps);
    return newProgressSteps;
  }, [isTokenNative, baseNetwork, destinationNetwork, networksByName, originNetworkId, pendingRedeem]);

  return (
    <S.ProgressBarOuterWrapper>
      <S.ProgressBarIconWrapper>
        {progressSteps.map(({ icon }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={clsx('progress-icon', { done: index <= progressBarStep })} key={index}>{icon}
          </div>

        ))}
      </S.ProgressBarIconWrapper>
      <ProgressBar progressStepsLength={progressSteps.length} progressBarStep={progressBarStep} progressBarPercentage={progressBarPercentage} />
      <S.ProgressBarTextWrapper>
        {progressSteps.map(({ status }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={clsx('progress-text', { done: index <= progressBarStep })}>{status}
          </div>

        ))}
      </S.ProgressBarTextWrapper>
    </S.ProgressBarOuterWrapper>

  );
};

export default TxProgressBar;
