import styled from 'styled-components';
import colors from '../../styles/colors';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background: url('/shadow.png') ${(props) => props.theme.main_bg || 'navy'};
  background-position: center;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: unset;

  .maintenance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 24px;
   padding:0 20px;
  


    .header-text{
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 7px;

    }
   .paragraph-text{
      font-size: 16px;
      margin-bottom: 25px;

      &:last-of-type{
        margin-top: 25px;
             }

      a{
        color:white;
        text-decoration: underline;

        &:hover{
          text-decoration: underline;
          color: ${colors.aqua_bg};
          cursor: pointer;
      }
     }
   
  
  }


  p > a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);

    &:hover {
      color: ${colors.aqua_bg};
      cursor: pointer;
    }
  }
`;

const S = { Wrapper };

export default S;
