import styled, { css } from 'styled-components';

// const CIRCLE_WIDTH = 22;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 25px 0 25px;

  ${({ theme }) => theme.isMobile && css`
    font-size: 12px;
  `};

  .circle {
    fill: ${({ theme }) => theme.main_bg};
    &.done {
      fill: ${({ theme }) => theme.white};
      transition-delay: 8s;
    } 

    &.current {
      transform: scale(1.2);  
      animation: grow 3s infinite linear;
    }

    @keyframes grow {
      0% { transform: scale(1); }
      50% { transform: scale(1.2);}
      100% { transform: scale(1); }
    }
  }
`;

const ProgressBarLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  margin:10px -6px 0 -6px;
`;

const ProgressBarLineInner = styled.div`
  &.active {
    height: 2px;
    width: ${(props) => props.barCompleted}%;
    background-color: white; 
    transition: width 6s ease-in-out;
  }
`;

const ProgessBarLineAndCircleWrapper = styled.div`
  display: flex;
  flex-direction: row;

 
 
  flex: 1;
  // remove the last progress line
  &:last-child {
    flex: 0;
    & > div:last-child {
      display: none;
    }
  }
`;

const S =
{
  ProgressBarWrapper,
  ProgressBarLine,
  ProgessBarLineAndCircleWrapper,
  ProgressBarLineInner
};

export default S;
