const isWalletConnected = (state) => !!state.wallet.account;
const walletAddress = (state) => state.wallet.account;
const walletBalance = (state) => state.wallet.connectedWalletBalance;
const accountType = (state) => state.wallet.accountType;
const network = (state) => state.wallet.network;
const connectingWallet = (state) => state.wallet.connectingWallet;
const connectingWalletNetworkId = (state) => state.wallet.connectingWalletNetworkId;
const providerName = (state) => state.wallet.additions?.providerName;
const recipientWalletAddress = (state) => state.wallet.recipientWalletAddress;
const decodedWalletBalance = (state) => state.wallet.decodedWalletBalance;
const cardanoWalletAddress = (state) => state.wallet.cardanoWalletAddress;
const walletType = (state) => state.wallet.walletType;
const connectingWalletProvider = (state) => state.wallet.connectingWalletProvider;

export default { isWalletConnected,
  walletAddress,
  walletBalance,
  network,
  accountType,
  connectingWallet,
  connectingWalletNetworkId,
  providerName,
  recipientWalletAddress,
  decodedWalletBalance,
  cardanoWalletAddress,
  walletType,
  connectingWalletProvider
};
