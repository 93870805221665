import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal } from '../../../redux/actions/modalActions';
import ModalBody from '../ModalBody';
import CircleLoader from '../../Loaders/CircleLoader';
import Button from '../../Button';
import S from './styled';

// TODO ConfirmDepositModal not in use
const ConfirmDepositModal = ({ closeModal, txStatus = 'pending' }) => (
  <S.Wrapper>
    <ModalBody showHeader={false} closeModal={closeModal}>
      {txStatus === 'pending' && (
        <>
          <CircleLoader />
          <S.Typography className="head mb-15">
            Wait for confirmation Deposit in ETH chain
          </S.Typography>
          <S.Typography className="aqua mb-30">
            Confirm this transaction in your wallet
          </S.Typography>
        </>
      )}

      <Button onClick={closeModal} className="dark-navy">
        <S.Typography className="weight-medium">Close</S.Typography>
      </Button>
    </ModalBody>
  </S.Wrapper>
);

ConfirmDepositModal.propTypes = {
  closeModal: PropTypes.func,
  txStatus: PropTypes.oneOf(['pending', 'error', 'success']),
};

const mapDispatchToProps = {
  closeModal,
};

export default connect(null, mapDispatchToProps)(ConfirmDepositModal);
