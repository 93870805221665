import styled, { css } from 'styled-components';
import mixins from '../../../styles/mixins';
import colors from '../../../styles/colors';
import Spinner from '../../../components/Spinner/index';
import RangeControl from '../../../components/RangeControl/RangeControl';

const ExchangeViewContainer = styled.div`
  height: 100%;
  width: 100%;
  background: red;
`;

const Wrapper = styled.section`
  border-radius: 20px;
  background: ${colors.dark_bg};
  margin: 9% auto 30px auto;
  ${mixins.flexItXY('flex-start', 'center')}
  flex-direction: column;
  max-width: ${(props) => props.width || '535'}px;
  width: 100%;

  &.mobile {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 632px) {
    border-radius: 0;
    max-width: 632px;
   margin-top: ${(props) => props.isBannerShown ? '25px' : '0'
};
    margin-left: auto;
    margin-right: auto;

  }

  .w-100 {
    width: 100%;
  }

  &.connected {

    &.mobile {
      margin-bottom: 30px;
      // margin-top: 25px;
    }
  }

  .burn-warning {
  }

  .status-wrapper{
    display: flex;
   width: 88%;
    justify-content:space-evenly;

    ${({ theme }) => theme.isMobile && css`
   flex-direction: column;
   align-items: center;
  `}
`;

const Header = styled.header`
  width: 100%;
  color: ${colors.white};
  ${mixins.flexItXY('center', 'center')};
  border-bottom: 1px solid ${colors.form_inp_bg};
  font: normal normal 700 25px/29px Roboto;
  display: flex;
  flex-direction: column;
  padding: 25px 0;

  p {
    font-size: 16px;
  }
`;

const ErrorInfo = styled.div`
  background: #bb8500;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 5px;
  display: flex;
  // align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 30px;

  svg {
    margin-right: 6px;
    width: 40px;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }

  &.mobile {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Grid = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;

  &.mobile {

  }

  &.wallet-connected.mobile {

  }

  &.withdraw-section {

    &.mobile {

    }
  }

  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }
`;

const DestinationWallet = styled.div`
  margin-bottom: 30px;

  &.mobile {
    margin-bottom: 20px;
  }
`;

const Actions = styled.div`
  display: flex;

  &.mobile {
    flex-direction: column;
  }
`;

const Numbers = styled.div`
  ${mixins.flexItXY('center', 'center')}
`;

const Number = styled.div`
  width: 40px;
  height: 40px;
  ${mixins.flexItXY('center', 'center')}
  background-color: ${colors.gray_extra_light};
  color: ${colors.white};
  border-radius: 5px;
  font: normal normal bold 18px/21px Roboto;
`;

const Line = styled.div`
  width: 145px;
  height: 1px;
  background-color: ${colors.gray_light};
  margin: 0 22px;
`;

const SwapAmount = styled.div`
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  input.dark {
    padding: 0 63px 0 20px;
    font-weight: 500;
    font-size: 18px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${({ theme }) => theme.isMobile && css`
    margin-bottom: 20px;
    width: 100%;
  `}
`;

const SelectToken = styled.div`
  align-self:end;
  width: 45%;

  margin-bottom: 30px;
  &.mobile {
    margin-bottom: 20px;
    width: 100%;
  }
`;

const Action = styled.div`
  margin-top: 10px;
`;

const BtnContentWrapper = styled.div`
width:100%;
height:100%;
display:flex;
justify-content:center;
`;
const BtnContent = styled.span`
  font: normal normal 500 16px/19px Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding:0 30px 0 30px;
  line-height:25px;

 


  &.loading {
    & > div {
      margin: unset;
      margin-right: 20px;
    }

    @media screen and (max-width: 400px) {
      padding-left: 40px;
    }
  }

  &.center {
    & > div {
      margin: unset;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;

const MaxAmountBtn = styled.div`
  text-transform: uppercase;
  text-decoration: underline;
  font: normal normal normal 16px/19px Roboto;
  position: absolute;
  bottom: 12px;
  right: 20px;
  cursor: pointer;
  color: ${colors.gray_light};
  -webkit-transition: all .35s ease;
  transition: all .35s ease;

  &:hover {
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    color: ${colors.aqua_bg};
  }
`;

const Preview = styled.div`
  ${mixins.flexItXY('center', 'center')}
  background-color: ${colors.gray_extra_light};
  border-radius: 5px;
  min-height: 40px;
  padding: 0 20px;
  margin-bottom: 20px;

  & > svg {
    margin: 0 10px;
  }
`;

const WithdrawPreview = styled.div`
  background-color: ${colors.gray_extra_light};
  border-radius: 5px;
  min-height: 40px;
  padding: 10px 20px;

  & > div:first-of-type {
    ${mixins.flexItXY('center', 'center')}
    margin-bottom: 8px;
    & > svg {
      margin: 0 10px;
    }
  }
  & > div:last-child {
    ${mixins.flexItXY('space-between', 'center')}
  }
`;

const FieldLabel = styled.label`
  font: normal normal normal 16px/19px Roboto;
  text-transform: capitalize;
  color: ${colors.gray_light};

  &.small {
    font: normal normal normal 14px/16px Roboto;
  }
`;

const Value = styled.span`
  color: ${colors.white};
  font: normal normal bold 18px/21px Roboto;

  &.small {
    font: normal normal normal 14px/16px Roboto;
  }
`;

const TokenIconWrapper = styled.div`
  margin: 0 8px;
`;

const RightArrowWrapper = styled.div`
  margin: 0 25px;
`;

const Info = styled.div`
  margin-bottom: 20px;
  & > div {
    ${mixins.flexItXY('space-between')}
    margin-bottom: 20px;
  }

  & + button {
    margin-bottom: 10px;
  }

  &.mobile {
    & > div {
      margin-bottom: 10px;
    }
  }
`;

const Body = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 20px;
`;

const WithdrawBodyTop = styled.div`
  padding: 20px 20px 10px;
  border-bottom: 1px solid ${colors.gray_extra_light};
`;

const WithdrawTokenPreview = styled.div`
  font: normal normal bold 25px/29px Roboto;
  color: ${colors.aqua_bg};
  padding: 0 0 20px;
  ${mixins.flexItXY('center')}
`;

const WithdrawBodyBottom = styled.div`
`;

const WithdrawSwitchMessage = styled.div`
  font: normal normal normal 16px/19px Roboto;
  text-align: center;
  margin-bottom: 20px;
`;

const FakeSelect = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: ${colors.form_inp_bg};
  color: ${colors.white};
  padding: 0 9px 0 20px;
  cursor: pointer;


  font: normal normal bold 18px/21px Roboto;

  
  &.disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
  }

  &.fake-input {
    border-radius: 5px;
    ${mixins.flexItXY('flex-start', 'center')};
    cursor: auto;

    svg {
      margin-right: 6px;
    }
  }

  &.mobile {
    border-radius: 5px;
  }

  ${mixins.flexItXY('space-between', 'center')};

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
    white-space: nowrap;
    font-weight: 500;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  left: 0;
  bottom: -20px;
  font: normal normal normal 12px/14px Roboto;
  opacity: 0.5;
  color: ${colors.red};
`;

const ClipBoardContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  background: ${colors.gray_extra_light};
  border-radius: 5px;
  word-break: break-all;
  &.mobile {
    height: 75px;
  }

  &.copy-destination-address {
    padding: 0 17px;
  }

  svg {
    cursor: pointer;
  }
`;

const DestinationContainer = styled.div`
  color: ${colors.white};
  font-size: 16px;
  line-height: 19px;
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
`;

const RangeContainer = styled(RangeControl)`
`;

const Separator = styled.span`
  width: 100;
  display: inline-block;
  position: relative;
  margin-bottom: 30px;

  &:before {
    content: '';
    width: calc(100% + 60px);
    height: 1px;
    background: ${colors.gray_extra_light};
    position: absolute;
    bottom: 0;
    left: -30px;
  }
`;

const TransactionWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 27, 67, 0.9);
  z-index: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 50px;
`;

const TransactionContainer = styled.div`
max-width: 535px;
width: 100%;
  background: #00132f;
  border-radius: 25px;
  margin: 0px auto;
  margin-top: ${({ theme }) => theme.isBannerShown ? '150px' : '100px'};

  & > div:first-of-type {
    height: 65px;
    border-bottom: 1px solid ${colors.gray_extra_light};
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      font-size: 25px;
      padding: 0 10px;
      font-weight: bold;
      margin: unset;
      text-align: center;
    }
  }

  & > div:last-of-type {
    & > svg {
      display: block;
      margin: 30px auto;
    }

    ul {
      list-style: none;
      padding: unset;

      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }

    a {
      display: block;
      text-align: center;
      color: ${colors.gray_extra_light4};
    }

      rect{
        stroke: ${colors.gray_extra_light4};
      }
      line{
        stroke: ${colors.gray_extra_light4};
    }
  }

  #add-token-button{
    min-height:40px;
    margin-bottom:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:  ${colors.main_bg};
  }

  .fee-value{
    font-size: 14px;
  }

  &.mobile {
    width: 90%;
    margin-top: 50px;
  }

  @media (max-height: 570px) {
    margin-top: 350px;
    border-radius: 0px;

  }
`;

const TransactionInfo = styled.div`
  display: flex;
  padding:30px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray_extra_light};

  &.mobile {
    padding-bottom: 10px;
    & > span {
      &:not(:nth-of-type(2)) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateY(-28px);

        & > p {
          margin-right: unset;
        }
      }

      &:nth-of-type(2) {
        @media screen and (max-width: 340px) {
          &:before {
            width: 30px;
            left: -40px;
          }

          &:after {
            width: 30px;
            right: -40px;
          }
        }
      }

      &:first-of-type {
        svg {
          width: 25px;
          height: 40px;
        }
      }

      &:last-of-type {
        svg {
          width: 40px;
          height: 40px;
          margin-left: 5px;
        }
      }

      &:nth-of-type(2) {
        img {
          width: 50px;
          height: 50px;
        }

        svg {
          width: 25px;
          height: 25px;
          right: -15px;
        }
      }
    }
  }

  &.portout {
    padding: unset;
    border-bottom: unset;
    margin-bottom: 50px;
  }

  & > span {
    display: flex;

    img{
      height:85px;
    }



    p {
      margin-right: 20px;
      margin-top: 18px;
      color: rgba(255, 255, 255, 0.5);
    }
  
   
    .network-label span {
      display: none;
    }
   
    .network-label img {
       height: ${({ theme }) => theme.isMobile ? '41px' : '57px'};

    }


    // svg {
    //   height: 50px;
    // }
    
    &:nth-of-type(2) {
      position: relative;
      &:before {
        content: '';
        width: 40px;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        display: inline-block;
        left: -65px;
        top: 50%;
      }
      &:after {
        content: '';
        width: 40px;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        display: inline-block;
        right: -65px;
        top: 50%;
      }

      & > svg {
        width: 40px;
        height: 40px;
        position: absolute;
        right: -20px;
        top: -10px;
      }
    }
  }

  span:last-child{
    margin-left:20px;
    margin-right:15px;
  }
`;

const TransactionStat = styled.div`
  padding: 30px 30px 0px 30px;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  justify-content: center;
  border-bottom: 1px solid ${colors.gray_extra_light};
  font-size: 18px;

  ${({ theme }) => theme.isMobile && css`
  grid-template-columns: 1fr 1fr;
  `}
 
  & > div {
    padding-left: 5px;
    margin-bottom:23px;
   
    &:nth-child(-n+3) {
     font-weight:700;
    }


    &:nth-child(6) {
      font-weight: ${(props) => (props.baseNetworkName === 'CARDANO' ? '' : '700')} 
    }
    & > div {
      &:first-of-type {
        font-size:14px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        margin-bottom:12px;
        font-weight:400;
      }

    
    }

   
    a{
      color: ${colors.white} !important;
      text-align: left !important;
    }

  }

  ${({ theme }) => theme.isMobile && css`

  `}

`;

const CheckTx = styled.a`
  transform: translateY(-15px);
  text-decoration: underline;
  color: ${colors.aqua_bg}; 
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;

  -webkit-transition: all .5s ease;
  transition: all .5s ease;

  svg g, svg path {
    stroke: ${colors.aqua_bg}
  }

  &:not(:disabled):hover {
    color: ${colors.white};
    -webkit-transition: all .35s ease;
    transition: all .35s ease;

    svg g, svg path {
      stroke: ${colors.white}
    }

    path {
      stroke: ${colors.white}
    }
  }

  & > svg {
    margin-left: 5px;
 g{
  opacity: 1;
 }
    path {
      stroke: ${colors.aqua_bg}
    
    }
  }
`;

const ActionsContainer = styled.div`
  padding: 30px;
  box-sizing: border-box;

  &.mobile {
    padding-top: unset;

    &.success {
      padding: 30px;
    }
  }
`;

const ScanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  line-height: 28px;

  & > a {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }

  &.mobile {
    flex-direction: column;

    & > a:first-of-type {
      margin-bottom: 20px;
    }
  }
`;

const MintingContainer = styled.div`
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: unset;

  &.mobile {
    & > p {
      margin-bottom: 15px;
    }
  }
`;

const CheckStatus = styled.a`
  display: flex;
  align-items: center;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  font-size: 14px;
 

  &:not(:disabled):hover {
    color: ${colors.white};
    -webkit-transition: all .35s ease;
    transition: all .35s ease;

    svg g, svg path {
      opacity:1;
      stroke: ${colors.white}
    }
  }

  svg {
    margin-left: 7px;
  }
`;

const ConfirmingTokenText = styled.span`
margin-left:11px;
line-height:1.5;

@media only screen and (max-width: 500px) {

  font-size: 13px;


}
@media only screen and (max-width: 376px) {

  font-size: 11px;


}
`;

const CopyText = styled.div`
margin-left:25px;
margin-right:25px;
text-align:center;
line-height:30px;
margin-bottom:20px;
color: ${({ theme }) => theme.gray_light};
font-size:12px;

@media (max-width: 800px){
  margin-top: 0px;
}


`;

const CopySpan = styled.span`

  text-decoration: underline;
  margin-right:4px;
  color: ${({ theme }) => theme.gray_light};

  &:hover {
    color: ${colors.aqua_bg};
    cursor: pointer;
   
  }

`;

const ArrowWrapper = styled.span`

  &:hover {
    opacity: 0.5;
    transition: all 420ms cubic-bezier(0.165, 0.84, 0.44, 1);

  }
`;

const CFBcontainer = styled.div``;

const CFBmsg = styled.div``;

const TokenIcon = styled.div.attrs((props) => ({
  style: {
    backgroundImage: `url(${props.background})`,
  },
}))`
margin-left:-10px;
margin-right:14px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${colors.navy_dark};
  background-size: 28px;
  background-position: center;
  background-repeat: no-repeat;

`;

const StyledSpinner = styled(Spinner)`
  position: relative;

  border: 5px solid ${colors.aqua_bg};


  &:before {
    background: ${colors.main_bg};
  }
`;

const ConfirmationTextWrapper = styled.div`
padding-left:15px;
`;

const FeesBox = styled.div`
  display: flex;
  width: 100%;
  padding:10px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid  ${({ theme }) => theme.gray_extra_light} ;

  ${({ theme }) => theme.isMobile && css`
    padding: 10px 35px;
    flex-direction: column;
    align-items: center;
  `}

  &:nth-child(2n) {
    margin-right:40px;
    font-size: 14px;

  }
  .fee-field {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    ${({ theme }) => !theme.isMobile && css`
      &:not(:last-of-type) {
        padding-right: 20px;
      }
    `}

    .fee-label {
      font-size: 14px;
      line-height: 22px;
      margin-right:13px;
      color: ${({ theme }) => theme.gray_light};
      white-space: nowrap;
      margin-bottom:13px;
    }
  }
`;

const TokenSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;

  //change width of modal token input
  & > div {
   width:220px;
  }


  ${({ theme }) => theme.isMobile && css`
    flex-direction: column;
    & > div {
      width:100%;
     }
  `};
`;

const TokenImageAndNameWrapper = styled.div`
  display: flex;
  align-items: center;
  `;

const S = {
  CopyText,
  CopySpan,
  CheckStatus,
  MintingContainer,
  ScanContainer,
  ExchangeViewContainer,
  ActionsContainer,
  TransactionStat,
  TransactionInfo,
  CheckTx,
  TransactionWrapper,
  TransactionContainer,
  Separator,
  ErrorInfo,
  RangeContainer,
  DestinationContainer,
  ClipBoardContainer,
  ErrorMessage,
  FakeSelect,
  WithdrawSwitchMessage,
  WithdrawBodyBottom,
  WithdrawTokenPreview,
  WithdrawBodyTop,
  Body,
  Info,
  Value,
  Wrapper,
  Header,
  Grid,
  SwapAmount,
  SelectToken,
  Action,
  BtnContent,
  MaxAmountBtn,
  DestinationWallet,
  Actions,
  Numbers,
  Number,
  Line,
  Preview,
  FieldLabel,
  TokenIconWrapper,
  RightArrowWrapper,
  WithdrawPreview,
  ConfirmingTokenText,
  ArrowWrapper,
  TokenIcon,
  StyledSpinner,
  CFBcontainer,
  CFBmsg,
  BtnContentWrapper,
  ConfirmationTextWrapper,
  TokenSelectContainer,
  FeesBox,
  TokenImageAndNameWrapper
};

export default S;
