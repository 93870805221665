import { createSelector } from 'reselect';
import TokenSelectors from './tokenSelectors';

const selectedToken = (state) => state.bridge.selected_token;
const selectedTokenOnTargetNetwork = (state) => state.bridge.selected_token_on_target_network;
const networkId = (state) => state.bridge.networkId;
const swapAmount = (state) => state.bridge.swapAmount;
const destinationNetwork = (state) => state.bridge.destinationNetwork;
const allowanceForToken = (state) => state.bridge.allowanceForToken;
const destinationWallet = (state) => state.bridge.destinationWallet;
const pendingRedeem = (state) => state.bridge.pendingRedeem;
const processingPort = (state) => state.bridge.processingPort;
const baseNetwork = (state) => state.bridge.baseNetwork;
const gasRangePrice = (state) => state.bridge.gasRangePrice;
const isUserInputBalanceMax = (state) => state.bridge.isUserInputBalanceMax;

const isMaxAmoutExceed = createSelector(TokenSelectors.balances, selectedToken, swapAmount, (balances, selectedToken, swapAmountVar) => balances[selectedToken?.web3_address] < swapAmountVar,);

const isToNativeSelector = createSelector(
  processingPort,
  TokenSelectors.originNetworkId,
  (processingPortVar, originNetworkId) => processingPortVar?.target_network_id === originNetworkId,
);

const isTokenHasLiquidity = createSelector(
  TokenSelectors.liquidityTokens,
  selectedToken,
  (liquidityTokens, selectedTokenValue) => {
    if (!liquidityTokens) {
      return false;
    }

    return liquidityTokens.includes(selectedTokenValue?.web3_address);
  },
);

export default {
  selectedToken,
  networkId,
  swapAmount,
  destinationNetwork,
  allowanceForToken,
  destinationWallet,
  pendingRedeem,
  baseNetwork,
  processingPort,
  gasRangePrice,
  selectedTokenOnTargetNetwork,
  isUserInputBalanceMax,
  isToNative: isToNativeSelector,
  isTokenHasLiquidity,
  isMaxAmoutExceed
};
