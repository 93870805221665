import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompleteModalTemplate from './components/CompleteModalTemplate';
import { ReactComponent as SuccessStakingSVG } from '../../../assets/stakingComplete.svg';

/*                       ACTIONS                           */
import { closeModal } from '../../../redux/actions/modalActions';
import { stakeTokensFinish, setStakingLoading } from '../../../redux/actions/stakeActions';
import { createNetworkLabel } from '../../../constants/general';
import { useFetchPortxBalances } from '../../../hooks/usePortxBalances';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import { numFormatter } from '../../../services/utilsService';

const CompleteStakeModal = () => {
  const dispatch = useDispatch();
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  useFetchPortxBalances();

  const handleCloseModalClick = useCallback(async () => {
    dispatch(stakeTokensFinish());
    dispatch(setStakingLoading({ stake: false, completeStake: false }));
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <CompleteModalTemplate
      title="Staking Complete!"
      logo={<SuccessStakingSVG />}
      subTitle1="Amount"
      subTitle1Value1={numFormatter(swapAmount)}
      subTitle2="Stake on"
      subTitle1Value2={createNetworkLabel(baseNetwork)}
      BTNtxt="Close"
      BTNonClick={handleCloseModalClick}
    />
  );
};

export default CompleteStakeModal;
