import styled from 'styled-components/macro';
import colors from '../../styles/colors';
import mixins from '../../styles/mixins';
// import arrow from '../../assets/bsc.svg';

const Wrapper = styled.section`
  border-radius: 20px;
  background: ${colors.dark_bg};
  margin: 10px auto 25px auto;
  max-width: ${(props) => props.width || '535'}px;
  width: 90%;


  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-bottom:none;
    border-top:none;
  }


  .carousel{
    padding:7px 40px 10px 35px;
    font-size:14px;
    color: rgba(255, 255, 255, 0.5);
    font: normal normal 400 16px/19px Roboto;
 
    .get-to-know-text{
      line-height:25px;
    }


    .prev-arrow-container{
      transform: rotate(90deg);
      svg { 
        fill:${colors.white} !important; 
    }
    }

    .next-arrow-container{
      transform: rotate(-90deg);
      svg { 
        fill: ${colors.white} !important; 
    }
    }
   
  
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    background:none;
    opacity:1;
    margin-top: -15px;

    &:hover{
      opacity:0.5;

    }

  }

`;

const CloseIconWrapper = styled.div`
display:flex;
align-items:flex-end;
margin: 0 20px 0 20px ;
.close-icon {
  margin-bottom:8px;
  transition: all 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

}

`;
const Header = styled.header`
  width: 100%;
  color: ${colors.white};
  ${mixins.flexItXY('center', 'center')};
  font-size: 19px;
  display: flex;
  flex-direction: column;
  padding: 23px 0 0 0;

  p {
    font-size: 16px;
  }


  
`;

const S = { Wrapper, Header, CloseIconWrapper };

export default S;
