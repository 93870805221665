import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompleteModalTemplate from './components/CompleteModalTemplate';
import S from './styled';
import { numFormatter } from '../../../services/utilsService';
import { ReactComponent as SuccessUnStakingSVG } from '../../../assets/unstakingComplete.svg';

/*                       ACTIONS                           */
import { stakeTokensFinish, setUnstakingLoading } from '../../../redux/actions/stakeActions';
import { createNetworkLabel } from '../../../constants/general';
import usePortxBalances from '../../../hooks/usePortxBalances';
import { closeModal, openClaimModal } from '../../../redux/actions/modalActions';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';

const CompleteUnStakeModal = () => {
  const dispatch = useDispatch();
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const sdkData = useSelector(stakeSelectors.sdkData);
  const isAvaibClaim = sdkData?.totalPendingAndBalance > 0.001;

  const [{ data: portxBalance }] = usePortxBalances();

  const handleCloseModalClick = useCallback(async () => {
    if (!isAvaibClaim) {
      dispatch(closeModal());
      return;
    }
    dispatch(stakeTokensFinish());
    dispatch(setUnstakingLoading({ unstaking: false, unstakingComplete: false }));
    dispatch(openClaimModal());
  }, [dispatch, isAvaibClaim]);

  return (
    <CompleteModalTemplate
      title="Unstake Complete!"
      logo={<SuccessUnStakingSVG />}
      subTitle1="Unstake"
      subTitle1Value1={numFormatter(swapAmount)}
      subTitle2="Chain"
      subTitle1Value2={createNetworkLabel(baseNetwork)}
      extraRow={<S.PortxBalanceWrapper><S.ItemTitle>Remaining staked amount</S.ItemTitle><S.MiniValue>{`${numFormatter(portxBalance?.portxStakedConverted)} PORTX`}</S.MiniValue></S.PortxBalanceWrapper>}
      BTNtxt={isAvaibClaim ? `Claim ${numFormatter(sdkData?.totalPendingAndBalance, 4)} PORTX rewards` : 'Close'}
      BTNonClick={handleCloseModalClick}
    />
  );
};

export default CompleteUnStakeModal;
