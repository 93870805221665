import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import ExchangePortInView from '../components/ExchangePortInView';
import BridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import WalletSelectors from '../../../redux/selectors/walletSelectors';
import GeneralSelectors from '../../../redux/selectors/generalSelectors';
import TokensSelectors from '../../../redux/selectors/tokenSelectors';
import transactionSelectors from '../../../redux/selectors/transactionSelectors';
import BridgeService from '../../../services/bridge';

// actions
import {
  getGasPrices,
  setGasPrice,
  setWithdrawLoading,
  updateProgressBar,
  updateProgressBarPercentage,
} from '../../../redux/actions/generalActions';
import {
  setBaseNetwork,
  waitForRedeemStatus,
} from '../../../redux/actions/bridgeActions';
import {
  setRedeemTx,
  setTXHash,
  updateBlockConfirmations,
  getReceiptMined,
  calculatePortxFee,
  setIsRedeemOnGoing
} from '../../../redux/actions/transactionActions';

// actions type
import { GET_USER_PENDING_PORT_SUCCESS, GET_PENDING_REDEEM_SUCCESS } from '../../../redux/actionTypes/bridgeActionTypes';
import { HANDLE_PORT_OUT_TOKENS_FINISHED } from '../../../redux/actionTypes/transactionActionTypes';

import ToastBlock, { toastOptions } from '../../../components/Toasts/ToastBlock';
import { isObjectEmpty } from '../../../services/utilsService';
import useTransactionFee from '../../../hooks/useTransactionFee';
import { fetchTokens } from '../../../redux/actions/tokenActions';

const ContainerExchangePortInView = (props) => {
  const {
    walletAddress,
    setWithdrawLoading,
    isMobile,
    pendingRedeem,
    tokens,
    // updateRedeem,
    networksByName,
    setBaseNetwork,
    gasPrices,
    getGasPrices,
    selectedToken,
    setGasPrice,
    settledGasPrice,
    networksByChain,
    tokensById,
    networkId,
    destinationChainId,
    networks,
    networksById,
    txHash,
    withdrawLoading,
    isWalletConnected,
    redeemTx,
    updateBlockConfirmations,
    baseNetwork,
    processingPort,
    nativeCoinRates,
    interval_id,
    currentTxBlock,
    currentTxNonce,
    fetchTokens,
    getReceiptMined,
    setTXHash,
    setRedeemTx, // -> refactor: REMOVE THIS
    calculatePortxFee,
    waitForRedeemStatus,
    setIsRedeemOnGoing,
    updateProgressBar,
    progressBarStep
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [gasRangePrice, setGasRangePrice] = useState(0);
  const [gas, setGas] = useState(21000);

  const ethTransactionFee = useTransactionFee(gas, gasRangePrice);

  useEffect(() => {
    if (!tokens.length) {
      fetchTokens();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pendingRedeem) {
      history.push('/');
    } else {
      setIsRedeemOnGoing(true);
    }
  }, [history, pendingRedeem, setIsRedeemOnGoing]);

  const redeem = useMemo(() => {
    if (!pendingRedeem || !tokens) {
      return null;
    }
    const token = tokens.find(
      (item) => item.id === pendingRedeem?.target_token_id
    );
    if (!token) {
      return null;
    }
    return {
      ...pendingRedeem,
      amountWei: pendingRedeem.amount,
      amount: BridgeService.fromWei(pendingRedeem.amount, token?.decimals),
      target_token_symbol: token.symbol,
      target_token_name: token.name,
      target_token_image: token.token_image,
      target_token_web3_address: token.web3_address,
    };
  }, [pendingRedeem, tokens]);

  const contractBalanceDeficitMessage = useMemo(() => {
    if (tokensById && !isObjectEmpty(tokensById) && redeem?.target_token_id) {
      return `Your attempted port of ${tokensById[redeem?.target_token_id]?.symbol} from ${redeem?.base_network.trim() === 'BSC' ? 'BNB' : redeem?.base_network} to ${redeem?.target_network.trim() === 'BSC' ? 'BNB' : redeem?.target_network} has been flagged for further checks to ensure security and safety of the bridge. Please check back in a few hours or open a support ticket to track the approval progress.`;
    }
    return '';
  }, [tokensById, redeem?.target_token_id, redeem?.base_network, redeem?.target_network]);

  const connectMMToNetwork = useCallback(async () => {
    const targetNetworkId = redeem?.target_network_id;
    localStorage.setItem('switchTo', networksById[targetNetworkId].chain_id);
    setBaseNetwork(networksById[redeem?.target_network_id]);
  }, [redeem, networksById, setBaseNetwork]);

  const handlePortOutTokens = useCallback(
    async (gasPrice) => {
      setWithdrawLoading(true);
      const tokensOnBridgeContract = await BridgeService.getTokenBalance(
        redeem.target_token_web3_address,
        networksById[redeem.target_network_id].bridges.ChainportMainBridge,
        networksById[redeem.target_network_id].chain_id
      );
      // getting the tx hash from the local storage that takes from redux - bugfix for taking the value directly from redux (redux value update too late)
      let tx = txHash;
      if (parseFloat(tokensOnBridgeContract) < redeem.amountWei) {
        toast(
          <ToastBlock content={contractBalanceDeficitMessage} />,
          toastOptions
        );
      } else {
        try {
          if (!tx) {
            // after a refresh it might have not correct values

            try {
              tx = await BridgeService.releaseTokens(
                redeem.signature,
                redeem.target_token_web3_address,
                redeem.amountWei,
                redeem.nonce,
                { from: walletAddress, gasPrice: (gasPrice * 10 ** 9).toFixed() },
                redeem.target_network,
              );
            } catch (e) {
              console.warn(e);
              setWithdrawLoading(false);
              return;
            }
            setTXHash(tx);
            setRedeemTx(redeemTx);
          }
          if (baseNetwork?.blockchain_type !== 'cardano') {
            const receipt = await getReceiptMined(interval_id, currentTxBlock, currentTxNonce, walletAddress, tx);
            if (!receipt) {
              return;
            }
            if (receipt) {
              tx = receipt.transactionHash;
            }
          }
          const redeemObj = await waitForRedeemStatus(walletAddress);
          // const updatedRedeem = {
          //   ...redeem,
          //   ...await updateRedeem({
          //     redeem_id: redeem.id,
          //     target_tx_hash: tx,
          //   }),
          // };

          // updatedRedeem.target_tx_status =
          //     updatedRedeem.target_tx_status || receipt.status;
          const token = tokensById[redeemObj?.target_token_id];
          redeemObj.amount = BridgeService.fromWei(
            redeemObj.amountWei || redeemObj.amount,
            token?.decimals
          );
          const targetNetwork =
            redeemObj.target_network || destinationChainId;
          redeemObj.tx_hash_link =
            redeemObj.target_tx_hash &&
            `${networksByName[targetNetwork]?.explorer_url}tx/${redeemObj.target_tx_hash}`;
          if (token) {
            redeemObj.token = token;
            redeemObj.link = `${networksByName[targetNetwork]?.explorer_url}/token/${token.web3_address}?a=${walletAddress}`;
          }
          if (redeemObj.target_tx_hash) {
            redeemObj.target_gasPrice = await BridgeService
              .getTransactionGasPrice(redeemObj.target_tx_hash, networksByName[redeemObj.target_network].chainId);
          }
          if (redeemObj.base_tx_hash && redeemObj.base_network_id !== networksByName.CARDANO.id) {
            redeemObj.base_gasPrice = await BridgeService
              .getTransactionGasPrice(redeemObj.base_tx_hash, networksByName[redeemObj.base_network].chainId);
          }
          // when the processingPort get popup it will trigger the "check it out page"
          dispatch({
            type: GET_USER_PENDING_PORT_SUCCESS,
            payload: redeemObj,
          });
          // clear this so it wont save the pending redeem at the end;
          dispatch({
            type: GET_PENDING_REDEEM_SUCCESS, // bridge
            payload: null,
          });
          dispatch({ type: HANDLE_PORT_OUT_TOKENS_FINISHED });
          localStorage.removeItem('targetNetwork');
          history.push('/');
        } catch (e) {
          toast.error(e.message);
        } finally {
          updateBlockConfirmations(0);
        }
      }
    },
    [setWithdrawLoading, redeem, networksById, txHash, contractBalanceDeficitMessage, getReceiptMined, interval_id, currentTxBlock, currentTxNonce, walletAddress, waitForRedeemStatus, tokensById, destinationChainId, networksByName, dispatch, history, setTXHash, setRedeemTx, redeemTx, updateBlockConfirmations, baseNetwork?.blockchain_type]
  );

  useEffect(() => {
    getGasPrices();
  }, [getGasPrices]);

  useEffect(() => {
    setGasPrice(gasRangePrice);
  }, [setGasPrice, gasRangePrice]);

  const resumePortIn = useCallback(() => {
    if (withdrawLoading && pendingRedeem && txHash && walletAddress) {
      handlePortOutTokens(settledGasPrice);
    }
  }, [handlePortOutTokens, pendingRedeem, settledGasPrice, txHash, walletAddress, withdrawLoading]);

  // resume the release part of the tx (from bridge to user)
  useEffect(() => {
    if (isWalletConnected) {
      resumePortIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWalletConnected]);

  useEffect(() => {
    if (baseNetwork?.id !== networksByName?.CARDANO?.id) {
      const setGasP = async () => {
        if (
          redeem &&
        walletAddress &&
        redeem?.target_network_id === networksByName.ETHEREUM.id &&
        !txHash
        ) {
          let gasLimit = 21000;

          const txMeta = {
            from: walletAddress,
            estimateGas: true,
          };
          gasLimit = await BridgeService.releaseTokens(
            redeem.signature,
            redeem.target_token_web3_address,
            redeem.amountWei,
            redeem.nonce,
            txMeta,
            redeem.target_network,
          );
          setGas(gasLimit);
        }
      };
      setGasP();
    }

    const progressBarNum = redeem?.base_network_id === networksByName.CARDANO.id ? 3 : 2;

    if (progressBarStep < progressBarNum) {
      updateProgressBar(progressBarNum);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networksByName, walletAddress, redeem?.target_network_id, redeem]);

  return (
    redeem && (
      <ExchangePortInView
        walletAddress={walletAddress}
        isMobile={isMobile}
        handlePortOutTokens={() => handlePortOutTokens(settledGasPrice)}
        redeem={redeem}
        gasPrices={gasPrices}
        setGasRangePrice={setGasRangePrice}
        ethTransactionFee={ethTransactionFee}
        selectedToken={selectedToken}
        gasRangePrice={gasRangePrice}
        txHash={txHash}
        txHashStatus={`${networksById[redeem?.target_network_id]?.explorer_url}tx/${txHash}`}
        // TODO check if we can remove this
        network={networkId}
        networksByChain={networksByChain}
        setWithdrawLoading={setWithdrawLoading}
        networks={networks}
        connectMMToNetwork={connectMMToNetwork}
        networksById={networksById}
        processingPort={processingPort}
        nativeCoinRates={nativeCoinRates}
        networksByName={networksByName}
        calculatePortxFee={calculatePortxFee}
        baseNetwork={baseNetwork}
        {...props}
      />
    )
  );
};

ContainerExchangePortInView.propTypes = {
  walletAddress: PropTypes.string,
  setWithdrawLoading: PropTypes.func,
  // updateRedeem: PropTypes.func,
  setBaseNetwork: PropTypes.func,
  isMobile: PropTypes.bool,
  pendingRedeem: PropTypes.object,
  networksByName: PropTypes.object,
  networksById: PropTypes.object,
  tokensById: PropTypes.object,
  tokens: PropTypes.array,
  getGasPrices: PropTypes.func,
  gasPrices: PropTypes.object,
  setGasPrice: PropTypes.func,
  selectedToken: PropTypes.object,
  networksByChain: PropTypes.object,
  settledGasPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  networkId: PropTypes.number,
  destinationChainId: PropTypes.number,
  networks: PropTypes.array,
  txHash: PropTypes.string,
  withdrawLoading: PropTypes.bool,
  isWalletConnected: PropTypes.bool,
  redeemTx: PropTypes.string,
  updateBlockConfirmations: PropTypes.func,
  baseNetwork: PropTypes.object,
  destinationNetwork: PropTypes.object,
  processingPort: PropTypes.object,
  isRedeemOnGoing: PropTypes.bool,
  nativeCoinRates: PropTypes.object,
  interval_id: PropTypes.number,
  currentTxBlock: PropTypes.number,
  currentTxNonce: PropTypes.number,
  fetchTokens: PropTypes.func,
  getReceiptMined: PropTypes.func,
  setTXHash: PropTypes.func,
  setRedeemTx: PropTypes.func,
  calculatePortxFee: PropTypes.func,
  waitForRedeemStatus: PropTypes.func,
  progressBarStep: PropTypes.number,
  updateProgressBar: PropTypes.func,
  setIsRedeemOnGoing: PropTypes.func,

};

const mapStateToProps = (state) => ({
  networkId: GeneralSelectors.networkId(state),
  walletAddress: WalletSelectors.walletAddress(state),
  withdrawLoading: GeneralSelectors.withdrawLoading(state),
  isMobile: GeneralSelectors.isMobile(state),
  pendingRedeem: BridgeSelectors.pendingRedeem(state),
  tokens: TokensSelectors.tokens(state),
  tokensById: TokensSelectors.tokensById(state),
  networksByName: GeneralSelectors.networksByName(state),
  networksByChain: GeneralSelectors.networksByChain(state),
  networksById: GeneralSelectors.networksById(state),
  gasPrices: GeneralSelectors.gasPrices(state),
  destinationChainId: BridgeSelectors.destinationNetwork(state)?.chainId,
  selectedToken: BridgeSelectors.selectedToken(state),
  settledGasPrice: GeneralSelectors.settledGasPrice(state),
  walletBalance: WalletSelectors.walletBalance(state),
  isWalletConnected: WalletSelectors.isWalletConnected(state),
  networks: GeneralSelectors.networks(state),
  txHash: transactionSelectors.txHash(state),
  redeemTx: transactionSelectors.redeemTx(state),
  baseNetwork: BridgeSelectors.baseNetwork(state),
  destinationNetwork: BridgeSelectors.destinationNetwork(state),
  processingPort: BridgeSelectors.processingPort(state),
  isRedeemOnGoing: transactionSelectors.isRedeemOnGoing(state),
  nativeCoinRates: GeneralSelectors.nativeCoinRates(state),
  interval_id: transactionSelectors.intervalId(state),
  currentTxBlock: transactionSelectors.currentTxBlock(state),
  currentTxNonce: transactionSelectors.currentTxNonce(state),
  progressBarStep: GeneralSelectors.progressBarStep(state)

});

const mapDispatchToProps = {
  setWithdrawLoading,
  setBaseNetwork,
  getGasPrices,
  setGasPrice,
  setIsRedeemOnGoing,
  updateBlockConfirmations,
  fetchTokens,
  getReceiptMined,
  setTXHash,
  setRedeemTx,
  calculatePortxFee,
  waitForRedeemStatus,
  updateProgressBar,
  updateProgressBarPercentage,

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerExchangePortInView);
