import styled from 'styled-components/macro';

const ErrorInfo = styled.div`
  grid-area: error-info;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: #F3BA2F;



  &.strict {
    color: #ff6767;
    svg {
      fill: #ff6767;
      width:40px;
    }
  }

  svg {
    margin-right: 6px;
    fill: #F3BA2F;
    width:40px;
  }

  p {
    font-size: 14px;
    line-height: 19px;
    text-align: left;
  }

  &.mobile {
    padding-left: 15px;
    padding-right: 15px;
  }


  
`;

const S = {
  ErrorInfo,
};

export default S;
