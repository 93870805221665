import {
  IS_REDUCE_FEES_PORTX,
} from '../actionTypes/myActivityActionTypes';

const INITIAL_STATE = {
  isReducedFeesPortx: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
  case IS_REDUCE_FEES_PORTX:
    return {
      ...state,
      isReducedFeesPortx: action.payload,
    };
  default:
    return state;
  }
};
