import {
  TOKEN_MODAL,
  CONFIRM_DEPOSIT_MODAL,
  CONFIRM_WITHDRAW_MODAL,
  CONFIRM_TX_MODAL,
  UNSTAKE_MODAL,
  STAKE_MODAL,
  CLAIM_MODAL,
  COMPLETE_STAKE_MODAL,
  COMPLETE_UNSTAKE_MODAL,
  COMPLETE_CLAIM_MODAL,
} from './modalTypes';
import TokenModal from './TokenModal/containers';
import ConfirmDepositModal from './ConfirmationModals/ConfirmDepositModal';
import ConfirmWithdrawModal from './ConfirmationModals/ConfirmWithdrawModal';
import ConfirmTxModal from './ConfirmTxModal/ConfirmTxModal';
import UnstakeModal from './UnstakeModal/UnstakeModal';
import StakeModal from './StakeModal/StakeModal';
import ClaimModal from './ClaimModal/ClaimModal';
import CompleteClaimModal from './CompleteModals/CompleteClaimModal';
import CompleteStakeModal from './CompleteModals/CompleteStakeModal';
import CompleteUnStakeModal from './CompleteModals/CompleteUnStakeModal';

export default {
  [TOKEN_MODAL]: TokenModal,
  [CONFIRM_DEPOSIT_MODAL]: ConfirmDepositModal,
  [CONFIRM_WITHDRAW_MODAL]: ConfirmWithdrawModal,
  [CONFIRM_TX_MODAL]: ConfirmTxModal,
  [UNSTAKE_MODAL]: UnstakeModal,
  [STAKE_MODAL]: StakeModal,
  [CLAIM_MODAL]: ClaimModal,
  [COMPLETE_STAKE_MODAL]: CompleteStakeModal,
  [COMPLETE_UNSTAKE_MODAL]: CompleteUnStakeModal,
  [COMPLETE_CLAIM_MODAL]: CompleteClaimModal,
};
