import styled from 'styled-components/macro';

const Wrapper = styled.div`
  svg{
    width: 15px;
    height: 15px;
  }
`;

const S = {
  Wrapper
};

export default S;
