/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import ModalBody from '../ModalBody';
import Button from '../../Button';
import S from './styled';
import { shortenAddress, numFormatter } from '../../../services/utilsService';
import translate from '../../../services/translate/translate';

/*                       ACTIONS                           */
import { closeModal } from '../../../redux/actions/modalActions';
import { createNetworkLabel } from '../../../constants/general';
import useButtonOption from './useButtonOption';
import { ReactComponent as YellowWarningSVG } from '../../../assets/yellowWarning.svg';
import { ReactComponent as StakingSVG } from '../../../assets/staking.svg';
import { ReactComponent as AwaySVG } from '../../../assets/away-icon.svg';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import walletSelectors from '../../../redux/selectors/walletSelectors';
import transactionSelectors from '../../../redux/selectors/transactionSelectors';
import generalSelectors from '../../../redux/selectors/generalSelectors';

const StakeModal = () => {
  const dispatch = useDispatch();

  const walletAddress = useSelector(walletSelectors.walletAddress);
  const selectedToken = useSelector(bridgeSelectors.selectedToken);
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const stakingLoading = useSelector(stakeSelectors.stakingLoading);
  const txHash = useSelector(transactionSelectors.txHash);
  const networksByName = useSelector(generalSelectors.networksByName);

  const polyNet = networksByName.POLYGON;
  const buttonOption = useButtonOption();

  const onAddressClick = useCallback((e, isToken) => {
    e.stopPropagation();
    const url = isToken
      ? `${baseNetwork?.explorer_url}token/${selectedToken?.web3_address}`
      : `${baseNetwork?.explorer_url}address/${walletAddress}`;
    window.open(url, '_blank',
      'noopener, noreferrer');
  }, [baseNetwork?.explorer_url, selectedToken?.web3_address, walletAddress]);

  return (
    <S.Wrapper isCloseEnable={!stakingLoading?.staking}>
      <ModalBody showHeader={true} closeModal={() => { dispatch(closeModal()); }} title={<><StakingSVG />Confirm Staking</>}>
        <S.RowWrapper>
          <S.ItemTitle>Amount</S.ItemTitle><S.Value>{`${numFormatter(swapAmount)} PORTX`}</S.Value>
        </S.RowWrapper>
        <S.RowWrapper>
          <S.ItemTitle>Token address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, true)}>{shortenAddress(selectedToken?.web3_address || 'ab')}</S.LinkTo></S.Value>
        </S.RowWrapper>
        <S.borderDividerWrapper>
          <S.BorderDivider />
        </S.borderDividerWrapper>
        <S.RowWrapper>
          <S.ItemTitle>Stake on</S.ItemTitle><S.Value>{createNetworkLabel(baseNetwork)}</S.Value>
        </S.RowWrapper>
        <S.RowWrapper>
          <S.ItemTitle>Wallet address</S.ItemTitle><S.Value><S.LinkTo onClick={(e) => onAddressClick(e, false)}>{shortenAddress(walletAddress)}</S.LinkTo></S.Value>
        </S.RowWrapper>
        {buttonOption.state === 'approve' || buttonOption.state === 'approveLoading' ?
          <S.YellowWarningWrapper>
            <S.SVGwarningWrapper>
              <YellowWarningSVG />
            </S.SVGwarningWrapper>
            <S.YellowWarning>In the first interaction with PORTX, per chain, one more signature must be made to allow the wallet interact with the token.</S.YellowWarning>
          </S.YellowWarningWrapper>
          :
          buttonOption.state === 'staking' ?
            <S.YellowWarningWrapper>
              <S.SVGwarningWrapper>
                <YellowWarningSVG />
              </S.SVGwarningWrapper>
              <S.YellowWarning>Notice - After staking, fees for porting are paid with PORTX. This will be changeable in the next version of ChainPort.</S.YellowWarning>
            </S.YellowWarningWrapper>
            :
            ''
        }
        <S.ButtonWrapper>
          <Button
            className={buttonOption.className}
            onClick={buttonOption.action}
            // disabled={}
          > {buttonOption.text}
          </Button>
        </S.ButtonWrapper>
        {txHash && (
          <S.CheckTx href={`${polyNet?.explorer_url}tx/${txHash}`} target="_blank" rel="noopener noreferrer">
            {translate('modal.check_tx')}
            <AwaySVG />
          </S.CheckTx>
        )}
      </ModalBody>
    </S.Wrapper>
  );
};

export default StakeModal;
