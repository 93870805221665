import Axios from 'axios';
import {
  GET_PENDING_REDEEM_FAIL,
  GET_PENDING_REDEEM_REQUEST,
  GET_PENDING_REDEEM_SUCCESS,
  GET_USER_PENDING_PORT_FAILED,
  GET_USER_PENDING_PORT_REQUEST,
  GET_USER_PENDING_PORT_SUCCESS,
  REFRESH_SELECTED_TOKEN_META,
  RESET_SWAP_AMOUNT,
  SET_ALLOWANCE_FOR_TOKEN,
  SET_BASE_NETWORK,
  SET_DESTINATION_NETWORK,
  SET_GAS_RANGE_PRICE,
  SET_SELECTED_TOKEN,
  SET_SELECTED_TOKEN_ON_TARGET_NETWORK,
  SET_SWAP_AMOUNT,
  UPDATE_REDEEM_FAIL,
  UPDATE_REDEEM_REQUEST,
  UPDATE_REDEEM_SUCCESS,
  SET_IS_BALANCE_MAX,
} from '../actionTypes/bridgeActionTypes';
import { fetchTokens, checkTokenIsNative, setBalances } from './tokenActions';
import BridgeService from '../../services/bridge';
import { normalLogin, softClear } from './walletActions';
import config from '../../config/config';
import { removePortTransaction, setRedeemTx, clearTransactionState, setPortTimeoutId, resetTXHash, refreshAllowance, clearCalculatePortxFee } from './transactionActions';
import WalletSelectors from '../selectors/walletSelectors';
import BridgeSelectors from '../selectors/bridgeSelectors';
import { setAckLoading, setWithdrawLoading, getGasPrices, updateProgressBar, updateProgressBarPercentage } from './generalActions';
import { isObjectEmpty, wait } from '../../services/utilsService';
import { GET_TOKEN_TYPE_SUCCESS } from '../actionTypes/tokenActionTypes';

const { baseUrl: baseURL } = config;
const axios = Axios.create({ baseURL });

export const setSelectedToken = (token) => async (dispatch, getState) => {
  const state = getState();
  const {
    general: { networksByName }, wallet: { account },
  } = state;
  const tokenNetworkId = networksByName[token.network_name]?.chainport_network_id;
  dispatch({
    type: SET_SELECTED_TOKEN,
    payload: token,
  });
  let isNativeToken;
  if (!isObjectEmpty(token)) {
    isNativeToken = await dispatch(checkTokenIsNative(tokenNetworkId, token.web3_address));
  }
  dispatch({
    type: GET_TOKEN_TYPE_SUCCESS,
    payload: { isNativeToken },
  });
  if (token?.web3_address && account && token.network_name !== networksByName.CARDANO.name) {
    dispatch(refreshAllowance(account, token));
    dispatch(setBalances([token], account));
  }
};

export const setSelectedTokenOnTargetNetwork = (token) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_TOKEN_ON_TARGET_NETWORK,
    payload: token,
  });
};

export const setSwapAmount = (amount) => (dispatch) => {
  dispatch({
    type: SET_SWAP_AMOUNT,
    payload: amount,
  });
};

export const setIsUserInputBalanceMax = (bool) => (dispatch) => {
  dispatch({
    type: SET_IS_BALANCE_MAX,
    payload: bool,
  });
};

export const setBaseNetwork = (payload, isFirstLoad = false) => (dispatch, getState) => {
  const state = getState();
  const baseNetwork = BridgeSelectors.baseNetwork(state);
  const accountType = WalletSelectors.accountType(state);
  const { networksByName } = state.general;
  dispatch(getGasPrices());
  if (baseNetwork?.id !== payload?.id) {
    const rawNetwork = state.general.networks.find((net) => net?.id === payload?.id);
    dispatch({
      type: SET_BASE_NETWORK,
      payload: rawNetwork,
    });
    // clear account when we switch from Cardano to EVM
    if (accountType !== 'inPageProvider' && baseNetwork?.id === networksByName.CARDANO.id) {
      dispatch(softClear());
    }
    if (!isFirstLoad) {
      dispatch(normalLogin(null, payload.id));
    }
  }
};

export const setDestinationNetwork = (payload) => (dispatch, getState) => {
  const rawNetwork = getState().general.networks.find((net) => net.id === payload?.id);

  dispatch({
    type: SET_DESTINATION_NETWORK,
    payload: rawNetwork,
  });
};

export const setAllowanceForToken = (allowanceMeta) => (dispatch) => {
  dispatch({
    type: SET_ALLOWANCE_FOR_TOKEN,
    payload: allowanceMeta,
  });
};

export const refreshSelectedTokenMeta =
  (token, walletAddress) => (dispatch) => {
    BridgeService.getTokenBalance(
      token.web3_address,
      walletAddress,
      token.chain_id
    )
      .then(BridgeService.fromWei)
      .then((bal) => ({ ...token, balance: Number(bal) }))
      .then(() => {
        dispatch({
          type: REFRESH_SELECTED_TOKEN_META,
          payload: {},
        });
      });
  };

export const resetSwapAmount = () => (dispatch) => {
  dispatch({
    type: RESET_SWAP_AMOUNT,
  });
};

const getOriginTokenDecimal = (networkId, tokenAddress) => async () => {
  try {
    const resp = await axios('/token/get', { params: { token_web3_address: tokenAddress, network_id: networkId,
    } });
    const { base_token } = resp.data;
    return base_token.decimals;
  } catch (err) {
    console.warn(err);
    throw err;
  }
};

const mintPortWithToken = (processingPort) => (dispatch, getState) =>
  new Promise(async (resolve) => {
    if (processingPort) {
      const state = getState();
      const { networksByName } = state.general;
      const port = { ...processingPort };
      let tokens = getState().token.tokensById;
      if (!tokens || !tokens[port?.target_token_id]) {
        await dispatch(fetchTokens());
      }
      tokens = getState().token.tokensById;
      const token = tokens[port?.target_token_id];
      let decimalToPass = token?.decimals;
      if (token && port.target_network_id === networksByName.CARDANO.id) {
        const tokenOriginDecimal = await dispatch(getOriginTokenDecimal(port.target_network_id, token?.web3_address));
        decimalToPass = tokenOriginDecimal;
      }
      port.amount = BridgeService.fromWei(port.amount, decimalToPass);

      const targetNetwork =
        port.target_network || state.bridge.destinationNetwork.chainId;
      port.tx_hash_link =
        port.target_tx_hash &&
        `${networksByName[targetNetwork]?.explorer_url}tx/${port.target_tx_hash}`;
      if (token) {
        port.token = token;
        port.link = `${networksByName[targetNetwork]?.explorer_url}token/${token.web3_address
        }?a=${getState().wallet.account}`;
      }
      if (port.target_network_id !== networksByName.CARDANO.id) {
        if (port.target_tx_hash) {
          port.target_gasPrice = await BridgeService.getTransactionGasPrice(port.target_tx_hash, networksByName[port.target_network].chainId);
        }
      }
      if (port.base_network_id !== networksByName.CARDANO.id) {
        if (port.base_tx_hash) {
          port.base_gasPrice = await BridgeService.getTransactionGasPrice(port.base_tx_hash, networksByName[port.base_network].chainId);
        }
      }

      resolve(port);
    } else {
      resolve(processingPort);
    }
  });

export const updateRedeem = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_REDEEM_REQUEST });
  try {
    const response = await axios.put('/redeem', data);
    const {
      data: { redeem },
    } = response;
    if (data && redeem?.id) {
      redeem.target_tx_hash = data?.target_tx_hash;
    }
    // const mintedRedeem = await dispatch(mintPortWithToken(redeem));
    dispatch({ type: UPDATE_REDEEM_SUCCESS, payload: redeem });
    return redeem;
  } catch (error) {
    dispatch({ type: UPDATE_REDEEM_FAIL, payload: error });
  }
};

export const fetchPendingRedeem = (walletAddress) => async (dispatch, getState) => {
  dispatch({ type: GET_PENDING_REDEEM_REQUEST });
  const { redeemTx, processingLoader, tx_hash, isRedeemOnGoing } = getState().transactions;
  // const { destinationNetwork, baseNetwork, processingPort } = getState().bridge;
  const { progressBarStep, progressBarPercentage, loading, networksByName } = getState().general;
  const { withdraw } = loading;
  // TODO: create a better logic for progressbar
  // if (!processingPort && baseNetwork.id === networksByName.CARDANO.id && isRedeemOnGoing) {
  //   dispatch(normalLogin('inPageProvider', destinationNetwork));
  // }
  try {
    const {

      data: { redeem },
    } = await axios('/redeem/pending', {
      params: { user_web3_address: walletAddress },
    });
    const progressBarNum = redeem?.base_network_id === networksByName.CARDANO.id ? 3 : 2;
    if (redeem && !redeem.target_tx_hash && tx_hash) {
      redeem.target_tx_hash = tx_hash;
    }
    if (redeem?.id) {
      const { account } = getState().wallet;

      dispatch(removePortTransaction('burnTokens', account));
      if (progressBarStep < progressBarNum) {
        // TODO change hardcoded values when adding Cardano
        dispatch(updateProgressBar((progressBarNum - 1) + 1));
      }

      const { networksById } = getState().general;
      if (networksById[redeem.target_network_id]) {
        // const switchNetworkId = redeem.target_network_id;
        const walletData = JSON.parse(localStorage.getItem('walletData'));
        // walletData.baseNetworkId = switchNetworkId;
        localStorage.setItem('targetNetwork', redeem.base_network_id);
        localStorage.setItem('localNetwork', networksById[redeem.target_network_id]);
        localStorage.setItem('walletData', JSON.stringify(walletData));
      }
    }
    if (tx_hash && redeemTx) {
      // TODO remove await + move logic from container (fetchPendingRedeem)
      await dispatch(setRedeemTx(null));
    }
    if (
      redeem?.id &&
      !redeem?.target_tx_hash &&
      redeemTx
    ) {
      dispatch(
        updateRedeem({
          redeem_id: redeem.id,
          target_tx_hash: redeemTx,
        })
      );
      redeem.target_tx_hash = redeemTx;
      localStorage.removeItem('redeem');
      localStorage.removeItem('targetNetwork');
    }
    if (redeem?.target_tx_status === 1) {
      const port = await dispatch(mintPortWithToken(redeem));
      dispatch({
        type: GET_USER_PENDING_PORT_SUCCESS,
        payload: port,
      });
      dispatch({
        type: GET_PENDING_REDEEM_SUCCESS,
        payload: null,
      });
    } else {
      if (redeem) {
        dispatch({
          type: GET_PENDING_REDEEM_SUCCESS,
          payload: redeem,
        });
        if (progressBarStep === progressBarNum && isRedeemOnGoing && withdraw && tx_hash) {
          dispatch(updateProgressBarPercentage(progressBarPercentage + 2));
        }
      }
      if (!processingLoader) {
        dispatch({
          type: GET_USER_PENDING_PORT_SUCCESS,
          payload: null,
        });
      }
    }
    return redeem;
  } catch (error) {
    console.log(error, 'error');
    dispatch({ type: GET_PENDING_REDEEM_FAIL, payload: error });
  }
};

export const fetchPendingPort = (web3Address) => async (dispatch, getState) => {
  const state = getState();
  const { destinationNetwork } = state.bridge;
  const { recipientWalletAddress } = state.wallet;
  const { progressBarStep, progressBarPercentage, networksByName } = state.general;
  dispatch({ type: GET_USER_PENDING_PORT_REQUEST }); // clear pending port
  // const { processingLoader } = getState().transactions;
  const addressToPass = (recipientWalletAddress && destinationNetwork.blockchainType === 'cardano') ? recipientWalletAddress : web3Address;
  try {
    const { data } = await axios('/port/pending', {
      params: { user_web3_address: addressToPass },
    });

    const port =
      data.ports_to_ack[0] &&
      (await dispatch(mintPortWithToken(data.ports_to_ack[0])));

    // if (!processingLoader) {
    dispatch({
      type: GET_USER_PENDING_PORT_SUCCESS,
      payload: port,
    });
    // }
    const progressBarNum = port?.base_network_id === networksByName.CARDANO.id ? 3 : 2;
    if (port && progressBarStep < progressBarNum) {
      dispatch(updateProgressBar(progressBarStep + 1));
    }

    if (port && progressBarStep === progressBarNum) {
      dispatch(updateProgressBarPercentage(progressBarPercentage + 3.5));
    }

    return port;
  } catch (err) {
    console.warn(err);
    dispatch({ type: GET_USER_PENDING_PORT_FAILED, payload: err });
  }
};

export const acknowledgePort = (data) => async (dispatch, getState) => {
  try {
    const body = data.signature
      ? { port_out_ack: true, redeem_id: data.id }
      : { port_out_ack: true, port_id: data.id };
    const url = data.signature ? '/redeem' : '/port';
    await axios.put(url, body);
  } catch (err) {
    console.warn(err);
  } finally {
    const web3Address = getState().wallet.account;
    if (data.signature) {
      await dispatch(fetchPendingRedeem(web3Address));
    } else {
      await dispatch(fetchPendingPort(web3Address));
    }
    await wait(1_500);
    dispatch(setAckLoading(false));
    localStorage.removeItem('expiryPersist');
    localStorage.removeItem('persist:root');
    dispatch(resetTXHash());
    dispatch(updateProgressBar(0));
    dispatch(setWithdrawLoading(false));
    dispatch(clearTransactionState());
    dispatch(clearCalculatePortxFee());
    // dispatch(softClear());
  }
};

export const waitForProcessingPort = (web3Address) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    let timeoutId;
    let port;
    const state = getState();
    const { portTimeoutId } = state.transactions;
    port = await dispatch(
      fetchPendingPort(web3Address)
    );

    let timeoutForPortId;
    let timeoutForTxStatus;
    if (!portTimeoutId) {
      // clear all timeouts after 30 min
      timeoutId = setTimeout(() => {
        if (timeoutForPortId) {
          clearTimeout(timeoutForPortId);
          timeoutForPortId = null;
        }
        if (timeoutForTxStatus) {
          clearTimeout(timeoutForTxStatus);
          timeoutForTxStatus = null;
        }

        reject(new Error('Port timeout!'));
        dispatch(clearTransactionState());
      }, 30 * 60 * 1000);
      dispatch(setPortTimeoutId(timeoutId));
    }

    const checkForPortId = async () => {
      port = await dispatch(
        fetchPendingPort(web3Address)
      );

      if (port?.id) {
        clearTimeout(timeoutForPortId);
        timeoutForPortId = null;
      } else {
        timeoutForPortId = setTimeout(checkForPortId, 1000);
      }
    };

    const checkForTxStatus = async () => {
      port = await dispatch(
        fetchPendingPort(web3Address)
      );
      if (port?.target_tx_status) {
        clearTimeout(timeoutForTxStatus);
        timeoutForTxStatus = null;
        clearTimeout(timeoutId);
        timeoutId = null;
        resolve(port);
        return port;
      }
      timeoutForTxStatus = setTimeout(checkForTxStatus, 5 * 1000);
    };

    timeoutForPortId = setTimeout(checkForPortId, 1000);

    if (port?.target_tx_status) {
      clearTimeout(timeoutId);
      timeoutId = null;
      resolve(port);
      return port;
    }

    timeoutForTxStatus = setTimeout(checkForTxStatus, 5 * 1000);
  });

export const waitForRedeemSignature = (web3Address) => (dispatch) => new Promise(async (resolve, reject) => {
  let timeout;
  let redeemTimeout;
  let redeem = await dispatch(fetchPendingRedeem(web3Address));

  if (redeem?.signature) {
    resolve(redeem);
    return redeem;
  }

  timeout = setTimeout(() => {
    if (redeemTimeout) {
      clearTimeout(redeemTimeout);
      redeemTimeout = null;
    }
    reject(new Error('Redeem timeout!'));
  }, 30 * 60 * 1000);

  const redeemTimeoutHandler = async () => {
    redeem = await dispatch(fetchPendingRedeem(web3Address));
    if (redeem?.signature) {
      clearTimeout(timeout);
      timeout = null;
      clearTimeout(redeemTimeout);
      redeemTimeout = null;
      resolve(redeem);
      return redeem;
    }
    redeemTimeout = setTimeout(redeemTimeoutHandler, 2 * 1000);
  };

  redeemTimeout = setTimeout(redeemTimeoutHandler, 2 * 1000);
});

export const waitForRedeemStatus = (web3Address) => (dispatch) => new Promise(async (resolve, reject) => {
  let timeout;
  let redeemTimeout;
  let redeem = await dispatch(fetchPendingRedeem(web3Address));

  if (redeem?.target_tx_status) {
    resolve(redeem);
    return redeem;
  }

  timeout = setTimeout(() => {
    if (redeemTimeout) {
      clearTimeout(redeemTimeout);
      redeemTimeout = null;
    }
    reject(new Error('Redeem timeout!'));
  }, 30 * 60 * 1000);

  const redeemTimeoutHandler = async () => {
    redeem = await dispatch(fetchPendingRedeem(web3Address));
    if (redeem?.target_tx_status) {
      clearTimeout(timeout);
      timeout = null;
      clearTimeout(redeemTimeout);
      redeemTimeout = null;
      resolve(redeem);
      return redeem;
    }
    redeemTimeout = setTimeout(redeemTimeoutHandler, 2 * 1000);
  };

  redeemTimeout = setTimeout(redeemTimeoutHandler, 2 * 1000);
});

export const setGasRangePrice = (gasRangePrice) => (dispatch) => {
  dispatch({
    type: SET_GAS_RANGE_PRICE,
    payload: gasRangePrice
  });
};
