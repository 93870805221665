import styled from 'styled-components/macro';

const LanguageSwitchContainer = styled.div`
display: flex;
color: ${({ theme }) => theme.gray_light};
justify-content: center;
margin-bottom: 20px;
font-size: 12px`;

const LangSpan = styled.span`
  text-decoration: underline;
  margin-left:4px;
  margin-right:8px;

  color: ${({ theme }) => theme.aqua_bg};
  opacity: 0.5;


  &:hover {
    cursor: pointer;
    opacity: 1;
  }

`;

const CloseIconWrapper = styled.div`
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;

.close-icon{
  border-radius: 50%;
border: 0.5px solid ${(props) => props.theme.gray_light};
width:11px;
height:11px;
display:flex;
justify-content:center;
align-items:center;

}

&:hover {
  .close-icon{
  border: 0.5px solid ${(props) => props.theme.white};
  path{
    stroke:${(props) => props.theme.white};
  }
  }
}
`;
const S = {
  LanguageSwitchContainer,
  LangSpan,
  CloseIconWrapper
};
export default S;
