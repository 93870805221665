export default {
  main_bg: '#001B43',
  lighter_bg: '#1D3253',
  form_inp_bg: '#1a2b44',
  white: '#fff',
  black: '#000000',
  gray_light: 'rgba(255, 255, 255, 0.5)',
  gray_extra_light: 'rgba(255, 255, 255, 0.1)',
  gray_extra_light2: 'rgba(255, 255, 255, 0.15)',
  gray_extra_light3: '#FBFBFB',
  gray_extra_light4: 'rgba(255, 255, 255, 0.7)',
  gray_extra_light5: 'rgba(38, 54, 78, 0.15)',
  gray_extra_light6: 'rgba(255, 255, 255, 0.05)',
  gray_extra_light7: 'rgba(255,255,255, 0.3)',
  dark_gray: '#737373',
  aqua_bg: '#2FF6D3',
  light_aqua_bg: '#66e7dc',
  dark_text: '#1c2220',
  light_grey: '#ccc',
  dark_bg: '#00132F',
  navy_medium: '#26364E',
  navy_dark: '#192A43',
  red: '#ff0000',
  red02: 'FF6767',
  yellow: '#fedf84',
  purple: '#8247E5',
  dark_grey2: '#192A43',
  light_grey2: '#F3F3F3',
  wallet_modal_bg: 'rgb(0, 19, 47)',
  blue: '#154793',
  blue01: '#1780AE',
  green: '#008000',
  green01: 'rgba(35,195,167, 0.25)',
  green02: 'rgba(35,195,167, 0.4)',
  green03: 'rgba(35,195,167, 0.55)',
  green04: 'rgba(35,195,167, 0.7)',
  green05: 'rgba(35,195,167, 0.85)',
  green06: '#23C3A7',
  dark_green: '#029080',
  dark_green1: '#137D7B',
  green07: '#04D300',
  grey01: '#808997',
  transparent: 'transparent',
  yellow02: '#F4C144',
  yellow03: '#F3BA2F',
};
