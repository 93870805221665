import React from 'react';
import _noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import Spinner from '../../Spinner';

/*                       ACTIONS                           */
import { addAllowance } from '../../../redux/actions/transactionActions';
import { claimTokens, setClaimingLoading } from '../../../redux/actions/stakeActions';
import { setApproveLoading } from '../../../redux/actions/generalActions';
import transactionSelectors from '../../../redux/selectors/transactionSelectors';
import TreeDotsLoader from '../../ThreeDotsLoader/TreeDotsLoader';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';
import generalSelectors from '../../../redux/selectors/generalSelectors';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import walletSelectors from '../../../redux/selectors/walletSelectors';
import { numFormatter } from '../../../services/utilsService';
import useTransactionFee from '../../../hooks/useTransactionFee';

const BUTTON_OPTIONS = {
  Loading: 'Loading',
  Approve: 'Approve',
  ApproveConfirmWallet: 'ApproveConfirmWallet',
  ApproveLoading: 'ApproveLoading',
  Claiming: 'Claiming',
  ClaimingConfirmWallet: 'ClaimingConfirmWallet',
  ClaimingLoading: 'ClaimingLoading',
  NotEnoughGas: 'NotEnoughGas',
};

const useButtonOption = () => {
  const ethTransactionFee = useTransactionFee(21000, 35);
  const dispatch = useDispatch();
  const walletAddress = useSelector(walletSelectors.walletAddress);
  const selectedToken = useSelector(bridgeSelectors.selectedToken);
  const tokenAllowance = useSelector(bridgeSelectors.allowanceForToken);
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const approveLoading = useSelector(generalSelectors.approveLoading);
  const claimingLoading = useSelector(stakeSelectors.claimingLoading);
  const txHash = useSelector(transactionSelectors.txHash);
  const sdkData = useSelector(stakeSelectors.sdkData);

  const buttonOptions = {
    [BUTTON_OPTIONS.Loading]: {
      text: <><TreeDotsLoader /></>,
      action: () => {
      },
      state: 'approve',
      className: 'primary w-100 action',
    },
    [BUTTON_OPTIONS.Approve]: {
      text: 'Approve PORTX interaction with your wallet',
      action: () => {
        dispatch(setApproveLoading(true));
        // 0 for the gas range price (relevant to ETH only)
        dispatch(addAllowance(walletAddress, 0, selectedToken, swapAmount, true));
      },
      state: 'approve',
      className: 'primary w-100 action',
    },
    [BUTTON_OPTIONS.ApproveConfirmWallet]: {
      text: <><Spinner />Confirm Tx in your wallet</>,
      action: _noop,
      state: 'approveConfirmWallet',
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.ApproveLoading]: {
      text: <><Spinner />Approving PORTX</>,
      action: _noop,
      state: 'approveLoading',
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.Claiming]: {
      text: `Claim ${numFormatter(sdkData?.totalPendingAndBalance)} PORTX`,
      action: () => {
        dispatch(setClaimingLoading({ claiming: true, claimingComplete: false }));
        dispatch(claimTokens(Number(sdkData?.totalPendingAndBalance, 10)));
      },
      state: 'Claiming',
      className: 'primary w-100 action',
    },
    [BUTTON_OPTIONS.ClaimingConfirmWallet]: {
      text: <><Spinner />Confirm claiming Tx in your wallet</>,
      action: _noop,
      state: 'ClaimingConfirmWallet',
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.ClaimingLoading]: {
      text: <><Spinner />Claiming rewards in progress</>,
      action: _noop,
      state: 'ClaimingLoading',
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.NotEnoughGas]: {
      text: `Unstake ${numFormatter(sdkData?.totalPendingAndBalance)} PORTX`,
      action: _noop,
      state: 'NotEnoughGas',
      disabled: true,
      className: 'primary w-100 action opacity5',
    },
  };

  const getButtonOption = () => {
    if (sdkData?.totalPendingAndBalance === '0') {
      return BUTTON_OPTIONS.Loading;
    }
    if (approveLoading && !txHash) {
      return BUTTON_OPTIONS.ApproveConfirmWallet;
    }
    if (approveLoading && txHash) {
      return BUTTON_OPTIONS.ApproveLoading;
    }
    if (claimingLoading?.claiming && !txHash) {
      return BUTTON_OPTIONS.ClaimingConfirmWallet;
    }
    if (claimingLoading?.claiming && txHash) {
      return BUTTON_OPTIONS.ClaimingLoading;
    }
    if (tokenAllowance?.allowance < swapAmount) {
      return BUTTON_OPTIONS.Approve;
    }
    if (!ethTransactionFee?.isEnoughBalance && !txHash) {
      return BUTTON_OPTIONS.NotEnoughGas;
    }

    return BUTTON_OPTIONS.Claiming;
  };
  return buttonOptions[getButtonOption()];
};

export default useButtonOption;
