import React, { useMemo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { langObserver } from '@dcentralab/unified-frontend';
import { SUPPORTED_LANGUAGES } from '../../services/translate/translateService';
import S from './styled';
import CloseIcon from '../Icons/CloseIcon';
import colors from '../../styles/colors';
import { showModalBasedOnLocalStorage } from '../../services/utilsService';
import translate from '../../services/translate/translate';

// 14 days
const EXPIRATION_DURATION = 1000 * 60 * 60 * 24 * 14;

const LanguageSwitch = () => {
  const [closeLanguageSwitch, setCloseLanguageSwitch] = useState(false);
  const intl = useIntl();

  const handleLanguageSwitchClose = () => {
    setCloseLanguageSwitch(true);
  };

  const selectedLang = (lang) => {
    const langObj = SUPPORTED_LANGUAGES.find((el) => el.value.substring(0, 2) === lang.substring(0, 2));
    return langObj;
  };

  const langToSwitchObj = useMemo(() => {
    const appLang = intl.locale;
    const langLSStr = appLang.substring(0, 2);
    const browserPreferanceLang = navigator?.languages[0] || window.navigator.language
    || navigator.language || navigator.userLanguage;
    const browserPreferanceLangStr = browserPreferanceLang.substring(0, 2);
    const defaultEngLang = selectedLang('en');
    const langToSwitch = langLSStr !== browserPreferanceLangStr ? selectedLang(browserPreferanceLangStr) : defaultEngLang;
    if (langToSwitch?.value) {
      return langToSwitch;
    }
    return defaultEngLang;
  }, [intl.locale]);

  const handleSwitchLang = useCallback(() => {
    langObserver.emit('SET_LOCALE', langToSwitchObj?.value);
    localStorage.setItem('dufLocale', langToSwitchObj?.value);
  }, [langToSwitchObj?.value]);

  const showSwitchLang = showModalBasedOnLocalStorage('languageSwitch', EXPIRATION_DURATION, closeLanguageSwitch);
  return (
    <>
      {showSwitchLang && !closeLanguageSwitch && (langToSwitchObj?.value !== intl.locale) &&
        <S.LanguageSwitchContainer>
          {translate('common.chainport_available')}
          <S.LangSpan onClick={handleSwitchLang}>
            { langToSwitchObj?.label }
          </S.LangSpan>
          <S.CloseIconWrapper onClick={handleLanguageSwitchClose}><CloseIcon color={colors.gray_light} size={4.3} strokeWidth={6} /></S.CloseIconWrapper>
        </S.LanguageSwitchContainer> }
    </>);
};

export default LanguageSwitch;
