import React from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import useSetNetworksOnLoad from '../../hooks/useSetNetworksOnLoad';

const ChainportRoute = ({ component: Comp, ...rest }) => {
  useSetNetworksOnLoad();

  return (
    <Route
      {...rest}
      render={(props) => <Comp {...props} />}
    />
  );
};

export default ChainportRoute;

ChainportRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  // path: PropTypes.string,
  history: PropTypes.object,

};
