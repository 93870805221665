import React from 'react';
import PropTypes from 'prop-types';
import S from './styled';

export const Mailto = ({ email, subject, body, childrenBTN, childrenText }) => (
  <S.ContainerDiv>
    <S.MailToRef target="_top" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{childrenBTN}</S.MailToRef>
    <S.MailToText>&nbsp;{childrenText}</S.MailToText>
  </S.ContainerDiv>
);

Mailto.propTypes = {
  email: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.node,
  childrenBTN: PropTypes.node,
  childrenText: PropTypes.node,
};
