import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  getMobileOperatingSystem,
} from '../../services/walletService';
import S from './styled';

const isDeviceIOS = getMobileOperatingSystem() === 'iOS';

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { children: null, show: null };
  }

  componentDidMount() {
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.props.closeModal();
      }
    });
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.modalOpen && this.state.children !== null) {
      setTimeout(() => {
        this.setState({ show: null });
      }, 300);
      return setTimeout(() => {
        this.setState({ children: null });
      }, 500);
    }

    if (newProps.modalOpen) {
      this.setState({ show: true });
    }

    return this.setState({ children: newProps.children });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.props.closeModal);
  }

  render() {
    return (
      <S.Backdrop
        className={`modal-backdrop ${this.state.show ? 'open' : ''} ${
          this.props.className
        }`}
        role="button"
        tabIndex={0}
        onClick={() => {
          // this.props.closeModal();
        }}
      >
        <S.ScrollContainer isDeviceIOS={isDeviceIOS} className="modal-scroll-container">
          <S.ModalWrapper
            style={{ maxWidth: this.props.width }}
            isDeviceIOS={isDeviceIOS}
          >
            <S.ModalContent
              className={clsx('modal', { mobile: this.props.isMobile })}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {this.state.children !== null && this.state.children}
            </S.ModalContent>
          </S.ModalWrapper>
        </S.ScrollContainer>
      </S.Backdrop>
    );
  }
}

Modal.defaultProps = {
  width: 'auto',
  className: '',
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default Modal;
