import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import TokenModal from '../components';
import { closeModal } from '../../../../redux/actions/modalActions';
import { setSelectedToken, setSelectedTokenOnTargetNetwork } from '../../../../redux/actions/bridgeActions';
import {
  addToken,
  fetchTokens,
  setBalances,
} from '../../../../redux/actions/tokenActions';
import { trim } from '../../../../services/utilsService';
import BridgeService from '../../../../services/bridge';
import WalletSelectors from '../../../../redux/selectors/walletSelectors';
import TokenSelectors from '../../../../redux/selectors/tokenSelectors';
import GeneralSelectors from '../../../../redux/selectors/generalSelectors';
import BridgeSelectors from '../../../../redux/selectors/bridgeSelectors';
import { BLACKLISTED_TOKEN, NATIVE_CARDANO_ASSET } from '../../../Toasts/toastMessages';
import ToastBlock, { toastOptions } from '../../../Toasts/ToastBlock';
import translate from '../../../../services/translate/translate';

const ContainerTokenModal = (props) => {
  const [searchString, setSearchString] = useState('');

  const handleSearchString = ({ currentTarget = {} }) => (
    setSearchString(currentTarget.value));

  const handleCreateToken = useCallback(async () => {
    try {
      let tokenMeta;
      if (props.baseNetwork.blockchainType !== 'cardano') {
        tokenMeta = await BridgeService.getTokenMetaFromAddress(
          trim(searchString).toLowerCase(), props.baseNetwork?.name
        );

        const tokenBalanceWei = await BridgeService.getTokenBalance(
          trim(searchString).toLowerCase(),
          props.walletAddress,
          tokenMeta.chain_id
        );

        const tokenBalance = await BridgeService.fromWei(tokenBalanceWei, parseInt(tokenMeta?.decimals, 10));
        if (props.blackListedTokens.includes(tokenMeta.web3_address)) {
          toast(<ToastBlock content={BLACKLISTED_TOKEN} />, toastOptions);
        } else {
          props.addToken(tokenMeta);

          props.setSelectedToken({
            ...tokenMeta,
            balance: Number(tokenBalance),
          });
        }
      } else {
        // here we check the token is valid or not
        toast(<ToastBlock content={NATIVE_CARDANO_ASSET} />, toastOptions);
      }
      props.setBalances([tokenMeta], props.walletAddress);
      props.closeModal();
    } catch (e) {
      toast.error(translate('common.token_do_not_exist'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchString,
    props.walletAddress,
    props.addToken,
    props.setSelectedToken,
    props.networksByName,
    props.walletAddress,
    props.setSelectedTokenOnTargetNetwork,
    props.selectedToken,
  ]);

  useEffect(() => {
    props.fetchTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TokenModal
      searchString={searchString}
      handleSearch={handleSearchString}
      tokens={props.tokens}
      balances={props.balances}
      handleCreateToken={handleCreateToken}
      isMobile={props.isMobile}
      setSelectedTokenOnTargetNetwork={props.setSelectedTokenOnTargetNetwork}
      selectedToken={props.selectedToken}
      blackListedTokens={props.blackListedTokens}
      isTokenNative={props.isTokenNative}
      baseNetwork={props.baseNetwork}
      networksByName={props.networksByName}
      {...props}
    />
  );
};

ContainerTokenModal.propTypes = {
  walletAddress: PropTypes.string,
  isWalletConnected: PropTypes.bool,
  setSelectedToken: PropTypes.func,
  setSelectedTokenOnTargetNetwork: PropTypes.func,
  closeModal: PropTypes.func,
  tokens: PropTypes.array,
  balances: PropTypes.object,
  networksByName: PropTypes.object,
  addToken: PropTypes.func,
  fetchTokens: PropTypes.func,
  setBalances: PropTypes.func,
  modalType: PropTypes.string,
  isMobile: PropTypes.bool,
  selectedToken: PropTypes.object,
  blackListedTokens: PropTypes.array,
  isTokenNative: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  baseNetwork: PropTypes.object,
};

const mapStateToProps = (state) => ({
  walletAddress: WalletSelectors.walletAddress(state),
  isWalletConnected: WalletSelectors.isWalletConnected(state),
  networksByName: GeneralSelectors.networksByName(state),
  networksByChain: GeneralSelectors.networksByChain(state),
  tokens: TokenSelectors.tokens(state),
  balances: TokenSelectors.balances(state),
  blackListedTokens: TokenSelectors.blackListedTokens(state),
  liquidityTokens: TokenSelectors.liquidityTokens(state),
  selectedToken: BridgeSelectors.selectedToken(state),
  modalType: state.modal.modalType,
  isMobile: state.general.isMobile,
  isTokenNative: TokenSelectors.isTokenNative(state),
  baseNetwork: state.bridge.baseNetwork,
});

const mapDispatchToProps = {
  closeModal,
  setSelectedToken,
  addToken,
  fetchTokens,
  setBalances,
  setSelectedTokenOnTargetNetwork,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerTokenModal);
