import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translate from '../../services/translate/translate';
import GeneralSelectors from '../../redux/selectors/generalSelectors';
import S from './styled';
import { numberWithCommas } from '../../services/utilsService';

// TODO check if it is possible to remove the duplicate code for mobile and desktop view

const Footer = ({ tvl, isMobile }) => {
  if (isMobile) {
    return (
      <S.FooterContainer className="mobile">
        <div className="total-value-locked">$ {numberWithCommas(tvl, 18, true)} {translate('common.locked')}  </div>

        <div className="footer-links">
          <a href="https://docs.chainport.io" target="_blank" rel="noopener noreferrer"> {translate('common.docs')} </a>
          <a href="https://chainport.io" target="_blank" rel="noopener noreferrer"> {translate('common.about')}</a>
          <a href="https://t.me/chainport" target="_blank" rel="noopener noreferrer">
            Telegram
          </a>
          <a href="https://twitter.com/chain_port" target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
          <a href="https://medium.com/chainport" target="_blank" rel="noopener noreferrer">
            Medium
          </a>
          <a
            href="https://github.com/chainport/smart-contracts/tree/master/audits"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('footer.audit')}
          </a>

          <a href="https://status.chainport.io/" target="_blank" rel="noopener noreferrer">{translate('common.app_status')} </a>
          <a href="https://chainport.io/terms-of-services" target="_blank" rel="noopener noreferrer"> {translate('common.terms')}</a>
          <a href="https://chainport.io/privacy-policy" target="_blank" rel="noopener noreferrer"> {translate('common.privacy')}</a>
        </div>
        <p>ChainPort.io / All rights reserved { new Date().getFullYear() }</p>
      </S.FooterContainer>
    );
  }

  return (
    <S.FooterContainer>
      <div className="footer-left">
        <p>{ translate('common.all_right_reserved', { year: new Date().getFullYear() })}</p>
        <a href="https://status.chainport.io/" target="_blank" rel="noopener noreferrer">{translate('common.app_status')}</a>
        <a href="https://chainport.io/terms-of-services" target="_blank" rel="noopener noreferrer">{translate('common.terms')}</a>
        <a href="https://chainport.io/privacy-policy" target="_blank" rel="noopener noreferrer">{translate('common.privacy')}</a>
      </div>
      <p />
      <div className="total-value-locked">$ {numberWithCommas(tvl, 18, true)} {translate('common.locked')}</div>

      <div className="footer-links">
        <a href="https://docs.chainport.io" target="_blank" rel="noopener noreferrer">{translate('common.docs')} </a>
        <a href="https://chainport.io" target="_blank" rel="noopener noreferrer">{translate('common.about')}</a>
        <a href="https://t.me/chainport" target="_blank" rel="noopener noreferrer">
          Telegram
        </a>
        <a href="https://twitter.com/chain_port" target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        <a href="https://medium.com/chainport" target="_blank" rel="noopener noreferrer">
          Medium
        </a>
        <a
          // href="https://github.com/chainport/smart-contracts/tree/master/audits"
          href="https://www.certik.com/projects/chainport"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate('footer.audit')}
        </a>
      </div>

    </S.FooterContainer>);
};

Footer.propTypes = {
  isMobile: PropTypes.bool,
  // productVersion: PropTypes.string,
  tvl: PropTypes.string,
};

Footer.defaultProps = {
  isMobile: false,
  tvl: '',
};

const mapStateToProps = (state) => ({
  isMobile: GeneralSelectors.isMobile(state),
  // productVersion: GeneralSelectors.productVersion(state),
  tvl: GeneralSelectors.tvl(state),
});

export default connect(mapStateToProps)(Footer);
