import Dec from 'decimal.js';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { isUndefined } from 'lodash';
import axiosRetry from 'axios-retry';
import MetamaskIcon from '../components/Icons/ProviderIcons/MetaMaskIcon';
import FortmaticIcon from '../components/Icons/ProviderIcons/FortmaticIcon';
import WalletConnectIcon from '../components/Icons/ProviderIcons/WalletConnectIcon';
import TrustIcon from '../components/Icons/ProviderIcons/TrustIcon';
import generalInitialState from '../redux/reducers/generalInitialState';
import { nameOfNetwork } from './walletService';
import config from '../config/config';
import { MILLION, BILLION, TRILLION, QUADRILLION, QUINTILLION, EVM_WALLET_ADDRESS_CHAR_NUM } from '../constants/general';
import translate from './translate/translate';

const axios = Axios.create({ baseURL: config.baseUrl });

export const isEmptyBytes = (string) =>
  string === '0x0000000000000000000000000000000000000000';

export const trim = (stringValue) => stringValue.split(' ').join('');

export const requireAddress = (address) => {
  if (typeof address !== 'string')
    throw new Error('errors.address_is_not_a_string');
  if (address === '') throw new Error('errors.address_is_empty_string');
  if (address.length < EVM_WALLET_ADDRESS_CHAR_NUM) throw new Error('errors.address_too_short');
  if (isEmptyBytes(address)) throw new Error('errors.address_is_empty_bytes');
  if (!new RegExp(/0x[0-9a-fA-F]{40}/).test(address))
    throw new Error('errors.invalid_address');
};

export const validAddressBool = (address) => {
  if (typeof address !== 'string')
    return false;
  if (address === '') return false;
  if (address.length < EVM_WALLET_ADDRESS_CHAR_NUM) return false;
  if (isEmptyBytes(address)) return false;
  if (!new RegExp(/0x[0-9a-fA-F]{40}/).test(address)) return false;
  return true;
};
/**
 * Mock method to simulate async call
 *
 * @param val {any}
 * @param time {Number}
 * @return {Promise<any>}
 */
export const wait = (time = 500, val = true) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(val);
    }, time);
  });

export const shortenAddress = (address) => (`${address.slice(0, 5)}...${address.slice(-5)}`);
export const shortenAddressForMobile = (address) => (`${address.slice(0, 3)}...${address.slice(-5)}`);

export const sortNetworks = (networks, propertyToSortBy, expectedOrder) => networks.sort((a, b) => (expectedOrder.indexOf(a[propertyToSortBy]) - expectedOrder.indexOf(b[propertyToSortBy])));

// round up to the nearest 100 in JavaScript

export const roundUpToTwoDecimals = (num) => Math.ceil(num * 100) / 100;

export const roundUp = (number) => (number > 100 && Math.round(number / 100) * 100);

export const formatLocaleNumber = (number, numDecimals = 8, removeTrailingZeroes = true) => {
  let localedStr = number.toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  });

  if (removeTrailingZeroes) {
    localedStr = localedStr
      .replace(/(\.\d*[1-9])0*$/, '$1') // 123.1200 -> 123.12
      .replace(/\.0*$/, '') // 123.00 -> 123
      .replace(/^0.0+$/, '0'); // 0.00 -> 0
  }

  return localedStr;
};

export const numberWithCommas = (
  x,
  decimals = 8,
  removeTrailingZeroes = true,
  exact = false
) => {
  if (!Number.isFinite(parseFloat(x))) return '0';
  if (parseFloat(x).toString() === '0') return '0';
  let formatted;
  if (exact) {
    const str = `${x}`;
    const strSliced = str.indexOf('.') >= 0
      ? str.slice(0, str.indexOf('.') + decimals + 1)
      : str;
    formatted = parseFloat(strSliced, 10);
    if (x >= 1_000) {
      const parts = Dec(formatted).toFixed(decimals).split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      formatted = parts.join('.');
    } else {
      formatted = String(formatted);
    }
  } else {
    const parts = Dec(x).toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    formatted = parts.join('.');
  }
  if (removeTrailingZeroes) {
    formatted = formatted
      .replace(/(\.\d*[1-9])0*$/, '$1') // 123.1200 -> 123.12
      .replace(/\.0*$/, '') // 123.00 -> 123
      .replace(/^0.0+$/, '0'); // 0.00 -> 0
  }
  return formatted;
};

// used only in ttvl graph
export const formatNumber = (
  _num,
  decimals = 2,
  removeTrailingZeroes = true
) => {
  try {
    if (!Number.isFinite(parseFloat(_num))) return '0';
    const sign = parseFloat(_num) < 0 ? '-' : '';
    const num = Math.abs(parseFloat(_num));
    if (num < 10000)
      return numberWithCommas(_num, decimals, removeTrailingZeroes);

    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    let i;

    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break;
      }
    }

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    return (
      sign +
      Dec(num / si[i].value)
        .toFixed(decimals)
        .replace(rx, '$1') +
      si[i].symbol
    );
  } catch (e) {
    return _num.toString();
  }
};

export const isToValid = async (toAddress, walletAddress) => {
  if (!toAddress || isUndefined(toAddress)) {
    return false;
  }
  if (!parseInt(toAddress, 16) || toAddress === walletAddress) {
    return true;
  }
  try {
    const { data } = await axios('/meta?abi=false&stats=false');
    let returnedVal = false;
    // eslint-disable-next-line array-callback-return
    Object.keys(data.cp_network_ids).map((keyIndex) => {
      // use keyName to get current key's name
      // and a[keyName] to get its value
      if (data.cp_network_ids[keyIndex + 1]?.ChainportMainBridge === toAddress || data.cp_network_ids[keyIndex + 1]?.ChainportSideBridge === toAddress) {
        returnedVal = true;
      }
    });
    return returnedVal;
  } catch (err) {
    console.log(err);
  }
};

export const isTranslateString = (str) =>
  /[\w+]\.[\w+]/gi.test(str) && !/\s+/g.test(str);

export const toSelectFormat = (arr = [], label, value) =>
  arr.map((el) => ({ ...el, label: el[label], value: el[value] })) || [];

export const setIcon = (walletName) => {
  if (walletName === 'MetaMask') return MetamaskIcon;
  if (walletName === 'WalletConnect') return WalletConnectIcon;
  if (walletName === 'Fortmatic') return FortmaticIcon;
  if (walletName === 'Trust') return TrustIcon;
};
export const getConfigFromLoaclStorage = () => {
  const configLS = localStorage.getItem('config') || '{}';
  return JSON.parse(configLS);
};

export const getAppMeta = async () => {
  try {
    const { data } = await axios('/meta?abi=true&stats=true');
    const contractsData = await axios('/api/meta');
    const rpcsAmount = window.CONFIG?.networks.length; // check here how many rpc we have for the for loop
    for (let i = 1; i <= rpcsAmount; i += 1) {
      // for each iteration we will keep both objects netwroks array in alignment (same rpc in each)
      const metaCurrent = data.cp_network_ids[i];
      const metaApiCurrent = contractsData.data?.api_meta_info[metaCurrent?.name];
      // null check
      if (metaCurrent && metaApiCurrent) {
        // iterate over the api to make sure all the contracts are aligned
        metaApiCurrent.map((item) => {
          // null check (if one of them is null, maintance should not trigger yet! only if 2 different contracts exists)
          if (metaCurrent?.bridges.ChainportSideBridge && item?.contract_address && metaCurrent?.bridges.ChainportMainBridge) {
            // check sidechain if exists
            if (item.bridge_type === 'sidechain') {
              if (metaCurrent?.bridges.ChainportSideBridge !== item?.contract_address) {
                data.maintenance = true;
                return;
              }
            }
            // check mainchain if exists
            if (item.bridge_type === 'mainchain') {
              if (metaCurrent?.bridges.ChainportMainBridge !== item?.contract_address) {
                data.maintenance = true;
                return;
              }
            }
          }
          return null;
        });
      }
    }

    const { maintenance, tvl, stats, native_coins_usd_values: nativeCoinsUsdValues, sorted_networks: sortedNetworks, fee_manager, perpetual_contract, portx_poly_address } = data;
    const abis = Object.keys(data.abis).reduce((acc, cur) => {
      acc[cur] = JSON.parse(data.abis[cur]);
      return acc;
    }, {});

    const networksById = {};
    const networksByChain = {};
    const networksByName = {};

    // remove .test when BE removes it
    const newNetworks = Object.values(data.cp_network_ids);
    sortNetworks(newNetworks, 'name', sortedNetworks);
    const networks = newNetworks.map((net) => ({
      ...net,
      // networkId: it is passed to unified header
      networkId: net.chain_id,
      chainId: net.chain_id,
      value: net.rpc,
      label: net.label,
      rpcUrl: net.rpc,
      icon: net.network_icon,
      id: net.chainport_network_id,
      blockchainType: net.blockchain_type,
    }));

    networks.forEach((network) => {
      networksById[network.id] = network;
      networksByChain[network.chain_id] = network;
      networksByName[network.name] = network;
    });
    const general = {
      networksByName,
      networksById,
      networksByChain,
      networks,
      tvl,
      fee_manager,
      perpetual_contract,
      portx_poly_address,
      stats,
      nativeCoinsUsdValues,
      sortedNetworks,
      ...generalInitialState,
    };

    window.CONFIG = { ...general, abis, maintenance };
    const config = getConfigFromLoaclStorage();
    try {
      localStorage.setItem('config', JSON.stringify({ general, abis, maintenance }));
      if (config.maintenance !== maintenance) {
        window.location.reload();
      }
    } catch (err) {
      localStorage.setItem('config', JSON.stringify({ general, abis, maintenance }));
      window.location.reload();
    }
    return ({ general, abis });
  } catch (err) {
    const config = getConfigFromLoaclStorage();
    const overrideConfig = { ...config, maintenance: true };
    localStorage.setItem('config', JSON.stringify(overrideConfig));
    console.log(err);
    return overrideConfig;
  }
};

export const getNetworkErrorMessage = (targetNetworkId) => {
  if (!targetNetworkId) {
    return false;
  }
  const targetNetwork = window.CONFIG.networksById[targetNetworkId];
  const chainId = window.CONFIG.networksById[targetNetworkId].chain_id;
  return translate('modal.pls_switch', { targetNetwork: targetNetwork.label + nameOfNetwork(
    chainId
  ) });
};

export const isObjectEmpty = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const isValidNetworkName = (networkName, networksByName) => {
  if (!networkName) return false;

  if (Object.prototype.hasOwnProperty.call(networksByName, networkName.toUpperCase())) {
    return true;
  }

  toast.error(`ChainPort doesn't recognize "${networkName}" network`);
  return false;
};

export const hasProperNetworkName = (token, networkName) => {
  if (token?.network_name === networkName) {
    return true;
  }

  toast.error('You passed token address from different chain');
};

const ABBREVIATIONS = ['BSC', 'ETH', 'POLY'];

export const capitalizeString = (string = '') => {
  if (ABBREVIATIONS.includes(string)) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getBlockConfirmations = async (txHash, id) => {
  try {
    const resp = await axios.post('/transaction', {
      tx_hash: txHash,
      network_id: id,
    });
    return resp.data.n_confirmations;
  } catch (err) {
    if (err.response) {
      // The request was made and the server responded with a status code
      console.log(err.response.data);
      console.log(err.response.status);
    } else if (err.request) {
      // The request was made but no response was received
      console.log(err.request);
    } else {
      // Something happened in setting up the request that triggered an err
      console.log('err', err.message);
    }
  }
};

export const setExpirationInLS = (key) => {
  const currentDate = new Date().getTime().toString();
  const storedObj = {
    timestamp: currentDate,
  };
  localStorage.setItem(key, JSON.stringify(storedObj));
};

export const checkExpiryInLocalStorage = (key, EXPIRATION_DURATION, isModalClosed = 'true') => {
  const objectFromLocalStorage = JSON.parse(localStorage.getItem(key));
  const prevDateFromLocalStorage = objectFromLocalStorage?.timestamp;
  const currentDate = new Date().getTime().toString();
  if (!prevDateFromLocalStorage || currentDate - prevDateFromLocalStorage > EXPIRATION_DURATION) {
    const storedObj = {
      timestamp: currentDate,
    };
    if (isModalClosed) {
      localStorage.setItem(key, JSON.stringify(storedObj));
    }

    // expired
    return true;
  }
  return false;
};

export const showModalBasedOnLocalStorage = (key, expirationDuration, isModalClosed) => {
  if (isModalClosed || localStorage.getItem(key)) {
    return checkExpiryInLocalStorage(key, expirationDuration, isModalClosed);
  } return true;
};

export const labelFormatter = (t) => new Date(t).toLocaleString();

export const tvlFormatter = (value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);

export const formatDateShort = (dateToConvert) => {
  const options = { year: 'numeric', month: 'long', day: '2-digit' };
  const localDate = new Date(dateToConvert).toLocaleDateString('en-US', options);
  return localDate.replaceAll('/', '.');
};

export const formatDateShortEpoch = (dateToConvertEpoch) => {
  const options = { year: 'numeric', month: 'long', day: '2-digit' };
  const localDate = new Date(parseInt(dateToConvertEpoch, 10) * 1000).toLocaleDateString('en-US', options);
  return localDate.replaceAll('/', '.');
};

export const getDurationDatesUnix = (start, end) => {
  const startDate = new Date(parseInt(start, 10) * 1000);
  const endDate = new Date(parseInt(end, 10) * 1000);
  // To calculate the time difference of two dates
  const diffTime = endDate.getTime() - startDate.getTime();
  // To calculate the no. of days between two dates
  return Math.round(diffTime / (1000 * 3600 * 24));
};

export const calculateWithRateToFixed = (coinRate, processingPortFee) => (coinRate * processingPortFee).toFixed(2);

// custom num formatter (only for stats page currently)
export const millionFormatter = (num, decimals = 0) => {
  if (num < MILLION) {
    return numberWithCommas(num, decimals);
  }
  if (num < BILLION) {
    const newNum = num / MILLION;
    return `${numberWithCommas(newNum, 2)}M`;
  }
  if (num < TRILLION) {
    const newNum = num / BILLION;
    return `${numberWithCommas(newNum, 3)}B`;
  }
  if (num < QUADRILLION) {
    const newNum = num / TRILLION;
    return `${numberWithCommas(newNum, 3)}T`;
  }
  const newNum = num / QUADRILLION;
  return `${numberWithCommas(newNum, 3)}P`;
};

export const shortenName = (name) => `${name.slice(0, 15)}...`;

// current number format standard in the app
export const numFormatter = (num, digits = 2) => {
  if ((num && num.toString() === '0') || !num) {
    return '0';
  }
  const numArray = num?.toString().split('.');
  const isNumNeedDigits = numArray[1]?.startsWith('00');

  if (num < MILLION && isNumNeedDigits && num > 0.00001) {
    const zeroAmount = numArray[1]?.match('(0+)*');
    if (zeroAmount[0]?.length >= 6) {
      return numberWithCommas(num, 2, true);
    }
    return numberWithCommas(num, (zeroAmount[0].length + 1), true);
  }
  if (num < MILLION) {
    return numberWithCommas(num, digits, true);
  }
  if (num < BILLION) {
    const newNum = num / MILLION;
    return `${numberWithCommas(newNum, 2)}M`;
  }
  if (num < TRILLION) {
    const newNum = num / BILLION;
    return `${numberWithCommas(newNum, 3)}B`;
  }
  if (num < QUADRILLION) {
    const newNum = num / TRILLION;
    return `${numberWithCommas(newNum, 3)}T`;
  }
  if (num < QUINTILLION) {
    const newNum = num / QUADRILLION;
    return `${numberWithCommas(newNum, 3)}Q`;
  }
  const newNum = num / QUADRILLION;
  return `${numberWithCommas(newNum, 3)}P`;
};

// use this only if u need custom digits to display (num foramtter shows only 2 after the dot)
export const customDigitFormatter = (num, digits) => {
  if ((num && num.toString() === '0') || !num || !digits) {
    return '0';
  }
  const returnedNum = Number(num, 10).toFixed(digits + 2).slice(0, (-digits + 2));
  const splitNum = returnedNum.toString().split('.');
  if (Array.from(new Set(splitNum[1])).toString() === '0') {
    return numberWithCommas(splitNum[0]);
  }
  return numberWithCommas(returnedNum);
};

export const formatCurrency = (num) => `$${formatNumber(num)}`;

export const localNetwork = parseInt(localStorage.getItem('localNetwork'), 10);

export const useQuery = () => new URLSearchParams(window.location.search);

export const retry = (fn, attempts = 1, intervalMS = 0) => new Promise((resolve, reject) => {
  let curr = 0;

  const tick = async () => {
    const retval = await fn();
    if (retval) {
      return resolve(retval);
    }
    if (curr < attempts) {
      curr += 1;
      setTimeout(tick, intervalMS);
    } else {
      reject(new Error('retry function did not resolve'));
    }
  };
  tick();
});

export const validateWhiteListedAddress = async (walletAddress, tokenAddress, networkId, tokenAmount) => {
  try {
    const resp = await axios('/check_whitelist_address', { params: {
      address: walletAddress,
      token_address: tokenAddress,
      network_id: networkId,
      token_amount: tokenAmount } });
    if (!resp.data.success) {
      toast.error(resp.data.message);
      return false;
    }
    return true;
  } catch (err) {
    toast.error(err.message);
    throw err;
  }
};

export const validateAddress = async (walletAddress, blockchainType) => {
  try {
    axiosRetry(axios, {
      retries: 3, // number of retries
      retryDelay: (retryCount) => retryCount * 2000
    });
    const resp = await axios('/check_address', { params: { address: walletAddress, blockchain_type: blockchainType

    } });
    return resp.data.valid;
  } catch (err) {
    console.log(err);
  }
};

export const convertStringToHex = (str) => {
  let result = '';
  for (let i = 0; i < str.length; i += 1) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
};

export const stakingContract = () => window.CONFIG.fee_manager;
export const CARDANO_WALLET_ADDRESS_CHAR = config.environment === 'production' ? 103 : 108;
// export const calcAPY = (rewardPerSecond, totalStakeAmount) => {
//   const yearInSeconds = 60 * 60 * 24 * 365;
//   const anualRewards = rewardPerSecond * yearInSeconds; // each block is 1 seconds
//   return (anualRewards / totalStakeAmount) * 100;
// };

export const calcAPY = (rewardPerSecond, totalStakeAmount) => {
  const yearInSeconds = 60 * 60 * 24 * 365;
  const anualRewards = rewardPerSecond * yearInSeconds; // each block is 1 seconds
  return (anualRewards / totalStakeAmount) * 100;
};

export const polyExplorer = (txHash) => {
  if (config.environment.toLocaleLowerCase() === 'production') {
    return `https://polygonscan.com/tx/${txHash}`;
  }

  return `https://mumbai.polygonscan.com/tx/${txHash}`;
};

export const twoDigits = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  return num;
};

export const validateCardanoAddress = (address) => {
  const regexExpr = config.environment === 'production' ? /addr1[a-z0-9]+/ : /addr_test1[a-z0-9]+/;

  if (new RegExp(regexExpr).test(address)) {
    return true;
  }
  return false;
};

export const urlToPassPortx = (networksByName, isPortxStaked, baseNetwork) => {
  const URLS = {
    [networksByName.ETHEREUM?.id]: 'https://app.uniswap.org/#/swap?inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0x104f3152d8ebfc3f679392977356962ff36566ac',
    [networksByName.BSC?.id]: 'https://pancakeswap.finance/swap?outputCurrency=0x54c3b88b7e9702f915ddc6e483aaf369b2615f8d&chainId=56',
    [networksByName.POLYGON?.id]: 'https://app.uniswap.org/#/swap?inputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&outputCurrency=0x189586b5f6317538ae50c20a976597da38984a24',
    [networksByName.FANTOM?.id]: 'https://spooky.fi/#/swap?outputCurrency=0x504ec4f9af7bbf8cad73ccc2121a3a7fb4c81bcf',
  };
  if (isPortxStaked) {
    return '/stakeportx';
  }
  if (URLS[baseNetwork?.id]) {
    return URLS[baseNetwork?.id];
  }
  return 'https://www.chainport.io/token';
};
