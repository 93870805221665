import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import translate from '../../services/translate/translate';
import S from './styled';
import { GET_TO_KNOW_CAROUSEL_TEXT, GET_TO_KNOW_MODAL_EXPIRATION_DURATION } from '../../constants/general';
import { ReactComponent as ArrowSvg } from '../../assets/down-arrow.svg';
import WalletSelectors from '../../redux/selectors/walletSelectors';
import CloseIcon from '../../components/Icons/CloseIcon';
import { showModalBasedOnLocalStorage } from '../../services/utilsService';

const GetToKnowModal = ({ isWalletConnected }) => {
  const [isModalClosed, setCloseModal] = useState(false);

  const handleCloseGetToKnowModal = () => {
    setCloseModal(true);
  };

  const showModalBasedOnLS = showModalBasedOnLocalStorage('getToKnowModal', GET_TO_KNOW_MODAL_EXPIRATION_DURATION, isModalClosed);

  return (
    <>{!isWalletConnected && !isModalClosed && showModalBasedOnLS ?
      <S.Wrapper>
        <S.CloseIconWrapper>
          <S.Header> {translate('modal.get_to_know')} </S.Header>
          <div onClick={handleCloseGetToKnowModal}> <CloseIcon size={11} /></div>
        </S.CloseIconWrapper>

        <Carousel
          className="carousel"
          showIndicators={false}
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          renderArrowPrev={(clickHandler, hasPrev) =>
            hasPrev && (
              <div onClick={clickHandler} className="control-arrow control-prev">
                <div className="prev-arrow-container"><ArrowSvg /></div>
              </div>)}
          renderArrowNext={(clickHandler, hasNext) =>
            hasNext && (
              <div onClick={clickHandler} className="control-arrow control-next">
                <div className="next-arrow-container"> <ArrowSvg /></div>

              </div>)}
        >

          {GET_TO_KNOW_CAROUSEL_TEXT && GET_TO_KNOW_CAROUSEL_TEXT.map((el) => (
            <div className="get-to-know-text" key={el.id}> {el.text}</div>
          ))}
        </Carousel>
      </S.Wrapper>
      : null}
    </>);
};

GetToKnowModal.propTypes = {
  isWalletConnected: PropTypes.bool,

};

const mapStateToProps = (state) => ({
  isWalletConnected: WalletSelectors.isWalletConnected(state),
});

export default connect(mapStateToProps, null)(GetToKnowModal);
