import styled from 'styled-components';

const VerifyBannerWrapper = styled.div`
position: fixed;
    top: 0;
    height: 40px;
    width: 100%;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    gap: 15px;
    
background-color:${(props) => props.theme.dark_green};

    @media (max-width: 632px) {
      flex-direction: column;
      height:94px;
      position: fixed;
    }

`;

const CloseIconWrapper = styled.div`
position: absolute;
padding-right: 29px;
  right: 0;


  @media (max-width: 632px) {
    right: 0;
    padding-right: 24px;

    bottom:65px;
  }


`;
const UrlBanner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:30px;
    background-color: ${(props) => props.theme.dark_green1};
    width:206px;

    border-radius: 80px;

    `;

const BannerText = styled.div`
color: ${(props) => props.theme.white};
@media (max-width: 632px) {
 width:206px;
  text-align: center;
  line-height: 22px;
  margin-bottom:-10px;
 
}


`;

const UrlBannerText = styled.span`
color: ${(props) => props.theme.aqua_bg};
font-weight: 500;
padding-left:17px;
`;

const S = {
  VerifyBannerWrapper,
  BannerText,
  UrlBanner,
  UrlBannerText,
  CloseIconWrapper
};

export default S;
