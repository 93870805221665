import styled, { css } from 'styled-components';
import colors from '../../../../styles/colors';

const SwapAmount = styled.div`
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  input.dark {
    padding: 0 63px 0 20px;
    font-weight: 500;
    font-size: 18px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${({ theme }) => theme.isMobile && css`
  margin-bottom: 20px;
  `}
`;

const MaxAmountBtn = styled.div`
  text-transform: uppercase;
  text-decoration: underline;
  font: normal normal normal 16px/19px Roboto;
  position: absolute;
  bottom: 12px;
  right: 20px;
  cursor: pointer;
  color: ${colors.gray_light};
  -webkit-transition: all .35s ease;
  transition: all .35s ease;

  &:hover {
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    color: ${colors.aqua_bg};
  }
`;

const S = {
  SwapAmount,
  MaxAmountBtn,
};

export default S;
