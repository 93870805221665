import React, { useCallback } from 'react';
import _noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';
import Spinner from '../../Spinner';

/*                       ACTIONS                           */
import { withdrawStakedTokens, getUserStakedBalance } from '../../../redux/actions/stakeActions';
import usePortxBalances from '../../../hooks/usePortxBalances';
import generalSelectors from '../../../redux/selectors/generalSelectors';
import useTransactionFee from '../../../hooks/useTransactionFee';
import stakeSelectors from '../../../redux/selectors/stakeSelectors';
import bridgeSelectors from '../../../redux/selectors/bridgeSelectors';
import transactionSelectors from '../../../redux/selectors/transactionSelectors';
import walletSelectors from '../../../redux/selectors/walletSelectors';
import { numFormatter } from '../../../services/utilsService';

const BUTTON_OPTIONS = {
  WithdrawLoading: 'WithdrawLoading',
  UnstakingNoAmount: 'UnstakingNoAmount',
  Unstaking: 'Unstaking',
  WaitForWalletConfirmation: 'WaitForWalletConfirmation',
  UnstakingLoadingState: 'UnstakingLoadingState',
  IsMaxAmoutExceed: 'IsMaxAmoutExceed',
  NotEnoughGas: 'NotEnoughGas',
};

const useButtonOption = () => {
  const dispatch = useDispatch();
  const [{ data: portxBalance }] = usePortxBalances();
  const ethTransactionFee = useTransactionFee(21000, 35);

  const walletAddress = useSelector(walletSelectors.walletAddress);
  const baseNetwork = useSelector(bridgeSelectors.baseNetwork);
  const txHash = useSelector(transactionSelectors.txHash);
  const swapAmount = useSelector(bridgeSelectors.swapAmount);
  const withdrawStakedLoading = useSelector(stakeSelectors.withdrawStakedLoading);
  const withdrawLoading = useSelector(generalSelectors.withdrawLoading);
  const networksByName = useSelector(generalSelectors.networksByName);

  const polyNet = networksByName.POLYGON;

  const handleWithdrawStakedTokens = useCallback(async () => {
    if (baseNetwork?.name !== polyNet?.name) {
      return toast.error('Unstaking only available on Polygon network');
    }
    const currentAmount = await dispatch(getUserStakedBalance(walletAddress, baseNetwork));
    if ((parseFloat(currentAmount, 10)) < swapAmount) {
      return toast.error('Amount to withdraw exceed balance');
    }
    dispatch(withdrawStakedTokens());
  }, [baseNetwork, dispatch, polyNet?.name, swapAmount, walletAddress]);

  const buttonOptions = {
    [BUTTON_OPTIONS.WithdrawLoading]: {
      text: 'Another Tx behing processed',
      action: _noop,
      state: 'WithdrawLoading',
      disabled: true,
      className: 'primary w-100 action opacity5',
    },
    [BUTTON_OPTIONS.UnstakingNoAmount]: {
      text: 'Enter an amount',
      action: _noop,
      state: 'UnstakingNoAmount',
      disabled: true,
      className: 'primary w-100 action opacity5',
    },
    [BUTTON_OPTIONS.Unstaking]: {
      text: `Unstake ${numFormatter(swapAmount)} PORTX`,
      action: () => { handleWithdrawStakedTokens(); },
      state: 'Unstaking',
      disabled: false,
      className: 'primary w-100 action',
    },
    [BUTTON_OPTIONS.WaitForWalletConfirmation]: {
      text: <><Spinner />Confirm Tx in your wallet</>,
      action: _noop,
      state: 'WaitForWalletConfirmation',
      disabled: true,
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.UnstakingLoadingState]: {
      text: <><Spinner />Unstaking in process</>,
      action: _noop,
      state: 'UnstakingLoadingState',
      disabled: true,
      className: 'primary w-100 action opacity7',
    },
    [BUTTON_OPTIONS.IsMaxAmoutExceed]: {
      text: 'Amount exceed balance',
      action: _noop,
      state: 'IsMaxAmoutExceed',
      disabled: true,
      className: 'primary w-100 action opacity5',
    },
    [BUTTON_OPTIONS.NotEnoughGas]: {
      text: `Unstake ${numFormatter(swapAmount)} PORTX`,
      action: _noop,
      state: 'NotEnoughGas',
      disabled: true,
      className: 'primary w-100 action opacity5',
    },
  };

  const getButtonOption = () => {
    if (withdrawLoading) {
      return BUTTON_OPTIONS.WithdrawLoading;
    }
    if (portxBalance?.portxStakedConverted < swapAmount) {
      return BUTTON_OPTIONS.IsMaxAmoutExceed;
    }
    if (withdrawStakedLoading?.unstaking && txHash) {
      return BUTTON_OPTIONS.UnstakingLoadingState;
    }
    if (!txHash && withdrawStakedLoading.unstaking) {
      return BUTTON_OPTIONS.WaitForWalletConfirmation;
    }
    if (!swapAmount) {
      return BUTTON_OPTIONS.UnstakingNoAmount;
    }
    if (!ethTransactionFee?.isEnoughBalance && !txHash) {
      return BUTTON_OPTIONS.NotEnoughGas;
    }
    return BUTTON_OPTIONS.Unstaking;
  };

  return buttonOptions[getButtonOption()];
};

export default useButtonOption;
