import React from 'react';
import PropTypes from 'prop-types';
import S from './styled';

const Label = ({ id, label }) => (
  <S.Label htmlFor={id}>{label}</S.Label>

);

export default Label;

Label.propTypes = {
  label: PropTypes.node,
  id: PropTypes.string,
};
