import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ReactComponent as VerifiedSVG } from '../../../../assets/token-verified.svg';
import { ReactComponent as UnverifiedSVG } from '../../../../assets/token-unverified.svg';
import { ReactComponent as RedirectSVG } from '../../../../assets/redirect.svg';
import { BLACKLISTED_TOKEN } from '../../../Toasts/toastMessages';
import ToastBlock, { toastOptions } from '../../../Toasts/ToastBlock';
import { shortenAddress, shortenAddressForMobile } from '../../../../services/utilsService';
import { DIFFERENT_TOKEN_URL } from '../../../../constants/general';

import S from '../styled';
import generalSelectors from '../../../../redux/selectors/generalSelectors';

const Token = ({
  onTokenSelect,
  token,
  balance,
  isMobile,
  blackListedTokens,
  isLiquidityToken,
}) => {
  const networksByName = useSelector(generalSelectors.networksByName);
  const onClick = useCallback(() => {
    if (blackListedTokens.includes(token?.web3_address)) {
      toast(<ToastBlock content={BLACKLISTED_TOKEN} />, toastOptions);
    } else {
      onTokenSelect(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTokenSelect, token, balance]);

  const onAddressClick = useCallback(
    (e) => {
      e.stopPropagation();
      window.open(
        `${networksByName[token.network_name]?.explorer_url}${DIFFERENT_TOKEN_URL[token?.network_name.toLowerCase()] ? 'address/' : 'token/'}${
          token.web3_address
        }`,
        '_blank',
        'noopener, noreferrer'
      );
    },
    [token, networksByName]
  );

  const tokenBadge = useMemo(() => {
    if (isLiquidityToken) {
      return <VerifiedSVG className="token-verified" />;
    } if (blackListedTokens.includes(token?.web3_address)) {
      return <UnverifiedSVG className="token-unverified" />;
    }
    return null;
  }, [isLiquidityToken, blackListedTokens, token?.web3_address]);

  return (
    <S.Token className="token-list-item" key={Math.random()} onClick={onClick} blacklisted={blackListedTokens.includes(token.web3_address)}>
      <S.TokenImage background={token.token_image}>
        {tokenBadge}
      </S.TokenImage>
      <div className="token-info">
        <div className="topography head white">{token.symbol}</div>
        <div className={token.name.length < 25 ? 'overflow-visible' : null}>{token.name}</div>
      </div>
      <div
        className={clsx('topography token-link', { mobile: isMobile })}
        onClick={onAddressClick}
      >
        {isMobile ? (
          shortenAddressForMobile(token.web3_address)
        ) : (
          shortenAddress(token.web3_address)
        )}
        <RedirectSVG />
      </div>
    </S.Token>

  );
};

Token.propTypes = {
  onTokenSelect: PropTypes.func.isRequired,
  token: PropTypes.shape({
    network_name: PropTypes.string,
    token_image: PropTypes.string,
    symbol: PropTypes.string,
    name: PropTypes.string,
    web3_address: PropTypes.string,
    blacklisted_token: PropTypes.bool,
  }).isRequired,
  balance: PropTypes.string,
  isMobile: PropTypes.bool,
  blackListedTokens: PropTypes.array,
  isLiquidityToken: PropTypes.bool,

};

export default Token;
