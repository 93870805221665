import React from 'react';

const TrustIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="373.333"
    height="373.333"
    version="1"
    viewBox="0 0 280 280"
  >
    <path
      fill="#3476BB"
      d="M1340 2753c-8-4-33-20-55-38-109-86-242-148-441-209-87-27-233-46-389-53-88-3-164-11-183-19-60-24-62-42-51-390 15-493 39-734 99-999 26-116 41-158 104-285 45-92 63-116 146-200 52-52 104-102 115-110 42-32 188-121 302-185 65-37 132-75 148-85 17-11 48-29 69-41 21-11 44-25 50-30s31-23 56-39c73-48 98-43 222 42 15 10 29 18 32 18 2 0 33 18 67 40 35 22 80 47 99 57 19 9 37 20 40 24 3 3 23 14 45 25 22 10 42 21 45 24 6 7 130 80 136 80 3 0 10 4 17 10 7 5 39 30 72 54 182 133 291 291 356 516 76 263 127 763 128 1261l1 165-30 29c-29 28-31 28-198 37-297 14-448 51-691 166-38 17-138 83-186 120-27 22-89 29-125 15zm110-258c208-134 486-222 780-246 74-6 136-12 137-13 3-2-25-577-32-656-24-278-72-528-128-660-88-211-164-278-562-503-44-24-118-68-165-97l-85-52-35 19c-56 32-314 182-330 193-8 5-48 28-88 51-128 72-242 172-303 264-124 187-195 595-215 1233l-7 212h24c174 1 494 64 642 127 71 30 249 123 275 144 14 10 28 19 31 19 4 0 31-16 61-35z"
      transform="matrix(.1 0 0 -.1 0 280)"
    />
  </svg>
);

export default TrustIcon;
